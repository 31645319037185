import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { useTokenPrice } from '../useTokenPrice'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { useGalaxyStakingPoolContract, IAddressOrAddressMap } from './useContract'
import useSecondPerBlock from '../useSecondPerBlock'
import BigNumberJs from 'bignumber.js'

export function useInvestBoostAPR(galaxyAddress?: IAddressOrAddressMap, staker?: string, poolId?: number): number {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyAddress)
  const blockNumber = useBlockNumber()
  const naosPrice = useTokenPrice('NAOS')

  const secondPerBlock = useSecondPerBlock()
  const [depositAmount, setDepositAmount] = useState(BigNumber.from(0))
  const [depositWeight, setDepositWeight] = useState(BigNumber.from(0))
  const [poolTotalDepositWeight, setPoolTotalDepositWeight] = useState(BigNumber.from(0))
  const [poolRewardRate, setPoolRewardRate] = useState(BigNumber.from(0))

  useEffect(() => {
    if (galaxyContract && staker && poolId !== undefined) {
      galaxyContract.getStakeTotalDeposited(staker, poolId).then(setDepositAmount)
      galaxyContract.getStakeTotalDepositedWeight(staker, poolId).then(setDepositWeight)
      galaxyContract.getPoolTotalDepositedWeight(poolId).then(setPoolTotalDepositWeight)
      galaxyContract.getPoolRewardRate(poolId).then(setPoolRewardRate)
    }
  }, [galaxyContract, staker, poolId, blockNumber])

  return useMemo(() => {
    // if (poolTotalDepositWeight.gt(0) && depositAmount.gt(0) && secondPerBlock > 0) {
    //   const depositWeightNum = depositWeight.mul(1000).div(parseUnits('1', 18)).toNumber()
    //   const poolTotalDepositWeightNum = poolTotalDepositWeight.mul(1000).div(parseUnits('1', 18)).toNumber()
    //   const poolRewardRateJS = new BigNumberJs(poolRewardRate.mul(86400 * 365).toString())

    //   // .div(parseUnits('1', 18))

    //   console.log('poolRewardRate: ', poolRewardRate.toString())
    //   console.log('poolRewardRateNum: ', poolRewardRateNum.toString())
    //   const weightAvg = depositWeightNum / poolTotalDepositWeightNum
    //   const yearPoolRewardRate = poolRewardRateNum.toNumber() / secondPerBlock
    //   const divisor = depositAmount.div(parseUnits('1', 18)).toNumber()

    //   return (weightAvg * yearPoolRewardRate * naosPrice) / divisor
    // } else {
    //   return 0
    // }
    return 0
  }, [depositWeight, depositAmount, poolTotalDepositWeight, poolRewardRate, naosPrice, secondPerBlock])
}
