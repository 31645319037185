import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useGalaxyStakingPoolContract, IAddressOrAddressMap, useBoostPoolContract } from './useContract'
import { formatEther } from 'ethers/lib/utils'
import BigNumberJs from 'bignumber.js'
import { ChainId, SEC_PER_BLOCK_CHAIN } from '../../constants/blockchain'
import { useTokenPrice } from '../useTokenPrice'
import { useMulticallContract } from './useContract'
import ADDRESS from '../../constants/addresses'
import multicall from '../../utils/multicall'

const calcData = async (
  chainId: number,
  naosPrice: number,
  galaxyContract: any,
  constData: Record<string, any>,
  inputAmount: BigNumber
) => {
  const inputs = new BigNumberJs(formatEther(inputAmount))
  const newUserWeight = new BigNumberJs(
    formatEther(
      await galaxyContract.calcUserWeight(
        constData.bigNumber_poolDeposited.add(inputAmount),
        constData.bigNumber_userDeposited.add(inputAmount),
        constData.bigNumber_boostPoolDepositedWeight,
        constData.bigNumber_boostUserDepositedWeight
      )
    )
  )

  const newTotalWeight = constData.poolDepositedWeight.minus(constData.userDepositedWeight).plus(newUserWeight)
  const share = newUserWeight.div(newTotalWeight)
  const rewardPerSec = constData.rewardRate.div(SEC_PER_BLOCK_CHAIN[chainId])
  const newNAOS_APR = share
    .multipliedBy(rewardPerSec)
    .multipliedBy(60)
    .multipliedBy(60)
    .multipliedBy(24)
    .multipliedBy(365)
    .multipliedBy(naosPrice)
    .multipliedBy(100)
    .div(constData.userDeposited.plus(inputs))

  const newRatio = newUserWeight.div(constData.userDeposited.plus(inputs))

  return {
    newNAOS_APR,
    newRatio,
  }
}

export function useGetConstData(
  galaxyPoolAddress: IAddressOrAddressMap,
  boostAddres: IAddressOrAddressMap,
  staker: string | undefined
): {
  bigNumber_poolDeposited: BigNumber
  bigNumber_userDeposited: BigNumber
  bigNumber_boostPoolDepositedWeight: BigNumber
  bigNumber_boostUserDepositedWeight: BigNumber
  userDeposited: BigNumberJs
  poolDepositedWeight: BigNumberJs
  userDepositedWeight: BigNumberJs
  rewardRate: BigNumberJs
} {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyPoolAddress)
  const boostContract = useBoostPoolContract(boostAddres)
  const [constData, setConstData] = useState<{
    bigNumber_poolDeposited: BigNumber
    bigNumber_userDeposited: BigNumber
    bigNumber_boostPoolDepositedWeight: BigNumber
    bigNumber_boostUserDepositedWeight: BigNumber
    userDeposited: BigNumberJs
    poolDepositedWeight: BigNumberJs
    userDepositedWeight: BigNumberJs
    rewardRate: BigNumberJs
  }>({
    bigNumber_poolDeposited: BigNumber.from(0),
    bigNumber_userDeposited: BigNumber.from(0),
    bigNumber_boostPoolDepositedWeight: BigNumber.from(0),
    bigNumber_boostUserDepositedWeight: BigNumber.from(0),
    userDeposited: new BigNumberJs(0),
    poolDepositedWeight: new BigNumberJs(0),
    userDepositedWeight: new BigNumberJs(0),
    rewardRate: new BigNumberJs(0),
  })
  const multicallContract = useMulticallContract(ADDRESS.MULTICALL2, true)

  useEffect(() => {
    if (staker && galaxyContract && boostContract && multicallContract) {
      const calls = [
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getPoolTotalDeposited',
          value: [0],
        },
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getStakeTotalDeposited',
          value: [staker, 0],
        },
        {
          itf: boostContract.interface,
          address: boostContract.address as string,
          function: 'getPoolTotalDepositedWeight',
          value: [],
        },
        {
          itf: boostContract.interface,
          address: boostContract.address as string,
          function: 'getStakeTotalDepositedWeight',
          value: [staker],
        },
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getPoolTotalDepositedWeight',
          value: [0],
        },
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getStakeTotalDepositedWeight',
          value: [staker, 0],
        },
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getPoolRewardRate',
          value: [0],
        },
      ]

      multicall(multicallContract, calls)
        .then((res) => {
          setConstData({
            bigNumber_poolDeposited: res[0][0],
            bigNumber_userDeposited: res[1][0],
            bigNumber_boostPoolDepositedWeight: res[2][0],
            bigNumber_boostUserDepositedWeight: res[3][0],
            userDeposited: new BigNumberJs(formatEther(res[1][0])),
            poolDepositedWeight: new BigNumberJs(formatEther(res[4][0])),
            userDepositedWeight: new BigNumberJs(formatEther(res[5][0])),
            rewardRate: new BigNumberJs(formatEther(res[6][0])),
          })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [staker, multicallContract])

  return useMemo((): {
    bigNumber_poolDeposited: BigNumber
    bigNumber_userDeposited: BigNumber
    bigNumber_boostPoolDepositedWeight: BigNumber
    bigNumber_boostUserDepositedWeight: BigNumber
    userDeposited: BigNumberJs
    poolDepositedWeight: BigNumberJs
    userDepositedWeight: BigNumberJs
    rewardRate: BigNumberJs
  } => {
    return constData
  }, [constData])
}

export function useRebalancedAlphaRewards(
  chainId: number,
  galaxyPoolAddress: IAddressOrAddressMap,
  staker: string | undefined,
  inputAmount: BigNumber,
  currentAPR: number,
  currentBoost: number,
  constData: Record<string, any>
): number[] {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyPoolAddress)
  const naosPrice = useTokenPrice('NAOS')

  const [apr, setAPR] = useState(currentAPR)
  const [ratio, setRatio] = useState(currentBoost)

  useEffect(() => {
    if (chainId && naosPrice && galaxyContract && constData && inputAmount && inputAmount.gte(0) && staker) {
      calcData(chainId, naosPrice, galaxyContract, constData, inputAmount)
        .then((data) => {
          setAPR(Number(data.newNAOS_APR))
          setRatio(Number(data.newRatio) * 2.5)
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setAPR(currentAPR)
      setRatio(currentBoost)
    }
  }, [naosPrice, chainId, inputAmount, constData, staker])

  return useMemo((): number[] => {
    return [apr, ratio]
  }, [apr, ratio])
}
