import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { ChainId, SEC_PER_BLOCK } from '../constants/blockchain'

export function useSecondPerBlock() {
  const { chainId } = useWeb3React()
  return useMemo(() => {
    switch (chainId) {
      case ChainId.BSC:
      case ChainId.BSC_TEST:
        return SEC_PER_BLOCK.BSC
      default:
        return SEC_PER_BLOCK.ETH
    }
  }, [chainId])
}

export default useSecondPerBlock
