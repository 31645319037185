import styled from 'styled-components'
import { useActivePopups } from '../../redux/application/hooks'
import PopupItem from './PopupItem'

const FixedPopupWindow = styled.div`
  position: fixed;
  top: 112px;
  right: 5rem;
  max-width: 355px !important;
  width: 100%;
  z-index: 3000;

  @media (max-width: 500px) {
    left: 0;
    right: 0;
    top: 20px;
    margin: 0 auto;
  }
`

const Popups = () => {
  const activePopups = useActivePopups()

  return (
    <FixedPopupWindow>
      {activePopups.map((popup) => (
        <PopupItem key={popup.key} content={popup.content} popKey={popup.key} removeAfterMs={popup.removeAfterMs} />
      ))}
    </FixedPopupWindow>
  )
}

export default Popups
