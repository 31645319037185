import { createTheme } from '@material-ui/core'

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: '#1D77FF',
      main: '#1D77FF',
      dark: '#1D77FF',
    },
    secondary: {
      light: '#B0D0FF',
      main: '#B0D0FF',
      dark: '#B0D0FF',
    },
    info: {
      light: '#238888',
      main: '#238888',
      dark: '#238888',
    },
    text: {
      primary: '#ffffff',
      hint: 'rgba(255, 255, 255, 0.3)',
      secondary: '#1D77FF',
    },
    background: {
      default: '#040534',
      paper: '#10113E',
    },
    action: {
      // hover: 'currentColor',
      // hoverOpacity: 0,
    },
  },
  typography: {
    fontFamily: "'Helvetica', 'Roboto', 'Arial', sans-serif",
    h1: {
      fontFamily: "'Helvetica', 'Roboto', 'Arial', sans-serif",
      fontWeight: 'normal',
      fontSize: 24,
      lineHeight: '28px',
      color: '#ffffff',
      '@media (max-width:960px)': {
        fontSize: '15px',
      },
    },
    h2: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
      color: '#ffffff',
      letterSpacing: 0.15,
    },
    h3: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: '24px',
      letterSpacing: 0.15,
      color: '#ffffff',
    },
    h4: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: 0.25,
      color: '#ffffff',
    },
    h5: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      color: '#ffffff',
    },
    h6: {
      // TO USED
      fontFamily: "'Helvetica-Bold', 'Helvetica', 'Roboto', 'Arial', sans-serif",
      fontSize: 16,
      color: '#ffffff',
    },
    subtitle1: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 16,
      fontWeight: 400,
      color: '#ffffff',
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 1.25,
      lineHeight: '16.1px',
      color: '#ffffff',
    },
    body1: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: '#ffffff',
    },
    body2: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: 1.5,
      color: '#ffffff',
    },
    caption:{
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: 1.5,
      color: '#ffffff',
    }
  },
})

export default theme
