import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import {
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
  Link,
  Breadcrumbs,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import AccountStatus from '../../common/AccountStatus'
import WalletModal from '../modals/WalletModal'
import ImageLogo from '../../assets/navbar/logo.png'
import BreadcrumbMapping from '../../constants/breadcrumbMapping'
import LanguageStatus from '../../common/LanguageProvider/LangaugeStatus'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appHeader: {},
    root: {
      position: 'static',
      top: 0,
      zIndex: 100,
      width: 'calc(100% - 32px)',
      padding: '12px 16px',
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up(960)]: {
        padding: '28px 0 0 0',
        width: 'calc(100% - 3.75vw)',
        maxHeight: '10.546875vh',
        backgroundColor: theme.palette.background.paper,
        marginLeft: '3.75vw',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up(960)]: {
        paddingRight: '48px',
      },
    },
    userStatus: {
      display: 'flex',
      ustifyContent: 'flex-end',
      alignItems: 'stretch',
    },
    divider: {
      marginTop: '30px',
      width: '100%',
      backgroundColor: theme.palette.text.primary,
      opacity: '0.12',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    imageLogo: {
      width: '30px',
      cursor: 'pointer',
    },
    breadWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    proLabel: {
      width: '3rem',
      height: '1rem',
      marginLeft: '0.5rem',
      textAlign: 'center',
      backgroundColor: 'rgba(255, 214, 0, 0.25)',
      border: '0.8px solid rgba(255, 214, 0, 0.3)',
      borderRadius: '4px',
      padding: '0.4px 0.4px',
      position: 'relative',
      color: '#FFC700',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'italic',
      letterSpacing: '0.15px',
      lineHeight: '16px',
    },
    contentWrapper: {
      position: 'absolute',
      width: '1.75rem',
      height: '100%',
      background: 'linear-gradient(108.87deg, rgba(222, 212, 160, 0.7) 1.53%, rgba(249, 244, 220, 0) 86.56%)',
      borderRadius: '3px',
    },
  })
)

const HeaderTitle = () => {
  const { pathname } = useLocation()
  const classes = useStyles()

  let locationList: string[] = []
  pathname
    .split('/')
    .slice(1)
    .forEach((a, index) => {
      if (index === 0) return locationList.push('/' + a)
      locationList.push(locationList[index - 1] + '/' + a)
    })

  return (
    <Breadcrumbs separator={<NavigateNextIcon color="secondary" fontSize="small" />} aria-label="breadcrumb">
      {locationList.map((name) => {
        if (name === '/galaxy-pro') {
          return (
            <Link href={name} key={`bread-${name}`}>
              <div className={classes.breadWrapper}>
                <Trans>
                  <Typography variant="h2">Galaxy</Typography>
                  <div className={classes.proLabel}>
                    <div className={classes.contentWrapper}></div>
                    Pro
                  </div>
                </Trans>
              </div>
            </Link>
          )
        } else {
          return (
            <Link href={name} key={`bread-${name}`}>
              <Typography variant="h2">{BreadcrumbMapping[name] ? i18n._(BreadcrumbMapping[name]) : ''}</Typography>
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  )
}

const Header = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))

  const handleClickHome = () => {
    history.push('/home')
  }

  const showIcon = useMemo(() => {
    return <img className={classes.imageLogo} src={ImageLogo} onClick={handleClickHome} />
  }, [])
  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          {isPC ? <HeaderTitle /> : showIcon}
          <div className={classes.userStatus}>
            <AccountStatus />
            <LanguageStatus />
          </div>
        </div>
        <Divider light={true} className={classes.divider} />
      </div>
      <WalletModal />
    </>
  )
}

export default Header
