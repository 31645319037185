import styled from 'styled-components'
import clsx from 'clsx'

const FieldWrapper = styled.div<{height?:string}>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '68px')};
  //   padding-bottom: 8px;
`

const LabelWrapper = styled.div`
  position: absolute;
  top: -8px;
  left: 16px;
  background-color: rgb(38, 40, 73);
  display: flex;
  justify-content: center;
  align-items: center;
`
const FieldTitle = styled.label`
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-left: 4px;
`

const IconRender = styled.img<{ width?: string; aspectRatio?: string }>`
  width: ${(props) => (props.width ? props.width : '16px')};
  aspect-ratio: ${(props) => (props.aspectRatio ? props.aspectRatio : '1')};
  // height: 16px;
  margin-left: 4px;
  margin-right: 4px;
`

const ContentWrapper = styled.div<{height?:string}>`
  height: ${(props) => (props.height ? props.height : '68px')};
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export type LabelFieldKey = 'root'

const LabelField = ({
  children,
  fieldHeight,
  fieldTitle,
  isTitleShow,
  iconSrc,
  iconAlt,
  iconWidth,
  iconRatio,
  classes,
}: {
  children?: any
  fieldTitle?: string
  fieldHeight?: string
  isTitleShow?: boolean
  iconSrc?: string
  iconAlt?: string
  iconWidth?: string
  iconRatio?: string
  classes?: Partial<Record<LabelFieldKey, string>>
}) => {
  const { root: classRoot } = classes ?? {}

  return (
    <FieldWrapper className={clsx(classRoot)} height={fieldHeight}>
      <LabelWrapper>
        {isTitleShow && <FieldTitle>{fieldTitle}</FieldTitle>}
        <IconRender src={iconSrc} alt={iconAlt} width={iconWidth} aspectRatio={iconRatio} />
      </LabelWrapper>
      <ContentWrapper height={fieldHeight}>{children}</ContentWrapper>
    </FieldWrapper>
  )
}

export default LabelField
