import { useState, useMemo, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useMediaQuery,
  useTheme,
  createStyles,
  Theme,
  makeStyles,
  Typography,
  Tooltip,
  Grid,
  Divider,
  Button as MuiButton,
  StepLabel as MuiStepLabel,
} from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
import { useRWAStkaingPoolsApr } from '../../hooks/smartContracts/useStakingPoolsAprs'
import { displayBalance } from '../../utils/displayBalance'
import GradientCard from '../../common/GradientCard'
import ImgDefition from '../../assets/symbol/definition.png'
import InformationCard from '../../common/InformationCard'
import TestableAmountInput, { IAmountInputRefObj } from '../../common/TestableAmountInput'
import { MainButton } from '../../common/buttons'
import BoostListModal from '../../components/modals/BoostListModal'
import { useWeb3React } from '@web3-react/core'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import ADDRESS from '../../constants/addresses'
import { BigNumber } from '@ethersproject/bignumber'
import { useGalaxyStakingPoolContract, useStakingPoolContract } from '../../hooks/smartContracts/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/smartContracts/useApproveCallback'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { useGalaxyPoolBoostRatio } from '../../hooks/smartContracts/useGalaxyPoolBoostRatio'
import { useAlphaStakeTotalDeposited } from '../../hooks/smartContracts/useAlphaStakeTotalDeposited'
import { useBackToOrigin } from '../../redux/navbar/hooks'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ChainId } from '../../constants/blockchain'
import { useRebalancedAlphaRewards, useGetConstData } from '../../hooks/smartContracts/useRebalancedAlphaRewards'
import { useGalaxyPendingRewards } from '../../hooks/smartContracts/useGalaxy'
import { BarLoader } from 'react-spinners'
import useAlphaStableCoinRewards from '../../hooks/smartContracts/useAlphaStableCoinRewards'
import GradientBackground from '../../common/GradientBackground'
import {
  Basic,
  BasicContainer,
  BasicText,
  BasicBottom,
  BasicBottomLeft,
  BasicBottomRight,
  RightButtonWrapper,
  BasicTextFlex,
  DetailIcon,
  InfoFlex,
  InfoContent,
  ButtonFlex,
} from './styled'
import { ChevronRight } from 'react-feather'
import ImageCalculator from '../../assets/symbol/calculator.png'
import BoostCalculator from '../../components/BoostCalculator'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    addTokenButton: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'center',
    },
    detailModal: {
      width: 300,
      height: 'auto',
      backgroundColor: '#040534',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: 'rgba(255, 255, 255, 0.87)',
      padding: '8px 8px',
      [theme.breakpoints.down(960)]: {
        '&.MuiTooltip-tooltip': {
          marginTop: 12,
        },
      },
    },
    modalArrow: {
      '&::before': {
        backgroundColor: '#040534',
      },
    },
    boostColor: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  })
)

const InvestDrawerContent = ({
  poolName,
  setClose,
  alphaRootAddress,
  activeAlphaPool,
}: {
  poolName: string
  setClose: () => void
  alphaRootAddress: string
  activeAlphaPool: number
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const { account, chainId } = useWeb3React()
  const history = useHistory()
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const amountInput = useRef<IAmountInputRefObj>(null)
  const backFloatButton = useBackToOrigin()

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  const [showTooltip, setShowTooltip] = useState(false)
  const [showBoostModal, setShowBoostModal] = useState(false)
  const [showBoostCalculator, setShowBoostCalculator] = useState(false)
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [remainingCapacity, setRemainingCapacity] = useState<BigNumber>(BigNumber.from(0))
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)
  const [isApprove, approve] = useApproveCallback(
    ADDRESS.DAI_TOKEN,
    inputBignumber,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const galaxyPoolContract = useGalaxyStakingPoolContract(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const aprs = useRWAStkaingPoolsApr(
    account?.toLowerCase() ?? undefined,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )

  const alphaStableCoinReward = useAlphaStableCoinRewards(
    account ?? undefined,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    ADDRESS.ASSESSOR[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const AlphaPoolInfo = useMemo(() => {
    return [
      { apr: aprs[0].alpha[0], name: 'ALPHA', period: 7, id: 0 },
      // { apr: aprs[0].alpha[1], name: 'ALPHA', period: 90, id: 1 },
    ]
  }, [aprs])

  const [boost0] = useGalaxyPoolBoostRatio(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    ADDRESS.BOOST_POOL,
    account ?? undefined,
    0
  )
  const boosts = useMemo(() => ({ 0: boost0 }), [boost0])

  const constData = useGetConstData(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    ADDRESS.BOOST_POOL,
    account ?? undefined
  )

  const [newNAOS_APR, newRatio] = useRebalancedAlphaRewards(
    chainId ?? ChainId.BSC,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined,
    inputBignumber,
    aprs[0].alpha[activeAlphaPool].self,
    boosts[activeAlphaPool],
    constData
  )

  const alphaDepositAmount: BigNumber = useAlphaStakeTotalDeposited(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined,
    AlphaPoolInfo[0].id
  )

  const pendingReward = useGalaxyPendingRewards(galaxyPoolContract, account)

  useEffect(() => {
    if (galaxyPoolContract) {
      Promise.all([galaxyPoolContract?.getPoolCeiling(0), galaxyPoolContract?.getPoolTotalDeposited(0)]).then((res) => {
        setRemainingCapacity(res[0].sub(res[1]))
      })
    }
  }, [galaxyPoolContract])

  async function handleClaim(activeAlphaPool: number) {
    if (galaxyPoolContract && pendingReward.gt(0)) {
      openConfirmingModal(true)
      try {
        const poolId = AlphaPoolInfo[activeAlphaPool].id
        const estimateGas = await galaxyPoolContract.estimateGas.claim(poolId)
        const res = await galaxyPoolContract.claim(poolId, { gasLimit: calculateGasMargin(estimateGas) })
        openConfirmingModal(false, res.hash)
        addTransaction(res, { summary: t`Claim reward from ALPHA Pool` })
        amountInput.current?.resetAmount()
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleUpdateBoostRatio(poolId) {
    if (galaxyPoolContract && account) {
      openConfirmingModal(true)
      try {
        const estimateGas = await galaxyPoolContract.estimateGas.activateBoost(poolId, account)
        const res = await galaxyPoolContract.activateBoost(poolId, account, {
          gasLimit: calculateGasMargin(estimateGas),
        })
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: t`Update pool's boost ratio`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleDeposit() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (galaxyPoolContract && inputBignumber.gt(0) && inputBignumber.lte(daiBalance)) {
        openConfirmingModal(true)
        try {
          const poolId = AlphaPoolInfo[activeAlphaPool].id
          const estimateGas = await galaxyPoolContract.estimateGas.deposit(poolId, inputBignumber)
          const res = await galaxyPoolContract.deposit(poolId, inputBignumber, {
            gasLimit: calculateGasMargin(estimateGas),
          })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: t`Deposit ${displayBalance(inputBignumber)} ${collateralTokenName}`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <InformationCard
        setClose={setClose}
        title={t`Invest`}
        hideBorderLeft
        showAccountStatus
        showBottom
        handleSupplyOrder={handleDeposit}
        isApprove={isApprove}
        buttonContent={t`DEPOSIT`}
      >
        <InfoFlex>
          <GradientBackground width="100%" height={'108px'}>
            <BasicContainer>
              <GradientCard height={'60px'}>
                <InfoContent>
                  <ButtonFlex>
                    <Typography variant="h4" style={{ marginTop: '1px' }}>
                      <Trans>NAOS Boost</Trans>
                    </Typography>
                    <Typography variant="h4" style={{ marginLeft: '4px' }}>
                      {boosts[0].toFixed(2)}x
                    </Typography>
                  </ButtonFlex>

                  <ButtonFlex style={{ cursor: 'pointer' }} onClick={() => setShowBoostModal(true)}>
                    <Typography variant="body1" className={classes.boostColor}>
                      <Trans>Boost</Trans>
                    </Typography>
                  </ButtonFlex>
                </InfoContent>
              </GradientCard>
              <InfoContent style={{ justifyContent: 'space-between', height: '24px', paddingTop: '16px' }}>
                <BasicBottomLeft>
                  <Typography variant="subtitle1">
                    <Trans>Reward Boost Calculator</Trans>
                  </Typography>
                  <img
                    style={{ width: '22px', height: '18px', marginTop: '4px', marginLeft: '4px' }}
                    src={ImageCalculator}
                    alt="Calculator"
                  />
                </BasicBottomLeft>
                <RightButtonWrapper onClick={() => setShowBoostCalculator(true)}>
                  <ChevronRight color="#ffffff" width={20} />
                </RightButtonWrapper>
              </InfoContent>
            </BasicContainer>
          </GradientBackground>
          {/* <GradientCard width="100%" aspectRatio="280/60">
            <InfoContent>
              <ButtonFlex>
                <Typography variant="h4" style={{ marginTop: '1px' }}>
                  NAOS Boost
                </Typography>
                <Typography variant="h4" style={{ marginLeft: '4px' }}>
                  {boosts[0].toFixed(2)}x
                </Typography>
              </ButtonFlex>

              <ButtonFlex style={{ cursor: 'pointer' }} onClick={() => setShowBoostModal(true)}>
                <Typography variant="body1" className={classes.boostColor}>
                  Boost
                </Typography>
              </ButtonFlex>
            </InfoContent>
          </GradientCard> */}
        </InfoFlex>

        <Typography variant="h3" style={{ marginTop: 32 }}>
          <Trans>ALPHA Lending Pool</Trans>
        </Typography>
        <Typography variant="body2" style={{ marginTop: 8 }}>
          {/* Senior tranche - Lower risk, stable weekly return */}
          <Trans>Senior tranche - Lower risk, stable return</Trans>
        </Typography>

        {/* <Typography variant="body2" style={{ color: '#b0cfff' }}>
          Minimum investment: 5,000 DAI
        </Typography>
        <Typography variant="body2" style={{ color: '#b0cfff' }}>
          Minumum staking period: 30 days
        </Typography> */}

        <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
          <Typography variant="body2">
            <Trans>Pool Availability</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(remainingCapacity)}</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
          <BasicTextFlex>
            <Typography variant="body2">
              <Trans>RWA Interest APY</Trans>
            </Typography>
            <Tooltip
              title={t`The target investment return on the Tokens from the date of issuance is set at a fixed return (the "ALPHA APR”) from Corl at 7% while being deployed in actual financing. The IRR will be less than the ALPHA APR caused by the cash drag being invested in the pool but not being deployed in actual financing.`}
              arrow
              placement="top-end"
              classes={{ tooltip: classes.detailModal, arrow: classes.modalArrow }}
              open={showTooltip}
              onOpen={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
            >
              <DetailIcon src={ImgDefition} onClick={() => !isPC && setShowTooltip(!showTooltip)} />
            </Tooltip>
          </BasicTextFlex>

          <Typography variant="body2">{aprs[0].alpha[activeAlphaPool].alpha}%</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>NAOS Reward APR</Trans>
          </Typography>
          <Typography variant="body2">{aprs[0].alpha[activeAlphaPool].self}%</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Rewards Boost</Trans>
          </Typography>
          <Typography variant="body2">{boosts[activeAlphaPool].toFixed(2)} x</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Deposited</Trans>
          </Typography>
          <Typography variant="body2">
            {displayBalance(alphaDepositAmount)} {collateralTokenName}
          </Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Reward</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(alphaStableCoinReward)} BUSD</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        {pendingReward.gt(0) && (
          <>
            <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
              <Typography variant="body2">
                <Trans>Pending Reward</Trans>
              </Typography>
              <Typography variant="body2">{displayBalance(pendingReward)} NAOS</Typography>
            </Grid>
            <MainButton
              style={{
                marginTop: 16,
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.primary.light}`,
              }}
              disableStyle={'transparent'}
              onClick={() => handleClaim(0)}
            >
              <Typography variant="subtitle2" style={{ color: `${theme.palette.primary.light}` }}>
                <Trans>CLAIM</Trans>
              </Typography>
            </MainButton>
            <Divider className={classes.informationDivider} />
          </>
        )}

        <Typography variant="body2" style={{ marginTop: 40 }}>
          <Trans>
            Your deposit will be deployed in RWA lending to earn RWA yields whenever there is lending capacity and will
            be available to redeem when there is liquidity in the pool reserve.
          </Trans>
        </Typography>

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>{collateralTokenName} Balance</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(daiBalance)}</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <TestableAmountInput
          ref={amountInput}
          maxNum={daiBalance}
          amount={inputBignumber}
          setAmount={setInputBignumber}
          textFieldLabel={collateralTokenName}
        />
        <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
          <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            <Trans>Please set the amount</Trans>
          </Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2" style={{ display: 'flex', width: '150px', flexWrap: 'wrap' }}>
            <Trans>Rebalanced NAOS Reward APR</Trans>
          </Typography>
          {constData ? (
            <Typography variant="body2">
              {isNaN(newNAOS_APR) ? aprs[0].alpha[activeAlphaPool].self : newNAOS_APR.toFixed(2)}%
            </Typography>
          ) : (
            <BarLoader height={'20px'} width={'60px'} color={'white'} speedMultiplier={0.8} />
          )}
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Rebalanced Rewards Boost</Trans>
          </Typography>
          {constData ? (
            <Typography variant="body2">
              {isNaN(newRatio) ? boosts[activeAlphaPool].toFixed(2) : newRatio.toFixed(2)} x
            </Typography>
          ) : (
            <BarLoader height={'20px'} width={'60px'} color={'white'} speedMultiplier={0.8} />
          )}
        </Grid>

        {/* <MainButton onClick={handleDeposit} style={{ marginTop: 16 }}>
          <Typography variant="subtitle2">{isApprove === ApprovalState.APPROVED ? 'DEPOSIT' : 'APPROVE'}</Typography>
        </MainButton> */}

        {/* {connector === injected && !addNAOSToken.success && (
          <UnderLineButton color={'rgba(255, 255, 255, 0.6)'} onClick={addNAOSToken.addToken}>
            <Typography variant="body1" className={classes.addTokenButton}>
              Add NAOS token to your wallet
            </Typography>
          </UnderLineButton>
        )} */}
      </InformationCard>

      <BoostListModal
        rootAddress={alphaRootAddress}
        boostList={[]}
        open={showBoostModal}
        onClose={() => setShowBoostModal(false)}
        handleUpdate={handleUpdateBoostRatio}
        handleBoostMore={() => {
          backFloatButton()
          history.push('/farm/boost')
        }}
      />

      <BoostCalculator
        poolName={poolName}
        alphaRootAddress={alphaRootAddress}
        rwaAPR={aprs[0].alpha[activeAlphaPool].alpha}
        rewardsBoost={boost0}
        constData={constData}
        open={showBoostCalculator}
        onClose={() => setShowBoostCalculator(false)}
      />
    </>
  )
}

export default InvestDrawerContent
