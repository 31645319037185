import { BigNumber } from '@ethersproject/bignumber'
import BN from 'bignumber.js'
import { utils } from 'ethers'
import numeral from 'numeral'

export function displayBalance(
  number: BigNumber | BN | number | string | undefined,
  format: string = '0,0.00',
  shorten: boolean = false
): string {
  if (!number) {
    return numeral(0).format(format)
  }
  if (number instanceof BigNumber) {
    number = +utils.formatEther(number)
  } else if (number instanceof BN) {
    number = +number.div('1000000000000000000').toString() 
  } else if (typeof number === 'string') {
    number = +number
  }

  if (shorten && number >= 1000) {
    return numeral(number).format('0.0a').toUpperCase()
  }

  number = number.toFixed(6)
  return numeral(number).format(format)
}


export function displayBalanceUnits(
  number: BigNumber | number | string | undefined,
  format: string = '0,0.00',
  shorten: boolean = false
): string {
  if (!number) {
    return numeral(0).format(format)
  }
  if (number instanceof BigNumber) {
    let bnNum = new BN(number.toString())
    number = +bnNum.div('1000000000000000000000000000').toString() 
  } else if (typeof number === 'string') {
    number = +number
  }

  if (shorten && number >= 1000) {
    return numeral(number).format('0.0a').toUpperCase()
  }

  number = number.toFixed(6)
  return numeral(number).format(format)
}
