import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { ChainId } from '../../constants/blockchain'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useStakingPoolContract } from './useContract'
import { useTokenBalance } from './useTokenBalance'

export function useStakingPoolInfo(
  address?: IAddressOrAddressMap,
  staker?: string,
  poolId?: number
): [stakedAmount: BigNumber, rewardAmount: BigNumber, totalDeposited: BigNumber] {
  const { chainId } = useWeb3React()
  const contract = useStakingPoolContract(address)
  const blockNumber = useBlockNumber()
  const [stakedAmount, setStakedAmount] = useState<BigNumber>(BigNumber.from(0))
  const [rewardAmount, setRewardAmount] = useState<BigNumber>(BigNumber.from(0))
  const [tokenAddr, setTokenAddr] = useState('')
  const [totalDeposited] = useTokenBalance(tokenAddr, address && address[chainId ?? ChainId.MAINNET])

  useEffect(() => {
    if (contract && staker) {
      contract.getStakeTotalDeposited(staker, poolId ?? 0).then(setStakedAmount)
      contract.getStakeTotalUnclaimed(staker, poolId ?? 0).then(setRewardAmount)
      contract.getPoolToken(poolId ?? 0).then(setTokenAddr)
    }
  }, [contract, staker, blockNumber])

  return useMemo(() => [stakedAmount, rewardAmount, totalDeposited], [stakedAmount, rewardAmount, totalDeposited])
}
