import { ChangeEvent, useMemo, useState, useRef, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  useMediaQuery,
  useTheme,
  createStyles,
  Theme,
  makeStyles,
  Typography,
  TextField,
  Tooltip,
  Grid,
  Divider,
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  Select,
  FormControl,
  MenuItem,
  // Box,
  // Stepper,
  // Step,
  // StepLabel,
  // StepConnector,
  // withStyles,
} from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
// import { i18n } from '@lingui/core'
// import {stepConnectorClasses} from '@mui'
import {
  TitleWrapper,
  ContentWrapper,
  SelectionWrapper,
  ResultWrapper,
  OutcomePanel,
  StakeWrapper,
  InputWrapper,
  FooterWrapper,
  BasicWrapper,
  BasicLeftContent,
  BasicFlexContent,
  BasciBottom,
  BasicBottomLeft,
  BasicBottomRight,
  MainButton,
  Stepper,
  StepLine,
  StepIcon,
  StepLabel,
  StepUpper,
  ButtonWrapper,
} from './styled'
import { X as Close, ExternalLink as IconExternalLink } from 'react-feather'
import GradientCard from '../../common/GradientCard'
import GradientBackground from '../../common/GradientBackground'
import ExternalLink from '../commons/ExternalLink'
import { urls } from '../../constants/urls'
import { ChainId } from '../../constants/blockchain'
import { BigNumber } from 'ethers'
import ADDRESS from '../../constants/addresses'
import { useAlphaStakeTotalDeposited } from '../../hooks/smartContracts/useAlphaStakeTotalDeposited'
import { displayBalance } from '../../utils/displayBalance'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { useBoostPoolsInfo } from '../../hooks/smartContracts/useBoostPoolsInfo'
import TestableAmountInput, { IAmountInputRefObj } from '../../common/TestableAmountInput'
import { useApproveCallback } from '../../hooks/smartContracts/useApproveCallback'
import { useBoostPoolContract } from '../../hooks/smartContracts/useContract'
import { ApprovalState } from '../../hooks/smartContracts/useApproveCallback'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { useReBoostRatio } from '../../hooks/smartContracts/useReBoostRatio'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { useGalaxyStakingPoolContract } from '../../hooks/smartContracts/useContract'
import { useCalcMaxRatioAmount } from '../../hooks/smartContracts/useCalcMaxRatioAmount'
import { useBoostPoolLockTimes } from '../../hooks/smartContracts/useBoostPoolLockTimes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: '688px',
      overflowX: 'hidden',
    },
    paperMobile: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: '90%',
      overflow: 'hidden',
    },
    contentPaper: {
      overflow: 'hidden',
    },
    dividerHorizental: {
      width: '100%',
      height: '1px',
      margin: '0px auto',
      marginBottom: '24px',
      backgroundColor: theme.palette.text.primary,
      opacity: '12%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    radioRoot: {
      padding: '0px',
      marginRight: '8px',
    },
    ddl: {
      width: '100%',
      marginTop: 12,

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
    },
    select: {
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 14,
      color: 'rgba(255, 255, 255, 0.6)',
      padding: '0px 14px',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    menuItem: {
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    StepperBoxRoot: {
      width: '177px',
      height: '24px',
      border: 'none',
    },
    StepperRoot: {
      width: '177px',
      height: '48px',
      border: 'none',
      // margin: '0px 0px 24px 0px',
      padding: '0px 0px 24px 0px',
      backgroundColor: 'transparent',
      '& .MuiStepLabel-label .MuiStepLabel-alternativeLabel': {
        marginTop: '4px',
      },
    },
  })
)

const BoostCalculator = ({
  poolName,
  alphaRootAddress,
  rwaAPR,
  rewardsBoost,
  constData,
  open,
  onClose,
}: {
  poolName: string
  alphaRootAddress: string
  rwaAPR: number
  rewardsBoost: number
  constData: Record<string, any>
  open: boolean
  onClose: any
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const { account, chainId } = useWeb3React()
  const URL = useMemo(() => (chainId ? urls[chainId] : urls[ChainId.MAINNET]), [chainId])

  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [isApprove, approve] = useApproveCallback(
    ADDRESS?.NAOS_TOKEN,
    BigNumber.from(1),
    ADDRESS.BOOST_POOL[chainId ? (chainId === ChainId.MAINNET ? ChainId.BSC : chainId) : ChainId.BSC]
  )

  const galaxyPoolContract = useGalaxyStakingPoolContract(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )
  const boostPoolContract = useBoostPoolContract(
    ADDRESS.BOOST_POOL[chainId ? (chainId === ChainId.MAINNET ? ChainId.BSC : chainId) : ChainId.BSC]
  )
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const amountInput = useRef<IAmountInputRefObj>(null)
  const [step, setStep] = useState<number>(0)

  useEffect(() => {
    if (!open) {
      setStep(0)
    }
  }, [open])

  const [calculateMode, setCalculateMode] = useState<boolean[]>([true, false])
  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  const alphaDepositAmount: BigNumber = useAlphaStakeTotalDeposited(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined,
    0
  )

  const [naosBalance] = useTokenBalance(ADDRESS?.NAOS_TOKEN, account ?? undefined)
  const [lockTimes] = useBoostPoolLockTimes(ADDRESS.BOOST_POOL)

  const isDisabled = useMemo(
    () => inputBignumber.gt(naosBalance) && isApprove === ApprovalState.APPROVED,
    [inputBignumber, naosBalance]
  )

  const [lockIndex, setLockIndex] = useState(0)
  const lockTimeWeight = (lockIndex: number) => {
    switch (lockIndex) {
      case 1:
        return 4
      case 2:
        return 10
      case 3:
        return 25
      default:
        return 1
    }
  }

  const lockTimeLabel = ['3 months', '1 years', '2 years', '4 years']

  const newUserBoostWeight = useMemo(() => {
    return inputBignumber.mul(lockTimeWeight(lockIndex))
  }, [inputBignumber, lockIndex])

  const [reBoostRatio, reBoostAPR] = useReBoostRatio(
    alphaDepositAmount,
    newUserBoostWeight,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    chainId ?? ChainId.BSC,
    constData,
    0,
    step === 0 || step === 3
  )

  const [reMaxBoostAPR, maxRatioNAOS] = useCalcMaxRatioAmount(
    alphaDepositAmount,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    chainId ?? ChainId.BSC,
    constData,
    false,
    0,
    step === 0
  )

  // useEffect(() => {
  //   if(isApprove && step){
  //     setStep(2)
  //   }
  // }, [isApprove])

  function handleDDL(e: ChangeEvent<{ value: unknown }>) {
    setLockIndex(e.target.value as number)
  }

  function handleModeChange(triggerIndex: number) {
    if (triggerIndex === 0) {
      setCalculateMode([true, false])
    } else if (triggerIndex === 1) {
      setCalculateMode([false, true])
    }

    setLockIndex(0)
    setInputBignumber(BigNumber.from(0))
  }

  function handleMainButton() {
    if (alphaDepositAmount.eq(0)) {
      return
    } else if (calculateMode[0] && inputBignumber.gt(naosBalance)) {
      return
    } else if (calculateMode[1] && naosBalance.lt(maxRatioNAOS[3])) {
      return
    }

    if (step === 0) {
      if (isApprove === ApprovalState.APPROVED) {
        setStep(2)
      } else {
        setStep(1)
      }
      if (calculateMode[1]) {
        setLockIndex(3)
        setInputBignumber(maxRatioNAOS[3])
      }
    } else if (
      (step === 1 && isApprove !== ApprovalState.APPROVED) ||
      (step === 2 && inputBignumber.gt(BigNumber.from(0)))
    ) {
      handleStake()
    } else if (step === 3) {
      handleUpdateBoostRatio(0)
    }
  }

  function handlePrevious() {
    if (step === 1 || step === 2) {
      setStep(0)
      setInputBignumber(BigNumber.from(0))
    }
  }

  async function handleStake() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
      setStep(2)
    } else {
      if (boostPoolContract && inputBignumber.gt(0) && !isDisabled) {
        openConfirmingModal(true)
        try {
          const estimateGas = await boostPoolContract.estimateGas.deposit(inputBignumber, lockIndex)
          const res = await boostPoolContract.deposit(inputBignumber, lockIndex, {
            gasLimit: calculateGasMargin(estimateGas),
          })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: t`Stake ${displayBalance(inputBignumber)} NAOS`,
          })
          amountInput.current?.resetAmount()
          setStep(3)
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  async function handleUpdateBoostRatio(poolId) {
    if (galaxyPoolContract && account) {
      openConfirmingModal(true)
      try {
        const estimateGas = await galaxyPoolContract.estimateGas.activateBoost(poolId, account)
        const res = await galaxyPoolContract.activateBoost(poolId, account, {
          gasLimit: calculateGasMargin(estimateGas),
        })
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: t`Update pool's boost ratio`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  return (
    <>
      <Dialog
        classes={{
          paper: isPC ? classes.paper : classes.paperMobile,
        }}
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <DialogTitle disableTypography>
          <TitleWrapper>
            <Typography variant="h3">
              <Trans>NAOS Reward Boost Calculator</Trans>
            </Typography>
            <Close color="#ffffff" width={24} onClick={onClose} style={{ cursor: 'pointer' }} />
          </TitleWrapper>
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentPaper }}>
          <Divider orientation="horizontal" className={classes.dividerHorizental} />
          <ContentWrapper mobileHeight={step === 1 ? '466px' : ''}>
            {step === 0 && (
              <>
                <SelectionWrapper>
                  <GradientCard width="100%" height="88px">
                    <BasicWrapper>
                      <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
                        {poolName}
                      </Typography>
                      <BasicFlexContent>
                        <Typography variant="subtitle1">
                          <Trans>Total deposited</Trans>
                        </Typography>
                        <Typography variant="subtitle1">
                          {displayBalance(alphaDepositAmount)} {collateralTokenName}
                        </Typography>
                      </BasicFlexContent>
                    </BasicWrapper>
                  </GradientCard>
                  <GradientCard width="100%" height="332px">
                    <BasicWrapper>
                      <BasicFlexContent>
                        <Typography variant="subtitle1">
                          <Trans>NAOS Token</Trans>
                        </Typography>
                        <Typography variant="subtitle1">{displayBalance(naosBalance)} NAOS</Typography>
                      </BasicFlexContent>
                      <BasicLeftContent>
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                          }}
                          checked={calculateMode[0]}
                          onChange={() => {
                            handleModeChange(0)
                          }}
                          value="a"
                          //   name="radio-button-demo"
                          //   inputProps={{ 'aria-label': 'A' }}
                        />
                        <Typography variant="h5">
                          <Trans>Lock period</Trans>
                        </Typography>
                      </BasicLeftContent>
                      <FormControl
                        variant="outlined"
                        classes={{
                          root: classes.ddl,
                        }}
                        disabled={!calculateMode[0]}
                      >
                        <Select
                          value={lockIndex}
                          onChange={handleDDL}
                          labelWidth={0}
                          classes={{ root: classes.select, icon: classes.icon }}
                        >
                          {lockTimes.map((time, idx) => {
                            const lockDays = time.toNumber() / 60 / 60 / 24
                            return (
                              <MenuItem value={idx} classes={{ root: classes.menuItem }}>
                                <Typography
                                  variant="h3"
                                  style={{ color: calculateMode[0] ? '#ffffff' : 'rgba(255, 255, 255, 0.2)' }}
                                >
                                  {lockDays === 90 ? `${lockDays / 30} month` : `${lockDays / 360} years`}
                                </Typography>
                                <Typography
                                  variant="h3"
                                  style={{ color: calculateMode[0] ? '#ffffff' : 'rgba(255, 255, 255, 0.2)' }}
                                >
                                  {lockTimeWeight(idx)} x
                                </Typography>
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                      <Typography
                        variant="h5"
                        style={{
                          marginTop: '16px',
                          marginBottom: '8px',
                          color: calculateMode[0] ? '#ffffff' : 'rgba(255, 255, 255, 0.2)',
                        }}
                      >
                        <Trans>Stake Amount</Trans>
                      </Typography>
                      <TestableAmountInput
                        ref={amountInput}
                        maxNum={naosBalance}
                        amount={inputBignumber}
                        setAmount={setInputBignumber}
                        textFieldLabel={'NAOS'}
                        disabled={!calculateMode[0]}
                      />
                      <BasicLeftContent style={{ marginTop: '8px' }}>
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                          }}
                          checked={calculateMode[1]}
                          onChange={() => {
                            handleModeChange(1)
                          }}
                          value="a"
                          //   name="radio-button-demo"
                          //   inputProps={{ 'aria-label': 'A' }}
                        />
                        <Typography variant="h5">
                          <Trans>2.5x ratio</Trans>
                        </Typography>
                      </BasicLeftContent>
                    </BasicWrapper>
                  </GradientCard>
                  {isPC && (
                    <GradientBackground width="100%" height="48px">
                      <BasciBottom>
                        <BasicBottomLeft>
                          <Typography variant="subtitle1">
                            <Trans>Buy NAOS tokens</Trans>
                          </Typography>
                        </BasicBottomLeft>
                        <ExternalLink href={URL?.UNI_GET_NAOS}>
                          <BasicBottomRight>
                            <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 12 }} />
                          </BasicBottomRight>
                        </ExternalLink>
                      </BasciBottom>
                    </GradientBackground>
                  )}
                </SelectionWrapper>
                <ResultWrapper>
                  <Typography variant="h3" style={{ marginBottom: '16px' }}>
                    <Trans>Calculation Outcome</Trans>
                  </Typography>
                  <GradientCard width="280px" height="464px">
                    <OutcomePanel>
                      {calculateMode[0] && (
                        <>
                          <BasicFlexContent style={{ marginBottom: '8px' }}>
                            <Typography variant="subtitle1">
                              <Trans>APR</Trans>
                            </Typography>
                            <Typography variant="subtitle1">
                              {alphaDepositAmount.gt(0) ? `${reBoostAPR.toFixed(2)} %` : '0.00 %'}
                            </Typography>
                          </BasicFlexContent>
                          <BasicFlexContent>
                            <Typography variant="subtitle1">
                              <Trans>Boost ratio</Trans>
                            </Typography>
                            <Typography variant="subtitle1">
                              {alphaDepositAmount.gt(0) ? `${displayBalance(reBoostRatio)}x` : '1.00x'}
                            </Typography>
                          </BasicFlexContent>
                        </>
                      )}

                      {calculateMode[1] && (
                        <>
                          <BasicFlexContent style={{ marginBottom: '8px' }}>
                            <Typography variant="subtitle1">
                              <Trans>APR</Trans>
                            </Typography>
                            <Typography variant="subtitle1">
                              {alphaDepositAmount.gt(0) ? `${reMaxBoostAPR.toFixed(2)} %` : '0.00 %'}
                            </Typography>
                          </BasicFlexContent>
                          <BasicFlexContent style={{ marginBottom: '8px' }}>
                            <Typography variant="subtitle1">
                              <Trans>Boost ratio</Trans>
                            </Typography>
                            <Typography variant="subtitle1">{alphaDepositAmount.gt(0) ? '2.50x' : '1.00x'}</Typography>
                          </BasicFlexContent>
                          {lockTimeLabel.map((label, idx) => (
                            <BasicFlexContent style={{ marginBottom: '8px' }}>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: maxRatioNAOS[idx].eq(0)
                                    ? 'rgba(255, 255, 255, 0.2)'
                                    : naosBalance.lt(maxRatioNAOS[idx])
                                    ? 'rgba(255, 255, 255, 0.2)'
                                    : '#ffffff',
                                }}
                              >
                                {label}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: maxRatioNAOS[idx].eq(0)
                                    ? 'rgba(255, 255, 255, 0.2)'
                                    : naosBalance.lt(maxRatioNAOS[idx])
                                    ? 'rgba(255, 255, 255, 0.2)'
                                    : '#ffffff',
                                }}
                              >{t`Need ${displayBalance(maxRatioNAOS[idx], '0.0a', true)} NAOS`}</Typography>
                            </BasicFlexContent>
                          ))}
                        </>
                      )}
                    </OutcomePanel>
                  </GradientCard>
                </ResultWrapper>
              </>
            )}

            {step !== 0 && (
              <StakeWrapper>
                <Stepper width={isPC ? '327px' : '100%'}>
                  <StepLine
                    activate={step === 2 || step === 3}
                    width={isPC ? '120px' : '40%'}
                    default="rgba(255, 255, 255, 0.3)"
                    active="#1D77FF"
                  />

                  <StepLine
                    activate={step === 3}
                    width={isPC ? '120px' : '38%'}
                    default="rgba(255, 255, 255, 0.3)"
                    active="#1D77FF"
                    style={{ marginLeft: '0px' }}
                  />
                  <StepUpper width={isPC ? '327px' : '100%'}>
                    <StepLabel>
                      <StepIcon activate={step !== 0} default="#1D77FF" active="#1D77FF">
                        1
                      </StepIcon>
                      <Typography variant="caption" style={{ marginTop: '4px', color: '#1D77FF' }}>
                        <Trans>Approve</Trans>
                      </Typography>
                    </StepLabel>
                    <StepLabel>
                      <StepIcon activate={step === 2 || step === 3} default="rgba(255, 255, 255, 0.3)" active="#1D77FF">
                        2
                      </StepIcon>
                      <Typography
                        variant="caption"
                        style={{
                          marginTop: '4px',
                          color: step === 2 || step === 3 ? '#1D77FF' : 'rgba(255, 255, 255, 0.3)',
                        }}
                      >
                        <Trans>Stake</Trans>
                      </Typography>
                    </StepLabel>

                    <StepLabel>
                      <StepIcon activate={step === 3} default="rgba(255, 255, 255, 0.3)" active="#1D77FF">
                        3
                      </StepIcon>
                      <Typography
                        variant="caption"
                        style={{ marginTop: '4px', color: step === 3 ? '#1D77FF' : 'rgba(255, 255, 255, 0.3)' }}
                      >
                        <Trans>Rebalance</Trans>
                      </Typography>
                    </StepLabel>
                  </StepUpper>
                </Stepper>

                {(step === 1 || step === 2) && (
                  <>
                    <Typography variant="h3" style={{ marginBottom: '24px' }}>
                      <Trans> Stake Period</Trans>
                    </Typography>
                    <Typography variant="h3" style={{ marginBottom: '24px', color: 'rgba(255, 255, 255, 0.6)' }}>
                      <Trans>Lock NAOS tokens to claim NAOS rewards.</Trans>
                    </Typography>
                    <InputWrapper>
                      <BasicFlexContent>
                        <Typography variant="subtitle1">
                          <Trans>NAOS Token</Trans>
                        </Typography>
                        <Typography variant="subtitle1">{displayBalance(naosBalance)} NAOS</Typography>
                      </BasicFlexContent>
                      <FormControl
                        variant="outlined"
                        classes={{
                          root: classes.ddl,
                        }}
                      >
                        <Select
                          value={lockIndex}
                          onChange={handleDDL}
                          labelWidth={0}
                          classes={{ root: classes.select, icon: classes.icon }}
                        >
                          {lockTimes.map((time, idx) => {
                            const lockDays = time.toNumber() / 60 / 60 / 24
                            return (
                              <MenuItem value={idx} classes={{ root: classes.menuItem }}>
                                <Typography variant="h3">
                                  {lockDays === 90 ? `${lockDays / 30} month` : `${lockDays / 360} years`}
                                </Typography>
                                <Typography variant="h3">{lockTimeWeight(idx)} x</Typography>
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                      <Typography
                        variant="h5"
                        style={{
                          marginTop: '16px',
                          marginBottom: '8px',
                        }}
                      >
                        <Trans>Stake Amount</Trans>
                      </Typography>
                      <TestableAmountInput
                        ref={amountInput}
                        maxNum={naosBalance}
                        amount={inputBignumber}
                        setAmount={setInputBignumber}
                        textFieldLabel={'NAOS'}
                      />
                    </InputWrapper>
                    {isPC && (
                      <GradientBackground width="280px" height="48px">
                        <BasciBottom>
                          <BasicBottomLeft>
                            <Typography variant="subtitle1">
                              <Trans>Buy NAOS tokens</Trans>
                            </Typography>
                          </BasicBottomLeft>
                          <ExternalLink href={URL?.UNI_GET_NAOS}>
                            <BasicBottomRight>
                              <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 12 }} />
                            </BasicBottomRight>
                          </ExternalLink>
                        </BasciBottom>
                      </GradientBackground>
                    )}
                  </>
                )}
                {step === 3 && (
                  <>
                    <Typography variant="h3" style={{ marginBottom: '28px' }}>
                      <Trans>
                        Rebalance your reward weight in the boost pool, boost again to increase weight and reward APR.
                      </Trans>
                    </Typography>
                    <GradientCard width={isPC ? '320px' : '100%'} height="56px">
                      <BasicWrapper>
                        <BasicFlexContent>
                          <Typography variant="subtitle1">
                            <Trans>Rebalance</Trans>
                          </Typography>
                          <Typography variant="subtitle1">
                            {`from ${rewardsBoost.toFixed(2)}x -> ${reBoostRatio}x`}
                          </Typography>
                        </BasicFlexContent>
                      </BasicWrapper>
                    </GradientCard>
                  </>
                )}
              </StakeWrapper>
            )}
          </ContentWrapper>
        </DialogContent>
        <DialogContent classes={{ root: classes.contentPaper }}>
          <FooterWrapper mobileHeight={step === 1 || step === 2 ? '150px' : '100px'}>
            {!isPC && step !== 3 && (
              <GradientBackground width="100%" height="48px">
                <BasciBottom>
                  <BasicBottomLeft>
                    <Typography variant="subtitle1">
                      <Trans>Buy NAOS tokens</Trans>
                    </Typography>
                  </BasicBottomLeft>
                  <ExternalLink href={URL?.UNI_GET_NAOS}>
                    <BasicBottomRight>
                      <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 12 }} />
                    </BasicBottomRight>
                  </ExternalLink>
                </BasciBottom>
              </GradientBackground>
            )}
            <ButtonWrapper>
              {(step === 1 || step === 2) && (
                <MainButton
                  style={{
                    marginRight: isPC ? 16 : 0,
                    backgroundColor: 'transparent',
                    border: `1px solid ${theme.palette.primary.light}`,
                  }}
                  disableStyle={'transparent'}
                  onClick={handlePrevious}
                >
                  <Typography variant="subtitle2" style={{ color: `${theme.palette.primary.light}` }}>
                    <Trans>PREVIOUS</Trans>
                  </Typography>
                </MainButton>
              )}
              <MainButton
                onClick={handleMainButton}
                style={
                  isPC && step === 2
                    ? isApprove !== ApprovalState.APPROVED
                      ? { width: '160px' }
                      : { width: '120px' }
                    : {}
                }
                disable={
                  step === 0
                    ? (calculateMode[0] && inputBignumber.gt(naosBalance)) ||
                      (calculateMode[1] && naosBalance.lt(maxRatioNAOS[3])) ||
                      alphaDepositAmount.eq(0)
                    : step === 1
                    ? false
                    : step === 2
                    ? inputBignumber.gt(naosBalance) || inputBignumber.eq(0)
                    : false
                }
                disableStyle="solid"
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color:
                      step === 0
                        ? (calculateMode[0] && inputBignumber.gt(naosBalance)) ||
                          (calculateMode[1] && naosBalance.lt(maxRatioNAOS[3])) ||
                          alphaDepositAmount.eq(0)
                          ? 'rgba(255, 255, 255, 0.3)'
                          : '#ffffff'
                        : step === 1
                        ? '#ffffff'
                        : step === 2
                        ? inputBignumber.gt(naosBalance) || inputBignumber.eq(0)
                          ? 'rgba(255, 255, 255, 0.3)'
                          : '#ffffff'
                        : '#ffffff',
                  }}
                >
                  {step === 0
                    ? t`EXECUTE`
                    : step === 1
                    ? t`APPROVE`
                    : step === 2
                    ? isApprove !== ApprovalState.APPROVED
                      ? t`WAIT APPROVE`
                      : t`STAKE`
                    : step === 3 && t`REBALANCE`}
                </Typography>
              </MainButton>
            </ButtonWrapper>
          </FooterWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BoostCalculator
