import { Container, BackgroundBorder, BackgroundGradient, ContentWrapper } from './styled'
import clsx from 'clsx'
import styled from 'styled-components'
import { MouseEventHandler } from 'react'

const defaultWidth = '124px'
const defaultHeight = '44px'
const defaultBorderRadius = '16px'
const defaultBakgroundBorderBackground =
  'linear-gradient(180deg, rgba(35, 45, 136, 4e-05) 19.87%, rgba(35, 136, 136, 0.4) 100%)'
const defaultBackgrounGradientBackground =
  'linear-gradient(180deg, rgba(35, 45, 136, 0) 60%, rgba(35, 136, 136, 1) 100%)'

export type GradiendBackgroundKey = 'root' | 'border' | 'background' | 'wrapper'

const Test = styled.div``

export const GradientBackground = ({
  children,
  width,
  height,
  borderBackground,
  borderRadius,
  gradiantBackground,
  classes,
  onClick,
}: {
  children?: any
  width?: string
  height?: string
  borderBackground?: string
  gradiantBackground?: string
  borderRadius?: string
  classes?: Partial<Record<GradiendBackgroundKey, string>>
  onClick?: MouseEventHandler<HTMLElement>
}) => {
  const { root: classRoot, border: classBorder, background: classBackground, wrapper: classWrapper } = classes ?? {}

  return (
    <Container
      width={width ?? defaultWidth}
      onClick={onClick}
      height={height ?? defaultHeight}
      // cursor={onClick !== undefined}
      showCursor={onClick !== undefined}
      className={clsx(classRoot, classBorder)}
    >
      <BackgroundBorder
        className={clsx(classBackground)}
        background={borderBackground ?? defaultBakgroundBorderBackground}
      >
        <BackgroundGradient
          style={{ borderRadius: borderRadius ?? defaultBorderRadius }}
          background={gradiantBackground ?? defaultBackgrounGradientBackground}
          className={clsx(classBackground)}
        />
        <ContentWrapper className={clsx(classWrapper)}>{children ?? ''}</ContentWrapper>
      </BackgroundBorder>
    </Container>
  )
}

export default GradientBackground
