import { useMemo, useRef, useState } from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Typography, Grid } from '@material-ui/core'

import { useOpenConfirmingModal } from '../../../redux/application/hooks'
import { useTransactionAdder } from '../../../redux/transactions/hooks'
import { useTransmuterContract } from '../../../hooks/smartContracts/useContract'
import { useTokenBalance } from '../../../hooks/smartContracts/useTokenBalance'
import { ApprovalState, useApproveCallback } from '../../../hooks/smartContracts/useApproveCallback'
import ADDRESS from '../../../constants/addresses'
import { MainButton } from '../../../common/buttons'
import AmountInput, { IAmountInputRefObj } from '../../../common/AmountInput'
import { displayBalance } from '../../../utils/displayBalance'
import { Divider } from '../../../common/Divider'
import { PoolFlexCenter } from '../styled'
import { ChainId } from '../../../constants/blockchain'
import { Trans, t } from '@lingui/macro'

const StakeDrawerContent = () => {
  const { account, chainId } = useWeb3React()
  const amountInput = useRef<IAmountInputRefObj>(null)

  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )

  const transmuteContract = useTransmuterContract(ADDRESS.TRANSMUTER)
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, account ?? undefined)

  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [isApprove, approve] = useApproveCallback(ADDRESS?.NUSD_TOKEN, inputBignumber, ADDRESS.TRANSMUTER[chainId ?? 1])
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  async function handleStake() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (transmuteContract && inputBignumber.gt(0)) {
        openConfirmingModal(true)
        try {
          const res = await transmuteContract.stake(inputBignumber)
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Stake ${displayBalance(inputBignumber)} ${mintTokenName}`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>Stake {mintTokenName} for transmute</Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>{mintTokenName} Balance</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(nusdBalance)} {mintTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={amountInput}
        maxNum={nusdBalance}
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={mintTokenName}
      />
      <PoolFlexCenter style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set stake amount</Trans>
        </Typography>
      </PoolFlexCenter>
      {/* <PoolFlexCenter style={{ marginTop: `13px`, justifyContent: `flex-end` }}>
        <MainButton onClick={handleStake}>{isApprove === ApprovalState.APPROVED ? 'STAKE' : 'APPROVE'}</MainButton>
      </PoolFlexCenter> */}
      <MainButton onClick={handleStake} style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">{isApprove === ApprovalState.APPROVED ? t`STAKE` : t`APPROVE`}</Typography>
      </MainButton>
    </>
  )
}
export default StakeDrawerContent
