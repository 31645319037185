import React, { useState, useMemo, FC, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Backdrop,
  useMediaQuery,
  useTheme,
  createStyles,
  Theme,
  makeStyles,
  Typography,
  TextField,
  Tooltip,
  Grid,
  Divider,
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel as MuiStepLabel,
  StepContent,
  Box,
  CircularProgress,
  Modal,
} from '@material-ui/core'
import { useResizeDetector } from 'react-resize-detector'
import clsx from 'clsx'
import {
  ChevronRight,
  CheckCircle,
  Circle,
  ExternalLink as IconExternalLink,
  Globe,
  Plus,
  X as CloseIcon,
} from 'react-feather'
import { useParams } from 'react-router-dom'
import { formatEther, parseUnits } from '@ethersproject/units'
import styled from 'styled-components'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import { displayBalance } from '../../utils/displayBalance'
import { useRWAStkaingPoolsApr, useRWAStakingPoolsAprBE } from '../../hooks/smartContracts/useStakingPoolsAprs'
import {
  useAlphaRWAStakingPoolsTvl,
  useBetaInsuranceStakingPoolTVL,
  useAlphaRWAStakingPoolValue,
} from '../../hooks/smartContracts/useStakingPoolsTvl'
import { useOpenModal } from '../../redux/application/hooks'
import { ApplicationModal } from '../../redux/application/actions'
import {
  USED_CHAIN_ID,
  SECURITIZE_API_URL,
  SECURITIZE_CLIENT_KEY,
  SECURITIZE_CLIENT_ID,
  GOOGLE_CLIENT_ID,
} from '../../constants/env'
import { useNFTListStats, usePoolValue } from '../../hooks/useGalaxyBackendAPI'
import {
  useAlphaTokenPrice,
  useEpochReserveRatio,
  useFundingReserve,
  useGalaxyPoolRewardRate,
  useGalaxyPoolTotalSupplyCurrency,
  useGalaxyWhitelisted,
  useGalaxyPendingRewards,
} from '../../hooks/smartContracts/useGalaxy'

import ActionBoard from '../../common/ActionBoard'
import GradientCard from '../../common/GradientCard'
import GradientBackground from '../../common/GradientBackground'
import LineData, { LineDataType, CustomLineData } from '../../common/LineData'
import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  TitleBack,
  AssetOriginatorInfo,
  Basic,
  BasicContainer,
  BasicText,
  BasicTextFlex,
  BasicBottom,
  BasicBottomLeft,
  BasicBottomRight,
  BasicBottomRightIcon,
  Button,
  InformationSpace,
  Pool,
  PoolFlex,
  PoolFlexDivider,
  PoolFlexAsset,
  LineDataContainer,
  TitleBackContainer,
  UnderLineButton,
  DetailIcon,
  InfoFlex,
  InfoContent,
  ButtonFlex,
  SignButton,
  SignModalButtonWrapper,
  SignModalExamples,
  SignModalDocument,
} from './styled'
import LiquidityDetail from './LiquidityDetail'
import EpochDetail from './EpochDetail'
import AssetDetail from './AssetDetail'
import Assets from './Assets'
import { DrawerWidth } from '../../components/Navbar'
import ImageCoin from '../../assets/symbol/coin.png'
import ImgDefition from '../../assets/symbol/definition.png'
import InformationCard from '../../common/InformationCard'
import InvestDetail from './InvestDetail'
import { useWeb3React } from '@web3-react/core'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import ADDRESS from '../../constants/addresses'
// import AmountInput, { IAmountInputRefObj } from '../../common/AmountInput'
import TestableAmountInput, { IAmountInputRefObj } from '../../common/TestableAmountInput'
import { BigNumber } from '@ethersproject/bignumber'
import { MainButton } from '../../common/buttons'
import { useGalaxyStakingPoolContract, useStakingPoolContract } from '../../hooks/smartContracts/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/smartContracts/useApproveCallback'
import { useBlockNumber, useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import RedeemDrawerContent from './RedeemDrawerContent'
import { useGalaxyPoolBoostRatio } from '../../hooks/smartContracts/useGalaxyPoolBoostRatio'
import {
  getInvestmentInfo,
  setWhitelist,
  getKYCInvestorInfo,
  postSecuritizeKYCInfo,
  postSumsubKYCInfo,
  getKYCType,
} from '../../utils/getGalaxyInfo'
import { postAgreementApply, getAgreementApply, putAgreementApply } from '../../utils/getDocusignInfo'
import { getRegisteredInfo, postRegisteredInfo, statusCode } from '../../utils/getRegisteredAddressInfo'
import { useTokenTotalSupply } from '../../hooks/smartContracts/useTokenTotalSupply'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'
import BetaDrawer from './BetaDrawer'
import useAddTokenToWallet from '../../hooks/useAddTokenToWallet'
import { TOKEN } from '../../constants/tokens'
import { injected } from '../../utils/connectors'
import useStakingPoolId from '../../hooks/useStakingPoolId'
import { useSecondPerBlock } from '../../hooks/useSecondPerBlock'
import { useStakingPoolInfo } from '../../hooks/smartContracts/useStakingPoolsInfo'
import { useAlphaStakeTotalDeposited } from '../../hooks/smartContracts/useAlphaStakeTotalDeposited'
import numeral from 'numeral'
import { useShiftToLeft, useBackToOrigin } from '../../redux/navbar/hooks'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ChainId } from '../../constants/blockchain'
import queryString from 'query-string'
import LabelField from '../../components/commons/LabelField'
import { GoogleLogin } from 'react-google-login'
import { providers } from 'ethers'
import securitizeIcon from '../../assets/symbol/securitize-logo.svg'
import sumsubIcon from '../../assets/symbol/Logo_horizontal_white.png'
import TelegramLoginButton from 'react-telegram-login'
import { useRebalancedAlphaRewards } from '../../hooks/smartContracts/useRebalancedAlphaRewards'
import { utils } from 'ethers'
import {
  PoolNames,
  AssetType,
  AssetMaturity,
  AssetOriginatorNames,
  AODescription,
  AssetOriginatorsShow,
  AOGradientCardHeight,
  AOGradientCardMobileHeight,
  ExecutiveSummaryLink,
  WebsiteLink,
  DocuSignExampleLink,
} from '../../constants/investConstants'
import { ExternalLink } from '../../components/commons/ExternalLink'
import BoostListModal from '../../components/modals/BoostListModal'
import InvestDrawerContent from './InvestDrawerContent'
// import { Web3Provider } from '@ethersproject/providers'

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    disalbeTitleButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: 'rgba(255, 255, 255, 0.2)',
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
    },
    dividerVertical: {
      width: '1px',
      backgroundColor: theme.palette.text.primary,
      opacity: '60%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '32px auto',
      backgroundColor: theme.palette.text.primary,
      opacity: '60%',
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    tabs: {
      minHeight: 0,
      height: 32,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 4,
      marginTop: 24,
    },
    tab: {
      minWidth: 0,
      minHeight: 32,
      padding: 0,
      width: '50%',
      borderRadius: 3,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    hideIndicator: {
      display: 'none',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 360,
    },
    signPaper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 532,
      height: 300,
    },
    signTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    signModalDivider: {
      width: '100%',
      height: '1px',
      margin: '0px auto',
      marginBottom: '16px',
      backgroundColor: theme.palette.text.primary,
      opacity: '12%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    signButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    addTokenButton: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'center',
    },
    addedTokenSign: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'right',
    },
    addedTokenContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    stepLabel: {
      '&.MuiStepLabel-root': {
        alignItems: 'flex-start',
      },
    },
    titleMargin: {
      marginBottom: 10,
    },
    assetOriginatorBG: {
      backgroundColor: 'rgba(255, 255, 255) !important',
      // '&:before': {
      //   backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
      // },
    },
    marginBottom_24: {
      marginBottom: 24,
    },
    detailModal: {
      width: 300,
      height: 'auto',
      backgroundColor: '#040534',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: 'rgba(255, 255, 255, 0.87)',
      padding: '8px 8px',
      [theme.breakpoints.down(960)]: {
        '&.MuiTooltip-tooltip': {
          marginTop: 12,
        },
      },
    },
    modalArrow: {
      '&::before': {
        backgroundColor: '#040534',
      },
    },
    boostColor: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  })
)

const StepLabel = styled(MuiStepLabel)`
  .MuiStepLabel-label {
    color: white;
  }
`

export interface AssetDataType {
  nftID: string
  nftRegistry: string
  owner: string
  riskGroup: string
  maturityDate: string
  amount: string
  financeFee: string
  status: string
  available: string
  outstanding: string
}

export enum DocuSignIdentity {
  ENTITY,
  NONAMERICAN,
  AMERICAN,
}

export enum AlphaPoolAction {
  INVEST,
  REDEEM,
  INSURANCE,
}

export enum AlphaPoolDetail {
  INVEST,
  LIQUIDITY,
  ASSET,
  EPOCH,
}

// const PoolCard: FC<{ apr: any; isPC: boolean; unit: string } & React.ButtonHTMLAttributes<HTMLButtonElement>> = (
//   props
// ) => {
//   const { apr, isPC, unit, onClick } = props
//   return (
//     <GradientBackground width={isPC ? '48%' : '100%'} height="92px" onClick={onClick}>
//       <Grid
//         container
//         direction="column"
//         justifyContent="space-between"
//         // style={{ padding: '8px 13px 12px 16px', cursor: 'pointer' }}
//         style={{ padding: '8px 13px 12px 16px' }}
//       >
//         <Typography variant="body2" style={{ marginBottom: '8px' }}>
//           ESTIMATED APR
//         </Typography>
//         <Typography variant="h4">
//           {apr.alpha.toFixed(2)} % {unit}
//         </Typography>
//         <Typography variant="h4">{apr.naos.toFixed(2)} % with NAOS</Typography>
//         <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '4px' }}>
//           <Typography variant="body1">
//             ALPHA Lending Pool
//             {/* Coming Soon */}
//           </Typography>
//           <ChevronRight color="white" size={24} />
//         </Grid>
//       </Grid>
//     </GradientBackground>
//   )
// }

const Alpha = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const { width, ref } = useResizeDetector()

  const { account, library, chainId, connector } = useWeb3React()
  const { poolname } = useParams() as Record<string, any>

  const history = useHistory()
  useEffect(() => {
    if (chainId && chainId !== ChainId.BSC) {
      history.push('/home')
    }
  }, [chainId])

  let poolID = 0
  if (poolname.substr(0, 4) == 'rwa-') {
    poolID = Number(poolname.substr(4))
  }
  const [alphaRootAddress, setAlphaRootAddress] = useState(
    Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC])[poolID]
  )
  const galaxyPoolContract = useGalaxyStakingPoolContract(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  useEffect(() => {
    // if (galaxyPoolContract) {
    //   Promise.all([galaxyPoolContract?.getPoolCeiling(0), galaxyPoolContract?.getPoolTotalDeposited(0)]).then((res) => {
    //     setRemainingCapacity(res[0].sub(res[1]))
    //   })
    // }
    if (chainId !== undefined) {
      let rootAddress = Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC])[poolID]
      if (rootAddress !== undefined) {
        setAlphaRootAddress(rootAddress)
      }
    }
  }, [chainId])
  // }, [galaxyPoolContract, chainId])

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  // const [boost1] = useGalaxyPoolBoostRatio(
  //   ADDRESS.GALAXY_STAKING_POOL.default[0],
  //   ADDRESS.BOOST_POOL,
  //   account ?? undefined,
  //   1
  // )
  // const boosts = useMemo(() => ({ 0: boost0, 1: boost1 }), [boost0, boost1])

  const [activeAlphaPool, setActiveAlphaPool] = useState(0)
  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false])
  const openDrawer = (type: number) => {
    const status = new Array(3).fill(false)
    status[type] = true
    setDrawerOpen(status)
  }
  const closeDrawer = () => {
    setDrawerOpen([false, false, false])
  }

  const isWhiteList = useGalaxyWhitelisted(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account
  )
  const [whitelistModal, setWhiteListModal] = useState(false)
  const [isKYCProcessing, setIsKYCProcessing] = useState(false)
  const [isKYCInfoPending, setIsKYCInfoPending] = useState(false)
  const [isCheckDocuSign, setIsCheckDocuSign] = useState(true)
  const [isClickDocuSignBtn, setIsClickDocuSignBtn] = useState(false)
  const [kcyAgency, setKcyAgency] = useState<string>('securitize')
  const [isKYCDone, setIsKYCDone] = useState<boolean>(false)
  const [isWalletRegistered, setIsWalletRegistered] = useState<number>(0)

  const [isSignModalOpen, setIsSignModalOpen] = useState<boolean>(false)

  const handleSignModalClose = () => {
    setIsSignModalOpen(false)
  }
  // enum KYC_TYPE {
  //   NONE = 1,
  //   INDIVIDUAL = 2,
  //   ENTITY = 3,
  // }

  const documentSignedCheck = (chainId: number) => {
    if (account && chainId) {
      getAgreementApply(
        chainId,
        ADDRESS.GALAXY_STAKING_POOL[chainId as number][alphaRootAddress].toLowerCase(),
        account?.toLowerCase()
      )
        .then((res) => {
          setIsKYCInfoPending(false)
          if (res) {
            //Pass
            window.history.pushState(
              {},
              '',
              window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?event=wait_for_approve'
            )
            setActiveStep(4)
          } else {
            //No Pass
            setActiveStep(3)
          }
        })
        .catch((err) => {
          setIsKYCInfoPending(false)
          setActiveStep(3)
        })
    }
  }

  const handleRegisterWallet = () => {
    if (account && (externalToken || externalObj) && thirdPartyName) {
      const code = !!externalToken ? externalToken : externalObj
      getSigner().then((res) => {
        if (res && res.hasOwnProperty('signature')) {
          postRegisteredInfo(thirdPartyName, code, account?.toLowerCase(), res.signature.substring(2)).then((res) => {
            if (res && chainId) {
              documentSignedCheck(chainId)
            }
          })
        }
      })
    }
  }

  const registeredAddressCheck = () => {
    if (account && externalId) {
      getRegisteredInfo(externalId, account?.toLowerCase()).then((status) => {
        setIsKYCInfoPending(false)
        switch (status) {
          case statusCode.ADDRESS_BEEN_REGISTERED:
          case statusCode.UNREGISTERED:
          case statusCode.INTERNAL_ERROR:
            setIsWalletRegistered(status)
            break
          case statusCode.REGISTERED:
            if (chainId) {
              //docusign
              setIsWalletRegistered(status)
              documentSignedCheck(chainId)
            }
            break
          default:
            // console.log('default')
            break
        }
      })
    }
  }

  const getSigner = async () => {
    const message = 'This is to confirm that you approve registration of your wallet in NAOS'

    if (account) {
      const provider = library
      const signer = provider.getSigner(account)
      const hexMessage = utils.hexlify(utils.toUtf8Bytes(message))
      const address = await signer.getAddress()
      try {
        const signature = await provider.send('personal_sign', [hexMessage, account.toLowerCase()])
        return {
          signature,
          address,
        }
      } catch (err: any) {
        console.log(err.message)
      }
    }
  }

  const sumsubKYCCheck = (kycType: string) => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    }

    if ((externalToken || externalObj) && thirdPartyName) {
      const code = !!externalToken ? externalToken : externalObj
      postSumsubKYCInfo(account?.toLowerCase(), code, thirdPartyName, kycType)
        .then((res) => {
          const { hasVerified, hasToken, status, accessToken, msg } = res
          if (hasVerified) {
            setIsKYCDone(hasVerified)
            registeredAddressCheck()
          } else {
            //no pass kyc
            setIsKYCInfoPending(false)
            if (accessToken) {
              window.open(
                window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  `/kyc?access=${accessToken}&redirecturl=${
                    window.location.protocol + '//' + window.location.host + window.location.pathname
                  }&type=${kycType}`,
                '_self'
              )
            }
          }
        })
        .catch((err) => {
          setIsKYCInfoPending(false)
          setActiveStep(1)
          console.warn(err.message)
        })
    }
  }

  useEffect(() => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    } else if (isWhiteList) {
      setWhiteListModal(false)
    }

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    let step = 1
    let auth = {
      linkedAccount: '',
      code: '',
      country: '',
      auth: false,
    }
    let openModal = false
    if (parsed && parsed.code && parsed.country && account) {
      const { linkedAccount, code, country, authorized } = parsed
      if (window.localStorage.getItem('kycCode') === null || window.localStorage.getItem('kycCode') !== code) {
        window.localStorage.setItem('kycCode', code as string)
      } else {
        setActiveStep(1)
        return
      }

      if (linkedAccount === account) {
        // only for securitize
        auth = {
          linkedAccount: linkedAccount as string,
          code: code as string,
          country: country as string,
          auth: authorized === 'true' ? true : false,
        }
        setActiveStep(2)
        openModal = true
        setIsKYCInfoPending(true)
        postSecuritizeKYCInfo(account?.toLowerCase(), code)
          .then((res) => {
            const { hasVerified, hasToken, status, accessToken, msg } = res
            if (hasVerified) {
              documentSignedCheck(chainId)
            } else {
              setIsKYCInfoPending(false)
              if (status == 'processing') {
                setIsKYCProcessing(true)
              }
              if (msg) {
                setKYCMessage(msg)
              } else {
                setKYCMessage(status)
              }
              setActiveStep(2)
            }
          })
          .catch((err) => {
            setIsKYCInfoPending(false)
            setActiveStep(1)
            console.warn(err.message)
          })
      } else {
        setActiveStep(2)
      }
    }
    if (parsed && parsed.event) {
      openModal = true
      if (parsed.event == 'signing_complete') {
        step = 4
        putAgreementApply(
          chainId,
          ADDRESS.GALAXY_STAKING_POOL[chainId as number][alphaRootAddress].toLowerCase(),
          account?.toLowerCase()
        )
          .then((res) => {
            if (res === 'success') {
              window.history.pushState(
                {},
                '',
                window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?event=wait_for_approve'
              )
            } else {
              window.history.pushState(
                {},
                '',
                window.location.protocol + '//' + window.location.host + window.location.pathname
              )
            }
          })
          .catch((err) => {
            setIsCheckDocuSign(false)
            window.history.pushState(
              {},
              '',
              window.location.protocol + '//' + window.location.host + window.location.pathname
            )
          })
      } else if (parsed.event == 'wait_for_approve') {
        step = 4
      } else if (parsed.event === 'sumsub_kyc' && parsed.kycType && typeof parsed.kycType === 'string') {
        sumsubKYCCheck(parsed.kycType)
      }
    }
    if (account && !isWhiteList) {
      setIsWalletRegistered(0)
      if (openModal && !whitelistModal) {
        setWhiteListModal(true)
      } else if ((externalToken || externalObj) && externalId) {
        setActiveStep(2)
        if (isKycTypeChoose && isKYCDone) {
          registeredAddressCheck()
        }
        return
      }
      setActiveStep(step)
    }
  }, [account, chainId, isWhiteList])

  const pendingReward = useGalaxyPendingRewards(galaxyPoolContract, account)
  const [assetDetailData, setAssetDetailData] = useState<undefined | AssetDataType>(undefined)
  const [isDetailOpen, setDetailOpen] = useState<boolean[]>([false, false, false, false])
  const shiftFloatButton = useShiftToLeft()
  const backFloatButton = useBackToOrigin()

  const openDetail = (type: number) => {
    const status = new Array(4).fill(false)
    status[type] = true
    shiftFloatButton()
    setDetailOpen(status)
  }
  const closeDetail = () => {
    backFloatButton()
    setDetailOpen([false, false, false, false])
  }

  const totalAprs = useRWAStakingPoolsAprBE(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )

  const aprs = useRWAStkaingPoolsApr(
    account?.toLowerCase() ?? undefined,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )

  // const alphaTvls = useAlphaRWAStakingPoolsTvl()
  const alphaPoolValue = useAlphaRWAStakingPoolValue(chainId ?? ChainId.BSC, alphaRootAddress)
  const nftListStats = useNFTListStats(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )
  const epochReserve = useFundingReserve(chainId ?? ChainId.BSC, alphaRootAddress)
  const epochReserveRatio = useEpochReserveRatio(chainId ?? ChainId.BSC, alphaRootAddress)

  const AlphaPoolInfo = useMemo(() => {
    return [
      { apr: aprs[0].alpha[0], name: 'ALPHA', period: 7, id: 0 },
      // { apr: aprs[0].alpha[1], name: 'ALPHA', period: 90, id: 1 },
    ]
  }, [aprs])

  // for testing usage
  const [comingSoon] = useState(false)
  const [xAxisDate, setXAxisDate] = useState<Object>({})
  const lineData = usePoolValue(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  ) as LineDataType

  useEffect(() => {
    if (lineData && lineData.name && lineData.name.length > 0) {
      const dateArr = lineData.name
      const dateObj = {}
      let monthDate = ''
      dateArr.forEach((date: string) => {
        let splitString = date.split(/[\-]/)
        if (monthDate === '' || monthDate !== `${splitString[0]}-${+splitString[1]}`) {
          monthDate = `${splitString[0]}-${+splitString[1]}`
          dateObj[date] = monthDate
        }
      })
      setXAxisDate(dateObj)
    }
  }, [lineData])

  // const alphaDepositAmount0: BigNumber = useAlphaStakeTotalDeposited(
  //   ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
  //   account ?? undefined,
  //   AlphaPoolInfo[0].id
  // )
  // const alphaDepositAmounts = useMemo(() => ({ 0: alphaDepositAmount0 }), [alphaDepositAmount0])

  // const weightedBoosts = useMemo(() => {
  //   if (alphaDepositAmount0.eq(0) && alphaDepositAmount1.eq(0)) return '0.00'

  //   const alphaWeight0 = +formatEther(alphaDepositAmount0) * boost0
  //   const alphaWeight1 = +formatEther(alphaDepositAmount1) * boost1
  //   const totalAmount = +formatEther(alphaDepositAmount0.add(alphaDepositAmount1))
  //   return ((alphaWeight0 + alphaWeight1) / totalAmount).toFixed(2)
  // }, [alphaDepositAmount0, alphaDepositAmount1, boost0, boost1])

  // const [stakedAmount, rewardAmount] = useStakingPoolInfo(
  //   ADDRESS.STAKINGPOOL_WITH_TRANSFER,
  //   account ?? undefined,
  //   useStakingPoolId().BETA
  // )

  const UIAvailableChainId =
    USED_CHAIN_ID === ChainId.MAINNET
      ? [ChainId.MAINNET, ChainId.BSC]
      : [ChainId.RINKEBY, ChainId.BSC, ChainId.BSC_TEST]

  const steps = [
    {
      label: t`Connect wallet`,
      description: '',
    },
    {
      label: t`Login KYC Account`,
      description: t`Please sign up or sign in with Securitize account to continue your onboarding process.`,
    },
    {
      label: t`Start KYC`,
      description: '',
    },
    {
      label: t`Sign the Subscription Document`,
      description: '',
    },
    {
      label: t`We've received your signed agreement, The issuer will counter-sign and send you an executed version. NAOS will notify you via email when all is completed for you to invest.`,
      description: '',
    },
  ]

  const openModal = useOpenModal()

  const [activeStep, setActiveStep] = useState(0)
  const [kycMessage, setKYCMessage] = useState('')
  const [isKycTypeChoose, setIsKycTypeChoose] = useState<boolean>(false)

  const redirect =
    window.location.protocol + '//' + window.location.host + window.location.pathname + `?linkedAccount=${account}`

  const handleKYC = () => {
    setKcyAgency('securitize')
    window.open(
      `${SECURITIZE_API_URL}?issuerId=${SECURITIZE_CLIENT_ID}&scope=info details verification&redirecturl=${redirect}`,
      '_self'
    )
  }

  const [externalToken, setExternalToken] = useState('')
  const [externalId, setExternalId] = useState('')
  const [externalObj, setExternalObj] = useState<Record<string, any>>({})
  const [thirdPartyName, setThirdPartyName] = useState<string>('')

  const handleSumsubKYC = (kycType: string) => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    }
    if (!isKycTypeChoose) {
      setIsKYCInfoPending(true)
      setIsKycTypeChoose(true)
      sumsubKYCCheck(kycType)
    }
  }

  const successResponseGoogle = (e: any) => {
    setKcyAgency('sumsub')
    setThirdPartyName('google')
    setActiveStep(2)
    setExternalToken(e.tokenId)
    setExternalId(e.googleId)

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    if (parsed && parsed.linkedAccount) {
      window.history.pushState(
        {},
        '',
        window.location.protocol + '//' + window.location.host + window.location.pathname
      )
    }

    //Close Entity Button. If Entity KYC open, comment below codes.
    setIsKYCInfoPending(true)
    setIsKycTypeChoose(true)
    sumsubIndividualKYC('google', e.googleId, e.tokenId)

    //check does kyc pending or not
    // if(e.googleId){
    //   console.log('checkout')
    //   getKYCType(e.googleId).then((status)=> {
    //     switch(status){
    //       case KYC_TYPE.INDIVIDUAL:
    //         setIsKYCInfoPending(true)
    //         setIsKycTypeChoose(true)
    //         sumsubKYCCheck('individual')
    //         break
    //       case KYC_TYPE.ENTITY:
    //         setIsKYCInfoPending(true)
    //         setIsKycTypeChoose(true)
    //         sumsubKYCCheck('entity')
    //         break
    //       default:
    //         break
    //     }
    //   })
    // }
  }

  const failureResponseGoogle = (e: any) => {
    console.log('failure google rsps: ', e)

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    if (parsed && parsed.linkedAccount) {
      window.history.pushState(
        {},
        '',
        window.location.protocol + '//' + window.location.host + window.location.pathname
      )
    }
  }

  const responseTelegram = (e: any) => {
    setKcyAgency('sumsub')
    setThirdPartyName('telegram')
    setActiveStep(2)
    setExternalId(e.id)
    setExternalObj(e)

    //Close Entity Button. If Entity KYC open, comment below codes.
    setIsKYCInfoPending(true)
    setIsKycTypeChoose(true)
    sumsubIndividualKYC('telegram', e.id, undefined, e)
  }

  //Only use for close Entity KYC. If Entity KYC open, this function could comment out.
  const sumsubIndividualKYC = (name: string, id: string, token?: string, obj?: object) => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    }

    if ((token || obj) && name) {
      const code = !!token ? token : obj
      postSumsubKYCInfo(account?.toLowerCase(), code, name, 'individual')
        .then((res) => {
          const { hasVerified, hasToken, status, accessToken, msg } = res
          if (hasVerified) {
            setIsKYCDone(hasVerified)
            getRegisteredInfo(id, account?.toLowerCase()).then((status) => {
              setIsKYCInfoPending(false)
              switch (status) {
                case statusCode.ADDRESS_BEEN_REGISTERED:
                case statusCode.UNREGISTERED:
                case statusCode.INTERNAL_ERROR:
                  setIsWalletRegistered(status)
                  break
                case statusCode.REGISTERED:
                  if (chainId) {
                    //docusign
                    setIsWalletRegistered(status)
                    documentSignedCheck(chainId)
                  }
                  break
                default:
                  break
              }
            })
          } else {
            //no pass kyc
            setIsKYCInfoPending(false)
            if (accessToken) {
              window.open(
                window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  `/kyc?access=${accessToken}&redirecturl=${
                    window.location.protocol + '//' + window.location.host + window.location.pathname
                  }&type=individual`,
                '_self'
              )
            }
          }
        })
        .catch((err) => {
          setIsKYCInfoPending(false)
          setActiveStep(1)
          console.warn(err.message)
        })
    }
  }

  const submitKYC = async () => {
    setKcyAgency('securitize')
    window.open(SECURITIZE_API_URL.replace(/#\/authorize/, '#/settings'), '_blank')
  }

  const openSignDOCModal = () => {
    setIsSignModalOpen(true)
  }

  const signDOC = async () => {
    if (chainId !== undefined && chainId !== null && !isNaN(chainId) && account) {
      setIsClickDocuSignBtn(true)
      await postAgreementApply(
        chainId,
        ADDRESS.GALAXY_STAKING_POOL[chainId as number][alphaRootAddress].toLowerCase(),
        window.location.protocol + '//' + window.location.host + window.location.pathname,
        account?.toLowerCase()
      )
        .then((url) => {
          if (url && url !== '' && url !== null) {
            window.open(url, '_self')
          } else {
            setIsClickDocuSignBtn(false)
          }
        })
        .catch((err) => {
          setIsClickDocuSignBtn(false)
          console.error(err)
        })
    }
  }

  const handleSignExample = (linkId: number) => {
    window.open(DocuSignExampleLink[linkId])
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.informationBox, { [classes.boxLeft]: isDetailOpen.includes(true) })}>
        <TitleDiv>
          <TitleFront>
            <TitleIcon src={ImageCoin} />
            <TitleTextDiv>
              <Typography variant="body2" className={classes.greyHeader}>
                <Trans>Galaxy</Trans>
              </Typography>
              <Typography variant="h3">{i18n._(PoolNames[poolID])}</Typography>
            </TitleTextDiv>
          </TitleFront>
          <TitleBackContainer>
            <TitleBack className={classes.titleButtonExit} onClick={() => openDrawer(AlphaPoolAction.REDEEM)}>
              <Typography variant="body2" color="textSecondary">
                <Trans>REDEEM</Trans>
              </Typography>
            </TitleBack>
            <TitleBack
              className={classes.titleButton}
              onClick={() => {
                setActiveAlphaPool(0)
                if (isWhiteList) {
                  openDrawer(AlphaPoolAction.INVEST)
                } else {
                  setWhiteListModal(true)
                }
              }}
            >
              <Typography variant="body2" color="inherit">
                <Trans>INVEST</Trans>
              </Typography>
            </TitleBack>
          </TitleBackContainer>
        </TitleDiv>

        <GradientCard width="100%" height={isPC ? '80px' : '524px'}>
          <BasicContainer>
            <GradientCard height={isPC ? '80px' : '524px'}>
              <Basic>
                <BasicText style={isPC ? { flexBasis: '20%' } : {}}>
                  <Typography variant="h2">{i18n._(AssetType[poolID])}</Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Asset Type</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText style={isPC ? { flexBasis: '20%' } : {}}>
                  <Typography variant="h2">
                    {/* {`$ ${displayBalance(+alphaTvls[0] + +alphaTvls[1], '0,0')} ${mintTokenName}`} */}
                    {`$ ${displayBalance(+alphaPoolValue, '0,0')} ${collateralTokenName}`}
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Disbursement Amount</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">{totalAprs[0].alpha[0].alpha.toFixed(2)}%</Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Max APY in Stable</Trans>
                  </Typography>
                </BasicText>
                <Plus style={isPC ? { color: 'white' } : { color: 'white', margin: '8px 0' }} />
                <BasicText>
                  <Typography variant="h2">{totalAprs[0].alpha[0].naos.toFixed(2)}%</Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Max APR in NAOS</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText style={isPC ? { flexBasis: '20%' } : {}}>
                  <Typography variant="h2">{i18n._(AssetMaturity[poolID])}</Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Asset Maturity</Trans>
                  </Typography>
                </BasicText>
              </Basic>
            </GradientCard>
            {/* <BasicBottom>
              <BasicBottomLeft>
                <Typography variant="subtitle1">
                  This pool is directly integrated with a NAOS vault for liquidity.
                </Typography>
              </BasicBottomLeft>
              <BasicBottomRight onClick={() => openDetail(AlphaPoolDetail.LIQUIDITY)}>
                <Typography variant="subtitle1">Details</Typography>
                <BasicBottomRightIcon src={ImgDefition} />
              </BasicBottomRight>
            </BasicBottom> */}
          </BasicContainer>
        </GradientCard>

        {/* <Grid
          container
          direction={isPC ? 'row' : 'column'}
          justifyContent="space-between"
          style={isPC ? { margin: '20px 0' } : { margin: '20px 0', gap: 16 }}
        >
          <PoolCard
            apr={totalAprs[0].alpha[0]}
            isPC={isPC}
            unit={collateralTokenName}
            onClick={() => {
              setActiveAlphaPool(0)
              if (isWhiteList) {
                openDrawer(AlphaPoolAction.INVEST)
              } else {
                setWhiteListModal(true)
              }
            }}
          />

          <GradientBackground
            width={isPC ? '48%' : '100%'}
            height="92px"
            onClick={() => openDrawer(AlphaPoolAction.INSURANCE)}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              style={{ padding: '8px 13px 12px 16px', cursor: 'pointer' }}
            >
              <Typography variant="body2">APR</Typography>
              <Typography variant="h2">{totalAprs[0].beta[0].toFixed(2)} %</Typography>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '8px' }}>
                <Typography variant="body1">BETA Insurance Pool</Typography>
                <ChevronRight color="white" size={24} />
              </Grid>
            </Grid>
          </GradientBackground>
        </Grid> */}

        <Pool>
          <Typography variant="h2">
            <Trans>Asset Status</Trans>
          </Typography>
          <PoolFlex inputMarginTop={'11px'} inputMobileMarginTop={'16px'}>
            <GradientCard width={isPC ? '48%' : '100%'} height="auto">
              <PoolFlexAsset>
                <BasicTextFlex>
                  <Typography variant="h2">
                    <Trans>Asset Value</Trans>
                  </Typography>
                  <Typography variant="h2">{`$ ${displayBalance(nftListStats.totalAsset, '0,0')}`} USD</Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Number of Assets</Trans>
                  </Typography>
                  <Typography variant="body2">{nftListStats.count}</Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Average Loan Amount</Trans>
                  </Typography>
                  <Typography variant="body2">
                    {`$ ${displayBalance(
                      nftListStats.count > 0 ? nftListStats.totalAsset / nftListStats.count : 0,
                      '0,0'
                    )}`}{' '}
                    USD
                  </Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Weighted Interest Rate</Trans>
                  </Typography>
                  <Typography variant="body2">
                    {nftListStats.count > 0 ? (nftListStats.totalInterest / nftListStats.count).toFixed(2) : 0}%
                  </Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Average Maturity</Trans>
                  </Typography>
                  <Typography variant="body2">
                    <Trans>
                      {nftListStats.count > 0 ? (nftListStats.totalDays / nftListStats.count).toFixed(0) : 0} days
                    </Trans>
                  </Typography>
                </BasicTextFlex>
                <BasicTextFlex />
                <BasicTextFlex />
                <BasicTextFlex>
                  <Typography variant="h2">
                    <Trans>Reserve</Trans>
                  </Typography>
                  <Typography variant="h2">{`$ ${displayBalance(epochReserve, '0,0')}`} USD</Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Reserve Ratio</Trans>
                  </Typography>
                  <Typography variant="body2">{epochReserveRatio.toString()}%</Typography>
                </BasicTextFlex>
              </PoolFlexAsset>
            </GradientCard>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '48%' : '100%'} height="auto">
              <PoolFlexAsset>
                <BasicTextFlex>
                  <Typography variant="h2">
                    <Trans>Disbursement Amount</Trans>
                  </Typography>
                  <Typography variant="h2">
                    <Trans>Nov 11, 2021 - Present</Trans>
                  </Typography>
                </BasicTextFlex>
                {lineData && lineData.name && lineData.name.length > 0 && (
                  <LineDataContainer ref={ref}>
                    <CustomLineData
                      lineData={lineData}
                      height={160}
                      width={width}
                      yRange={[0, (dataMax) => Math.floor(dataMax * 1.2)]}
                      XAxisTicks={xAxisDate}
                      XInterval={0}
                      tickFormatY={(tick: number) => {
                        return `$${numeral(tick).format('0a').toUpperCase()}`
                      }}
                      labelFormatter={(value, name, props) => {
                        return [`$${numeral(value).format('0,0').toUpperCase()}`, name, props]
                      }}
                    />
                  </LineDataContainer>
                )}
              </PoolFlexAsset>
            </GradientCard>
          </PoolFlex>
        </Pool>

        <Pool style={AssetOriginatorsShow[poolID] ? {} : { display: 'none' }}>
          <Typography variant="h2" className={classes.titleMargin}>
            <Trans>Asset Originator</Trans>
          </Typography>
          {/* <GradientCard width="100%" height={isPC ? (isDetailOpen.includes(true) ? '246px' : '198px') : '440px'}> */}
          <GradientCard width="100%" height={isPC ? '220x' : '360px'}>
            <BasicContainer>
              <GradientCard height={isPC ? AOGradientCardHeight[poolID] : AOGradientCardMobileHeight[poolID]}>
                <AssetOriginatorInfo>
                  <Typography variant="h2" className={classes.titleMargin}>
                    {i18n._(AssetOriginatorNames[poolID])}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.titleMargin}>
                    {i18n._(AODescription[poolID])}
                  </Typography>
                </AssetOriginatorInfo>
              </GradientCard>
              <BasicBottom>
                <ExternalLink href={ExecutiveSummaryLink[poolID]}>
                  <BasicBottomRight>
                    <Typography variant="subtitle1">
                      <Trans>Corl Executive Summary</Trans>
                    </Typography>
                    <IconExternalLink color="#ffffff" width={18} style={{ marginLeft: 4 }} />
                  </BasicBottomRight>
                </ExternalLink>
                <ExternalLink href={WebsiteLink[poolID]}>
                  <BasicBottomRight style={isPC ? { marginLeft: 16 } : {}}>
                    <Typography variant="subtitle1">
                      <Trans>Website</Trans>
                    </Typography>
                    <Globe color="#ffffff" width={18} style={{ marginLeft: 4 }} />
                  </BasicBottomRight>
                </ExternalLink>
              </BasicBottom>
            </BasicContainer>
          </GradientCard>
        </Pool>

        <Pool style={isPC ? { marginBottom: 80 } : { marginBottom: 25 }}>
          <Typography variant="h2">
            <Trans>Assets</Trans>
          </Typography>
          <PoolFlex inputMarginTop={'10.5px'} inputMobileMarginTop={'11px'}>
            <GradientCard width="100%" height={isPC ? '650px' : '950px'}>
              <Assets
                data={nftListStats.list.map((nft, index) => {
                  return {
                    nftID: index.toString(),
                    nftRegistry: nft.nftRegistry,
                    owner: nft.owner,
                    riskGroup: nft.riskGroup,
                    maturityDate: nft.maturityDate,
                    amount: nft.amount,
                    financeFee: nft.interestFee,
                    status: nft.status,
                    available: nft.available,
                    outstanding: nft.outstanding,
                  } as AssetDataType
                })}
                chainId={chainId ?? ChainId.MAINNET}
                openDetail={() => openDetail(AlphaPoolDetail.ASSET)}
                setDetail={(data: AssetDataType) => {
                  setAssetDetailData(data)
                }}
              />
            </GradientCard>
          </PoolFlex>
        </Pool>

        <div className={classes.bottomSpace}></div>
      </div>

      {/* right side panel */}
      <div className={clsx({ [classes.boxRight]: isDetailOpen.includes(true) }, classes.boxRightHide)}>
        <AssetDetail
          open={isDetailOpen[AlphaPoolDetail.ASSET]}
          assetDetailData={assetDetailData}
          setClose={() => closeDetail()}
        />
        {/* <InvestDetail
          pendingRewards={pendingReward}
          boosts={boosts}
          // weightedBoosts={weightedBoosts}
          galaxyPoolContract={galaxyPoolContract}
          alphaPoolInfo={AlphaPoolInfo}
          alphaDepositAmounts={alphaDepositAmounts}
          betaStakeAmounts={stakedAmount}
          betaPoolReward={rewardAmount}
          rootAddress={alphaRootAddress}
          open={isDetailOpen[AlphaPoolDetail.INVEST]}
          setClose={() => closeDetail()}
        /> */}
        {/* <LiquidityDetail open={isDetailOpen[AlphaPoolDetail.LIQUIDITY]} setClose={() => closeDetail()} /> */}
        {/* <EpochDetail open={isDetailOpen[AlphaPoolDetail.EPOCH]} setClose={() => closeDetail()} /> */}
      </div>

      <ActionBoard open={isDrawerOpen[AlphaPoolAction.REDEEM]} setClose={closeDrawer}>
        <RedeemDrawerContent
          setClose={closeDrawer}
          poolId={poolID}
          alphaRootAddress={alphaRootAddress}
          showAlpha={true}
        />
      </ActionBoard>

      <ActionBoard open={isDrawerOpen[AlphaPoolAction.INVEST]} setClose={closeDrawer}>
        <InvestDrawerContent
          poolName={PoolNames[poolID]}
          setClose={closeDrawer}
          alphaRootAddress={alphaRootAddress}
          activeAlphaPool={activeAlphaPool}
        />
      </ActionBoard>

      <BetaDrawer
        isOpen={isDrawerOpen[AlphaPoolAction.INSURANCE]}
        onClose={closeDrawer}
        apr={totalAprs[0].beta[0].toFixed(2)}
      />

      <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={whitelistModal}
        onClose={() => setWhiteListModal(false)}
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">
            <Trans>Onboarding</Trans>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography variant="h4">
            <Trans>
              ALPHA Lenging pools are backed by real-world assets and financing those assets on-chain requires identity
              verification and legal agreements to ensure regulatory requirements are adhered.
            </Trans>
          </Typography>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ padding: 0, background: 'transparent', marginTop: 20 }}
          >
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  StepIconComponent={activeStep > index ? CheckCircle : Circle}
                  style={{ color: 'white' }}
                  classes={{
                    root: index === 4 ? classes.stepLabel : '',
                  }}
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography variant="body1" style={{ marginBottom: 22 }}>
                    {step.description}
                  </Typography>
                  {activeStep == 2 && index == 2 && kycMessage && (
                    <Typography style={{ color: isKYCProcessing ? 'white' : 'red', marginBottom: 22 }}>
                      {kycMessage}
                    </Typography>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {activeStep == 0 && (
                        <Button
                          className={classes.titleButton}
                          onClick={() => {
                            if (chainId && !UIAvailableChainId.includes(chainId)) {
                              return
                            }
                            openModal(ApplicationModal.WALLET)
                          }}
                        >
                          <Typography variant="subtitle2">
                            <Trans>Connect</Trans>
                          </Typography>
                        </Button>
                      )}
                      {activeStep == 1 && (
                        <>
                          <LabelField
                            fieldHeight="120px"
                            isTitleShow={false}
                            iconSrc={sumsubIcon}
                            iconAlt="Sumsub Icon"
                            iconWidth="68px"
                            iconRatio="800/186"
                            classes={{ root: classes.marginBottom_24 }}
                          >
                            <GoogleLogin
                              clientId={GOOGLE_CLIENT_ID}
                              render={(renderProps) => (
                                <Button
                                  style={{ marginBottom: '8px' }}
                                  className={classes.titleButton}
                                  onClick={renderProps.onClick}
                                >
                                  <Typography variant="subtitle2">
                                    <Trans>Log in with Google</Trans>
                                  </Typography>
                                </Button>
                              )}
                              buttonText={t`Login`}
                              onSuccess={successResponseGoogle}
                              onFailure={failureResponseGoogle}
                              isSignedIn={false}
                            />

                            <div style={{ marginTop: '8px' }}>
                              <TelegramLoginButton dataOnauth={responseTelegram} botName="NAOS_Sumsub_KYC_bot" />
                            </div>
                          </LabelField>

                          <LabelField
                            isTitleShow={false}
                            iconSrc={securitizeIcon}
                            iconAlt="Securitize Icon"
                            iconWidth="72px"
                            iconRatio="522.657/101.019"
                          >
                            <Button className={classes.titleButton} onClick={handleKYC}>
                              {isKYCInfoPending && (
                                <CircularProgress size={20} style={{ color: 'white', marginRight: '10px' }} />
                              )}
                              <Typography variant="subtitle2">
                                <Trans>Login with Securitize</Trans>
                              </Typography>
                            </Button>
                          </LabelField>
                        </>
                      )}
                      {activeStep == 2 &&
                        !isKYCProcessing &&
                        (kcyAgency === 'securitize' ? (
                          <Button className={classes.titleButton} onClick={submitKYC}>
                            <Typography variant="subtitle2">
                              <Trans>Continue</Trans>
                            </Typography>
                          </Button>
                        ) : !isKycTypeChoose ? (
                          <>
                            <Button
                              className={classes.titleButton}
                              onClick={() => {
                                handleSumsubKYC('individual')
                              }}
                            >
                              <Typography variant="subtitle2">
                                <Trans>Individual</Trans>
                              </Typography>
                            </Button>
                            <Button
                              className={classes.titleButton}
                              onClick={() => {
                                handleSumsubKYC('entity')
                              }}
                              style={{ marginTop: 12 }}
                            >
                              <Typography variant="subtitle2">
                                <Trans>Entity</Trans>
                              </Typography>
                            </Button>
                          </>
                        ) : !isKYCDone ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={32} style={{ color: 'white', margin: '0 auto' }} />
                          </div>
                        ) : isWalletRegistered === 0 ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={32} style={{ color: 'white', margin: '0 auto' }} />
                          </div>
                        ) : isWalletRegistered === statusCode.ADDRESS_BEEN_REGISTERED ? (
                          <Typography variant="subtitle2">
                            <Trans>This wallet has been registered already. Please register with another wallet.</Trans>
                          </Typography>
                        ) : (
                          isWalletRegistered === statusCode.UNREGISTERED && (
                            <Button className={classes.titleButton} onClick={handleRegisterWallet}>
                              <Typography variant="subtitle2">
                                <Trans>Register Wallet</Trans>
                              </Typography>
                            </Button>
                          )
                        ))}

                      {activeStep == 3 && (
                        <Button className={classes.titleButton} onClick={openSignDOCModal}>
                          <Typography variant="subtitle2">
                            <Trans>Sign document</Trans>
                          </Typography>
                        </Button>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {/* {activeStep === steps.length && (
            <Paper square elevation={0}>
              <Typography>All steps completed - you&apos;re finished</Typography>
            </Paper>
          )} */}
          {/* <Typography variant="h4" style={{ marginTop: 20 }}>
            Your application of the whitelist is processing.
          </Typography> */}
          <DialogActions style={{ marginTop: 24 }} />
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          paper: classes.signPaper,
        }}
        open={isSignModalOpen}
        onClose={handleSignModalClose}
      >
        <DialogTitle
          disableTypography
          classes={{
            root: classes.signTitle,
          }}
          onClick={handleSignModalClose}
        >
          <Typography variant="h3">
            <Trans>Onboarding</Trans>
          </Typography>
          <CloseIcon />
        </DialogTitle>

        <Divider orientation="horizontal" className={classes.signModalDivider} />
        <DialogContent>
          <Typography variant="h5" style={{ color: '#ffffff60' }}>
            <Trans>
              You'll be required to sign in subscription agreement and submit W8-Ben/W8-Ben-E/W9 form before investing.
              Click the following button for corresponding form samples that fits in your identity.
            </Trans>
          </Typography>
          <SignModalButtonWrapper>
            <SignModalExamples>
              {kcyAgency === 'securitize' && (
                <Button
                  className={classes.titleButtonExit}
                  onClick={() => {
                    handleSignExample(DocuSignIdentity.ENTITY)
                  }}
                >
                  <Typography variant="subtitle2" style={{ color: theme.palette.primary.light }}>
                    <Trans>Sample for Entity</Trans>
                  </Typography>
                </Button>
              )}

              <Button
                className={classes.titleButtonExit}
                onClick={() => {
                  handleSignExample(DocuSignIdentity.NONAMERICAN)
                }}
              >
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.light }}>
                  <Trans>Sample for Non-U.S citizen</Trans>
                </Typography>
              </Button>
              <Button
                className={classes.titleButtonExit}
                onClick={() => {
                  handleSignExample(DocuSignIdentity.AMERICAN)
                }}
              >
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.light }}>
                  <Trans>Sample for U.S citizen</Trans>
                </Typography>
              </Button>
            </SignModalExamples>
            <SignModalDocument>
              {isCheckDocuSign ? (
                <SignButton
                  className={classes.signButton}
                  onClick={signDOC}
                  height={kcyAgency === 'securitize' ? '124px' : '80px'}
                >
                  {isClickDocuSignBtn && <CircularProgress size={20} style={{ color: 'white', marginRight: '10px' }} />}
                  <Typography variant="h1">
                    <Trans>Sign Document</Trans>
                  </Typography>
                </SignButton>
              ) : (
                <Typography variant="body1" style={{ color: 'red' }}>
                  <Trans>Something went wrong, please refresh page. </Trans>
                </Typography>
              )}
            </SignModalDocument>
          </SignModalButtonWrapper>
        </DialogContent>
      </Dialog>

      <Backdrop open={comingSoon} style={{ zIndex: 1, color: '#ffffff' }}>
        <Typography variant="h1">
          <Trans>Coming Soon !!</Trans>
        </Typography>
      </Backdrop>
    </div>
  )
}
export default Alpha
