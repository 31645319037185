import styled from 'styled-components'

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 42px;
  @media only screen and (max-width: 960px) {
    padding: 15px 10px;
  }
`

export const TitleFront = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const TitleIcon = styled.img`
  height: 42px;
  @media only screen and (max-width: 960px) {
    height: 21px;
  }
`
export const TitleIconOne = styled(TitleIcon)`
  width: 42px;
  margin-right: 21px;
  margin-left: 21px;
  @media only screen and (max-width: 960px) {
    width: 21px;
    margin-right: 10.5px;
    margin-left: 10.5px;
  }
`

export const TitleIconTwo = styled(TitleIcon)`
  width: 84px;
  @media only screen and (max-width: 960px) {
    width: 42px;
  }
`

export const TitleTextDiv = styled.div`
  display: flex;
  align-items: center;
`

export const TitleTextMobileHide = styled(TitleTextDiv)`
  @media only screen and (max-width: 960px) {
    display: none;
  }
`

export const TitleTextMainDiv = styled(TitleTextDiv)`
  flex-grow: 1;
  margin-left: 42px;
  @media only screen and (max-width: 960px) {
    margin-left: 20px;
  }
`

export const TitleBack = styled.div`
  width: 140px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const Button = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
`
export const Basic = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin-right: 20px;
  @media only screen and (max-width: 960px) {
    margin-right: 10px;
  }
`

export const BasicContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const BasicText = styled.div`
  width: 150px;
  text-align: right;
  @media only screen and (max-width: 960px) {
    width: 100px;
  }
`

export const BasicTextFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`
export const BasicBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin: 8px 16px;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const BasicBottomLeft = styled.div``
export const BasicBottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    margin-top: 16px;
  }
`
export const BasicBottomRightIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 10px;
`

export const Pool = styled.div`
  margin-top: 24px;
`
export const PoolFlex = styled.div`
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
`
export const PoolFlexCenter = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: center;
`
export const PoolFlexAsset = styled.div`
  padding: 24px 16px;
`
