import { AbstractConnector } from '@web3-react/abstract-connector'
import { injected, walletconnect, ontoconnector } from '../utils/connectors'

import IconMetamask from '../assets/wallets/metamask.svg'
import IconWalletConnect from '../assets/wallets/walletconnect.svg'
import IconCoin98 from '../assets/wallets/coin98.svg'
import IconSafepal from '../assets/wallets/safepal_black.svg'
import IconOnto from '../assets/wallets/onto_white.svg'
import IconMath from '../assets/wallets/math_white.svg'

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  icon?: string
  description: string
  href?: string | null
  color?: string
  primary?: true
  mobile?: true
  mobileOnly?: true
  style?: Record<string,any>
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // INJECTED: {
  //   connector: injected,
  //   name: 'Injected',
  //   // iconURL: INJECTED_ICON_URL,
  //   description: 'Injected web3 provider.',
  //   href: null,
  //   color: '#010101',
  //   primary: true,
  // },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    icon: IconMetamask,
    description: 'Easy-to-use browser extension.',
    href: 'https://metamask.io/download',
    color: '#E8831D',
  },
  COIN98: {
    connector: injected,
    name: 'Coin98',
    icon: IconCoin98,
    description: 'Coin98 browser extension.',
    href: 'https://docs.coin98.com/products/coin98-wallet#coin98-mobile-wallet',
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    icon: IconWalletConnect,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  SAFEPAL: {
    connector: injected,
    name: 'SafePal',
    icon: IconSafepal,
    description: 'SafePal software wallet.',
    href: 'https://blog.safepal.io/developers-guide-how-to-optimize-your-dapp-in-the-safepal-wallet/',
    color: '#E8831D',
  },
  ONTO: {
    connector: ontoconnector,
    name: 'ONTO Wallet',
    icon: IconOnto,
    description: 'ONTO software wallet.',
    href: 'https://ontology-1.gitbook.io/onto-web-help-center/developers/onto-wallet-plugin-integration',
    color: '#E8831D',
    style: {
      width: '16px',
      marginRight: '4px'
    }
  },
  MATH: {
    connector: injected,
    name: 'MathWallet',
    icon: IconMath,
    description: 'MATH software wallet.',
    href: 'https://doc.mathwallet.org/',
    color: '#E8831D'
  },
}
