import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import React, { useContext } from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import styled from 'styled-components'
import getExplorerLink from '../../utils/getExplorerLink'

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const PopupBlock = styled.div`
  display: flex;
`

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useWeb3React()

  return (
    <PopupBlock>
      <div style={{ paddingRight: 16 }}>
        {success ? <CheckCircle color="green" size={24} /> : <AlertCircle size={24} />}
      </div>
      {/* <AutoColumn gap="8px"> */}
      {/* <TYPE.body fontWeight={500}> */}
      <div>
        <div style={{ fontSize: 17, marginBottom: 8 }}>
          {summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}
        </div>
        {/* </TYPE.body> */}
        <StyledLink href={`${getExplorerLink(chainId, hash, 'transaction')}`} target="_blank" rel="noopener noreferrer">
          <Trans>View on Blockchain Explorer</Trans>
        </StyledLink>
      </div>
      {/* <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>View on Etherscan</ExternalLink> */}
      {/* </AutoColumn> */}
    </PopupBlock>
  )
}
