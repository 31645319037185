import { ChainId } from './blockchain'

//defaultValue can be string, string[] or Record<string,string>
function setAddressMap(defaultValue, overwriteMappings = {}) {
  return {
    [ChainId.MAINNET]: defaultValue,
    [ChainId.RINKEBY]: defaultValue,
    [ChainId.BSC]: defaultValue,
    [ChainId.BSC_TEST]: defaultValue,
    ...overwriteMappings,
  }
}

type IAddresses = ReturnType<typeof setAddressMap>
interface IAddress {
  DAI_TOKEN: IAddresses
  NUSD_TOKEN: IAddresses
  NAOS_TOKEN: IAddresses
  UNI_ETH_NAOS_LPTOKEN: IAddresses
  CURVE_NUSD_3CRV_LPTOKEN: IAddresses
  SUSHI_TOKEN: IAddresses
  SUSHI_ETH_NAOS_LPTOKEN: IAddresses
  PANCAKE_NAOS_BNB_LPTOKEN: IAddresses
  ALPHA_TOKEN: IAddresses
  ALPHA_PRO_TOKEN: IAddresses

  FORMATION: IAddresses
  FORMATION_ADAPTER: IAddresses
  STAKINGPOOL: IAddresses
  STAKINGPOOL_WITH_TRANSFER: IAddresses
  TRANSMUTER: IAddresses
  TRANSMUTER_ADAPTER: IAddresses
  SUSHI_CHEF: IAddresses

  GALAXY_ROOT: IAddresses
  GALAXY_STAKING_POOL: IAddresses
  GALAXY_TRANCHE: IAddresses
  GALAXY_RESERVE: IAddresses
  // MEMBERLIST: IRecordAddresses
  GALAXY_PRO_OPERATOR: IAddresses
  GALAXY_PRO_TRANCHE: IAddresses
  GALAXY_PRO_RESERVE: IAddresses

  MEMBERLIST: IAddresses
  BOOST_POOL: IAddresses
  EPOCH_COORDINATOR: IAddresses
  EPOCH_COORDINATOR_PRO: IAddresses
  ASSESSOR: IAddresses
  ASSESSOR_PRO: IAddresses
  BETA_INSURANCE: IAddresses

  FAUCET: IAddresses
  MULTICALL2: IAddresses
}

export const ADDRESS: IAddress = {
  DAI_TOKEN: setAddressMap('0xe9e7cea3dedca5984780bafc599bd69add087d56', {
    [ChainId.MAINNET]: '0x6b175474e89094c44da98b954eedeac495271d0f',
    [ChainId.RINKEBY]: '0xd66c66e605d1656669446dd6b26197b6fe44bf4b',
  }),
  NUSD_TOKEN: setAddressMap('0xf71b4b8aa71f7923c94c7e20b8a434a4d9368eee', {
    [ChainId.MAINNET]: '0x1bef2e5de862034fb0ed456df59d29ecadc9934c',
    [ChainId.RINKEBY]: '0x3db84725cfb969329868d2e11dd34d71347e30cc',
  }),
  NAOS_TOKEN: setAddressMap('0x758d08864fb6cce3062667225ca10b8f00496cc2', {
    [ChainId.MAINNET]: '0x4a615bb7166210cce20e6642a6f8fb5d4d044496',
    [ChainId.RINKEBY]: '0xc83e6391dccf286ca64e67bac361faba69a48a61',
  }),
  UNI_ETH_NAOS_LPTOKEN: setAddressMap('0x9b577e849b128ee1a69144b218e809b8bb98c35d', {
    [ChainId.RINKEBY]: '0xfe69cb13a0435f60783a54c18c011d88a6e770d0',
  }),
  PANCAKE_NAOS_BNB_LPTOKEN: setAddressMap('0xcaa662ad41a662b81be2aea5d59ec0697628665f'), // only on bsc
  CURVE_NUSD_3CRV_LPTOKEN: setAddressMap('0x67d9eae741944d4402eb0d1cb3bc3a168ec1764c'),
  SUSHI_TOKEN: setAddressMap('0x6b3595068778dd592e39a122f4f5a5cf09c90fe2'),
  SUSHI_ETH_NAOS_LPTOKEN: setAddressMap('0x82ebcd936c9e938704b65027850e42393f8bc4d4'),

  FORMATION: setAddressMap('0x9591ff9c5070107000155ff6c5ce049aa1443dd3', {
    [ChainId.MAINNET]: '0x9ddceb30515ed297c1b72ff8f848f254104b7a12',
    [ChainId.RINKEBY]: '0xbee42b04e585ce44ee8a0e976dbfd8a62758a167',
  }),
  FORMATION_ADAPTER: setAddressMap('0x640848400fc1cbaf9e4a1ed18d1bd3e2b16d1de2', {
    [ChainId.MAINNET]: '0x8394bb87481046c1ec84c39689d402c00189df43',
    [ChainId.RINKEBY]: '0x176b79b23638791772ba308486b3717996794589',
  }),
  //Only ETH
  STAKINGPOOL: setAddressMap('0x99e4ea9ef6bf396c49b35ff9478ebb8890aef581', {
    [ChainId.RINKEBY]: '0xf3b970d29f47afb05bf3457335c74a56d7fa2338',
  }),
  //For some BSC and ETH
  STAKINGPOOL_WITH_TRANSFER: setAddressMap('0x6ebc2c41c1e29a5506b86b758b6c16dd5bbcf7d1', {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0x71ff4a0c691e0959a598aad345d110c25eab6ab5',
  }),
  TRANSMUTER: setAddressMap('0xb9ece39b356d5c0842301b42a716e4385617c871', {
    [ChainId.MAINNET]: '0x3ed6355ad74605c0b09415d6b0b29a294fd31265',
    [ChainId.RINKEBY]: '0xaa361260f2f50b33423d08024e328c15c88c455d',
  }),

  TRANSMUTER_ADAPTER: setAddressMap('0x4ca1a19d108b84f6f671ffe3555e7743c5ed6a2c', {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '',
  }),
  SUSHI_CHEF: setAddressMap('0xc2edad668740f1aa35e4d8f227fb8e17dca888cd'),

  GALAXY_ROOT: setAddressMap(['0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40'], {
    [ChainId.MAINNET]: [],
    [ChainId.RINKEBY]: ['0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9'],
  }),

  GALAXY_STAKING_POOL: setAddressMap(
    {
      '0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40': '0x5fD1476FE93D2d6bD524e181ae156DA4aF00f092',
      TestRoot: '0x413AA850ff68086Fbec4af8a37F2bE254D577b70',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x0E084fC19992A81D8917Ee29b4d11E5bea9B3D5D',
        TestRoot: '0x0E084fC19992A81D8917Ee29b4d11E5bea9B3D5D',
      },
    }
  ),

  // GALAXY_STAKING_POOL: {
  //   ETH: {},
  //   BSC: {
  //     '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': [
  //       setAddressMap('0x413AA850ff68086Fbec4af8a37F2bE254D577b70', {
  //         [ChainId.MAINNET]: '',
  //         [ChainId.RINKEBY]: '0x0E084fC19992A81D8917Ee29b4d11E5bea9B3D5D',
  //       }),
  //     ],
  //   },
  //   default: [
  //     setAddressMap('0x0E084fC19992A81D8917Ee29b4d11E5bea9B3D5D', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0x0E084fC19992A81D8917Ee29b4d11E5bea9B3D5D',
  //     }),
  //   ],
  // },

  // GALAXY_TRANCHE: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('0x5dAFFF6A6b32eeCd292420853B3D3dA17ec645D5', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0x712Ec18f151E5cd867c94A7bD305e10bc09854A4',
  //     }),
  //   ],
  // },

  GALAXY_TRANCHE: setAddressMap(
    {
      '0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40': '0x2e07a30Ba047058ed1c360Cbf00CfCb61B07A1aA',
      TestRoot: '0x9C81bFd7E7F832311748662Fae6Fe9999E127277',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x712Ec18f151E5cd867c94A7bD305e10bc09854A4',
        TestRoot: '0x712Ec18f151E5cd867c94A7bD305e10bc09854A4',
      },
    }
  ),

  GALAXY_RESERVE: setAddressMap(
    {
      '0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40': '0x9eEd257f7d5Bed450BC2B3562B24113C9fbD848b',
      TestRoot: '0xdfe436143f8c52570c19efedad86db1da90ef939',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x7f3611c68ef73e4e933ab3fcbfdd810599221941',
        TestRoot: '0x7f3611c68ef73e4e933ab3fcbfdd810599221941',
      },
    }
  ),

  GALAXY_PRO_OPERATOR: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0x84919Ce9CD84B2D87636A68C99FE8f2Aa176FbB7',
      TestRoot: '0x84919Ce9CD84B2D87636A68C99FE8f2Aa176FbB7',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x54c65AD9649EDB552611012c7f2594EF64913185',
        TestRoot: '0x54c65AD9649EDB552611012c7f2594EF64913185',
      },
    }
  ),

  // GALAXY_PRO_OPERATOR: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0x54c65AD9649EDB552611012c7f2594EF64913185',
  //     }),
  //   ],
  // },

  GALAXY_PRO_TRANCHE: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0x9C81bFd7E7F832311748662Fae6Fe9999E127277',
      TestRoot: '0x9C81bFd7E7F832311748662Fae6Fe9999E127277',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x712Ec18f151E5cd867c94A7bD305e10bc09854A4',
        TestRoot: '0x712Ec18f151E5cd867c94A7bD305e10bc09854A4',
      },
    }
  ),

  GALAXY_PRO_RESERVE: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0xdfe436143f8c52570c19efedad86db1da90ef939',
      TestRoot: '0xdfe436143f8c52570c19efedad86db1da90ef939',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x7f3611c68ef73e4e933ab3fcbfdd810599221941',
        TestRoot: '0x7f3611c68ef73e4e933ab3fcbfdd810599221941',
      },
    }
  ),

  // GALAXY_PRO_TRANCHE: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('0x5dAFFF6A6b32eeCd292420853B3D3dA17ec645D5', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0x712Ec18f151E5cd867c94A7bD305e10bc09854A4',
  //     }),
  //   ],
  // },

  // GALAXY_PRO_RESERVE: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('0x0A9dAb63E1505522374d64437c89f3dD1769c33E', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0x7f3611c68ef73e4e933ab3fcbfdd810599221941',
  //     }),
  //   ],
  // },

  MEMBERLIST: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0x20b880A99ec2fd7b75843C640D601bD25D35bb8f',
      TestRoot: '0x20b880A99ec2fd7b75843C640D601bD25D35bb8f',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0xF70852767cC7A8Bf9c9010C8Facd78b50fFC71c9',
        TestRoot: '0xF70852767cC7A8Bf9c9010C8Facd78b50fFC71c9',
      },
    }
  ),

  // MEMBERLIST: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('0xF70852767cC7A8Bf9c9010C8Facd78b50fFC71c9', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0xF70852767cC7A8Bf9c9010C8Facd78b50fFC71c9',
  //     }),
  //   ],
  // },

  BOOST_POOL: setAddressMap('0x3dcd32dd2b225749aa830ca3b4f2411bfeb03db4', {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0x873b599831dc53466e03093120b859fc2ac36546',
  }),
  EPOCH_COORDINATOR: setAddressMap(
    {
      '0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40': '0xC451F6Fb645C62Ab48a8fc0601d526BE5e125244',
      TestRoot: '0x112e317dB8D1B3cEC4351A0FD98d8583De592061',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x77d5e1612254d9504e741e6d22bd0dbe3f9023f1',
        TestRoot: '0x77d5e1612254d9504e741e6d22bd0dbe3f9023f1',
      },
    }
  ),
  EPOCH_COORDINATOR_PRO: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0x112e317dB8D1B3cEC4351A0FD98d8583De592061',
      TestRoot: '0x112e317dB8D1B3cEC4351A0FD98d8583De592061',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0x77d5e1612254d9504e741e6d22bd0dbe3f9023f1',
        TestRoot: '0x77d5e1612254d9504e741e6d22bd0dbe3f9023f1',
      },
    }
  ),
  // EPOCH_COORDINATOR: setAddressMap('0xdC2dE8fcCd2C30b020fc1c7A125C1186056582B6', {
  //   [ChainId.MAINNET]: '',
  //   [ChainId.RINKEBY]: '0x77d5e1612254d9504e741e6d22bd0dbe3f9023f1',
  // }),
  // EPOCH_COORDINATOR_PRO: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('0xdC2dE8fcCd2C30b020fc1c7A125C1186056582B6', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0x77d5e1612254d9504e741e6d22bd0dbe3f9023f1',
  //     }),
  //   ],
  // },
  ASSESSOR: setAddressMap(
    {
      '0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40': '0x2E6FB688635A8C85D2B93e70087ed35c87ACe1D1',
      TestRoot: '0xee8acA43a032f787786Ef4F2E0CcAaD8370b5090',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0xa0c8dd06386b766b3d84ed1e736c5e079e6b4f4a',
        TestRoot: '0xa0c8dd06386b766b3d84ed1e736c5e079e6b4f4a',
      },
    }
  ),
  ASSESSOR_PRO: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0xee8acA43a032f787786Ef4F2E0CcAaD8370b5090',
      TestRoot: '0xee8acA43a032f787786Ef4F2E0CcAaD8370b5090',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0xa0c8dd06386b766b3d84ed1e736c5e079e6b4f4a',
        TestRoot: '0xa0c8dd06386b766b3d84ed1e736c5e079e6b4f4a',
      },
    }
  ),
  // ASSESSOR: setAddressMap('0x9365CCe2C734712a933D8F2BBFA3389e44f2ad6F', {
  //   [ChainId.MAINNET]: '',
  //   [ChainId.RINKEBY]: '0xa0c8dd06386b766b3d84ed1e736c5e079e6b4f4a',
  // }),
  // ASSESSOR_PRO: {
  //   ETH: [],
  //   BSC: [],
  //   default: [
  //     setAddressMap('0x9365CCe2C734712a933D8F2BBFA3389e44f2ad6F', {
  //       [ChainId.MAINNET]: '',
  //       [ChainId.RINKEBY]: '0xa0c8dd06386b766b3d84ed1e736c5e079e6b4f4a',
  //     }),
  //   ],
  // },
  ALPHA_TOKEN: setAddressMap(
    {
      '0xBA2EB07faAF61Cc035DAFF9f6F8DE5147207fb40': '0x0B5c5A982197cA4C06aff9642Fdab984fF284e12',
      TestRoot: '0xEf9E8151dEa82787aEF3e90E70dC7e9b0d0ae513',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0xd484b4ebd11c2d09f71fe56b5e8615dba91d292d',
        TestRoot: '0xd484b4ebd11c2d09f71fe56b5e8615dba91d292d',
      },
    }
  ),
  // ALPHA_TOKEN: setAddressMap('0x9Ac40cB55a37c90A1851a902c07a59DeCBC8BEF7', {
  //   [ChainId.MAINNET]: '',
  //   [ChainId.RINKEBY]: '0xd484b4ebd11c2d09f71fe56b5e8615dba91d292d',
  // }),
  ALPHA_PRO_TOKEN: setAddressMap(
    {
      '0x5C531B5085c30CA5cFaFB177DB3F222D35039d53': '0xEf9E8151dEa82787aEF3e90E70dC7e9b0d0ae513',
      TestRoot: '0xEf9E8151dEa82787aEF3e90E70dC7e9b0d0ae513',
    },
    {
      [ChainId.MAINNET]: {},
      [ChainId.RINKEBY]: {
        '0x31c0DEa38942DA3b57B3569AdC515A9706f39ae9': '0xd484b4ebd11c2d09f71fe56b5e8615dba91d292d',
        TestRoot: '0xd484b4ebd11c2d09f71fe56b5e8615dba91d292d',
      },
    }
  ),
  BETA_INSURANCE: setAddressMap('0xcd2e1ebfc804dd867ca052133fa70d9db6d86ab7', {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0x1d258b0828df640720bcc55932c779f882060004',
  }),

  FAUCET: setAddressMap('0x80c42708841b96a58cac6cc0be765c2ff40c258d'), // only on rinkeby

  MULTICALL2: setAddressMap('0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4', {
    [ChainId.MAINNET]: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    [ChainId.RINKEBY]: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    [ChainId.BSC]: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
  })
}

export default ADDRESS