import { Contract } from 'ethers'
import { abi as ERC20_ABI } from '../../abis/ERC20Mock.json'
import { abi as StakingPool_ABI } from '../../abis/StakingPool.json'
import { abi as Formation_ABI } from '../../abis/Formation.json'
import { abi as Transmuter_ABI } from '../../abis/Transmuter.json'
import { abi as NToken_ABI } from '../../abis/NToken.json'
import { abi as Faucet_ABI } from '../../abis/Faucet.json'
import { abi as Adapter_ABI } from '../../abis/YearnVaultAdapter.json'
import { abi as SushiChef_ABI } from '../../abis/SushiMasterChef.json'
import { abi as UniswapPair_ABI } from '../../abis/UniswapV2Pair.json'
import { abi as GalaxyStakingPools_ABI } from '../../abis/GalaxyStakingPools.json'
import { abi as BoostPool_ABI } from '../../abis/BoostPool.json'
import { abi as EpochCoordinator_ABI } from '../../abis/EpochCoordinator.json'
import { abi as Assessor_ABI } from '../../abis/Assessor.json'
import { abi as GalaxyReserve_ABI } from '../../abis/GalaxyReserve.json'
import { abi as BetaInsurance_ABI } from '../../abis/BetaInsurance.json'
import { abi as Tranche_ABI } from '../../abis/Tranche.json'
import { abi as Operator_ABI } from '../../abis/Operator.json'
import { abi as Memberlist_ABI } from '../../abis/Memberlist.json'
import { abi as Multicall2_ABI } from '../../abis/Multicall2.json'

import { useMemo } from 'react'
import { getContract } from '../../utils/getContract'
import {
  ERC20Mock,
  StakingPool,
  Formation,
  Transmuter,
  NToken,
  Faucet,
  YearnVaultAdapter,
  SushiMasterChef,
  UniswapV2Pair,
  GalaxyStakingPools,
  BoostPool,
  EpochCoordinator,
  Assessor,
  GalaxyReserve,
  BetaInsurance,
  Tranche,
  Operator,
  Memberlist,
  Multicall2,
} from '../../abis/types'
import { useWeb3React } from '@web3-react/core'

export type IAddressOrAddressMap = string | { [chainId: number]: string }

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: IAddressOrAddressMap | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null

    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') {
      address = addressOrAddressMap
    } else {
      address = addressOrAddressMap[chainId]
    }

    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<ERC20Mock>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useNTokenContract(tokenAddress?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<NToken>(tokenAddress, NToken_ABI, withSignerIfPossible)
}

export function useStakingPoolContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<StakingPool>(address, StakingPool_ABI, withSignerIfPossible)
}

export function useSushiChefContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<SushiMasterChef>(address, SushiChef_ABI, withSignerIfPossible)
}

export function useFormationContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Formation>(address, Formation_ABI, withSignerIfPossible)
}

export function useAdapterContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<YearnVaultAdapter>(address, Adapter_ABI, withSignerIfPossible)
}

export function useTransmuterContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Transmuter>(address, Transmuter_ABI, withSignerIfPossible)
}

export function useTrancheContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Tranche>(address, Tranche_ABI, withSignerIfPossible)
}

export function useOperatorContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Operator>(address, Operator_ABI, withSignerIfPossible)
}

export function useMemberlistContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Memberlist>(address, Memberlist_ABI, withSignerIfPossible)
}

export function useFaucetContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Faucet>(address, Faucet_ABI, withSignerIfPossible)
}

export function useUniswapV2PairContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<UniswapV2Pair>(address, UniswapPair_ABI, withSignerIfPossible)
}

export function useGalaxyStakingPoolContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<GalaxyStakingPools>(address, GalaxyStakingPools_ABI, withSignerIfPossible)
}

export function useGalaxyReserveContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<GalaxyReserve>(address, GalaxyReserve_ABI, withSignerIfPossible)
}

export function useBoostPoolContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<BoostPool>(address, BoostPool_ABI, withSignerIfPossible)
}

export function useEpochCoordinatorContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<EpochCoordinator>(address, EpochCoordinator_ABI, withSignerIfPossible)
}

export function useAssessorContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Assessor>(address, Assessor_ABI, withSignerIfPossible)
}

export function useBetaInsuranceContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<BetaInsurance>(address, BetaInsurance_ABI, withSignerIfPossible)
}

export function useMulticallContract(address?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<Multicall2>(address, Multicall2_ABI, withSignerIfPossible)
}