import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { useTransmuterContract, IAddressOrAddressMap } from './useContract'

export function useTransmuterInfo(
  address?: IAddressOrAddressMap,
  account?: string
): [stakeNusd: BigNumber, transmutableDai: BigNumber, totalSupplyNtoken: BigNumber, availableTransfer: BigNumber] {
  const blockNumber = useBlockNumber()
  const transmuterContract = useTransmuterContract(address)
  const [stakeNusd, setStakeNusd] = useState<BigNumber>(BigNumber.from(0))
  const [transmutableDai, setTransmutableDai] = useState<BigNumber>(BigNumber.from(0))
  const [totalSupplyNtoken, setTotalSupplyNtoken] = useState<BigNumber>(BigNumber.from(0))
  const [availableTransfer, setAvailableTransfer] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (transmuterContract && account) {
      transmuterContract.depositedNTokens(account).then(setStakeNusd)
      transmuterContract.userInfo(account).then((v) => {
        setTransmutableDai(v.pendingdivs.add(v.inbucket))
      })
      transmuterContract.totalSupplyNtokens().then(setTotalSupplyNtoken)
      Promise.all([transmuterContract.buffer(), transmuterContract.unclaimedDividends()]).then(
        ([buffer, unclaimed]) => {
          setAvailableTransfer(buffer.add(unclaimed))
        }
      )
    }
  }, [transmuterContract, account, blockNumber])

  return useMemo(
    () => [stakeNusd, transmutableDai, totalSupplyNtoken, availableTransfer],
    [stakeNusd, transmutableDai, totalSupplyNtoken, availableTransfer]
  )
}
