import { i18n } from '@lingui/core'
import { Grid, useTheme } from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language'
import { useState } from 'react'

import { activate, LANGUAGE } from '.'
import { LanguageMenu, LanguageOption, OpenButton } from '../AccountStatus/styled'

const langaugeOptionName = {
  [LANGUAGE.EN]: 'English',
  [LANGUAGE.ZHCN]: '简体中文',
  [LANGUAGE.ZHTW]: '繁體中文',
  [LANGUAGE.KO]: '한국어',
  [LANGUAGE.RU]: 'Русский',
  [LANGUAGE.TR]: 'Türkçe',
  [LANGUAGE.ES]: 'Español',
  [LANGUAGE.PT]: 'Português',
  [LANGUAGE.VI]: 'Tiếng Việt',
}

/**
 * language locale controller
 */
const LanguageStatus = () => {
  let local = i18n.locale
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSlectLangauge = (option) => {
    if (option && local !== option) {
      activate(option)
    }
    handleClose()
  }

  return (
    <Grid
      style={{
        color: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'stretch',
        position: 'relative',
      }}
    >
      <OpenButton onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        <LanguageIcon />
      </OpenButton>
      {open && (
        <LanguageMenu onMouseEnter={handleOpen} onMouseLeave={handleClose}>
          {Object.keys(langaugeOptionName).map((key) => (
            <LanguageOption
              key={`language-option-${key}`}
              disable={local === key}
              onClick={() => handleSlectLangauge(key)}
            >
              {langaugeOptionName[key]}
            </LanguageOption>
          ))}
        </LanguageMenu>
      )}
    </Grid>
  )
}

export default LanguageStatus
