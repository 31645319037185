import { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { utils } from 'ethers'
import {
  useMediaQuery,
  useTheme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  createStyles,
  Theme,
  makeStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { Trans, t } from '@lingui/macro'

import GradientCard from '../../common/GradientCard'
import ActionBoard from '../../common/ActionBoard'
import {
  TitleDiv,
  TitleFront,
  TitleTextDiv,
  TitleBackContainer,
  TitleBack,
  Button,
  BasicTextFlex,
  Pool,
  PoolFlex,
  PoolFlexCenter,
  PoolFlexAsset,
} from './styled'
import StakeDrawerContent from './actionContents/stake'
import UnstakeDrawerContent from './actionContents/unstake'
import InformationCard from '../../common/InformationCard'
import ADDRESS from '../../constants/addresses'
import { MainButton } from '../../common/buttons'

import { useTransmuterInfo } from '../../hooks/smartContracts/useTransmuterInfo'
import { useAdapterInfo } from '../../hooks/smartContracts/useVaultAdapterInfo'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { useVaultStableCoinApr } from '../../hooks/useVaultStableCoinApr'
import { useTransmuterContract } from '../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { displayBalance } from '../../utils/displayBalance'
import { ChainId } from '../../constants/blockchain'
import { usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'
import { MapIcon } from '../../common/MapIcon'

const LeftTypography = styled(Typography)`
  text-align: left;
`
const RightTypography = styled(Typography)`
  text-align: right;
`

enum TransmuterAction {
  EXIT,
  STAKE,
  UNSTAKE,
  TRANSMUTE,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
      '&:hover': {
        borderRadius: '16px',
      },
    },
    disableTitleButtonExit: {
      color: 'rgba(255, 255, 255, 0.2)',
      border: `1px solid rgba(255, 255, 255, 0.2)`,
      backgroundColor: 'transparent',
      marginRight: '8px',
    },
    transStatusDetial: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '16px 0 16px 16px',
      [theme.breakpoints.down(960)]: {
        padding: '16px 0 0 0',
      },
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      maxWidth: 280,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
  })
)

const Transmute = () => {
  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false, false])
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const collateralTokenName = useMemo(
    () => (chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || !chainId ? 'BUSD' : 'DAI'),
    [chainId]
  )
  const mintTokenName = useMemo(
    () => (chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || !chainId ? 'nBUSD' : 'nUSD'),
    [chainId]
  )

  const transmuterContract = useTransmuterContract(ADDRESS.TRANSMUTER)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, account ?? undefined)
  const [stakeNusd, transmutableDai, totalSupplyNtoken, availableTransfer] = useTransmuterInfo(
    ADDRESS.TRANSMUTER,
    account ?? undefined
  )
  const apr = useVaultStableCoinApr(chainId)
  const [formationAdapterDai] = useAdapterInfo(ADDRESS.FORMATION_ADAPTER)
  const [transmuterAdapterDai] = useAdapterInfo(ADDRESS.TRANSMUTER_ADAPTER)
  const transmutableRatio = useMemo(() => {
    if (stakeNusd.gt(0)) {
      const ratioBN = transmutableDai.mul(100).div(stakeNusd)
      return Math.min(ratioBN.toNumber(), 100)
    }
    return 100
  }, [transmutableDai, stakeNusd])

  const closeDrawer = () => {
    setDrawerOpen([false, false, false, false])
  }

  const openDrawer = (type) => {
    const status = [...isDrawerOpen]
    status[type] = true
    setDrawerOpen(status)
  }

  async function handleTransmute() {
    if (transmuterContract && transmutableDai.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await transmuterContract.transmuteAndClaim()
        openConfirmingModal(false, res.hash)
        const minReward = Math.min(+utils.formatEther(stakeNusd), +utils.formatEther(transmutableDai))
        addTransaction(res, {
          summary: `Transmute ${displayBalance(minReward)} ${mintTokenName}`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleExit() {
    if (transmuterContract) {
      if (stakeNusd.lte(0) && transmutableDai.lte(0)) return
      openConfirmingModal(true)
      try {
        const res = await transmuterContract.transmuteClaimAndWithdraw()
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Transmuter: Exit ${mintTokenName} to ${collateralTokenName} `,
        })
        setDrawerOpen([false, false, false, false])
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.informationBox}>
          <TitleDiv>
            <TitleFront>
              <MapIcon cusStyled={{ width: '40px' }} name={mintTokenName} />
              <TitleTextDiv>
                <Typography variant="body2" className={classes.greyHeader}>
                  NAOS{' '}
                </Typography>
                <Typography variant="h3">
                  <Trans>
                    {mintTokenName} to {collateralTokenName} Transmuter
                  </Trans>
                </Typography>
              </TitleTextDiv>
            </TitleFront>
            <TitleBackContainer>
              <TitleBack
                className={stakeNusd.lte(0) ? classes.disableTitleButtonExit : classes.titleButtonExit}
                onClick={() => stakeNusd.gt(0) && openDrawer(TransmuterAction.EXIT)}
              >
                <Typography variant="body1" color="inherit">
                  <Trans>EXIT</Trans>
                </Typography>
              </TitleBack>
              <TitleBack className={classes.titleButton} onClick={() => openDrawer(TransmuterAction.STAKE)}>
                <Typography variant="body1">
                  <Trans>STAKE</Trans>
                </Typography>
              </TitleBack>
            </TitleBackContainer>
          </TitleDiv>

          <Pool style={{ marginTop: `0px` }}>
            <PoolFlex style={{ marginTop: `0px` }}>
              <GradientCard width={isPC ? '48%' : '100%'} height={isPC ? '216px' : '46.93vw'}>
                <PoolFlexAsset>
                  <BasicTextFlex>
                    <Typography variant="h2">
                      <Trans>Global Transmuter Status</Trans>
                    </Typography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Total Staked {mintTokenName}</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(totalSupplyNtoken)} {mintTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  {/* <BasicTextFlex>
                    <LeftTypography variant="body1">Yield Strategy APY</LeftTypography>
                    <RightTypography variant="body1">{apr} %</RightTypography>
                  </BasicTextFlex> */}
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Total {collateralTokenName} Deposited in Vault</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(formationAdapterDai.add(transmuterAdapterDai))} {collateralTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  {/* <BasicTextFlex>
                    <Typography variant="body2">Estimated DAI Daily Yield</Typography>
                    <Typography variant="body2">12.4 DAI</Typography>
                  </BasicTextFlex> */}
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Total {collateralTokenName} Available for Transmute</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(availableTransfer)} {collateralTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  {/* <BasicTextFlex>
                    <Typography variant="body2">Yearly transmutation rate</Typography>
                    <Typography variant="body2">12.4 %</Typography>
                  </BasicTextFlex> */}
                </PoolFlexAsset>
              </GradientCard>

              {isPC ? <></> : <div style={{ height: '16px' }}></div>}

              <GradientCard width={isPC ? '48%' : '100%'} height={isPC ? '216px' : '72.53vw'}>
                <PoolFlexAsset>
                  <BasicTextFlex style={{ marginBottom: 16 }}>
                    <Typography variant="h2">
                      <Trans>Your Transmute Status</Trans>
                    </Typography>
                  </BasicTextFlex>
                  <Grid
                    container
                    direction={isPC ? 'row' : 'column'}
                    justifyContent={isPC ? 'space-between' : 'center'}
                  >
                    <Grid style={{ position: 'relative', width: 128, height: 128, margin: '0 auto' }}>
                      <div style={{ position: 'absolute' }}>
                        <CircularProgress
                          variant="determinate"
                          value={transmutableRatio}
                          style={{ position: 'absolute', color: '#238888' }}
                          size={128}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={100 - transmutableRatio}
                          style={{
                            position: 'absolute',
                            color: 'rgba(255,255,255,0.2)',
                            transform: `rotate(${360 * (transmutableRatio / 100) - 90}deg)`,
                          }}
                          size={128}
                        />
                        <Grid style={{ width: 128, textAlign: 'center' }}>
                          <Typography variant="h2" style={{ lineHeight: '128px' }}>
                            {transmutableRatio}%
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid className={classes.transStatusDetial}>
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ padding: '0px 8px', borderLeft: '3px solid rgba(255,255,255,0.2)' }}
                      >
                        <Typography variant="body1">
                          <Trans>Staked {mintTokenName}</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {displayBalance(stakeNusd)} {mintTokenName}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ padding: '0px 8px', borderLeft: '3px solid #238888', marginTop: 8 }}
                      >
                        <Typography variant="body1">
                          <Trans>Transmutable {collateralTokenName} Quota</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {displayBalance(transmutableDai)} {collateralTokenName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </PoolFlexAsset>
              </GradientCard>
            </PoolFlex>
          </Pool>

          <Pool>
            <Typography variant="h2">
              <Trans>
                Transmute {mintTokenName} to {collateralTokenName}
              </Trans>
            </Typography>
            <PoolFlex>
              <GradientCard width={isPC ? '31%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(nusdBalance)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>{mintTokenName} Balance</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <Button
                    className={classes.titleButton}
                    onClick={() => {
                      openDrawer(TransmuterAction.STAKE)
                    }}
                  >
                    <Typography variant="subtitle2">
                      <Trans>STAKE</Trans>
                    </Typography>
                  </Button>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '31%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(stakeNusd)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>{mintTokenName} Staked</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton
                    className={classes.titleButton}
                    onClick={() => {
                      openDrawer(TransmuterAction.UNSTAKE)
                    }}
                  >
                    <Typography variant="subtitle2" className={stakeNusd.lte(0) ? classes.disableText : ''}>
                      <Trans>UNSTAKE</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '31%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(transmutableDai)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>Transmutable {collateralTokenName} Quota</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton
                    onClick={handleTransmute}
                    disable={stakeNusd.lte(0) || transmutableDai.lte(0)}
                    disableStyle={'solid'}
                  >
                    <Typography
                      variant="subtitle2"
                      className={stakeNusd.lte(0) || transmutableDai.lte(0) ? classes.disableText : ''}
                    >
                      <Trans>TRANSMUTE</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
              {/* {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '23%' : '100%'} height="244px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(daiBalance)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">DAI Balance</Typography>
                </PoolFlexCenter>
              </GradientCard> */}
            </PoolFlex>
          </Pool>
          <div className={classes.bottomSpace}></div>
        </div>
      </div>

      <ActionBoard open={isDrawerOpen[TransmuterAction.STAKE]} setClose={closeDrawer}>
        <InformationCard title={t`Stake`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <StakeDrawerContent />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[TransmuterAction.UNSTAKE]} setClose={closeDrawer}>
        <InformationCard title={t`Unstake`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <UnstakeDrawerContent />
        </InformationCard>
      </ActionBoard>

      <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={isDrawerOpen[TransmuterAction.EXIT]}
        onClose={closeDrawer}
      >
        <DialogTitle>
          <Typography variant="h3">
            <Trans>Transmute & Exit?</Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4">
            <Trans>
              Transmute available {collateralTokenName} and unstake {mintTokenName} back to your wallet.
            </Trans>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 24 }}>
          <MuiButton onClick={handleExit}>
            <Trans>EXIT</Trans>
          </MuiButton>
          <MuiButton onClick={closeDrawer} style={{ marginLeft: 0 }}>
            <Trans>Discard</Trans>
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default Transmute
