import { useMemo, useRef, useState } from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Typography, Grid, Checkbox as MuiCheckbox } from '@material-ui/core'
import styled from 'styled-components'
import { Trans, t } from '@lingui/macro'

import { useOpenConfirmingModal } from '../../../redux/application/hooks'
import { useTransactionAdder } from '../../../redux/transactions/hooks'
import { useFormationContract } from '../../../hooks/smartContracts/useContract'
import { useTokenBalance } from '../../../hooks/smartContracts/useTokenBalance'
import { ApprovalState, useApproveCallback } from '../../../hooks/smartContracts/useApproveCallback'
import { useFormationInfo } from '../../../hooks/smartContracts/useFormationInfo'

import ADDRESS from '../../../constants/addresses'
import { ChainId } from '../../../constants/blockchain'
import { MainButton } from '../../../common/buttons'
import AmountInput, { IAmountInputRefObj } from '../../../common/AmountInput'
import { Divider } from '../../../common/Divider'
import { displayBalance } from '../../../utils/displayBalance'
import { PoolFlexCenter } from '../styled'

const Checkbox = styled(MuiCheckbox)`
  color: #1d77ff;
  padding: unset;

  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: #1d77ff;
  }
`

const RepayDrawerContent = () => {
  const { account, chainId } = useWeb3React()
  const daiAmountInput = useRef<IAmountInputRefObj>(null)
  const nusdAmountInput = useRef<IAmountInputRefObj>(null)

  const collateralTokenName = useMemo(() => (chainId === ChainId.BSC ? 'BUSD' : 'DAI'), [chainId])
  const mintTokenName = useMemo(() => (chainId === ChainId.BSC ? 'nBUSD' : 'nUSD'), [chainId])

  const formationContract = useFormationContract(ADDRESS.FORMATION)
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, account ?? undefined)

  const [repayWithDai, setRepayWithDai] = useState(true)
  const [repayWithNusd, setRepayWithNusd] = useState(false)

  const [inputDaiBignumber, setInputDaiBignumber] = useState(BigNumber.from(0))
  const [inputNusdBignumber, setInputNusdBignumber] = useState(BigNumber.from(0))
  const [isNusdApprove, nusdApprove] = useApproveCallback(
    ADDRESS.NUSD_TOKEN,
    repayWithNusd ? inputNusdBignumber : BigNumber.from(0),
    ADDRESS.FORMATION[chainId ?? 1]
  )

  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [_, borrowedNusd] = useFormationInfo(ADDRESS.FORMATION, account ?? undefined)

  async function handleRepay() {
    if (isNusdApprove !== ApprovalState.APPROVED) {
      await nusdApprove()
    } else {
      let repayAmount = BigNumber.from(0)
      repayAmount = repayWithDai ? repayAmount.add(inputDaiBignumber) : repayAmount
      repayAmount = repayWithNusd ? repayAmount.add(inputNusdBignumber) : repayAmount

      if (formationContract && repayAmount.gt(0)) {
        openConfirmingModal(true)
        try {
          const res = await formationContract.repay(
            repayWithDai ? inputDaiBignumber : BigNumber.from(0),
            repayWithNusd ? inputNusdBignumber : BigNumber.from(0)
          )
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Repay ${displayBalance(repayAmount)} ${mintTokenName} debt`,
          })
          daiAmountInput.current?.resetAmount()
          nusdAmountInput.current?.resetAmount()
        } catch (error) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>
          Repay the remaining {displayBalance(borrowedNusd)} {mintTokenName} debt with {collateralTokenName} and/or{' '}
          {mintTokenName}.
        </Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>Remaining {mintTokenName} Debt</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(borrowedNusd)} {mintTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />

      <Grid container alignItems="center" style={{ marginTop: 24 }}>
        <Checkbox checked={repayWithDai} onChange={() => setRepayWithDai(!repayWithDai)} defaultChecked />
        <Typography variant="body2" style={{ marginLeft: 12 }}>
          <Trans>Repay with {collateralTokenName}</Trans>
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
        <Typography variant="body2">
          <Trans>{collateralTokenName} balance</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(daiBalance)} {collateralTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={daiAmountInput}
        maxNum={daiBalance}
        amount={inputDaiBignumber}
        setAmount={setInputDaiBignumber}
        textFieldLabel={collateralTokenName}
        showPercentage={false}
      />

      <Grid container alignItems="center" style={{ marginTop: 40 }}>
        <Checkbox checked={repayWithNusd} onChange={() => setRepayWithNusd(!repayWithNusd)} />
        <Typography variant="body2" style={{ marginLeft: 12 }}>
          <Trans>Repay with {mintTokenName}</Trans>
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
        <Typography variant="body2">
          <Trans>{mintTokenName} balance</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(nusdBalance)} {mintTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={nusdAmountInput}
        maxNum={nusdBalance}
        amount={inputNusdBignumber}
        setAmount={setInputNusdBignumber}
        textFieldLabel={mintTokenName}
        showPercentage={false}
      />
      {/* <PoolFlexCenter style={{ marginTop: `40px`, justifyContent: `flex-end` }}>
        <MainButton onClick={handleRepay}>
          <Typography variant="body1">{isNusdApprove === ApprovalState.APPROVED ? 'REPAY' : 'APPROVE'}</Typography>
        </MainButton>
      </PoolFlexCenter> */}
      <MainButton onClick={handleRepay} style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">{isNusdApprove === ApprovalState.APPROVED ? t`REPAY` : t`APPROVE`}</Typography>
      </MainButton>
    </>
  )
}
export default RepayDrawerContent
