import { BigNumber } from '@ethersproject/bignumber'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useBoostPoolContract, useGalaxyStakingPoolContract } from './useContract'
import { useMulticallContract } from '../smartContracts/useContract'
import ADDRESS from '../../constants/addresses'
import multicall from '../../utils/multicall'
import BigNumberJs from 'bignumber.js'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { SEC_PER_BLOCK_CHAIN } from '../../constants/blockchain'
import { useTokenPrice } from '../useTokenPrice'

export function useCalcMaxRatioAmount(
  alphaDepositAmount: BigNumber,
  galaxyAddress: IAddressOrAddressMap,
  chainId: number,
  constData: Record<string, any>,
  countWithInput: boolean,
  inputAmount: number,
  work: boolean
): [BigNumberJs, BigNumber[]] {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyAddress)
  const naosPrice = useTokenPrice('NAOS')
  const [updatedAPR, setUpdatedAPR] = useState<BigNumberJs>(new BigNumberJs(0))
  const [maxRatioNAOS, SetMaxRatioNAOS] = useState<BigNumber[]>([
    BigNumber.from(0),
    BigNumber.from(0),
    BigNumber.from(0),
    BigNumber.from(0),
  ])

  useEffect(() => {
    if (
      work &&
      (alphaDepositAmount.gt(0) || inputAmount > 0) &&
      chainId &&
      constData &&
      galaxyContract &&
      naosPrice &&
      constData.bigNumber_poolDeposited.sub(constData.bigNumber_userDeposited).gt(0)
    ) {
      const newUserDepositAmount = parseEther(inputAmount.toString())
      let dividend = constData.bigNumber_userDeposited
        .add(newUserDepositAmount)
        .mul(constData.bigNumber_boostPoolDepositedWeight)
        .sub(
          constData.bigNumber_poolDeposited.add(newUserDepositAmount).mul(constData.bigNumber_boostUserDepositedWeight)
        )
      let divisor = constData.bigNumber_poolDeposited.sub(constData.bigNumber_userDeposited)
      let newInput = dividend.div(divisor)

      if (countWithInput && newInput.lte(0)) {
        SetMaxRatioNAOS([BigNumber.from(0), BigNumber.from(0), BigNumber.from(0), BigNumber.from(0)])
      } else {
        SetMaxRatioNAOS([
          newInput.add(BigNumber.from('1000000000000000000')),
          newInput.div(4).add(BigNumber.from('1000000000000000000')),
          newInput.div(10).add(BigNumber.from('1000000000000000000')),
          newInput.div(25).add(BigNumber.from('1000000000000000000')),
        ])
      }

      if (constData.bigNumber_userDeposited.add(newUserDepositAmount).gt(0)) {
        galaxyContract
          .calcUserWeight(
            constData.bigNumber_poolDeposited.add(newUserDepositAmount),
            constData.bigNumber_userDeposited.add(newUserDepositAmount),
            constData.bigNumber_boostPoolDepositedWeight.add(newInput),
            constData.bigNumber_boostUserDepositedWeight.add(newInput)
          )
          .then((res) => {
            const newUserWeight = new BigNumberJs(formatEther(res))
            const newTotalWeight = constData.poolDepositedWeight
              .minus(constData.userDepositedWeight)
              .plus(newUserWeight)
            const share = newUserWeight.div(newTotalWeight)
            const rewardPerSec = constData.rewardRate.div(SEC_PER_BLOCK_CHAIN[chainId])
            const newNAOS_APR = share
              .multipliedBy(rewardPerSec)
              .multipliedBy(60)
              .multipliedBy(60)
              .multipliedBy(24)
              .multipliedBy(365)
              .multipliedBy(naosPrice)
              .multipliedBy(100)
              .div(constData.userDeposited.plus(new BigNumberJs(formatEther(newUserDepositAmount))))

            setUpdatedAPR(newNAOS_APR)
          })
      } else {
        setUpdatedAPR(new BigNumberJs(0))
      }
    } else {
      setUpdatedAPR(new BigNumberJs(0))
      SetMaxRatioNAOS([BigNumber.from(0), BigNumber.from(0), BigNumber.from(0), BigNumber.from(0)])
    }
  }, [chainId, constData, naosPrice, galaxyContract, alphaDepositAmount, inputAmount])

  return useMemo(() => {
    return [updatedAPR, maxRatioNAOS]
  }, [updatedAPR, maxRatioNAOS])
}
