import { useMemo, useRef, useState } from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Typography, Grid } from '@material-ui/core'
import { Trans } from '@lingui/macro'

import { useOpenConfirmingModal } from '../../../redux/application/hooks'
import { useTransactionAdder } from '../../../redux/transactions/hooks'
import { useTransmuterContract } from '../../../hooks/smartContracts/useContract'
import { useTransmuterInfo } from '../../../hooks/smartContracts/useTransmuterInfo'
import ADDRESS from '../../../constants/addresses'
import { MainButton } from '../../../common/buttons'
import AmountInput, { IAmountInputRefObj } from '../../../common/AmountInput'
import { Divider } from '../../../common/Divider'
import { displayBalance } from '../../../utils/displayBalance'
import { PoolFlexCenter } from '../styled'
import { ChainId } from '../../../constants/blockchain'

const UnstakeDrawerContent = () => {
  const { account, chainId } = useWeb3React()
  const amountInput = useRef<IAmountInputRefObj>(null)

  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )

  const transmuteContract = useTransmuterContract(ADDRESS.TRANSMUTER)
  const [stakeNusd] = useTransmuterInfo(ADDRESS.TRANSMUTER, account ?? undefined)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))

  async function handleUnstake() {
    if (transmuteContract && inputBignumber.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await transmuteContract.unstake(inputBignumber)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Unstake ${displayBalance(inputBignumber)} ${mintTokenName}`,
        })
        amountInput.current?.resetAmount()
      } catch (error) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>Remove {mintTokenName} for transmute</Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>Staked {mintTokenName}</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(stakeNusd)} {mintTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={amountInput}
        maxNum={stakeNusd}
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={mintTokenName}
      />
      <PoolFlexCenter style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set unstake amount</Trans>
        </Typography>
      </PoolFlexCenter>
      {/* <PoolFlexCenter style={{ marginTop: `13px`, justifyContent: `flex-end` }}>
        <MainButton onClick={handleUnstake}>
          <Typography variant="body1">UNSTAKE</Typography>
        </MainButton>
      </PoolFlexCenter> */}
      <MainButton onClick={handleUnstake} style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">
          <Trans>UNSTAKE</Trans>
        </Typography>
      </MainButton>
    </>
  )
}
export default UnstakeDrawerContent
