import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { ENV } from '../constants'

// Slices
import networkReducer from './slices/networkSlice'
import transactionReducer from './transactions/reducer'
import applicatonReducer from './application/reducer'
import navbarReducer from './navbar/reducer'

const reducers = combineReducers({
  network: networkReducer,
  transactions: transactionReducer,
  application: applicatonReducer,
  navbar: navbarReducer,
})

// Configure Middlewares
// const middleware = [
//   ...getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   }),
// ]
// middleware.push(epicMiddleware)

// Preloaded state, craete store and set middleware
const preloadedState = {}
const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
  // devTools: process.env.NEXT_ENV.isDev,
  devTools: ENV.IS_DEV,
  preloadedState,
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
