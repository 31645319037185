import { createReducer, nanoid } from '@reduxjs/toolkit'
import {
  addPopup,
  PopupContent,
  removePopup,
  updateBlockNumber,
  ApplicationModal,
  setOpenModal,
  updateLanguage,
} from './actions'
import { BigNumber } from 'ethers'

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

const AvailableLanguage = ['en', 'zh-Hant', 'zh-Hans']

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly popupList: PopupList
  readonly openModal: { modal: ApplicationModal | null; data?: Partial<IOpenModal> }
  readonly language: string
  // readonly vault: IVault
}

export interface IOpenModal {
  attemptingTxn: boolean
  errorMessage: string | undefined
  txHash: string | undefined
}

export interface IVault {
  deposits: {
    collaterals: BigNumber
    withdrawable: BigNumber
  }
  borrows: {
    remainingDebt: BigNumber
    canBorrow: BigNumber
    dateToMature: string
    globalMintable: BigNumber
  }
}

const initialState: ApplicationState = {
  blockNumber: {},
  popupList: [],
  openModal: { modal: null },
  language: '',
  // vault: {
  //   deposits: {
  //     collaterals: BigNumber.from(0),
  //     withdrawable: BigNumber.from(0),
  //   },
  //   borrows: {
  //     remainingDebt: BigNumber.from(0),
  //     canBorrow: BigNumber.from(0),
  //     dateToMature: '',
  //     globalMintable: BigNumber.from(0),
  //   },
  // },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    })
    .addCase(setOpenModal, (state, action) => {
      state.openModal = {
        modal: action.payload.modal,
        data: action.payload.data ?? {},
      }
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs = 25000 } }) => {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    })
    .addCase(updateLanguage, (state, { payload: lang }) => {
      state.language = AvailableLanguage.includes(lang) ? lang : 'en'
    })
)
