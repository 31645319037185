import { useEffect, useState, useMemo } from 'react'
import { DISPLAY_DECIMALS } from '../constants/display'
import { getYearnVaultDaiApy } from '../utils/getYearnVaultDaiApy'
import { getAlpacaVaultBusdApy } from '../utils/getAlpacaVaultBusdApy'
import { ChainId } from '../constants/blockchain'

export const useVaultStableCoinApr = (chainId: number | undefined) => {
  const [apr, setApr] = useState('0.00')
  useEffect(() => {
    !chainId || chainId === ChainId.BSC || chainId === ChainId.BSC_TEST
      ? getAlpacaVaultBusdApy().then((apy) => setApr(apy.toFixed(DISPLAY_DECIMALS)))
      : getYearnVaultDaiApy().then((apr) => setApr((100 * apr).toFixed(DISPLAY_DECIMALS)))
  }, [chainId])
  return useMemo(() => apr, [apr])
}
