import LineData, { MaximumLineData, LineDataType } from '../../../common/LineData'
import GradientWhite from '../../../common/GradientCard'
import {
  BasicText,
  BasicTextFlex,
  Pool,
  PoolFlex,
  PoolFlexDivider,
  PoolFlexWrapper,
  PoolFlexAsset,
  LineDataContainer,
} from '../styled'
import { Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getDefiAprHistory } from '../../../utils/getGalaxyInfo'
import { useResizeDetector } from 'react-resize-detector'
import { Trans } from '@lingui/macro'

export const DefiAprGraph = ({ isPC, widthPercent }) => {
  const [lineData, setLineData] = useState<LineDataType | null>(null)
  const [xAxisDate, setXAxisDate] = useState<Object>({})
  const { width, ref } = useResizeDetector()

  useEffect(() => {
    getDefiAprHistory().then((ret) => {
      const data = {
        ...ret,
        color: ['#9B9B9B', '#33ABC6', '#238888', '#1D77FF', '#4E4EFF'],
      }

      if (data) {
        const dateArr = data['name']
        const dateObj = Object.fromEntries(dateArr.map((key) => [key, '']))
        let monthDate = ''
        dateArr.forEach((date: string) => {
          let splitString = date.split(/[\-\s]/)
          if (monthDate === '' || monthDate !== `${splitString[0]}-${+splitString[1]}`) {
            monthDate = `${splitString[0]}-${+splitString[1]}`
            dateObj[date] = monthDate
          }
        })
        setXAxisDate(dateObj)
      }

      setLineData(data)

      const aprs = [Number(ret.numbers[3][ret.numbers[3].length - 1]), 0]
    })
  }, [])

  return (
    <GradientWhite width={isPC ? widthPercent : '100%'} height="280px">
      <PoolFlexAsset>
        <BasicText style={{ paddingLeft: 20 }}>
          <Trans>
            <Typography variant="h3">NAOS RWA APR</Typography>
            <Typography variant="h3">vs. Other Stable APRs</Typography>
          </Trans>
        </BasicText>
        {/* <LineDataContainer ref={ref}> */}
        <LineDataContainer ref={ref}>
          {lineData && (
            // <LineData lineData={lineData} height={280 * 0.68} width={width} XInterval={0} XAxisTicks={xAxisDate} />
            <MaximumLineData
              lineData={lineData}
              height={280 * 0.68}
              width={width}
              // yRange={[0, (dataMax) => Math.floor(dataMax * 1.2)]}
              XAxisTicks={xAxisDate}
              XInterval={0}
              yMaximum={20}
              // tickFormatY={(tick: number) => {
              //   return `$${numeral(tick).format('0a').toUpperCase()}`
              // }}
              // labelFormatter={(value, name, props) => {
              //   return [`$${numeral(value).format('0,0').toUpperCase()}`, name, props]
              // }}
            />
          )}
        </LineDataContainer>
      </PoolFlexAsset>
    </GradientWhite>
  )
}

export default DefiAprGraph
