import styled from 'styled-components'
import { X } from 'react-feather'

export const Container = styled.div<{ hideBorderLeft: boolean }>`
  background: inherit;
  border-left: ${(props) => (props.hideBorderLeft ? 'none' : '1px solid #2D2D55')};
`

export const InternalContainer = styled.div`
  margin: 0 20px 0 20px;
  padding-bottom: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    margin: 20px 20px 0 20px;
  }
`

export const ContainerTop = styled.div`
  display: flex;
  padding-bottom: 40px;
  align-items: center;
  justify-content: space-between;
  flex-basis: 3%;
  @media only screen and (min-width: 960px) {
    justify-content: center;
  }
`

export const ContainerClose = styled(X)`
  cursor: pointer;
  @media only screen and (min-width: 960px) {
    display: none;
  }
`

export const ContainerAccountStatus = styled.div``

export const TitleContainer = styled.div`
  flex-basis: 3%;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
`

export const TitleClose = styled(X)`
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
`

export const BottomWrapper = styled.div`
  width: 100%;
  flex-basis: 15%;
  padding: 20px 0px;
`
