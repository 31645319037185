import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

export const MainButton = styled.div<{ disable?: boolean; disableStyle?: string }>`
  height: 36px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${(props) =>
    props.disable
      ? props.disableStyle === 'transparent'
        ? `
        border: 1px solid;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.2);
      `
        : props.disableStyle === 'solid' &&
          `
        background-color: rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.2);
      `
      : `
      background-color: ${props.theme.palette.primary.dark};
      color: ${props.theme.palette.text.primary};
    `}
`

export const MinorButton = styled.div<{ width?; height? }>`
  height: ${({ height }) => (height ? `${height}px` : '36px')};
  width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    color: ${theme.palette.text.primary};
  `}
`

export const ClickableTypography = styled(Typography)`
  cursor: pointer;
`
