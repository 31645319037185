import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { X } from 'react-feather'

import { PopupContent } from '../../redux/application/actions'
import { useRemovePopup } from '../../redux/application/hooks'
import TransactionPopup from './TransactionPopup'

export const StyledClose = styled(X)`
  position: absolute;
  right: 10px;
  top: 10px;

  :hover {
    cursor: pointer;
  }
`

export const Popup = styled.div`
  display: inline-block;
  width: 100%;
  background-color: white;
  position: relative;
  border-radius: 10px;
  padding: 14px 20px;
  padding-right: 35px;
  overflow: hidden;
  box-sizing: border-box;
`

const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 5px;
  background-color: gray;
`

const AnimatedFader = animated(Fader)

export default function PopupItem({
  removeAfterMs,
  content,
  popKey,
}: {
  removeAfterMs: number | null
  content: PopupContent
  popKey: string
}) {
  const removePopup = useRemovePopup()
  const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])

  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs, removeThisPopup])

  // let popupContent

  // if ('txn' in content) {
  //   const {
  //     txn: { hash, success, summary },
  //   } = content
  //   popupContent = <TransactionPopup hash={hash} success={success} summary={summary} />
  // } else if ('listUpdate' in content) {
  //   const {
  //     listUpdate: { listUrl, oldList, newList, auto },
  //   } = content
  //   popupContent = <ListUpdatePopup popKey={popKey} listUrl={listUrl} oldList={oldList} newList={newList} auto={auto} />
  // }

  let popupContent
  if ('txn' in content) {
    const {
      txn: { hash, success, summary },
    } = content
    popupContent = <TransactionPopup hash={hash} success={success} summary={summary} />
  }

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined },
  })

  return (
    <Popup>
      <StyledClose onClick={removeThisPopup}>x</StyledClose>
      {popupContent}
      <AnimatedFader style={faderStyle} />
    </Popup>
  )
}
