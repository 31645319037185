import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'

import ADDRESS from '../../constants/addresses'
import {
  useEpochCoordinatorContract,
  useAssessorContract,
  useGalaxyStakingPoolContract,
  useGalaxyReserveContract,
  useMemberlistContract,
  IAddressOrAddressMap,
} from './useContract'
import { useBlockNumber } from '../../redux/application/hooks'
import { getDebtCeiling } from '../../utils/getGalaxyInfo'
import { parseEther } from '@ethersproject/units'
import { GalaxyStakingPools } from '../../abis/types'
import { useTokenBalance } from './useTokenBalance'
import { ChainId } from '../../constants/blockchain'

export function useEpochReserveRatio(chainId: number, alphaRootAddress: string) {
  const epochEoordinator = useEpochCoordinatorContract(ADDRESS.EPOCH_COORDINATOR[chainId][alphaRootAddress])
  const assessor = useAssessorContract(ADDRESS.ASSESSOR[chainId][alphaRootAddress])
  const [epochReserveRatio, setEpochReserveRatio] = useState(BigNumber.from(100))

  useEffect(() => {
    const setData = async () => {
      if (epochEoordinator && assessor) {
        Promise.all([assessor.seniorDebt(), assessor.seniorBalance(), epochEoordinator.epochReserve()]).then(
          ([seniorDebt, seniorBalance, epochReserve]) => {
            const seniorAsset = seniorDebt.add(seniorBalance)
            if (seniorAsset.gt(0)) {
              setEpochReserveRatio(epochReserve.mul(100).div(seniorAsset))
            }
          }
        )
      }
    }

    setData()

    const interval = setInterval(() => {
      if (epochReserveRatio.eq(0)) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 30000)

    return () => clearInterval(interval)
  }, [epochEoordinator, assessor])

  return useMemo(() => epochReserveRatio, [epochReserveRatio])
}

// export function useEpochReserve(chainId: number, alphaRootAddress: string) {
//   const epochEoordinator = useEpochCoordinatorContract(ADDRESS.EPOCH_COORDINATOR[chainId][alphaRootAddress])
//   const [epochReserve, setEpochReserve] = useState(BigNumber.from(0))

//   useEffect(() => {
//     const setData = async () => {
//       if (epochEoordinator) {
//         epochEoordinator.epochReserve().then((reserve) => {
//           setEpochReserve(reserve)
//         })
//       }
//     }

//     setData()

//     const interval = setInterval(() => {
//       if (epochReserve.eq(0)) {
//         setData().then(() => clearInterval(interval))
//       } else {
//         clearInterval(interval)
//       }
//     }, 5000)

//     return () => clearInterval(interval)
//   }, [epochEoordinator])

//   return useMemo(() => epochReserve, [epochReserve])
// }

export function useFundingReserve(chainId: number, alphaRootAddress: string) {
  // const contract = useGalaxyReserveContract(address)
  const [reserveBalance] = useTokenBalance(
    ADDRESS.DAI_TOKEN,
    ADDRESS.GALAXY_RESERVE[chainId ?? ChainId.BSC][alphaRootAddress]
  )
  const [trancheBalance] = useTokenBalance(
    ADDRESS.DAI_TOKEN,
    ADDRESS.GALAXY_TRANCHE[chainId ?? ChainId.BSC][alphaRootAddress]
  )
  const blockNumber = useBlockNumber()
  const [totalBalance, setTotalBalance] = useState(BigNumber.from(0))

  useEffect(() => {
    if (reserveBalance && trancheBalance) {
      setTotalBalance(reserveBalance.add(trancheBalance))
    }
  }, [reserveBalance, trancheBalance, blockNumber])

  return useMemo(() => totalBalance, [totalBalance])
}

export function useGalaxyDebtInfo(
  assessorAddress?: IAddressOrAddressMap
): [currentDebt: BigNumber, debtCeiling: BigNumber] {
  const contract = useAssessorContract(assessorAddress)
  const blockNumber = useBlockNumber()

  const [currentDebt, setCurrentDebt] = useState(BigNumber.from(0))
  const [debtCeiling, setDebtCeiling] = useState(BigNumber.from(0))

  useEffect(() => {
    if (contract) {
      contract.seniorDebt().then(setCurrentDebt)
    }
  }, [contract, blockNumber])

  useEffect(() => {
    getDebtCeiling().then((n: number) => {
      setDebtCeiling(parseEther(n.toString()))
    })
  }, [])

  return useMemo(() => [currentDebt, debtCeiling], [currentDebt, debtCeiling])
}

export function useGalaxyPoolRewardRate(galaxyPoolAddress?: IAddressOrAddressMap): BigNumber {
  const contract = useGalaxyStakingPoolContract(galaxyPoolAddress)
  const blockNumber = useBlockNumber()

  const [rate, setRate] = useState(BigNumber.from(0))

  useEffect(() => {
    if (contract) {
      contract.rewardRate().then(setRate)
    }
  }, [contract, blockNumber])

  return useMemo(() => rate, [rate])
}

export function useGalaxyPoolCurrentEpoch(galaxyPoolAddress?: IAddressOrAddressMap): number {
  const contract = useGalaxyStakingPoolContract(galaxyPoolAddress)
  const blockNumber = useBlockNumber()

  const [currentEpoch, setCurrentEpoch] = useState(0)

  useEffect(() => {
    if (contract) {
      contract.currentEpoch().then((n) => {
        setCurrentEpoch(n.toNumber())
      })
    }
  }, [contract, blockNumber])

  return useMemo(() => currentEpoch, [currentEpoch])
}

export function useGalaxyPoolLockPeriod(galaxyPoolAddress?: IAddressOrAddressMap, poolId?: number): number {
  const contract = useGalaxyStakingPoolContract(galaxyPoolAddress)
  const blockNumber = useBlockNumber()

  const [timelock, setTimelock] = useState(0)

  useEffect(() => {
    if (contract && poolId !== undefined) {
      contract.getPoolExpiredTimestamp(poolId).then((n) => {
        setTimelock(n.toNumber())
      })
    }
  }, [contract, blockNumber])

  return useMemo(() => timelock, [timelock])
}

export function useGalaxyPoolTotalSupplyCurrency(galaxyPoolAddress?: IAddressOrAddressMap): BigNumber {
  const contract = useGalaxyStakingPoolContract(galaxyPoolAddress)
  const blockNumber = useBlockNumber()

  const [total, setTotal] = useState(BigNumber.from(0))

  useEffect(() => {
    if (contract) {
      contract.totalSupplyCurrency().then(setTotal)
    }
  }, [contract, blockNumber])

  return useMemo(() => total, [total])
}

// price is set in 27 decimals
export function useAlphaTokenPrice(assessorAddress?: IAddressOrAddressMap): BigNumber {
  const contract = useAssessorContract(assessorAddress)
  const blockNumber = useBlockNumber()

  const [price, setPrice] = useState(BigNumber.from(0))

  useEffect(() => {
    if (contract) {
      contract['calcSeniorTokenPrice()']().then(setPrice)
    }
  }, [contract, blockNumber])

  return useMemo(() => price, [price])
}

export function useGalaxyRegisterInfo(galaxyAddress?: IAddressOrAddressMap, assessorAddress?: IAddressOrAddressMap, staker?: string) {
  const contract = useGalaxyStakingPoolContract(galaxyAddress)
  const blockNumber = useBlockNumber()
  const alphaPrice = useAlphaTokenPrice(assessorAddress)

  const [registerForRedeemed, setRegisterForRedeemed] = useState(BigNumber.from(0))
  const [redeemable, setRedeemable] = useState(BigNumber.from(0))

  useEffect(() => {
    if (contract && staker) {
      Promise.all([contract.userRemainingRedeemToken(staker),contract.userUnclaimedCurrency(staker)])
      .then(([registered, unclaimed]) => {
        setRedeemable(unclaimed)

        if (alphaPrice.gt(0)) {
          const value = registered.mul(alphaPrice).div(BigNumber.from('1000000000000000000000000000'))
          setRegisterForRedeemed(value.add(unclaimed))
        } else {
          setRegisterForRedeemed(registered.add(unclaimed))
        }
      })

      // contract.userRemainingRedeemToken(staker).then((registered) => {
      //   if (alphaPrice.gt(0)) {
      //     const value = registered.mul(alphaPrice).div(BigNumber.from('1000000000000000000000000000'))
      //     setRegisterForRedeemed(value)
      //   } else {
      //     setRegisterForRedeemed(registered)
      //   }
      // })
      // contract.userUnclaimedCurrency(staker).then(setRedeemable)
    }
  }, [contract, staker, blockNumber, alphaPrice])

  return useMemo(() => [registerForRedeemed, redeemable], [registerForRedeemed, redeemable])
}

export function useGalaxyWhitelisted(galaxyAddress?: IAddressOrAddressMap, staker?: string | null | undefined) {
  const contract = useGalaxyStakingPoolContract(galaxyAddress)
  const blockNumber = useBlockNumber()
  const alphaPrice = useAlphaTokenPrice()
  const [whitelisted, setWhitelisted] = useState(false)

  useEffect(() => {
    if (contract && staker) {
      contract.whitelist(staker).then((isWhitelisted) => {
        setWhitelisted(isWhitelisted)
      })
    }
  }, [contract, staker, blockNumber, alphaPrice])

  return useMemo(() => whitelisted, [whitelisted])
}

export function useMemberlisted(memberListAddress?: IAddressOrAddressMap, staker?: string | null | undefined) {
  const contract = useMemberlistContract(memberListAddress)
  const blockNumber = useBlockNumber()
  const alphaPrice = useAlphaTokenPrice()
  const [whitelisted, setWhitelisted] = useState(false)

  useEffect(() => {
    if (contract && staker) {
      contract.hasMember(staker).then((isWhitelisted) => {
        setWhitelisted(isWhitelisted)
      })
    }
  }, [contract, staker, blockNumber, alphaPrice])

  return useMemo(() => whitelisted, [whitelisted])
}

export function useGalaxyPendingRewards(
  galaxyPoolContract: GalaxyStakingPools | null,
  staker: string | null | undefined
) {
  const blockNumber = useBlockNumber()
  const [pendingReward, setPendingReward] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (staker && galaxyPoolContract) {
      const getAlphaReward = (alphaPoolId: number): Promise<BigNumber> =>
        galaxyPoolContract.getStakeTotalUnclaimed(staker, alphaPoolId).then((r) => r)

      // Promise.all([getAlphaReward(0), getAlphaReward(1)]).then((values) => setPendingReward(values))
      getAlphaReward(0).then((value) => setPendingReward(value))
    }
  }, [staker, galaxyPoolContract, blockNumber])

  return useMemo(() => pendingReward, [pendingReward])
}
