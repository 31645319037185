import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useBetaInsuranceContract } from './useContract'

export function useBetaInsuranceInfo(
  address?: IAddressOrAddressMap
): [betaSupply: BigNumber, betaPrice: BigNumber, lockAmount: BigNumber, redeemableAmount: BigNumber] {
  const contract = useBetaInsuranceContract(address, true)
  const blockNumber = useBlockNumber()
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0))
  const [betaSupply, setBetaSupply] = useState<BigNumber>(BigNumber.from(0))
  const [betaPrice, setBetaPrice] = useState<BigNumber>(BigNumber.from(0))
  const [lockAmount, setLockAmount] = useState<BigNumber>(BigNumber.from(0))
  const [redeemableAmount, setRedeemableAmount] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (contract) {
      contract.totalSupply().then(setBetaSupply)
      contract.lockAmount().then(setLockAmount)
      contract.getPricePerFullShare().then(setBetaPrice)
      contract.balance().then(setBalance)
    }
  }, [contract, blockNumber])

  useEffect(() => {
    setRedeemableAmount(balance.sub(lockAmount))
  }, [balance, lockAmount])

  return useMemo(
    () => [betaSupply, betaPrice, lockAmount, redeemableAmount],
    [betaSupply, betaPrice, lockAmount, redeemableAmount]
  )
}
