import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import usePrevious from '../usePrevious'
import { IAddressOrAddressMap, useBoostPoolContract } from './useContract'
import { useMulticallContract } from './useContract'
import multicall from '../../utils/multicall'
import { formatEther, parseUnits } from 'ethers/lib/utils'
import { deflate } from 'zlib'

export function useBoostPoolsInfo(
  address?: IAddressOrAddressMap,
  staker?: string,
  multicallAddress?: IAddressOrAddressMap,
): [
  stakedAmount: BigNumber,
  rewardAmount: BigNumber,
  claimImmediatelyAmount: BigNumber,
  lockTimes: BigNumber[],
  cooldownPeriod: BigNumber,
  claimPeriod: BigNumber,
  userClaimPeriod: [BigNumber, BigNumber],
  orders: any[]
] {
  const contract = useBoostPoolContract(address)
  const blockNumber = useBlockNumber()
  const [stakedAmount, setStakedAmount] = useState<BigNumber>(BigNumber.from(0))
  const [rewardAmount, setRewardAmount] = useState<BigNumber>(BigNumber.from(0))
  const [claimImmediatelyAmount, setClaimImmediatelyAmount] = useState<BigNumber>(BigNumber.from(0))
  const [cooldownPeriod, setCooldownPeriod] = useState<BigNumber>(BigNumber.from(0))
  const [claimPeriod, setClaimPeriod] = useState<BigNumber>(BigNumber.from(0))
  const [userClaimPeriod, setUserClaimPeriod] = useState<[BigNumber, BigNumber]>([BigNumber.from(0), BigNumber.from(0)])
  const [lockTimeCount, setLockTimeCount] = useState<BigNumber>(BigNumber.from(0))

  const [orderCount, setOrderCount] = useState<number>(0)
  const prevCount = usePrevious<number>(orderCount)
  const multicallContract = useMulticallContract(multicallAddress, true)

  // TODO
  useEffect(() => {
    if (contract && staker && multicallContract) {
      const calls = [
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'getStakeTotalDeposited',
          value: [staker],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'getStakeTotalUnclaimed',
          value: [staker],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'getStakeTotalUnclaimedImmediately',
          value: [staker],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'cooldownPeriod',
          value: [],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'getLockTimeWeightedListLength',
          value: [],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'CLAIM_PERIOD',
          value: [],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'getUserClaimPeriod',
          value: [staker],
        },
        {
          itf: contract.interface,
          address: contract.address as string,
          function: 'userOrderCount',
          value: [staker],
        },
      ]

      multicall(multicallContract, calls)
        .then((res) => {
          setStakedAmount(res[0][0])
          setRewardAmount(res[1][0])
          setClaimImmediatelyAmount(res[2][0])
          setCooldownPeriod(res[3][0])
          setLockTimeCount(res[4][0])
          setClaimPeriod(res[5][0])
          setUserClaimPeriod(res[6])
          setOrderCount(res[7][0].toNumber())
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [contract, staker, blockNumber, multicallContract])

  const [orders, setOrders] = useState<any[]>([])
  useEffect(() => {
    if (contract && staker && prevCount !== undefined && orderCount > prevCount) {
      const promises: Promise<any>[] = []
      for (let idx = prevCount; idx < orderCount; idx++) {
        promises.push(contract.getUserDepositOrderByIndex(staker, idx))
      }
      Promise.all(promises).then((newOrders) => setOrders([...orders, ...newOrders]))
    }
  }, [contract, staker, orderCount])

  const [lockTimes, setLockTimes] = useState<BigNumber[]>([])
  useEffect(() => {
    if (contract && lockTimeCount.toNumber() > 0) {
      const promises: Promise<any>[] = []
      for (let i = 0; i < lockTimeCount.toNumber(); i++) {
        promises.push(contract.getLockTimeWeightedByIndex(i))
      }

      Promise.all(promises).then((values) => {
        const times: any[] = []
        for (const value of values) {
          switch (value.lockTime.toNumber()) {
            case 3600:
              times.push(BigNumber.from(7776000))
              break
            case 7200:
              times.push(BigNumber.from(31104000))
              break
            case 14400:
              times.push(BigNumber.from(62208000))
              break
            case 28800:
              times.push(BigNumber.from(124416000))
              break
            default:
              times.push(value.lockTime)
              break
          }
        }
        setLockTimes(times)
      })
    }
  }, [contract, lockTimeCount])

  return useMemo(
    () => [
      stakedAmount,
      rewardAmount,
      claimImmediatelyAmount,
      lockTimes,
      cooldownPeriod,
      claimPeriod,
      userClaimPeriod,
      orders,
    ],
    [
      stakedAmount,
      rewardAmount,
      claimImmediatelyAmount,
      lockTimes,
      cooldownPeriod,
      claimPeriod,
      userClaimPeriod,
      orders,
    ]
  )
}
