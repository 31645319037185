import { ChainId } from './blockchain'

function setTokensMap(
  defaultType: string,
  defaultAddr: string,
  defaultSymbol: string,
  defaultDec: string,
  defaultImg: string,
  overwriteTypes = {},
  overwriteAddrs = {},
  overwriteMappings = {}
) {
  return {
    type: {
      [ChainId.MAINNET]: defaultType,
      [ChainId.RINKEBY]: defaultType,
      [ChainId.BSC]: defaultType,
      [ChainId.BSC_TEST]: defaultType,
      ...overwriteTypes,
    },
    symbol: defaultSymbol,
    address: {
      default:{
        [ChainId.MAINNET]: defaultAddr,
        [ChainId.RINKEBY]: defaultAddr,
        [ChainId.BSC]: defaultAddr,
        [ChainId.BSC_TEST]: defaultAddr,
      },
      ...overwriteAddrs,
    },
    decimals: defaultDec,
    image: defaultImg,
    ...overwriteMappings,
  }
}

export type TokenMap = ReturnType<typeof setTokensMap>
// export type TokenSeniorMap = ReturnType<typeof setSeniorTokensMap>

interface TokenInfo {
  NAOS: TokenMap
  ALPHA_PRO: TokenMap
  BETA_INSURANCE: TokenMap
}

export const TOKEN: TokenInfo = {
  NAOS: setTokensMap(
    'ERC20',
    '0x4a615bb7166210cce20e6642a6f8fb5d4d044496',
    'NAOS',
    '18',
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496/logo.png',
    { [ChainId.BSC]: 'ERC20' },
    {
      [ChainId.RINKEBY]: '0xc83e6391dccf286ca64e67bac361faba69a48a61',
      [ChainId.BSC]: '0x758d08864fb6cce3062667225ca10b8f00496cc2',
    },
    {}
  ),
  ALPHA_PRO: setTokensMap(
    'ERC20',
    '',
    'ALPHA',
    '18',
    '',
    { [ChainId.BSC]: 'ERC20' },
    {
      default: {
        [ChainId.RINKEBY]: '0xd484B4EBD11C2D09f71Fe56b5E8615dBA91D292d',
        [ChainId.BSC]: '',
      },
    },
    {}
  ),
  BETA_INSURANCE: setTokensMap(
    'ERC20',
    '',
    'Beta',
    '18',
    '',
    { [ChainId.BSC]: 'ERC20' },
    {
      [ChainId.RINKEBY]: '0x1d258b0828df640720bcc55932c779f882060004',
      [ChainId.BSC]: '0xcd2e1ebfc804dd867ca052133fa70d9db6d86ab7',
    },
    {}
  ),
}
