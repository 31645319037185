import { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme, Typography, Divider } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import ADDRESS from '../../constants/addresses'
import { ChainId } from '../../constants/blockchain'
import { PoolNames, BetaPoolIds } from '../../constants/insuranceConstants'
import GradientCard from '../../common/GradientCard'
import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  Basic,
  BasicContainer,
  BasicTextCenter,
  Button,
  ButtonDark,
  BasicTextFlex,
  Pool,
  PoolFlex,
  PoolFlexCenter,
  PoolFlexAsset,
  ButtonContainerReverse,
  BasicTextSpaceBetween,
  MiniButton,
} from './styled'
import ActionBoard from '../../common/ActionBoard'
import { MainButton } from '../../common/buttons'
import DepositDrawerContent from './deposit'
import MintDrawerContent from './mint'
import StakeDrawerContent from '../Farm/Pool/actionContents/naosPools/stake'
import UnstakeDrawerContent from '../Farm/Pool/actionContents/naosPools/unstake'
import RedeemDrawerContent from './RedeemDrawerContent'
import BetaDrawer from '../Invest/BetaDrawer'
import InformationCard from '../../common/InformationCard'

import { useNTokenContract } from '../../hooks/smartContracts/useContract'
import { useFormationInfo } from '../../hooks/smartContracts/useFormationInfo'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { displayBalance } from '../../utils/displayBalance'
import { useStkaingPoolsApr } from '../../hooks/smartContracts/useStakingPoolsAprs'
import { useStakingPoolContract } from '../../hooks/smartContracts/useContract'
import { usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'
import { useStakingPoolInfo } from '../../hooks/smartContracts/useStakingPoolsInfo'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { useBetaInsuranceStakingPoolTVL } from '../../hooks/smartContracts/useStakingPoolsTvl'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'

import betaTokenImage from '../../assets/symbol/betaToken.svg'

export enum StakingPoolType {
  NAOS,
  NAOS_WITH_TRANSFER,
  SUSHI,
  BOOST,
}

export enum PoolAction {
  DEPOSIT,
  BORROW,
  EXIT,
  STAKE,
  UNSTAKE,
  CLAIM,
  INSURANCE,
  REDEEM,
}

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    disableTitleButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: 'rgba(255, 255, 255, 0.2)',
    },
    disableTitleButtonExit: {
      color: 'rgba(255, 255, 255, 0.2) !important',
      border: `1px solid rgba(255, 255, 255, 0.2) !important`,
      backgroundColor: 'transparent !important',
      marginRight: '16px',
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '16px',
    },
    dividerVertical: {
      width: '1px',
      backgroundColor: theme.palette.text.primary,
      opacity: '60%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '32px auto',
      backgroundColor: theme.palette.text.primary,
      opacity: '60%',
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    tabs: {
      minHeight: 0,
      height: 32,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 4,
      marginTop: 24,
    },
    tab: {
      minWidth: 0,
      minHeight: 32,
      padding: 0,
      width: '50%',
      borderRadius: 3,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    hideIndicator: {
      display: 'none',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 360,
    },
    addTokenButton: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'center',
    },
    addedTokenSign: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'right',
    },
    addedTokenContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    stepLabel: {
      '&.MuiStepLabel-root': {
        alignItems: 'flex-start',
      },
    },
    titleMargin: {
      marginBottom: 10,
    },
    assetOriginatorBG: {
      backgroundColor: 'rgba(255, 255, 255) !important',
      // '&:before': {
      //   backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
      // },
    },
    marginBottom_24: {
      marginBottom: 24,
    },
    detailModal: {
      width: 300,
      height: 'auto',
      backgroundColor: '#040534',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: 'rgba(255, 255, 255, 0.87)',
      padding: '8px 8px',
      [theme.breakpoints.down(960)]: {
        '&.MuiTooltip-tooltip': {
          marginTop: 12,
        },
      },
    },
    modalArrow: {
      '&::before': {
        backgroundColor: '#040534',
      },
    },
  })
)

const ClaimContent = ({ isPC, rewardAmount, handleClaim, classDisableText }) => {
  return (
    <>
      <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
        <Typography variant="h2">{displayBalance(rewardAmount)}</Typography>
      </PoolFlexCenter>
      <PoolFlexCenter style={{ marginTop: `20px` }}>
        <Typography variant="h4">
          <Trans>NAOS Rewards</Trans>
        </Typography>
      </PoolFlexCenter>
      <PoolFlexCenter>
        <MainButton
          style={{ width: isPC ? `inherit` : `80%` }}
          onClick={handleClaim}
          disable={rewardAmount.lte(0)}
          disableStyle={'solid'}
        >
          <Typography variant="subtitle2" className={rewardAmount.lte(0) ? classDisableText : ''}>
            <Trans>CLAIM REWARD</Trans>
          </Typography>
        </MainButton>
      </PoolFlexCenter>
    </>
  )
}

const Insurance = () => {
  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const history = useHistory()

  const supportedChains: any = [ChainId.BSC, ChainId.RINKEBY]
  const [blockPage, setBlockPage] = useState(false)
  useEffect(() => {
    if (supportedChains.indexOf(chainId) < 0) {
      setBlockPage(true)
    } else if (blockPage == true) {
      setBlockPage(false)
    }
  }, [chainId])
  const { poolname } = useParams() as Record<string, any>

  let poolId = 0
  if (poolname.substr(0, 5) == 'beta-') {
    poolId = Number(poolname.substr(5))
  }
  const stakingTokenAddr = ADDRESS.BETA_INSURANCE[chainId ?? ChainId.BSC]
  const name = 'BETA'
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )
  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )

  const nusdContract = useNTokenContract(ADDRESS.NUSD_TOKEN)
  const [collateralDai, borrowedNusd, availableToBorrow, collateralRatio] = useFormationInfo(
    ADDRESS.FORMATION,
    account ?? undefined
  )
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, account ?? undefined)

  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false, false])
  const closeDrawer = () => {
    setDrawerOpen([false, false, false, false])
  }
  const openDrawer = (type) => {
    const status = [...isDrawerOpen]
    status[type] = true
    setDrawerOpen(status)
  }

  const poolAddr = useMemo(() => ADDRESS.STAKINGPOOL_WITH_TRANSFER, [ADDRESS.STAKINGPOOL_WITH_TRANSFER])
  const stakingPoolContract = useStakingPoolContract(poolAddr)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [stakedAmount, rewardAmount, totalDeposited] = useStakingPoolInfo(poolAddr, account ?? undefined, poolId)
  const betaTvls = useBetaInsuranceStakingPoolTVL()
  const [betaSupply, betaPrice, lockAmount] = useBetaInsuranceInfo(ADDRESS.BETA_INSURANCE)

  async function handleClaim() {
    if (stakingPoolContract && rewardAmount.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await stakingPoolContract.claim(poolId)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Claim ${displayBalance(rewardAmount)} NAOS`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleExit() {
    if (stakingPoolContract) {
      if (stakedAmount.lte(0) && rewardAmount.lte(0)) return
      openConfirmingModal(true)
      try {
        const res = await stakingPoolContract.exit(0)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Exit BETA Pool`,
        })
        closeDrawer()
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  const [alphaRootArray, setAlphaRootArray] = useState([ADDRESS.BETA_INSURANCE[chainId ?? ChainId.BSC]])

  useEffect(() => {
    if (chainId) {
      setAlphaRootArray([ADDRESS.BETA_INSURANCE[chainId ?? ChainId.BSC]])
      const newPoolname = `beta-${BetaPoolIds[chainId]}`
      if (poolname !== newPoolname) {
        history.push(`/insurance/${newPoolname}`)
      }
    }
  }, [chainId])
  const [betaBalance] = useTokenBalance(ADDRESS.BETA_INSURANCE, account ?? undefined)
  const aprs = useStkaingPoolsApr()
  const chainName = useMemo(() => {
    if (chainId !== undefined) {
      if (chainId === ChainId.BSC || chainId === ChainId.BSC_TEST) {
        return 'bsc'
      } else if (chainId === ChainId.RINKEBY || chainId === ChainId.MAINNET) {
        return 'eth'
      } else {
        return 'bsc'
      }
    } else {
      return 'bsc'
    }
  }, [chainId])
  const chain = useMemo(() => {
    if (
      chainId !== undefined &&
      (chainId === ChainId.BSC || chainId === ChainId.RINKEBY || chainId === ChainId.MAINNET)
    ) {
      return chainId
    } else {
      return ChainId.BSC
    }
  }, [chainId])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.informationBox}>
          <TitleDiv>
            <TitleFront>
              <TitleIcon src={betaTokenImage} />
              <TitleTextDiv>
                <Typography variant="body2" className={classes.greyHeader}>
                  NAOS
                </Typography>
                <Typography variant="h3">{i18n._(PoolNames[0])}</Typography>
              </TitleTextDiv>
            </TitleFront>
          </TitleDiv>

          <Pool style={{ marginTop: `0px` }}>
            <PoolFlex style={{ marginTop: `0px` }}>
              <GradientCard width={isPC ? '48%' : '100%'} height={isPC ? '130px' : '208px'}>
                <PoolFlexAsset>
                  <BasicTextFlex>
                    <Typography variant="h2">
                      <Trans>Deposit</Trans>
                    </Typography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <BasicTextFlex
                      style={{
                        width: '100%',
                        marginTop: isPC ? '0px' : '16px',
                        marginBottom: isPC ? '6px' : '16px',
                        alignItems: 'center',
                      }}
                    >
                      <BasicTextSpaceBetween style={{ flexBasis: '85%' }}>
                        <Typography variant="body1">
                          <Trans>Wallet Balance</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {displayBalance(daiBalance)} {collateralTokenName}
                        </Typography>
                      </BasicTextSpaceBetween>
                      <MiniButton
                        style={{ display: isPC ? 'inherit' : 'none' }}
                        onClick={() => openDrawer(PoolAction.DEPOSIT)}
                      >
                        <Typography variant="body1" style={{ textAlign: 'end' }}>
                          <Trans>DEPOSIT</Trans>
                        </Typography>
                      </MiniButton>
                    </BasicTextFlex>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <BasicTextFlex style={{ width: '100%', marginBottom: '6px', alignItems: 'center' }}>
                      <BasicTextSpaceBetween style={{ flexBasis: '85%' }}>
                        <Typography variant="body1">
                          <Trans>Collateral Balance</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {displayBalance(collateralDai)} {collateralTokenName}
                        </Typography>
                      </BasicTextSpaceBetween>
                      <MiniButton
                        style={{ display: isPC ? 'none' : 'inherit' }}
                        onClick={() => openDrawer(PoolAction.DEPOSIT)}
                      >
                        <Typography variant="body1" style={{ textAlign: 'center' }}>
                          <Trans>DEPOSIT</Trans>
                        </Typography>
                      </MiniButton>
                    </BasicTextFlex>
                  </BasicTextFlex>
                </PoolFlexAsset>
              </GradientCard>

              {isPC ? <></> : <div style={{ height: '16px' }}></div>}

              <GradientCard width={isPC ? '48%' : '100%'} height={isPC ? '130px' : '208px'}>
                <PoolFlexAsset>
                  <BasicTextFlex>
                    <Typography variant="h2">
                      <Trans>Mint</Trans>
                    </Typography>
                  </BasicTextFlex>

                  <BasicTextFlex>
                    <BasicTextFlex
                      style={{
                        width: '100%',
                        marginTop: isPC ? '0px' : '16px',
                        marginBottom: isPC ? '6px' : '16px',
                        alignItems: 'center',
                      }}
                    >
                      <BasicTextSpaceBetween style={{ flexBasis: '85%' }}>
                        <Typography variant="body1">
                          <Trans>Available to Mint</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {+collateralRatio
                            ? displayBalance(
                                availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd)
                              )
                            : '0'}{' '}
                          {mintTokenName}
                        </Typography>
                      </BasicTextSpaceBetween>
                      <MiniButton
                        style={{ display: isPC ? 'inherit' : 'none' }}
                        onClick={() => openDrawer(PoolAction.BORROW)}
                      >
                        <Typography variant="body1" style={{ textAlign: 'end' }}>
                          <Trans>MINT</Trans>
                        </Typography>
                      </MiniButton>
                    </BasicTextFlex>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <BasicTextFlex style={{ width: '100%', marginBottom: '6px', alignItems: 'center' }}>
                      <BasicTextSpaceBetween style={{ flexBasis: '85%' }}>
                        <Typography variant="body1">
                          <Trans>Wallet Balance</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {displayBalance(nusdBalance)} {mintTokenName}
                        </Typography>
                      </BasicTextSpaceBetween>
                      <MiniButton
                        style={{ display: isPC ? 'none' : 'inherit' }}
                        onClick={() => openDrawer(PoolAction.BORROW)}
                      >
                        <Typography variant="body1" style={{ textAlign: 'center' }}>
                          <Trans>BORROW</Trans>
                        </Typography>
                      </MiniButton>
                    </BasicTextFlex>
                  </BasicTextFlex>
                </PoolFlexAsset>
              </GradientCard>
            </PoolFlex>
          </Pool>

          <Pool>
            <PoolFlex>
              <Typography variant="h2">
                <Trans>Get BETA Tokens</Trans>
              </Typography>
              <ButtonContainerReverse>
                <ButtonDark className={classes.titleButtonExit} onClick={() => openDrawer(PoolAction.REDEEM)}>
                  <Typography variant="body2" color="inherit">
                    <Trans>REDEEM</Trans>
                  </Typography>
                </ButtonDark>
                <Button className={classes.titleButton} onClick={() => openDrawer(PoolAction.INSURANCE)}>
                  <Typography variant="body2">
                    <Trans>INSURE</Trans>
                  </Typography>
                </Button>
              </ButtonContainerReverse>
            </PoolFlex>
            <BasicContainer>
              {/* <GradientCard height={isPC ? '80px' : '330px'}>
                <Basic>
                  <BasicText>
                    <Typography variant="h2">PORTFOLIO</Typography>
                  </BasicText>
                  <BasicText>
                    <Typography variant="h2">$ {displayBalance(betaTvls[0], '0,0')} USD</Typography>
                  </BasicText>
                  <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                  <Divider orientation="horizontal" className={classes.dividerHorizental} />
                  <BasicText>
                    <Typography variant="h2">BETA Balance</Typography>
                  </BasicText>
                  <BasicText>
                    <Typography variant="h2">{displayBalance(betaBalance, '0,0')} BETA</Typography>
                  </BasicText>
                </Basic>
              </GradientCard> */}

              {/* {isPC ? <></> : <div style={{ height: '16px' }}></div>} */}
              <div style={{ height: '16px' }}></div>

              <GradientCard height={isPC ? '80px' : '400px'}>
                <Basic>
                  {/* <BasicText style={{ alignItems: 'center' }}>
                    <Typography variant="h2">BETA</Typography>
                    <Typography variant="h4">Staked Token</Typography>
                  </BasicText>
                  <Divider orientation="vertical" flexItem className={classes.dividerVertical} /> */}
                  {/* <Divider orientation="horizontal" className={classes.dividerHorizental} /> */}
                  <BasicTextCenter>
                    <Typography variant="h2">$ {displayBalance(betaTvls[0], '0,0')}</Typography>
                    <Typography variant="h4">
                      <Trans>TVL</Trans>
                    </Typography>
                  </BasicTextCenter>
                  <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                  <Divider orientation="horizontal" className={classes.dividerHorizental} />
                  <BasicTextCenter>
                    <Typography variant="h2">
                      {chainName &&
                      aprs[chainName][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chain]] &&
                      aprs[chainName][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chain]][poolId]
                        ? aprs[chainName][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chain]][poolId].toFixed(2)
                        : 0}{' '}
                      %
                    </Typography>
                    <Typography variant="h4">
                      <Trans>APR</Trans>
                    </Typography>
                  </BasicTextCenter>
                  <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                  <Divider orientation="horizontal" className={classes.dividerHorizental} />
                  <BasicTextCenter>
                    <Typography variant="h2">$ {displayBalance(lockAmount, '0,0')}</Typography>
                    <Typography variant="h4">
                      <Trans>Insurance Amount</Trans>
                    </Typography>
                  </BasicTextCenter>
                </Basic>
              </GradientCard>
            </BasicContainer>
          </Pool>

          <Pool>
            <PoolFlex>
              <Typography variant="h2">
                <Trans>Stake BETA Tokens</Trans>
              </Typography>
              {/* <Button
                className={classes.disableTitleButtonExit}
                // onClick={() => openDrawer(PoolAction.WITHDRAW)}
              >
                <Typography variant="body2" color="inherit">
                  EXIT
                </Typography>
              </Button> */}
            </PoolFlex>
            <PoolFlex>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '31%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(betaBalance)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>Available to Stake</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton style={{ width: isPC ? `inherit` : `80%` }} onClick={() => openDrawer(PoolAction.STAKE)}>
                    <Typography variant="subtitle2">
                      <Trans>STAKE</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '31%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(stakedAmount)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>BETA Staked</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton
                    style={{ width: isPC ? `inherit` : `80%` }}
                    onClick={() => stakedAmount.gt(0) && openDrawer(PoolAction.UNSTAKE)}
                    disable={stakedAmount.lte(0)}
                    disableStyle={'solid'}
                  >
                    <Typography variant="subtitle2" className={stakedAmount.lte(0) ? classes.disableText : ''}>
                      <Trans>UNSTAKE</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '31%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <ClaimContent
                  isPC={isPC}
                  rewardAmount={rewardAmount}
                  handleClaim={handleClaim}
                  classDisableText={classes.disableText}
                />
              </GradientCard>
            </PoolFlex>
          </Pool>
          <div className={classes.bottomSpace}></div>
        </div>
      </div>

      <ActionBoard open={isDrawerOpen[PoolAction.DEPOSIT]} setClose={closeDrawer}>
        <InformationCard title={t`Deposit`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <DepositDrawerContent />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[PoolAction.BORROW]} setClose={closeDrawer}>
        <InformationCard title={t`Mint`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <MintDrawerContent />
        </InformationCard>
      </ActionBoard>
      <BetaDrawer
        isOpen={isDrawerOpen[PoolAction.INSURANCE]}
        onClose={closeDrawer}
        apr={
          chainName &&
          aprs[chainName][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chain]] &&
          aprs[chainName][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chain]][poolId]
            ? aprs[chainName][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chain]][poolId].toFixed(2)
            : 0
        }
      />
      <ActionBoard open={isDrawerOpen[PoolAction.REDEEM]} setClose={closeDrawer}>
        <InformationCard setClose={closeDrawer} title={t`Redeem`} hideBorderLeft showAccountStatus>
          {/* <Typography variant="h5">Redeem DAI</Typography> */}
          <RedeemDrawerContent poolId={poolId} alphaRootAddress={alphaRootArray[0]} />
        </InformationCard>
      </ActionBoard>

      <ActionBoard open={isDrawerOpen[PoolAction.STAKE]} setClose={closeDrawer}>
        <InformationCard title={t`STAKE`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <StakeDrawerContent
            poolId={poolId}
            poolAddr={poolAddr[chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC]}
            tokenAddr={stakingTokenAddr}
            symbol={name}
            availableAmount={betaBalance}
            chainId={chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC}
          />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[PoolAction.UNSTAKE]} setClose={closeDrawer}>
        <InformationCard title={t`UNSTAKE`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <UnstakeDrawerContent
            poolId={poolId}
            poolAddr={poolAddr[chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC]}
            tokenAddr={stakingTokenAddr}
            symbol={name}
            availableAmount={stakedAmount}
            chainId={chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC}
          />
        </InformationCard>
      </ActionBoard>
    </>
  )
}
export default Insurance
