import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Tooltip,
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import { MainButton } from '../../common/buttons'
import GradientCard from '../../common/GradientCard'
import { Divider } from '../../common/Divider'
import { displayDate } from '../../utils/displayDate'
import { displayBalance, displayBalanceUnits } from '../../utils/displayBalance'
import { getGalaxyOrder } from '../../utils/getGalaxyInfo'
import {
  useBetaInsuranceContract,
  useGalaxyStakingPoolContract,
  useOperatorContract,
} from '../../hooks/smartContracts/useContract'
import {
  useGalaxyPoolCurrentEpoch,
  useGalaxyPoolLockPeriod,
  useGalaxyRegisterInfo,
} from '../../hooks/smartContracts/useGalaxy'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import ADDRESS from '../../constants/addresses'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
// import AmountInput, { IAmountInputRefObj } from '../../common/AmountInput'
import TestableAmountInput, { IAmountInputRefObj } from '../../common/TestableAmountInput'
import { PoolFlexCenter } from '../Farm/styled'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'
import { useBetaRedeemable } from '../../hooks/smartContracts/useBetaRedeemable'

import OrderActive from '../../assets/drawer/orderRedeemable.png'
import OrderInactive from '../../assets/drawer/orderPending.png'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ChainId } from '../../constants/blockchain'
import { useApproveCallback, ApprovalState } from '../../hooks/smartContracts/useApproveCallback'
import { Trash2 } from 'react-feather'
import { formatEther } from '@ethersproject/units'
import BN from 'bignumber.js'

function RedeemRow({ amount, redeemable, inQueue, collateralToken }) {
  return (
    <Grid container alignItems="center" style={{ margin: '12px 0px' }}>
      <Grid direction="column">
        <Typography variant="subtitle1">
          {displayBalance(amount)} {collateralToken}
        </Typography>
        <Typography variant="body1">{inQueue ? t`Queueing` : t`Redeemable: ${displayBalance(redeemable)}`}</Typography>
      </Grid>
    </Grid>
  )
}

function OrderRow({ amount, startDate, isValidEpoch, lockTime, onClick, collateralToken }) {
  // const isDue = Date.now() > startDate.toNumber() * 1000 ? true : false
  // const postfix = isDue
  //   ? isValidEpoch
  //     ? 'Unlocked'
  //     : 'Wait for asset settlement'
  //   : `Locked for ${(lockTime.toNumber() / 60 / 60 / 24).toFixed(2)} Days`

  const postfix = isValidEpoch ? t`Unlocked` : t`Wait for asset settlement`

  const date = displayDate(new Date(startDate.toNumber() * 1000))

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ margin: '12px 0px', cursor: isValidEpoch ? 'pointer' : 'unset' }}
      onClick={onClick}
    >
      <Grid direction="column">
        <Typography variant="subtitle1">
          {displayBalance(amount)} {collateralToken}
        </Typography>
        <Typography variant="body1">
          {date}, {i18n._(postfix)}
        </Typography>
      </Grid>
      <img src={isValidEpoch ? OrderActive : OrderInactive} />
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      minHeight: 0,
      height: 32,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 4,
      marginTop: 24,
    },
    tab: {
      minWidth: 0,
      minHeight: 32,
      padding: 0,
      width: '50%',
      borderRadius: 3,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    hideIndicator: {
      display: 'none',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 360,
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    detailModal: {
      width: 200,
      height: 'auto',
      backgroundColor: '#040534',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: 'rgba(255, 255, 255, 0.87)',
      [theme.breakpoints.down(960)]: {
        '&.MuiTooltip-tooltip': {
          marginTop: 12,
        },
      },
    },
    modalArrow: {
      '&::before': {
        backgroundColor: '#040534',
      },
    },
    redeemOrderCancelBtn: {
      color: '#FFFFFF',
      opacity: '0.6',
      cursor: 'pointer',
    },
  })
)

const RedeemDrawerContent = ({
  seniorTokenPrice,
  seniorTokenBalance,
  remainingRedeemToken,
  payoutCurrencyAmount,
}: {
  seniorTokenPrice: BigNumber
  seniorTokenBalance: BigNumber
  remainingRedeemToken: BigNumber
  payoutCurrencyAmount: BigNumber
}) => {
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const classes = useStyles()
  const { account, chainId } = useWeb3React()

  const [invests, setInvests] = useState<Record<number, any[]>>({ 0: [], 1: [] })
  const [redeems, setRedeems] = useState<Record<number, any[]>>({ 0: [], 1: [] })
  const [tabValue, setTabValue] = useState(0)
  const [registerModalInfo, setRegisterModalInfo] = useState<{
    open: boolean
    poolId: undefined | number
    orderIds: undefined | number[]
  }>({
    open: false,
    poolId: undefined,
    orderIds: undefined,
  })

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )
  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )
  const galaxyContract = useGalaxyStakingPoolContract(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'])
  const currentEpoch = useGalaxyPoolCurrentEpoch(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'])
  // const currentEpoch = 0
  const lockTime = [
    useGalaxyPoolLockPeriod(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'], 0),
    // useGalaxyPoolLockPeriod(ADDRESS.GALAXY_STAKING_POOL.default[0], 1),
  ]
  // const lockTime = [0, 0]
  // const [registerForRedeem, redeemable] = useGalaxyRegisterInfo(
  //   ADDRESS.GALAXY_STAKING_POOL.default[0],
  //   account ?? undefined
  // )

  const registerForRedeem = useMemo(() => {
    return new BN(remainingRedeemToken.mul(seniorTokenPrice).div('1000000000000000000000000000').toString())
  }, [seniorTokenBalance, seniorTokenPrice])

  const [redeemableRatio, setRedeemableRatio] = useState(0)
  useEffect(() => {
    if (payoutCurrencyAmount.gt(0)) {
      setRedeemableRatio(
        payoutCurrencyAmount.mul(100).div(payoutCurrencyAmount.add(registerForRedeem.toString())).toNumber()
      )
    }
  }, [registerForRedeem, payoutCurrencyAmount])

  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  // async function handleRedeem() {
  //   if (galaxyContract && redeemable.gt(0)) {
  //     openConfirmingModal(true)
  //     try {
  //       const estimateGas = await galaxyContract.estimateGas.withdraw()
  //       const res = await galaxyContract.withdraw({ gasLimit: calculateGasMargin(estimateGas) })
  //       openConfirmingModal(false, res.hash)
  //       addTransaction(res, {
  //         summary: `Withdraw redeemable`,
  //       })
  //     } catch (error: any) {
  //       openConfirmingModal(false, undefined, error, chainId)
  //     }
  //   }
  // }

  async function handleRegister() {
    if (
      galaxyContract &&
      registerModalInfo.open &&
      registerModalInfo.poolId !== undefined &&
      registerModalInfo.orderIds !== undefined
    ) {
      openConfirmingModal(true)
      try {
        const estimateGas = await galaxyContract.estimateGas.redeem(
          registerModalInfo.poolId,
          registerModalInfo.orderIds
        )
        const res = await galaxyContract.redeem(registerModalInfo.poolId, registerModalInfo.orderIds, {
          gasLimit: calculateGasMargin(estimateGas),
        })
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Register orders, id: ${registerModalInfo.orderIds[0]}`,
        })
        registerToRedeem(registerModalInfo.poolId, registerModalInfo.orderIds)
        setRegisterModalInfo({
          open: false,
          poolId: undefined,
          orderIds: undefined,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  function registerToRedeem(poolId, toMoveInvestIds) {
    const newInvest = { ...invests }
    const newRedeem = { ...redeems }

    newInvest[poolId] = []
    for (const invest of invests[poolId]) {
      if (toMoveInvestIds.includes(+invest.depositedOrderIndex)) {
        newRedeem[poolId].push(invest)
      } else {
        newInvest[poolId].push(invest)
      }
    }

    setInvests(newInvest)
    setRedeems(newRedeem)
  }

  useEffect(() => {
    if (account) {
      getGalaxyOrder(
        account?.toLowerCase(),
        ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot']?.toLowerCase()
      ).then(({ redeemList, investList }) => {
        if (redeemList && redeemList.length > 0) {
          // const redeems = { 0: [], 1: [] }
          const redeems: Record<string, any> = { 0: [] }
          redeemList.map((r) => r.poolId === 0 && redeems[r.poolId].push(r))
          setRedeems(redeems)
        }
        if (investList && investList.length > 0) {
          // const invests = { 0: [], 1: [] }
          const invests: Record<string, any> = { 0: [] }
          investList.map((l) => l.poolId === 0 && invests[l.poolId].push(l))
          setInvests(invests)
        }
      })
    }
  }, [account])

  // BETA Hooks
  // const beta = useBetaInsuranceInfo(ADDRESS?.BETA_INSURANCE, account ?? undefined)

  const galaxyPoolContract = useGalaxyStakingPoolContract(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot']
  )
  const operatorContract = useOperatorContract(
    ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC]['TestRoot']?.toLowerCase()
  )
  const [showTooltip, setShowTooltip] = useState(false)
  const [showRedeemCancelTooltip, setShowRedeemCancelTooltip] = useState(false)
  const betaContract = useBetaInsuranceContract(ADDRESS.BETA_INSURANCE)
  const [, betaPrice, lockAmount, redeemableAmount] = useBetaInsuranceInfo(ADDRESS.BETA_INSURANCE)
  const [nusdReedemable] = useBetaRedeemable()
  const [betaBalance] = useTokenBalance(ADDRESS.BETA_INSURANCE, account ?? undefined)
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const amountInput = useRef<IAmountInputRefObj>(null)
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)
  const [isApprove, approve] = useApproveCallback(
    ADDRESS.ALPHA_PRO_TOKEN[chainId ?? ChainId.BSC]['TestRoot'],
    inputBignumber,
    ADDRESS.GALAXY_PRO_TRANCHE[chainId ?? ChainId.BSC]['TestRoot']
  )

  const [betaRatio, setBetaRatio] = useState(0)
  useEffect(() => {
    if (redeemableAmount.gt(0)) {
      setBetaRatio(redeemableAmount.mul(100).div(redeemableAmount.add(lockAmount)).toNumber())
    }
  }, [redeemableAmount, lockAmount])

  const receiveAmount = useMemo(() => {
    let nusd, dai
    if (nusdReedemable.gte(inputBignumber)) {
      nusd = inputBignumber
      dai = BigNumber.from(0)
    } else {
      nusd = nusdReedemable
      dai = inputBignumber.sub(nusdReedemable)
    }
    return {
      NUSD: nusd,
      DAI: dai,
    }
  }, [inputBignumber])

  async function handleBetaRedeem() {
    if (betaContract && inputBignumber.gt(0) && inputBignumber.lte(redeemableAmount)) {
      openConfirmingModal(true)
      try {
        const res = await betaContract.withdraw(inputBignumber)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Withdraw ${displayBalance(inputBignumber)} ${mintTokenName}`,
        })
        amountInput.current?.resetAmount()
      } catch (error) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function cancelRedeemOrder() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (operatorContract) {
        openConfirmingModal(true)
        try {
          const estimateGas = await operatorContract.estimateGas.redeemOrder(BigNumber.from(0))
          const res = await operatorContract.redeemOrder(BigNumber.from(0), {
            gasLimit: calculateGasMargin(estimateGas),
          })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Cancel Redeem Order`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  async function handleRedeemOrder() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (
        operatorContract &&
        inputBignumber.gte(0) &&
        inputBignumber.lte(seniorTokenBalance.add(remainingRedeemToken))
      ) {
        openConfirmingModal(true)
        try {
          const estimateGas = await operatorContract.estimateGas.redeemOrder(inputBignumber)
          const res = await operatorContract.redeemOrder(inputBignumber, {
            gasLimit: calculateGasMargin(estimateGas),
          })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Redeem Order ${displayBalance(inputBignumber)} ALPHA`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  async function handleDisburse() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (operatorContract) {
        openConfirmingModal(true)
        try {
          const estimateGas = await operatorContract.estimateGas['disburse()']()
          const res = await operatorContract['disburse()']({ gasLimit: calculateGasMargin(estimateGas) })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Disburse ${displayBalance(payoutCurrencyAmount)} ${collateralTokenName}`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      {/* ALPHA tab content*/}
      {/* {tabValue === 0 && ( */}
      <>
        {/* <Tabs
          value={tabValue}
          onChange={(_, v) => {
            setTabValue(v)
          }}
          classes={{ indicator: classes.hideIndicator, root: classes.tabs }}
        >
          <Tab label="Redeem Order" classes={{ root: classes.tab }} />
          <Tab label="Disburse" classes={{ root: classes.tab }} />
        </Tabs> */}

        {/* Supply Order tab content*/}
        {/* {tabValue === 0 && (
          <> */}
        <Typography variant="body2" style={{ marginTop: 30 }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita dolorem laborum ullam omnis! Totam
          laudantium maxime eligendi, distinctio dolorem ut.
        </Typography>

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Wallet Balance</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(seniorTokenBalance)} ALPHA</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Current Price</Trans>
          </Typography>
          <Typography variant="body2">{displayBalanceUnits(seniorTokenPrice)} USD</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Registered</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(remainingRedeemToken)} ALPHA</Typography>
        </Grid>
        {/* <Divider className={classes.informationDivider} /> */}

        {/* <Grid container justifyContent="flex-start" style={{ margin: '20px 0px' }}>
          <Tooltip
            title={'When epochs close, “Current Supply Order” turns into 0'}
            arrow
            placement="top-end"
            classes={{ tooltip: classes.detailModal, arrow: classes.modalArrow }}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
          >
            <Typography variant="body2">Current Redeem Order</Typography>
          </Tooltip>

          <Typography variant="body2">{displayBalance(remainingRedeemToken)} ALPHA</Typography>
        </Grid> */}

        {/* <Grid container justifyContent="space-between" alignItems="center" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">{displayBalance(remainingRedeemToken)} ALPHA</Typography>
          <Tooltip
            title={'Click to clear your redeem order'}
            arrow
            placement="bottom-end"
            classes={{ tooltip: classes.detailModal, arrow: classes.modalArrow }}
            open={showRedeemCancelTooltip}
            onOpen={() => setShowRedeemCancelTooltip(true)}
            onClose={() => setShowRedeemCancelTooltip(false)}
          >
            <Trash2
              className={classes.redeemOrderCancelBtn}
              style={{ visibility: 'hidden' }}
              // style={remainingRedeemToken.gt(0) ? { visibility: 'visible' } : { visibility: 'hidden' }}
              onClick={cancelRedeemOrder}
            />
          </Tooltip>
        </Grid> */}
        <Divider className={classes.informationDivider} style={{ marginBottom: '16px' }} />

        <TestableAmountInput
          ref={amountInput}
          maxNum={seniorTokenBalance.add(remainingRedeemToken)}
          amount={inputBignumber}
          setAmount={setInputBignumber}
          textFieldLabel={'ALPHA'}
        />
        <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
          <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            <Trans>Please set the amount</Trans>
          </Typography>
        </Grid>

        <MainButton onClick={handleRedeemOrder} style={{ marginTop: 16 }}>
          <Typography variant="subtitle2">
            {isApprove === ApprovalState.APPROVED
              ? remainingRedeemToken.gt(0)
                ? t`UPDATE`
                : t`REDEEM ORDER`
              : t`APPROVE`}
          </Typography>
        </MainButton>
        {/* </>
        )} */}

        {/* Disburse tab content*/}
        {/* {tabValue === 1 && (
          <>
            <Typography variant="body2" style={{ marginTop: 40 }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis eos sint officiis doloribus, itaque
              consectetur possimus sequi commodi quae delectus!
            </Typography>

            <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
              <Typography variant="body2">{collateralTokenName} Balance</Typography>
              <Typography variant="body2">{displayBalance(daiBalance)}</Typography>
            </Grid>

            <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
              <Typography variant="body2">Redeemable {collateralTokenName}</Typography>
              <Typography variant="body2">{displayBalance(payoutCurrencyAmount)}</Typography>
            </Grid>

            <MainButton onClick={handleDisburse} style={{ marginTop: 16 }}>
              <Typography variant="subtitle2">
                {isApprove === ApprovalState.APPROVED ? 'DISBURSE' : 'APPROVE'}
              </Typography>
            </MainButton>
          </>
        )} */}

        {/* <MainButton style={{ marginTop: 16 }} onClick={handleRedeem}>
            REDEEM
          </MainButton> */}

        {/* <Typography variant="subtitle1" style={{ margin: '24px 0px' }}>
            Registered to redeem
          </Typography> */}

        {/* {redeems[0].length + redeems[1].length > 0 ? ( */}
        {/* {redeems[0].length > 0 ? (
            <Grid>
              {redeems[0].map((redeem, idx) => (
                <>
                  <RedeemRow
                    key={`redeem-${idx}`}
                    amount={BigNumber.from(redeem.amount)}
                    redeemable={BigNumber.from(redeem.redeemedCurrency)}
                    inQueue={redeem.redeemToken === redeem.remainingRedeemToken}
                    collateralToken={collateralTokenName}
                  />
                  <Divider />
                </>
              ))} */}
        {/* {redeems[1].map((redeem, idx) => (
                <>
                  <RedeemRow
                    key={`redeem-${idx}`}
                    amount={BigNumber.from(redeem.amount)}
                    redeemable={BigNumber.from(redeem.redeemedCurrency)}
                    inQueue={redeem.redeemToken === redeem.remainingRedeemToken}
                    collateralToken={collateralTokenName}
                  />
                  <Divider />
                </>
              ))} */}
        {/* </Grid>
          ) : (
            <Typography variant="body2">No redemption registered</Typography>
          )} */}

        {/* <Typography variant="subtitle1" style={{ margin: '24px 0px' }}>
            Investments
          </Typography>
          <Typography variant="body2">Register to redeem investments</Typography> */}

        {/* {
            <Grid style={{ marginTop: 32 }}>
              {invests[0].map((invest, idx) => (
                <>
                  <OrderRow
                    key={`invest-${idx}`}
                    amount={BigNumber.from(invest.amount)}
                    startDate={BigNumber.from(invest.expiredTime)}
                    lockTime={BigNumber.from(lockTime[0])}
                    isValidEpoch={currentEpoch > +invest.epoch}
                    onClick={() =>
                      setRegisterModalInfo({
                        open: currentEpoch > +invest.epoch,
                        poolId: +invest.poolId,
                        orderIds: [+invest.depositedOrderIndex],
                      })
                    }
                    collateralToken={collateralTokenName}
                  />
                  <Divider />
                </>
              ))}
             
            </Grid>
          } */}
      </>
      {/* )} */}

      {/* BETA tab content */}
      {/* {tabValue === 1 && (
        <>
          <Box style={{ marginTop: 24 }}>
            <GradientCard width="100%" height="88px">
              <Grid
                container
                justifyContent={isPC ? 'space-between' : 'center'}
                alignItems="center"
                style={{ height: '100%' }}
              >
                <Grid style={{ width: 56, height: 56, paddingLeft: 12 }}>
                  <div style={{ position: 'relative' }}>
                    <CircularProgress
                      variant="determinate"
                      value={betaRatio}
                      style={{ position: 'absolute', color: '#238888' }}
                      size={56}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={100 - betaRatio}
                      style={{
                        position: 'absolute',
                        color: 'rgba(255,255,255,0.2)',
                        transform: `rotate(${360 * (betaRatio / 100) - 90}deg)`,
                      }}
                      size={56}
                    />
                    <Grid style={{ width: 56, textAlign: 'center' }}>
                      <Typography variant="h4" style={{ lineHeight: '56px' }}>
                        {betaRatio}%
                      </Typography>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: 16,
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ padding: '0px 8px', borderLeft: '3px solid rgba(255,255,255,0.2)' }}
                  >
                    <Typography variant="body1">Deposited</Typography>
                    <Typography variant="body1">
                      {displayBalance(lockAmount.add(redeemableAmount), undefined, true)} {collateralTokenName}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ padding: '0px 8px', borderLeft: '3px solid #238888', marginTop: 8 }}
                  >
                    <Typography variant="body1">Redeemable</Typography>
                    <Typography variant="body1">
                      {displayBalance(redeemableAmount, undefined, true)} {collateralTokenName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </GradientCard>
          </Box>

          <Typography variant="body2" style={{ marginTop: 20 }}>
            BETA token redemption will be in {mintTokenName} first than {collateralTokenName}
          </Typography>

          <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
            <Typography variant="body2">BETA Balance</Typography>
            <Typography variant="body2">{displayBalance(betaBalance)}</Typography>
          </Grid>
          <Divider margin={16} />

         

          <Grid container justifyContent="space-between">
            <Typography variant="body2">BETA Price</Typography>
            <Typography variant="body2">{displayBalance(betaPrice, '0,0.0000')}</Typography>
          </Grid>
          <Divider margin={16} />
          <AmountInput
            ref={amountInput}
            maxNum={betaBalance}
            amount={inputBignumber}
            setAmount={setInputBignumber}
            textFieldLabel={'BETA'}
          />
          <Grid container justifyContent="center" style={{ marginTop: `12px`, justifyContent: 'flex-start' }}>
            <Typography variant="body2">Will Receive</Typography>
          </Grid>
          <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
            <Typography variant="body2">
              - {mintTokenName}: {displayBalance(receiveAmount.NUSD)}
            </Typography>
          </Grid>
          <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
            <Typography variant="body2">
              - {collateralTokenName}: {displayBalance(receiveAmount.DAI)}
            </Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <PoolFlexCenter style={{ marginTop: `13px`, justifyContent: `flex-end` }}>
              <MainButton onClick={handleBetaRedeem} disable={betaBalance.lte(0)} disableStyle={'solid'}>
                Redeem
              </MainButton>
            </PoolFlexCenter>
          </Grid>
        </>
      )} */}

      {/* <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={registerModalInfo.open}
        onClose={() =>
          setRegisterModalInfo({
            ...registerModalInfo,
            open: false,
          })
        }
      >
        <DialogTitle>
          <Typography variant="h3">Register to redeem</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4">Selected investment will shown in the redemption list</Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 24 }}>
          <Button onClick={handleRegister}>REGISTER</Button>
          <Button
            onClick={() =>
              setRegisterModalInfo({
                ...registerModalInfo,
                open: false,
              })
            }
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}

export default RedeemDrawerContent
