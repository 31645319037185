import styled from 'styled-components'

export const Container = styled.div`
  width: 360px;
  padding: 24px 12px;
  height: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #040534;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  border-radius: 40px 0px 0px 40px;
  @media only screen and (max-width: 960px) {
    width: 100%;
    padding: unset;
  }
`
