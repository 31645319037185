async function getAlpacaApyInfo() {
  return fetch('https://alpaca-static-api.s3.amazonaws.com/v1/landing/summary.json').then((f) => f.json())
}

export function getAlpacaVaultBusdApy() {
  return getAlpacaApyInfo().then((vaults) => {
    const alpacaLendingPools: Array<any> = vaults.data.lendingPools
    const busd: number = +alpacaLendingPools.filter((pool) => pool.sourceName === 'BUSD Vault')[0].totalApy
    return busd ?? 0
  })
}
