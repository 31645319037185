import styled from 'styled-components'

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 22px;
  }
`

export const TitleFront = styled.div`
  display: flex;
  @media only screen and (max-width: 960px) {
    margin-bottom: 27px;
  }
`

export const TitleIcon = styled.img`
  height: 42px;
  width: auto;
`
export const TitleTextDiv = styled.div`
  margin-left: 20px;
`

export const TitleBackContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 960px) {
    margin-top: 4px;
    align-self: flex-end;
  }
`

export const TitleBack = styled.div`
  width: 80px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // @media only screen and (max-width: 960px) {
  //   width: 80px;
  // }
`

export const Basic = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 18px 0;
  @media only screen and (max-width: 960px) {
    display: block;
    text-align: center;
    padding: 32px 0;
  }
`

export const BasicContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const BasicText = styled.div`
  text-align: center;
  flex-grow: 1;
`
export const BasicTextFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
`

export const BasicBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin: 0px 16px;
  @media only screen and (max-width: 960px) {
    height: 90px;
    margin: 8px 16px 12px 16px;
    flex-direction: column;
  }
`
export const BasicBottomLeft = styled.div`
  @media only screen and (max-width: 960px) {
    margin-right: auto;
  }
`
export const BasicBottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    justify-content: center;
  }
`

export const RightButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  item-align: center;
  cursor: pointer;
`

export const BasicBottomRightIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-right: 20px;
  margin-left: 10px;
`

export const PoolContainer = styled.div`
  margin-top: 40px;
  // margin-bottom: 40px;
  @media only screen and (max-width: 960px) {
    margin-bottom: 40px;
  }
`
export const PoolFlex = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    margin-top: 0px;
    display: block;
  }
`
export const PoolFlexCenter = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`
export const PoolFlexDivider = styled.div`
  width: 48%;
  height: 100%;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`
export const PoolFlexAsset = styled.div`
  padding: 24px 16px;
  @media only screen and (max-width: 960px) {
    padding: 8px 16px;
  }
`
export const LineDataContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 15px;
`
export const InformationSpace = styled.div`
  height: 36px;
  @media only screen and (max-width: 960px) {
    height: 6px;
  }
`

export const Button = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    color: ${theme.palette.text.primary};
  `}
`

export const SubButton = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
    background-color: none;
    color: ${theme.palette.text.primary};
  `}
`

export const DisabledButton = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
`

export const DetailIcon = styled.img`
  width: 13.33px;
  height: 13.33px;
  margin-left: 5.33px;
`
