import styled from 'styled-components'

export const Container = styled.div`

`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
`;

export const OptionButton = styled.div<{ chosen?: boolean }>`
  width: 64px;
  height: 32px;
  opacity: ${ props => props.chosen?'1':'0.3'};
  background: ${ props => props.chosen?'#1D77FF':'none'};
  border: ${ props => props.chosen?'none':'1px solid #FFFFFF'};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
