const defaultPoolName = 'Real World Assets Pool'
const defaultMobileName = 'RWA Pool'
const defaultAssetType = 'Emerging Market Consumer'
const defaultAssetMaturity = '1 year'
const defaultAOGradientCardHeight = '220px'
const defaultAOGradientCardMobileHeight = '360px'

export const PoolNames: string[] = [defaultPoolName, 'Test Pro Pool 2']
export const MobilePoolNames: string[] = [defaultMobileName, 'Test Pro Pool 2']
export const AssetType: string[] = ['Working Capital Loans', defaultAssetType]
export const AssetMaturity: string[] = ['24 Months Revolving', defaultAssetMaturity]

export const AssetOriginatorNames: string[] = PoolNames
export const AODescription: string[] = [
  'Corl is a Fintech company based in Toronto, Canada and invests in the digital economy with revenue-based financing. Since its inception in 2019, Corl has originated loans across more than 60 companies using its AI-powered software to analyze and mitigate investment risk.',
  '',
]
export const AssetOriginatorsShow: boolean[] = [true, false]
export const AOGradientCardHeight: string[] = ['160px', defaultAOGradientCardHeight]
export const AOGradientCardMobileHeight: string[] = ['260px', defaultAOGradientCardMobileHeight]
export const ExecutiveSummaryLink: string[] = [
    'https://drive.google.com/file/d/1mZanjtJw50b1xPco7qJTGVXm6d--zb-C/view?usp=sharing',
    ''
]
export const WebsiteLink: string[] = [
    'https://corl.io/',
    ''
]