import { useCallback } from 'react'
import TransactionConfirmationModal, { TransactionErrorContent } from './TransactionConfirmationModal'

export default function GeneralConfirmationModal({
  isOpen,
  attemptingTxn,
  txHash,
  errorMessage,
  onDismiss,
}: {
  isOpen: boolean
  attemptingTxn: boolean
  txHash: string | undefined
  errorMessage: string | undefined
  onDismiss: () => void
}) {
  const confirmationContent = useCallback(
    // () => (errorMessage ? <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} /> : <ConfirmationModalContent />),
    () => (errorMessage ? <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} /> : <></>),
    [onDismiss, errorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
    />
  )
}
