import styled from 'styled-components'

export const Container = styled.div<{ width: string; height: string; showCursor: boolean; aspectRatio: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.02) 100%);
  border-radius: 16px;
  cursor: ${(props) => (props.showCursor ? 'pointer' : 'inherit')};
  aspect-ratio: ${(props) => props.aspectRatio};
`
