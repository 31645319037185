import React from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { AlertTriangle, ArrowUpCircle, X } from 'react-feather'
import { Button, Dialog, Typography, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { Trans } from '@lingui/macro'

import getExplorerLink from '../../../utils/getExplorerLink'

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  min-width: 400px;

  @media (max-width: 500px) {
    min-width: 280px;
  }
`
const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: white;
  font-weight: 400;

  :focus {
    outline: none;
  }
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 16,
    background: '#212338',
    opacity: 0.9,
  },
}))

// export function ConfirmationModalContent({
//   title,
//   bottomContent,
//   onDismiss,
//   topContent,
// }: {
//   title: string
//   onDismiss: () => void
//   topContent: () => React.ReactNode
//   bottomContent?: () => React.ReactNode | undefined
// }) {
//   return (
//     <Wrapper>
//       {/* <Text fontWeight={500} fontSize={16}>

//           </Text> */}
//       <Typography>{title}</Typography>
//       <X onClick={onDismiss} />

//       {topContent()}

//       {bottomContent && <div>{bottomContent()}</div>}
//     </Wrapper>
//   )
// }

export function ConfirmationPendingContent({
  onDismiss,
  pendingText,
}: {
  onDismiss: () => void
  pendingText?: string
}) {
  return (
    <Wrapper>
      <Grid container direction="column" alignItems="center" style={{ color: 'white' }}>
        <X onClick={onDismiss} style={{ alignSelf: 'flex-end', cursor: 'pointer' }} />
        <CircularProgress size={100} style={{ margin: '60px 0px', color: '#55B6FF' }} />
        <Typography variant="subtitle1">
          <Trans>Waiting For Confirmation</Trans>
        </Typography>
        {pendingText && <Typography>{pendingText}</Typography>}
        <Typography variant="body2">
          <Trans>Confirm this transaction in your wallet</Trans>
        </Typography>
      </Grid>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  inline,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: number
  inline?: boolean // not in modal
}) {
  return (
    <Wrapper>
      <Grid container direction="column" alignItems="center" style={{ color: 'white' }}>
        <X onClick={onDismiss} style={{ alignSelf: 'flex-end', cursor: 'pointer' }} />
        <ArrowUpCircle strokeWidth={0.5} size={90} color="#55B6FF" style={{ margin: '32px 0px' }} />
        <Typography variant="h6">
          <Trans>Transaction Submitted</Trans>
        </Typography>
        {chainId && hash && (
          <StyledLink
            href={getExplorerLink(chainId, hash, 'transaction')}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginBottom: 32 }}
          >
            <Trans>View on Blockchain Explorer</Trans>
          </StyledLink>
        )}
        <Button
          onClick={onDismiss}
          variant="contained"
          style={{
            color: 'white',
            background: '#1d77ff',
            padding: '12px 16px',
            borderRadius: 20,
            width: '100%',
          }}
        >
          <Trans>Close</Trans>
        </Button>
      </Grid>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  return (
    <Wrapper>
      <Grid container direction="column" alignItems="center" style={{ color: 'white' }}>
        <Grid container item justify="space-between">
          <Typography variant="h6">Error</Typography>
          <div>
            <X onClick={onDismiss} style={{ cursor: 'pointer' }} />
          </div>
        </Grid>
        <AlertTriangle color="#55B6FF" style={{ strokeWidth: 1, margin: '32px 0px' }} size={100} />
        <Typography style={{ maxWidth: 300, marginBottom: 24, wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
          {message}
        </Typography>
        <Button
          onClick={onDismiss}
          variant="contained"
          style={{
            color: 'white',
            background: '#1d77ff',
            padding: '12px 16px',
            borderRadius: 20,
            width: '100%',
          }}
        >
          <Trans>Dismiss</Trans>
        </Button>
      </Grid>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText?: string
  // currencyToAdd?: Currency | undefined
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
}: ConfirmationModalProps) {
  const { chainId } = useWeb3React()
  const classes = useStyles()

  if (!chainId) return null

  return (
    <Dialog open={isOpen} onClose={onDismiss} classes={{ paper: classes.paper }}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
      ) : (
        content()
      )}
    </Dialog>
  )
}
