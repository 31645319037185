async function getYfiApyInfo() {
  return fetch('https://api.yearn.finance/v1/chains/1/vaults/all').then((f) => f.json())
}

export function getYearnVaultDaiApy() {
  return getYfiApyInfo().then((vaults) => {
    const dai = vaults.filter(
      (v) => v.symbol === 'yvDAI' && v.address === '0xdA816459F1AB5631232FE5e97a05BBBb94970c95'
    ) as Array<any>
    const recommended = dai.reduce((acc, val) => Math.max(acc, val.apy.net_apy), 0)
    return recommended
  })
}
