import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import {
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  List,
  ListItem,
  Fade,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@material-ui/core'
import { createStyles, Theme, makeStyles, styled } from '@material-ui/core/styles'
import { useWeb3React } from '@web3-react/core'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'

import { ChainId } from '../../constants/blockchain'
import BreadcrumbMapping from '../../constants/breadcrumbMapping'
import { MediaOptionList } from './MediaModal'
import { ExtendDrawerWidth, DrawerWidth } from '.'

import ImageLogo from '../../assets/navbar/logo.png'
import Image1 from '../../assets/navbar/home.svg'
import Image2 from '../../assets/navbar/vault.svg'
import Image3 from '../../assets/navbar/transmute.svg'
import Image4 from '../../assets/navbar/farm.svg'
import Image5 from '../../assets/navbar/Invest.svg'
import Image6 from '../../assets/navbar/pro_invest.svg'
import Image7 from '../../assets/navbar/FAQ.svg'
import Image8 from '../../assets/navbar/insurance_beta.svg'
import Image1Des from '../../assets/navbar/home_des.svg'
import Image2Des from '../../assets/navbar/vault_des.svg'
import Image3Des from '../../assets/navbar/transmute_des.svg'
import Image4Des from '../../assets/navbar/farm_des.svg'
import Image5Des from '../../assets/navbar/Invest_des.svg'
import Image6Des from '../../assets/navbar/pro_invest_des.svg'
import Image7Des from '../../assets/navbar/FAQ_des.svg'
import Image8Des from '../../assets/navbar/insurance_beta_des.svg'
import ArrowIcon from '../../assets/symbol/arrow_circle.svg'
import LogoNameIcon from '../../assets/symbol/company_full_logo.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DrawerWidth,
      flexShrink: 0,
      height: '100vh',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    drawerPaper: {
      width: DrawerWidth,
      border: '0px',
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden',
      // for opening drawer animation
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerOpened: {
      width: ExtendDrawerWidth,
    },

    drawerOptionList: {
      overflowX: 'hidden',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },

    iconLogo: {
      paddingTop: '22px',
    },
    iconBlock: {
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageIcon: {
      width: '24px',
    },
    imageLogo: {
      position: 'absolute',
      left: '17px',
      width: '42px',
    },
    logoFullName: {
      marginLeft: '20px',
    },
    drawerDivider: {
      backgroundColor: '#7F8097',
      height: '1px',
      margin: '50px 25px 20px',
      width: '0px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&.drawerOpen': {
        width: ExtendDrawerWidth - 50,
      },
    },
    iconButton: {
      display: 'flex',
      justifyContent: 'left',
      padding: '25px',
      height: '72px',
      color: '#B0D0FF',
      fontSize: '13px',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: '#FFFFFF33',
      },
    },
    iconButtonCurrent: {
      color: '#3177FF',
      backgroundColor: theme.palette.background.paper,
      borderLeft: 'solid 4px',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    drawerExtendText: {
      paddingLeft: '20px',
      transform: 'translateX(-20px)',
      opacity: 0,
      transition: theme.transitions.create(['transform', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerExtendTextOpened: {
      transform: 'translateX(0px)',
      opacity: 1,
    },
    arrowIcon: {
      width: '22px',
    },
    arrowIconButton: {
      cursor: 'pointer',
      position: 'absolute',
      left: '60px',
      top: '110px',
      zIndex: 1250,
      transition: theme.transitions.create(['transform', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    buttonOpened: {
      left: ExtendDrawerWidth - 10,
      height: '22px',
      transform: 'rotate(-180deg)',
    },
    drawerMask: {
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.6);',
      position: 'fixed',
      zIndex: 2,
    },

    bottomNavication: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: 1000,
      backgroundColor: theme.palette.background.default,
      justifyContent: 'start',
      overflowX: 'scroll',
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    bottomNavicationAction: {
      color: '#b0cfff',
      minWidth: '75px',
      paddingTop: '8px',
      paddingLeft: '0px',
      paddingRight: '0px',
      '&.Mui-selected': {
        color: '#3177ff',
        paddingTop: '8px',
        backgroundColor: theme.palette.background.paper,
      },
      '& .MuiBottomNavigationAction-label': {
        fontFamily: "'Roboto', 'Arial', sans-serif",
        fontSize: 12,
        fontWeight: 'normal',
        letterSpacing: 0.4,
        lineHeight: '16px',
        textAlign: 'center',
      },
    },
  })
)

const IconMapping = {
  home: {
    name: 'Home',
    enable: Image1Des,
    normal: Image1,
  },
  formation: {
    name: 'Formation',
    enable: Image2Des,
    normal: Image2,
  },
  transmuter: {
    name: 'Transmuter',
    enable: Image3Des,
    normal: Image3,
  },
  galaxy: {
    name: 'Galaxy',
    enable: Image5Des,
    normal: Image5,
  },
  // 'galaxy-pro': {
  //   name: 'Galaxy Pro',
  //   enable: Image6Des,
  //   normal: Image6,
  // },
  insurance: {
    name: 'Insurance',
    enable: Image8Des,
    normal: Image8,
  },
  farm: {
    name: 'Farm',
    enable: Image4Des,
    normal: Image4,
  },
  faq: {
    name: 'FAQ',
    enable: Image7Des,
    normal: Image7,
  },
}

const galaxyList = ['galaxy', 'galaxy-pro', 'insurance']

const IconBlock = ({ iconName, enable }: { iconName: string; enable: boolean }) => {
  const classes = useStyles()
  const icon = IconMapping[iconName]

  return (
    <div className={classes.iconBlock}>
      <img alt={iconName} className={classes.imageIcon} src={enable ? icon.enable : icon.normal} />
    </div>
  )
}

const DrawerExtendText = ({ children, isOpen }) => {
  const classes = useStyles()
  return <div className={clsx(classes.drawerExtendText, isOpen && classes.drawerExtendTextOpened)}>{children}</div>
}

const Circle = styled('div')({
  border: '2px solid #B0D0FF',
  borderRadius: '50%',
  margin: '3px',
})

const CommunityIcon = () => {
  const classes = useStyles()

  return (
    <div
      className={classes.iconBlock}
      style={{
        padding: '25px',
        marginTop: '25px',
      }}
    >
      <Circle />
      <Circle />
      <Circle />
    </div>
  )
}

function getMainPath(path: string) {
  return path.split('/')[1]
}

const BarDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const { chainId } = useWeb3React()
  const { pathname } = useLocation()
  const mainPath = getMainPath(pathname)
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const history = useHistory()
  const hideGalaxy = useMemo(() => chainId === ChainId.MAINNET || !chainId, [chainId])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(`/${newValue}`)
  }

  const handleOptionClick = (newValue: string) => {
    if (isOpen) {
      setIsOpen(false)
    }
    history.push(newValue)
  }

  return (
    <>
      {isPC ? (
        <>
          <div
            className={clsx(classes.arrowIconButton, isOpen && classes.buttonOpened)}
            onClick={() => setIsOpen(!isOpen)}
          >
            <img alt="arrowImage" title="arrowImage" className={classes.arrowIcon} src={ArrowIcon} />
          </div>
          <Fade in={isOpen}>
            <div className={classes.drawerMask} onClick={() => setIsOpen(false)} />
          </Fade>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, isOpen && classes.drawerOpened),
            }}
            anchor="left"
          >
            <div className={classes.iconLogo} key={'logo'} onClick={() => handleOptionClick('/home')}>
              <img alt="" className={classes.imageLogo} src={ImageLogo} />
              <Fade in={isOpen}>
                <img alt="" className={classes.logoFullName} src={LogoNameIcon} />
              </Fade>
              <div className={clsx(classes.drawerDivider, isOpen && 'drawerOpen')} />
            </div>

            <List className={classes.drawerOptionList}>
              {Object.keys(IconMapping)
                .filter((key) => key !== 'faq')
                .map((key) => (
                  <Tooltip
                    key={key}
                    disableHoverListener={isOpen}
                    placement="right"
                    title={i18n._(BreadcrumbMapping['/' + key])}
                  >
                    <ListItem
                      button
                      disableGutters
                      className={clsx(classes.iconButton, mainPath === key && classes.iconButtonCurrent)}
                      key={'/' + key}
                      onClick={() => handleOptionClick('/' + key)}
                      disabled={hideGalaxy && galaxyList.includes(key)}
                    >
                      <IconBlock iconName={key} enable={mainPath === key} />
                      <DrawerExtendText isOpen={isOpen}>{i18n._(BreadcrumbMapping['/' + key])}</DrawerExtendText>
                    </ListItem>
                  </Tooltip>
                ))}

              <Tooltip disableHoverListener={isOpen} placement="right" title={t`FAQ`}>
                <ListItem
                  button
                  disableGutters
                  className={clsx(classes.iconButton, getMainPath(pathname) === 'faq' && classes.iconButtonCurrent)}
                  key={'/faq'}
                  onClick={() =>
                    window.open('https://naosfinance.gitbook.io/naos-finance/', '_blank', 'noopener noreferrer')
                  }
                >
                  <IconBlock iconName={'faq'} enable={mainPath === 'faq'} />
                  <DrawerExtendText isOpen={isOpen}>
                    <Trans>FAQ</Trans>
                  </DrawerExtendText>
                </ListItem>
              </Tooltip>

              <div className={clsx(classes.drawerDivider, isOpen && 'drawerOpen')} />

              {isOpen ? <MediaOptionList /> : <CommunityIcon />}
            </List>
          </Drawer>
        </>
      ) : (
        <div>
          <BottomNavigation value={mainPath} onChange={handleChange} showLabels className={classes.bottomNavication}>
            {Object.keys(IconMapping)
              .filter((key) => key !== 'faq')
              .map((key) => (
                <BottomNavigationAction
                  key={key}
                  label={i18n._(IconMapping[key].name)}
                  value={key}
                  icon={<IconBlock iconName={key} enable={mainPath === key} />}
                  classes={{ root: classes.bottomNavicationAction }}
                />
              ))}
          </BottomNavigation>
        </div>
      )}
    </>
  )
}

export default BarDrawer
