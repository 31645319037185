import { Typography, Grid } from '@material-ui/core'
import { BigNumber } from 'ethers'
import { useRef, useState } from 'react'
import { Trans, t } from '@lingui/macro'

import AmountInput, { IAmountInputRefObj } from '../../../../../common/AmountInput'
import { MainButton } from '../../../../../common/buttons'
import { Divider } from '../../../../../common/Divider'
import { ApprovalState, useApproveCallback } from '../../../../../hooks/smartContracts/useApproveCallback'
import { displayBalance } from '../../../../../utils/displayBalance'
import { useStakingPoolContract } from '../../../../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../../../../redux/application/hooks'
import { useTransactionAdder } from '../../../../../redux/transactions/hooks'
import { PoolFlexCenter } from '../../../styled'

interface IStakingPoolDrawerProps {
  poolId: number
  tokenAddr: string
  poolAddr: string
  symbol: string
  availableAmount: BigNumber
  chainId: number
}

const StakeDrawerContent = ({
  poolId,
  poolAddr,
  tokenAddr,
  symbol,
  availableAmount,
  chainId,
}: IStakingPoolDrawerProps) => {
  const amountInput = useRef<IAmountInputRefObj>(null)
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))

  const [isApprove, approve] = useApproveCallback(tokenAddr, inputBignumber, poolAddr)
  const stakingPoolContract = useStakingPoolContract(poolAddr)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  async function handleStake() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (stakingPoolContract && inputBignumber.gt(0)) {
        openConfirmingModal(true)
        try {
          const res = await stakingPoolContract.deposit(poolId, inputBignumber)
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Stake ${displayBalance(inputBignumber)} ${symbol}`,
          })
          amountInput.current?.resetAmount()
        } catch (error) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>Stake {symbol} tokens to earn NAOS tokens.</Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>{symbol} Balance</Trans>
        </Typography>
        <Typography variant="body2">{displayBalance(availableAmount)}</Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={amountInput}
        maxNum={availableAmount}
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={symbol}
      />
      <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set stake amount</Trans>
        </Typography>
      </Grid>
      {/* <Grid justifyContent="flex-end">
        <PoolFlexCenter style={{ marginTop: `13px`, justifyContent: `flex-end` }}>
          <MainButton onClick={handleStake}> {isApprove === ApprovalState.APPROVED ? 'Stake' : 'APPROVE'}</MainButton>
        </PoolFlexCenter>
      </Grid> */}
      <MainButton onClick={handleStake} style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">{isApprove === ApprovalState.APPROVED ? t`STAKE` : t`APPROVE`}</Typography>
      </MainButton>
    </>
  )
}

export default StakeDrawerContent
