import { GALAXY_API_HOST } from '../constants/endpoints'
import fetch from 'node-fetch'

export enum statusCode {
  ADDRESS_BEEN_REGISTERED = 1,
  UNREGISTERED = 2,
  REGISTERED = 3,
  INTERNAL_ERROR = 4,
}

function getEndpoint(path) {
  return `${GALAXY_API_HOST}/api/v1/galaxy${path}`
}

export async function getRegisteredInfo(externalId: string, walletAddress: string) {
  const url = `/wallets?externalId=${externalId}&walletAddress=${walletAddress}`
  const res = await fetch(getEndpoint(url), {
    method: 'GET',
  })

  const data: any = await res.json()
  if (data && data.hasOwnProperty('status')) {
    return data.status
  }
  throw new Error(data.errors.message)
}

export async function postRegisteredInfo(
  thirdPartyName: string,
  externalToken: string | Record<string, any>,
  walletAddress: string,
  signature: string
) {
  const postData = {
    thirdPartyName,
    externalToken,
    walletAddress,
    signature,
  }

  const res = await fetch(getEndpoint('/wallets'), {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const data: any = await res.json()
  if (data && data.hasOwnProperty('isPass')) {
    return data.isPass
  }
  throw new Error(data.errors.message)
}
