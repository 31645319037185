import styled from 'styled-components'

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`

export const TitleFront = styled.div`
  display: flex;
`

export const TitleIcon = styled.img`
  width: 42px;
  height: 42px;
`
export const TitleTextDiv = styled.div`
  margin-left: 20px;
`

export const TitleBackContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 960px) {
    margin-top: 4px;
    align-self: flex-end;
  }
`

export const TitleBack = styled.div`
  width: 140px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Basic = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  @media only screen and (max-width: 960px) {
    display: block;
    text-align: center;
  }
`

export const BasicContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const BasicText = styled.div``
export const BasicTextFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
`

export const BasicBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin: 8px 16px;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const BasicBottomLeft = styled.div``
export const BasicBottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    margin-top: 16px;
  }
`
export const BasicBottomRightIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 10px;
`

export const InvestmentsTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 8px;
  @media only screen and (min-width: 960px) {
    justify-content: center;
    margin-bottom: 0px;
    // flex-basis: 40%;
  }
`

export const InvestmentsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  @media only screen and (min-width: 960px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0px;
    // flex-basis: 30%;
  }
`

export const GradientBlock = styled.div`
  width: 100%;
  aspect-ratio: 34/13;
  // border-radius: 16px;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  @media only screen and (min-width: 960px) {
    aspect-ratio: 1111/88;
    background-size: 100% 100%;
  }
`

export const Pool = styled.div<{ inputMobileMarginTop?: string }>`
  margin-top: 40px;
  @media only screen and (max-width: 960px) {
    margin-top: ${(props) => (props.inputMobileMarginTop === undefined ? 0 : props.inputMobileMarginTop)};
  }
`

export const PoolFlex = styled.div<{ inputMobileMarginTop?: string }>`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    display: block;
    margin-top: ${(props) => (props.inputMobileMarginTop === undefined ? 0 : props.inputMobileMarginTop)};
  }
`

export const PoolFlexWrapper = styled.div`
  border-image-source: linear-gradient(0deg, #55b6ff 17.67%, rgba(85, 182, 255, 0.2) 97.61%);
`
export const PoolFlexDivider = styled.div`
  width: 48%;
  height: 100%;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`
export const PoolFlexAsset = styled.div`
  padding: 20px 0 0 0;
  @media only screen and (max-width: 960px) {
    padding: 8px 16px;
  }
`
export const LineDataContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 15px;
`
export const InformationSpace = styled.div`
  height: 36px;
  @media only screen and (max-width: 960px) {
    height: 6px;
  }
`

export const BalanceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`