import { useCallback, useEffect, useState } from 'react'

export default function useTemporalState<T>(defaultVal: T, timeout = 1000): [T, (val: T) => void] {
  const [val, setVal] = useState<T>(defaultVal)

  const setValue = useCallback((val) => {
    setVal(val)
  }, [])

  useEffect(() => {
    if (val !== defaultVal) {
      const t = setTimeout(() => {
        setVal(defaultVal)
      }, timeout)

      return () => {
        clearTimeout(t)
      }
    }
    return undefined
  }, [val, setValue, timeout])

  return [val, setValue]
}
