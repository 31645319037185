import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const LineLabel = styled.p<{ color: string }>`
  color: ${(props) => props.color};
`

export const LineToolTip = styled.div`
  background-color: #FFFFFF;
  border: 1px solid rgb(204, 204, 204);
  line-height: 8px;
  padding: 10px 10px;
`
