import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { Container } from './styled'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #040534 !important',
      borderRadius: '40px 0px 0px 40px !important',
      overflowY: 'hidden',
      [theme.breakpoints.down(960)]: {
        borderRadius: '0 !important',
        width: '100%',
      },
    },
  })
)

const ActionBoard = ({ children, open, setClose }: { children?: JSX.Element; open: boolean; setClose: () => void }) => {
  const classes = useStyles()
  return (
    <Drawer anchor="right" open={open} onClose={setClose} classes={{ paper: classes.root }}>
      <Container>{children ?? <div />}</Container>
    </Drawer>
  )
}

export default ActionBoard
