import { ChainId } from '../constants/blockchain'
import { USED_CHAIN_ID } from '../constants/env'

export const EvmNetworkConfiguration = {
  [ChainId.BSC]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
    ],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [ChainId.BSC_TEST]: {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      'https://data-seed-prebsc-2-s1.binance.org:8545/',
      'https://data-seed-prebsc-1-s2.binance.org:8545/',
    ],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
}

export async function switchUsingNetwork(chainId) {
  if (window.ethereum) {
    if (window.onto && window.onto.selectedAddress && window.onto.selectedAddress.length > 40) {
      return
    }
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
    } catch (err: any) {
      // Chain is not added
      if (err.code === 4902) {
        addEvmNetwork(chainId)
      } else {
        console.log('switch network failed')
      }
    }
  }
}

export async function addEvmNetwork(chainId = USED_CHAIN_ID) {
  if (window.ethereum) {
    if (window.onto && window.onto.selectedAddress && window.onto.selectedAddress.length > 40) {
      return
    }
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [EvmNetworkConfiguration[chainId]],
      })
    } catch (err: any) {
      console.log('add network failed')
    }
  }
}
