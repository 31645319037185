import { useEffect } from 'react'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { en, zh ,ko , ru , tr,es , pt , vi} from 'make-plural/plurals'

export enum LANGUAGE {
  EN = 'en',
  ZHCN = 'zh-CN',
  ZHTW = 'zh-TW',
  KO = 'ko-kr',
  RU = 'ru-RU',
  TR = 'tr-TR',
  ES = 'es-ES',
  PT = 'pt-PT',
  VI = 'vi-VN'
}

i18n.loadLocaleData({
  [LANGUAGE.EN]: { plurals: en },
  [LANGUAGE.ZHCN]: { plurals: zh },
  [LANGUAGE.ZHTW]: { plurals: zh },
  [LANGUAGE.KO]: { plurals: ko },
  [LANGUAGE.RU]: { plurals: ru },
  [LANGUAGE.TR]: { plurals: tr },
  [LANGUAGE.ES]: { plurals: es },
  [LANGUAGE.PT]: { plurals: pt },
  [LANGUAGE.VI]: { plurals: vi },
})

export async function activate(locale: string) {
  const { messages } = await import(`./../../locales/${locale}/messages.js`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

const LanguageProvider = ({ children }) => {
  useEffect(() => {
    // Activate the default locale on page load
    activate(LANGUAGE.EN)
  }, [])
  return (
    <I18nProvider key={'languageProvider'} i18n={i18n}>
      {children}
    </I18nProvider>
  )
}

export default LanguageProvider
