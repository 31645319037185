import { useMediaQuery, useTheme, createStyles, Theme, makeStyles, Typography, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import {
  TitleDiv,
  TitleFront,
  TitleIconOne,
  TitleIconTwo,
  TitleTextDiv,
  TitleTextMobileHide,
  TitleTextMainDiv,
  Pool,
  BasicContainer,
  Basic,
  BasicText,
} from '../../components/pool/PoolBar'
import GradientBackground from '../../common/GradientBackground'

import { useRWAStkaingPoolsApr, useRWAStakingPoolsAprBE } from '../../hooks/smartContracts/useStakingPoolsAprs'
import { displayBalance } from '../../utils/displayBalance'
import {
  useAlphaRWAStakingPoolsTvl,
  useBetaInsuranceStakingPoolTVL,
  useAlphaRWAStakingPoolValue,
} from '../../hooks/smartContracts/useStakingPoolsTvl'
import { ExternalLink as IconExternal, ChevronDown, ChevronUp, ChevronRight } from 'react-feather'
import { ChainId } from '../../constants/blockchain'
import ADDRESS from '../../constants/addresses'
import { useEffect, useMemo, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
        marginBottom: 60,
      },
    },
    subRow: {
      marginTop: 8,
    },
  })
)

const InvestPoolBar = ({
  poolName,
  mobilePoolName,
  alphaRootAddress,
  poolId,
  image,
  link,
  onlyLink,
}: {
  poolName: string
  mobilePoolName: string
  alphaRootAddress: string
  poolId: number
  image: string
  link: string
  onlyLink: boolean
}) => {
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const history = useHistory()

  const { account, chainId } = useWeb3React()

  const aprs = useRWAStakingPoolsAprBE(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )

  const betaTvls = useBetaInsuranceStakingPoolTVL()

  const [extend, setExtend] = useState(false)

  const onClickNaosPool = (poolId: number = 0) => {
    if (onlyLink) {
      window.open(link)
    } else {
      history.push(`/insurance/beta-${poolId}`)
    }
  }

  return (
    <Pool style={{ marginTop: `13px` }}>
      <GradientBackground width="100%" height={isPC ? '80px' : '56px'}>
        {/* <GradientBackground width="100%" height={isPC ? '80px' : '56px'} onClick={() => setExtend(!extend)}> */}
        <BasicContainer>
          <TitleDiv>
            <TitleFront style={{ cursor: 'pointer' }}>
              <Grid container onClick={() => onClickNaosPool(poolId)}>
                <TitleIconOne src={image} />
                <TitleTextMainDiv>
                  <Typography variant="h2">{isPC ? poolName : mobilePoolName}</Typography>
                </TitleTextMainDiv>
                { !onlyLink && <>
                <TitleTextMobileHide>
                  <BasicText>
                    <Typography variant="h2">$ {displayBalance(betaTvls[0], '0,0')}</Typography>
                  </BasicText>
                </TitleTextMobileHide>
                <TitleTextDiv>
                  <BasicText>
                    <Typography variant="h2">{aprs[0].beta[0].toFixed(2)}%</Typography>
                  </BasicText>
                </TitleTextDiv>
                </>}
              </Grid>
              {/* <TitleTextDiv onClick={() => setExtend(!extend)}>
                <BasicText style={{ width: isPC ? 50 : 20 }}>
                  {extend ? (
                    <ChevronUp size={isPC ? 24 : 14} color="white" />
                  ) : (
                    <ChevronDown size={isPC ? 24 : 14} color="white" />
                  )}
                </BasicText>
              </TitleTextDiv> */}
            </TitleFront>
          </TitleDiv>
        </BasicContainer>
      </GradientBackground>

      {/* {extend && (
        <>
          <GradientBackground
            width="100%"
            height={isPC ? '48px' : '30px'}
            onClick={() => onClickNaosPool(1)}
            classes={{
              root: classes.subRow,
            }}
          >
            <BasicContainer>
              <TitleDiv style={{ padding: isPC ? '0px 42px' : '0px 10px' }}>
                <TitleFront>
                  <TitleIconOne src={ImageCoin} style={{ visibility: 'hidden' }} />
                  <TitleTextMainDiv>
                    <Typography variant="h4">BETA Insurance Pool</Typography>
                  </TitleTextMainDiv>
                  <TitleTextMobileHide>
                    <BasicText>
                      <Typography variant="h4">{`$ ${displayBalance(betaTvls[0], '0,0')}`}</Typography>
                    </BasicText>
                  </TitleTextMobileHide>
                  <TitleTextDiv>
                    <BasicText>
                      <Typography variant="h4">{aprs[0].beta[0].toFixed(2)}%</Typography>
                    </BasicText>
                  </TitleTextDiv>
                  <TitleTextDiv>
                    <BasicText style={{ width: isPC ? 50 : 20 }}>
                      <ChevronRight size={isPC ? 24 : 14} color="white" />
                    </BasicText>
                  </TitleTextDiv>
                </TitleFront>
              </TitleDiv>
            </BasicContainer>
          </GradientBackground>
        </>
      )} */}
    </Pool>
  )
}

export default InvestPoolBar
