import { Typography } from '@material-ui/core'
import {
  Container,
  InternalContainer,
  ContainerTop,
  ContainerClose,
  ContainerAccountStatus,
  TitleContainer,
  TitleClose,
  BottomWrapper,
} from './styled'
import AccountStatus from '../AccountStatus'
import { MainButton } from '../../common/buttons'
import { ApprovalState } from '../../hooks/smartContracts/useApproveCallback'

export const InformationCard = ({
  setClose,
  children,
  title,
  hideBorderLeft,
  showAccountStatus,
  showBottom,
  handleSupplyOrder,
  isApprove,
  buttonContent
}: {
  setClose: () => void
  children?: any
  title?: string
  hideBorderLeft?: boolean
  showAccountStatus?: boolean
  showBottom?: boolean
  handleSupplyOrder?: any
  isApprove?: ApprovalState
  buttonContent?:string
}) => {
  return (
    <Container hideBorderLeft={hideBorderLeft ?? false} style={{ overflowY: 'hidden', height: '100%' }}>
      <InternalContainer>
        <ContainerTop>
          <ContainerClose onClick={setClose} color="#FFFFFF" />
          <ContainerAccountStatus>
            {showAccountStatus ? <AccountStatus hideNetwork /> : <div></div>}
          </ContainerAccountStatus>
        </ContainerTop>
        <TitleContainer>
          <Typography variant="h2">{title}</Typography>
          <TitleClose onClick={setClose} color="#FFFFFF" />
        </TitleContainer>
        {/* {children ?? ''} */}
        <div style={{ overflowY: 'auto', overflowX: 'hidden', height: '100vh', flexBasis: '100%' }}>{children ?? ''}</div>
        {showBottom && (
          <BottomWrapper>
            <MainButton onClick={handleSupplyOrder} style={{ marginTop: 16 }}>
              <Typography variant="subtitle2">
                {isApprove === ApprovalState.APPROVED ? buttonContent : 'APPROVE'}
              </Typography>
            </MainButton>
          </BottomWrapper>
        )}
      </InternalContainer>
    </Container>
  )
}

export default InformationCard