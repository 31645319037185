import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import ADDRESS from '../../constants/addresses'
import { USED_CHAIN_ID } from '../../constants/env'
import { useBlockNumber } from '../../redux/application/hooks'
import { useBetaInsuranceContract, useTransmuterContract } from './useContract'
import { useTokenBalance } from './useTokenBalance'

export function useBetaRedeemable(): [nusdAmount: BigNumber, daiAmount: BigNumber] {
  const { chainId } = useWeb3React()
  const betaContract = useBetaInsuranceContract(ADDRESS.BETA_INSURANCE)
  const blockNumber = useBlockNumber()

  const [transmuterAddress, setTransmuterAddress] = useState(ADDRESS.TRANSMUTER[chainId ?? USED_CHAIN_ID])
  const transmuterContract = useTransmuterContract(transmuterAddress)
  const [depositedNusd, setDepositedNusd] = useState<BigNumber>(BigNumber.from(0))
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, ADDRESS.BETA_INSURANCE[chainId ?? USED_CHAIN_ID])

  const [daiInVault, setDaiInVault] = useState<BigNumber>(BigNumber.from(0))
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, ADDRESS.BETA_INSURANCE[chainId ?? USED_CHAIN_ID])

  const [nusdAmount, setNusdAmount] = useState<BigNumber>(BigNumber.from(0))
  const [daiAmount, setDaiAmount] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (betaContract) {
      betaContract.vaultCount().then((c) => {
        betaContract.getVaultTotalDeposited(c.sub(1)).then(setDaiInVault)
      })
      betaContract.transmuter().then(setTransmuterAddress)
    }
  }, [betaContract, blockNumber])

  useEffect(() => {
    if (transmuterContract && chainId) {
      transmuterContract.depositedNTokens(ADDRESS.BETA_INSURANCE[chainId]).then(setDepositedNusd)
    }
  }, [transmuterContract, chainId])

  useEffect(() => {
    if (transmuterContract && chainId) {
      transmuterContract.userInfo(ADDRESS.BETA_INSURANCE[chainId]).then(({ pendingdivs, inbucket }) => {
        const remainingNusd = depositedNusd.sub(pendingdivs).sub(inbucket)
        setNusdAmount(nusdBalance.add(remainingNusd))
      })
    }
  }, [transmuterContract, depositedNusd, nusdBalance, chainId])

  useEffect(() => {
    setDaiAmount(daiBalance.add(daiInVault))
  }, [daiBalance, daiInVault])

  return useMemo(() => [nusdAmount, daiAmount], [nusdAmount, daiAmount])
}
