import { useEffect, useMemo } from 'react'
import { useMediaQuery, useTheme, createStyles, Theme, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'

import {
  TitleDiv,
  TitleFront,
  TitleIconOne,
  TitleIconTwo,
  TitleTextDiv,
  TitleTextMobileHide,
  TitleTextMainDiv,
  Pool,
  BasicContainer,
  Basic,
  BasicText,
} from '../../components/pool/PoolBar'
import GradientBackground from '../../common/GradientBackground'
import ImageCoin from '../../assets/symbol/coin.png'
import NAOS_ETH from '../../assets/symbol/NAOS_ETH.png'
import nUSD_3CRV from '../../assets/symbol/nUSD_3CRV.png'
import NAOS_BNB from '../../assets/symbol/NAOS_BNB.png'
import BetaTokenImage from '../../assets/symbol/betaToken.svg'
import ALPACA from '../../assets/symbol/alpaca.svg'
import BEEFY from '../../assets/symbol/beefy.png'

import { useStkaingPoolsApr } from '../../hooks/smartContracts/useStakingPoolsAprs'
import { IS_DEV, USED_CHAIN_ID } from '../../constants/env'
import { displayBalance } from '../../utils/displayBalance'
import { useStkaingPoolsTvl } from '../../hooks/smartContracts/useStakingPoolsTvl'
import { ExternalLink as IconExternal } from 'react-feather'
import ExternalLink from '../../components/commons/ExternalLink'
import URLS from '../../constants/urls'
import { ChainId } from '../../constants/blockchain'
import { useWeb3React } from '@web3-react/core'
import useStakingPoolId from '../../hooks/useStakingPoolId'
import ADDRESS from '../../constants/addresses'
import { usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
        marginBottom: 60,
      },
    },
  })
)

const PoolBar = ({ isPC, onClick, img, isDuoIcon, poolName, tvl, apr, disabled = false }) => {
  return (
    <Pool style={{ marginTop: `13px` }}>
      {disabled ? (
        <GradientBackground width="100%" height={isPC ? '80px' : '56px'}>
          <BasicContainer>
            <TitleDiv>
              <TitleFront>
                {isDuoIcon ? <TitleIconTwo src={img} /> : <TitleIconOne src={img} />}

                <TitleTextMainDiv>
                  <Typography variant="h1">{poolName}</Typography>
                </TitleTextMainDiv>
                <TitleTextMobileHide>
                  <BasicText>
                    <Typography variant="h1">$ {tvl}</Typography>
                  </BasicText>
                </TitleTextMobileHide>
                <TitleTextDiv>
                  <BasicText>
                    <Typography variant="h1">{apr ? apr.toFixed(2) : 0} %</Typography>
                  </BasicText>
                </TitleTextDiv>
              </TitleFront>
            </TitleDiv>
          </BasicContainer>
        </GradientBackground>
      ) : (
        <GradientBackground width="100%" height={isPC ? '80px' : '56px'} onClick={onClick}>
          <BasicContainer>
            <TitleDiv>
              <TitleFront>
                {isDuoIcon ? <TitleIconTwo src={img} /> : <TitleIconOne src={img} />}

                <TitleTextMainDiv>
                  <Typography variant="h1">{poolName}</Typography>
                </TitleTextMainDiv>
                <TitleTextMobileHide>
                  <BasicText>
                    <Typography variant="h1">$ {tvl}</Typography>
                  </BasicText>
                </TitleTextMobileHide>
                <TitleTextDiv>
                  <BasicText>
                    <Typography variant="h1">{apr ? apr.toFixed(2) : 0} %</Typography>
                  </BasicText>
                </TitleTextDiv>
              </TitleFront>
            </TitleDiv>
          </BasicContainer>
        </GradientBackground>
      )}
    </Pool>
  )
}

const PoolBarList = ({ isPC, chainId, PoolId, tvls, aprs, disablePool }) => {
  const history = useHistory()

  function onClickPool(path) {
    return () => {
      const prefix = '/farm/'
      history.push(prefix + path)
    }
  }

  switch (chainId) {
    case ChainId.RINKEBY:
      return (
        <>
          <PoolBar
            isPC={isPC}
            onClick={onClickPool('naos')}
            img={ImageCoin}
            isDuoIcon={false}
            poolName={t`NAOS Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('boost')}
            img={ImageCoin}
            isDuoIcon={false}
            poolName={t`NAOS Boost Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.BOOST_POOL[ChainId.RINKEBY]][PoolId.NAOS_BOOST], '0,0')}
            apr={aprs['eth'][ADDRESS.BOOST_POOL[ChainId.RINKEBY]][PoolId.NAOS_BOOST]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('beta')}
            img={BetaTokenImage}
            isDuoIcon={false}
            poolName={t`NAOS BETA Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.RINKEBY]][PoolId.BETA], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.RINKEBY]][PoolId.BETA]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('naos-eth-lp')}
            img={NAOS_ETH}
            isDuoIcon={true}
            poolName={t`NAOS/ETH LP Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS_ETH_LP], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS_ETH_LP]}
            disabled={disablePool}
          />
        </>
      )
    case ChainId.MAINNET:
      return (
        <>
          <PoolBar
            isPC={isPC}
            onClick={onClickPool('naos')}
            img={ImageCoin}
            isDuoIcon={false}
            poolName={t`NAOS Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('naos-eth-lp')}
            img={NAOS_ETH}
            isDuoIcon={true}
            poolName={t`NAOS/ETH LP Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS_ETH_LP], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS_ETH_LP]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('naos-eth-slp')}
            img={NAOS_ETH}
            isDuoIcon={true}
            poolName={t`NAOS/ETH SLP Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS_ETH_SLP], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NAOS_ETH_SLP]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('nusd-3crv')}
            img={nUSD_3CRV}
            isDuoIcon={true}
            poolName={t`nUSD/3CRV Pool`}
            tvl={displayBalance(tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NUSD_3CRV], '0,0')}
            apr={aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][PoolId.NUSD_3CRV]}
            disabled={disablePool}
          />
        </>
      )
    case ChainId.BSC_TEST:
    default:
      return (
        <>
          <PoolBar
            isPC={isPC}
            onClick={onClickPool('naos-bnb-lp')}
            img={NAOS_BNB}
            isDuoIcon={true}
            poolName={t`NAOS/BNB Pool`}
            tvl={displayBalance(tvls['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][PoolId.NAOS_BNB_LP], '0,0')}
            apr={aprs['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][PoolId.NAOS_BNB_LP]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('boost')}
            img={ImageCoin}
            isDuoIcon={false}
            poolName={t`NAOS Boost Pool`}
            tvl={displayBalance(tvls['bsc'][ADDRESS.BOOST_POOL[ChainId.BSC]][PoolId.NAOS_BOOST], '0,0')}
            apr={aprs['bsc'][ADDRESS.BOOST_POOL[ChainId.BSC]][PoolId.NAOS_BOOST]}
            disabled={disablePool}
          />

          <PoolBar
            isPC={isPC}
            onClick={onClickPool('beta')}
            img={BetaTokenImage}
            isDuoIcon={false}
            poolName={t`BETA Staking Pool`}
            tvl={displayBalance(tvls['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][PoolId.BETA], '0,0')}
            apr={aprs['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][PoolId.BETA]}
            disabled={disablePool}
          />

          {/* <ExternalLink href={URLS!.ALPACA_FARM}>
            <Pool>
              <GradientBackground width="100%" height={isPC ? '80px' : '56px'}>
                <BasicContainer>
                  <TitleDiv>
                    <TitleFront>
                      <TitleIconTwo src={ALPACA} />
                      <TitleTextMainDiv>
                        <Typography variant="h1">Alpaca Auto-Compound</Typography>
                      </TitleTextMainDiv>
                      <IconExternal size={isPC ? 32 : 20} color="white" />
                    </TitleFront>
                  </TitleDiv>
                </BasicContainer>
              </GradientBackground>
            </Pool>
          </ExternalLink> */}

          {/* <ExternalLink href={URLS!.BEEFY_FARM}>
            <Pool>
              <GradientBackground width="100%" height={isPC ? '80px' : '56px'}>
                <BasicContainer>
                  <TitleDiv>
                    <TitleFront>
                      <TitleIconOne src={BEEFY} />
                      <TitleTextMainDiv>
                        <Typography variant="h1">Beefy Auto-Compound</Typography>
                      </TitleTextMainDiv>
                      <IconExternal size={isPC ? 32 : 20} color="white" />
                    </TitleFront>
                  </TitleDiv>
                </BasicContainer>
              </GradientBackground>
            </Pool>
          </ExternalLink> */}
        </>
      )
  }
}

const Farm = () => {
  const { chainId } = useWeb3React()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const classes = useStyles()
  const aprs = useStkaingPoolsApr()
  const tvls = useStkaingPoolsTvl()

  const PoolId = useStakingPoolId()
  const disablePool = useMemo(() => !chainId || chainId <= 0, [chainId])
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.informationBox}>
        <Pool style={{ marginTop: `0px` }}>
          <Basic style={{ padding: `0px 0px` }}>
            {/* <BasicText> */}
            <Typography variant="subtitle2">
              <Trans>Pool</Trans>
            </Typography>
            <Basic style={{ padding: `0px 0px` }}>
              <TitleTextMobileHide>
                <BasicText>
                  <Typography variant="subtitle2">
                    <Trans>TVL</Trans>
                  </Typography>
                </BasicText>
              </TitleTextMobileHide>
              <TitleTextDiv>
                <BasicText>
                  <Typography variant="subtitle2">
                    <Trans>MAX APR</Trans>
                  </Typography>
                </BasicText>
              </TitleTextDiv>
            </Basic>
          </Basic>

          <PoolBarList
            isPC={isPC}
            chainId={chainId}
            PoolId={PoolId}
            tvls={tvls}
            aprs={aprs}
            disablePool={disablePool}
          />
        </Pool>
      </div>
    </div>
  )
}
export default Farm
