import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useSushiChefContract } from './useContract'

export function useSushiMasterChefInfo(
  address?: IAddressOrAddressMap,
  staker?: string,
  poolId?: number
): [stakedAmount: BigNumber, rewardAmount: BigNumber] {
  const contract = useSushiChefContract(address, true)
  const blockNumber = useBlockNumber()
  const [stakedAmount, setStakedAmount] = useState<BigNumber>(BigNumber.from(0))
  const [rewardAmount, setRewardAmount] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (contract && staker && poolId) {
      contract.pendingSushi(poolId, staker).then(setRewardAmount)
      contract.userInfo(poolId, staker).then(({ amount }) => setStakedAmount(amount))
    }
  }, [contract, staker, poolId, blockNumber])

  return useMemo(() => [stakedAmount, rewardAmount], [stakedAmount, rewardAmount])
}
