import { useState, useMemo, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ExternalLink as IconExternalLink } from 'react-feather'
import {
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  createStyles,
  Theme,
  makeStyles,
  Backdrop,
} from '@material-ui/core'
import clsx from 'clsx'
import { Trans, t } from '@lingui/macro'

import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  TitleBackContainer,
  TitleBack,
  Basic,
  BasicContainer,
  BasicText,
  BasicBottom,
  BasicBottomLeft,
  BasicBottomRight,
  PoolContainer,
  PoolFlex,
  PoolFlexCenter,
  Button,
  SubButton,
} from './styled'
import ActionBoard from '../../../common/ActionBoard'
import GradientCard from '../../../common/GradientCard'
import GradientBackground from '../../../common/GradientBackground'
import InformationCard from '../../../common/InformationCard'
import { DrawerWidth } from '../../../components/Navbar'
import { ExternalLink } from '../../../components/commons/ExternalLink'
import StakeDrawerContent from './actionContents/naosPools/stake'
import UnstakeDrawerContent from './actionContents/naosPools/unstake'
import { MainButton } from '../../../common/buttons'

import ADDRESS from '../../../constants/addresses'
import { ChainId } from '../../../constants/blockchain'
import { useTokenBalance } from '../../../hooks/smartContracts/useTokenBalance'
import { useStakingPoolInfo } from '../../../hooks/smartContracts/useStakingPoolsInfo'
import { displayBalance } from '../../../utils/displayBalance'
import { useBetaInsuranceContract, useStakingPoolContract } from '../../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../../redux/application/hooks'
import { useTransactionAdder } from '../../../redux/transactions/hooks'
import { PoolAction, StakingPoolType } from '.'
import { useBetaInsurancePremiums } from '../../../hooks/smartContracts/useBetaInsurancePremiums'
import { BigNumber } from '@ethersproject/bignumber'
import { usePositionOffset, useBackToOrigin } from '../../../redux/navbar/hooks'

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   display: 'flex',
    //   justifyContent: 'center',
    // },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px - ${DrawerWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
    },
    disableTitleButtonExit: {
      color: 'rgba(255, 255, 255, 0.2)',
      border: `1px solid rgba(255, 255, 255, 0.2)`,
      backgroundColor: 'transparent',
      marginRight: '8px',
    },
    dividerVertical: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '32px auto',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      maxWidth: 280,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '40px',
      },
    },
  })
)

const ClaimContent = ({ isPC, rewardAmount, handleClaim, classDisableText }) => {
  return (
    <>
      <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
        <Typography variant="h2">{displayBalance(rewardAmount)}</Typography>
      </PoolFlexCenter>
      <PoolFlexCenter style={{ marginTop: `20px` }}>
        <Typography variant="h4">
          <Trans>NAOS Rewards</Trans>
        </Typography>
      </PoolFlexCenter>
      <PoolFlexCenter>
        <MainButton onClick={handleClaim} disable={rewardAmount.lte(0)} disableStyle={'solid'}>
          <Typography variant="subtitle2" className={rewardAmount.lte(0) ? classDisableText : ''}>
            <Trans>CLAIM REWARD</Trans>
          </Typography>
        </MainButton>
      </PoolFlexCenter>
    </>
  )
}

const ClaimContentWithBoost = ({ isPC, stakedAmount, rewardAmount, totalDeposited, handleBoost, handleClaim, classDisableText }) => {
  const classes = useStyles()
  const premiums = useBetaInsurancePremiums(ADDRESS.BETA_INSURANCE)

  const [boostAmount, setBoostAmount] = useState(BigNumber.from(0))
  const [ids, setIds] = useState<BigNumber[]>([])
  useEffect(() => {
    if (premiums.length > 0) {
      let boostAmount = BigNumber.from(0)
      const ids: BigNumber[] = []

      const current = BigNumber.from(Math.floor(Date.now() / 1000))
      for (let i = 0; i < premiums.length; i++) {
        const premium = premiums[i]

        ids.push(premium.premiumID)
        const toAdd = current
          .sub(premium.lastDistributedTimestamp)
          .mul(premium.NAOSAmount)
          .div(premium.end.sub(premium.start))

        boostAmount = boostAmount.add(toAdd)
      }

      setBoostAmount(boostAmount)
      setIds(ids)
    }
  }, [premiums])

  const [userBoostAmount, setUserBoostAmount] = useState(BigNumber.from(0))
  useEffect(() => {
    if (totalDeposited.gt(0) && stakedAmount.gt(0)) {
      setUserBoostAmount(boostAmount.mul(stakedAmount).div(totalDeposited))
    }
  }, [boostAmount, totalDeposited, stakedAmount])

  return (
    <>
      <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
        <Typography variant="h2">{displayBalance(rewardAmount)}</Typography>
      </PoolFlexCenter>
      <PoolFlexCenter style={{ marginTop: `20px` }}>
        <Typography variant="h4">
          <Trans>NAOS Rewards</Trans>
        </Typography>
      </PoolFlexCenter>
      {/* <PoolFlexCenter style={{ marginTop: 10 }}>
        <Typography variant="h4">( Boost to earn {displayBalance(userBoostAmount)} NAOS )</Typography>
      </PoolFlexCenter> */}

      {/* <PoolFlexCenter style={{ marginTop: 18 }}> */}
      <PoolFlexCenter>
        {/* <SubButton className={classes.titleButtonExit} onClick={() => handleBoost(ids)}>
          <Typography variant="body2" color="textSecondary">
            BOOST
          </Typography>
        </SubButton> */}

        <MainButton onClick={handleClaim} disable={rewardAmount.lte(0)} disableStyle={'solid'}>
          <Typography variant="subtitle2" className={rewardAmount.lte(0) ? classDisableText : ''}>
            <Trans>CLAIM REWARD</Trans>
          </Typography>
        </MainButton>
      </PoolFlexCenter>
    </>
  )
}

const NaosPool = ({
  poolId,
  name,
  stakingTokenAddr,
  imageCoin,
  apr,
  tvl,
  urlToGetToken,
  poolType,
  supportedChains,
}) => {
  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const isBeta = useMemo(() => name === 'BETA', [name])
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const [liqudityOpen, setLiquidityOpen] = useState(false)
  const [epochOpen, setEpochOpen] = useState(false)
  const [assetDetailOpen, setAssetDetailOpen] = useState(false)
  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false, false])
  const flexBoxHeight = useMemo(() => {
    return `${window.innerHeight - 111}px`
  }, [])

  const poolAddr = useMemo(
    () => (poolType === StakingPoolType.NAOS ? ADDRESS.STAKINGPOOL : ADDRESS.STAKINGPOOL_WITH_TRANSFER),
    [poolType]
  )
  const stakingPoolContract = useStakingPoolContract(poolAddr)
  const betaInsuranceContract = useBetaInsuranceContract(ADDRESS.BETA_INSURANCE)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [balance] = useTokenBalance(stakingTokenAddr, account ?? undefined)
  const [stakedAmount, rewardAmount, totalDeposited] = useStakingPoolInfo(poolAddr, account ?? undefined, poolId)

  const closeDrawer = () => {
    setDrawerOpen([false, false, false, false])
  }

  const openDrawer = (type: number) => {
    const status = [...isDrawerOpen]
    status[type] = true
    setDrawerOpen(status)
  }

  async function handleClaim() {
    if (stakingPoolContract && rewardAmount.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await stakingPoolContract.claim(poolId)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Claim ${displayBalance(rewardAmount)} NAOS`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleExit() {
    if (stakingPoolContract) {
      if (stakedAmount.lte(0) && rewardAmount.lte(0)) return
      openConfirmingModal(true)
      try {
        const res = await stakingPoolContract.exit(poolId)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Exit ${name} Pool`,
        })
        closeDrawer()
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleBoost(premiumIDs) {
    if (betaInsuranceContract) {
      openConfirmingModal(true)
      try {
        const res = await betaInsuranceContract.distributeNAOSToStakingPool(premiumIDs)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Boost rewards from BETA Insurance`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  const [blockPage, setBlockPage] = useState(false)
  useEffect(() => {
    if (supportedChains.indexOf(chainId) < 0) {
      setBlockPage(true)
    } else if (blockPage == true) {
      setBlockPage(false)
    }
  }, [chainId])

  const networkName = supportedChains.indexOf(ChainId.BSC) >= 0 ? 'BSC' : 'ETH'

  return (
    <>
      <div
        className={clsx(classes.informationBox, { [classes.boxLeft]: liqudityOpen || epochOpen || assetDetailOpen })}
      >
        <Backdrop open={blockPage} style={{ zIndex: 1, color: '#ffffff', marginTop: '95px' }}>
          <Typography variant="h1">
            <Trans>Please switch to {networkName} network!</Trans>
          </Typography>
        </Backdrop>
        <TitleDiv>
          <TitleFront>
            <TitleIcon src={imageCoin} />
            <TitleTextDiv>
              <Typography variant="body2" className={classes.greyHeader}>
                NAOS
              </Typography>
              {name === 'BETA' ? (
                <Typography variant="h3">
                  <Trans>BETA Staking Pool</Trans>
                </Typography>
              ) : (
                <Typography variant="h3">
                  <Trans>{name} Pool</Trans>
                </Typography>
              )}
            </TitleTextDiv>
          </TitleFront>
          <TitleBackContainer>
            <TitleBack
              className={
                stakedAmount.lte(0) && rewardAmount.lte(0) ? classes.disableTitleButtonExit : classes.titleButtonExit
              }
              onClick={() => (stakedAmount.gt(0) || rewardAmount.gt(0)) && openDrawer(PoolAction.EXIT)}
            >
              <Typography variant="body2" color="inherit">
                <Trans>EXIT</Trans>
              </Typography>
            </TitleBack>
            <TitleBack className={classes.titleButton} onClick={() => openDrawer(PoolAction.STAKE)}>
              <Typography variant="body2">
                <Trans>STAKE</Trans>
              </Typography>
            </TitleBack>
          </TitleBackContainer>
        </TitleDiv>
        <GradientBackground width="100%" height={isPC ? '128px' : '548px'}>
          <BasicContainer>
            <GradientCard height={isPC ? '80px' : '442px'}>
              <Basic>
                <BasicText style={{ alignItems: 'center' }}>
                  <Typography variant="h2">{name}</Typography>
                  <Typography variant="h4">
                    <Trans>Staked Token</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">$ {displayBalance(tvl, '0,0')}</Typography>
                  <Typography variant="h4">
                    <Trans>TVL</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">{apr ? apr.toFixed(2) : 0} %</Typography>
                  <Typography variant="h4">
                    <Trans>MAX APR</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">
                    <Trans>Instant</Trans>
                  </Typography>
                  <Typography variant="h4">
                    <Trans>Cooldown</Trans>
                  </Typography>
                </BasicText>
              </Basic>
            </GradientCard>
            <BasicBottom>
              <BasicBottomLeft>
                {poolType === StakingPoolType.NAOS ? (
                  <Typography variant="subtitle1">
                    <Trans>Purchase {name} tokens</Trans>
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    <Trans>Get {name} tokens</Trans>
                  </Typography>
                )}
              </BasicBottomLeft>
              <ExternalLink href={urlToGetToken}>
                <BasicBottomRight>
                  <Typography variant="subtitle1">
                    <Trans>Get {name}</Trans>
                  </Typography>
                  <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 12 }} />
                </BasicBottomRight>
              </ExternalLink>
            </BasicBottom>
          </BasicContainer>
        </GradientBackground>
        <PoolContainer>
          <Typography variant="h2">
            <Trans>Stake to earn NAOS tokens</Trans>
          </Typography>
          <PoolFlex>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '31%' : '100%'} height="232px">
              {isPC ? <></> : <div style={{ height: '1px' }}></div>}
              <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                <Typography variant="h2">{displayBalance(balance)}</Typography>
              </PoolFlexCenter>
              <PoolFlexCenter style={{ marginTop: `20px` }}>
                <Typography variant="h4">
                  <Trans>Available to Stake</Trans>
                </Typography>
              </PoolFlexCenter>
              <PoolFlexCenter>
                <Button onClick={() => openDrawer(PoolAction.STAKE)}>
                  <Typography variant="subtitle2">
                    <Trans>STAKE</Trans>
                  </Typography>
                </Button>
              </PoolFlexCenter>
            </GradientCard>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '31%' : '100%'} height="232px">
              {isPC ? <></> : <div style={{ height: '1px' }}></div>}
              <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                <Typography variant="h2">{displayBalance(stakedAmount)}</Typography>
              </PoolFlexCenter>
              <PoolFlexCenter style={{ marginTop: `20px` }}>
                <Typography variant="h4">
                  <Trans>{name} Staked</Trans>
                </Typography>
              </PoolFlexCenter>
              <PoolFlexCenter>
                <MainButton
                  onClick={() => stakedAmount.gt(0) && openDrawer(PoolAction.UNSTAKE)}
                  disable={stakedAmount.lte(0)}
                  disableStyle={'solid'}
                >
                  <Typography variant="subtitle2" className={stakedAmount.lte(0) ? classes.disableText : ''}>
                    <Trans>UNSTAKE</Trans>
                  </Typography>
                </MainButton>
              </PoolFlexCenter>
            </GradientCard>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '31%' : '100%'} height="232px">
              {isPC ? <></> : <div style={{ height: '1px' }}></div>}
              {!isBeta ? (
                <ClaimContent isPC={isPC} rewardAmount={rewardAmount} handleClaim={handleClaim} classDisableText={classes.disableText} />
              ) : (
                <ClaimContentWithBoost
                  isPC={isPC}
                  stakedAmount={stakedAmount}
                  rewardAmount={rewardAmount}
                  totalDeposited={totalDeposited}
                  handleClaim={handleClaim}
                  handleBoost={handleBoost}
                  classDisableText={classes.disableText}
                />
              )}
            </GradientCard>
          </PoolFlex>
        </PoolContainer>
        <div className={classes.bottomSpace}></div>
      </div>

      <div className={clsx({ [classes.boxRight]: liqudityOpen || epochOpen }, classes.boxRightHide)}></div>

      <ActionBoard open={isDrawerOpen[PoolAction.STAKE]} setClose={closeDrawer}>
        <InformationCard title={t`STAKE`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <StakeDrawerContent
            poolId={poolId}
            poolAddr={poolAddr[chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC]}
            tokenAddr={stakingTokenAddr}
            symbol={name}
            availableAmount={balance}
            chainId={chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC}
          />
        </InformationCard>
      </ActionBoard>

      <ActionBoard open={isDrawerOpen[PoolAction.UNSTAKE]} setClose={closeDrawer}>
        <InformationCard title={t`UNSTAKE`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <UnstakeDrawerContent
            poolId={poolId}
            poolAddr={poolAddr[chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC]}
            tokenAddr={stakingTokenAddr}
            symbol={name}
            availableAmount={stakedAmount}
            chainId={chainId ? (blockPage ? ChainId.BSC : chainId) : ChainId.BSC}
          />
        </InformationCard>
      </ActionBoard>

      <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={isDrawerOpen[PoolAction.EXIT]}
        onClose={closeDrawer}
      >
        <DialogTitle>
          <Typography variant="h3">
            <Trans>Exit {name} Pool?</Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4">
            <Trans>Unstake {name} and claim your rewards.</Trans>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 24 }}>
          <MuiButton onClick={handleExit}>
            <Trans>Continue</Trans>
          </MuiButton>
          <MuiButton onClick={closeDrawer}>
            <Trans>Cancel</Trans>
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default NaosPool
