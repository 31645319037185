import { t  } from "@lingui/macro"

const defaultPoolName = 'Real World Assets Pool'
const defaultMobileName = 'RWA Pool'
const defaultAssetType = t`Emerging Market Consumer`
const defaultAssetMaturity = t`1 year`
const defaultAOGradientCardHeight = '220px'
const defaultAOGradientCardMobileHeight = '360px'

export const PoolNames: string[] = [t`Corl`, t`Test Pool2`]
export const MobilePoolNames: string[] = [t`Corl`, t`Test Pool2`]
export const AssetType: string[] = [t`Revenue Based Finance`, defaultAssetType]
export const AssetMaturity: string[] = [t`24 Months Revolving`, defaultAssetMaturity]

export const AssetOriginatorNames: string[] = PoolNames
export const AODescription: string[] = [
  t`Corl is a Fintech company based in Toronto, Canada and invests in the digital economy with revenue-based financing. Since its inception in 2019, Corl has originated loans across more than 60 companies using its AI-powered software to analyze and mitigate investment risk.`,
  '',
]
export const AssetOriginatorsShow: boolean[] = [true, false]
export const AOGradientCardHeight: string[] = ['160px', defaultAOGradientCardHeight]
export const AOGradientCardMobileHeight: string[] = ['260px', defaultAOGradientCardMobileHeight]
export const ExecutiveSummaryLink: string[] = [
    'https://drive.google.com/file/d/1mZanjtJw50b1xPco7qJTGVXm6d--zb-C/view?usp=sharing',
    ''
]
export const WebsiteLink: string[] = [
    'https://corl.io/',
    ''
]

//
// Entity
// Non-American
// American
export const DocuSignExampleLink: string[] = [
  'https://drive.google.com/file/d/1ulhk-8_d1S5WNwutrFN1hWM9PI9US76K/view?usp=sharing',
  'https://drive.google.com/file/d/1eKsNhMIJFdHqJ_kzTgyLpbmapRno6JkV/view?usp=sharing',
  'https://drive.google.com/file/d/1IvsJl5fyt-XxtYCXGEh-gFASPYW-rKnb/view?usp=sharing'
]