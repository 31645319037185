import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider as StoreProvider } from 'react-redux'

import { MuiProvider, StyledProvider } from './theme'
import store from './redux'

import './index.css'
import App from './pages/App'
import reportWebVitals from './reportWebVitals'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './utils/connectors'
import LanguageProvider from './common/LanguageProvider'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { GTMParams } from './constants/env'

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <GTMProvider state={GTMParams}>
          <LanguageProvider>
            <BrowserRouter>
              <MuiProvider>
                <StyledProvider>
                  <App />
                </StyledProvider>
              </MuiProvider>
            </BrowserRouter>
          </LanguageProvider>
        </GTMProvider>
      </Web3ReactProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
