import { createReducer } from '@reduxjs/toolkit'
import { shiftToLeft, backToOrigin } from './actions'

export interface NavbarState {
  floatButtonPositionRight: number
}

const initialState: NavbarState = {
  floatButtonPositionRight: 0,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(shiftToLeft, (state) => {
      state.floatButtonPositionRight = 320 + 12
    })
    .addCase(backToOrigin, (state) => {
      state.floatButtonPositionRight = 0
    })
)
