import { useMemo, useState, useEffect, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useHistory } from 'react-router-dom'
import { BigNumber } from '@ethersproject/bignumber'
import { Grid, createStyles, Theme, makeStyles, Typography, Divider } from '@material-ui/core'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { Trans, t } from '@lingui/macro'

import ImgDefition from '../../assets/symbol/definition.png'
import GradientCard from '../../common/GradientCard'
import InformationCard from '../../common/InformationCard'
import { InternalLink } from '../../components/commons/InternalLink'
import { useInvestBoostAPR } from '../../hooks/smartContracts/useInvestBoostAPR'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'
import {
  BasicTextFlex,
  InformationSpace,
  InfoFlex,
  Button,
  InfoContent,
  BasicText,
  ButtonFlex,
  DetailIcon,
  DetailModal,
  ModalWrapper,
} from './styled'
import ADDRESS from '../../constants/addresses'
import { displayBalance } from '../../utils/displayBalance'
import { MainButton } from '../../common/buttons'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { IAmountInputRefObj } from '../../common/AmountInput'
import BoostListModal from '../../components/modals/BoostListModal'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { useBackToOrigin } from '../../redux/navbar/hooks'
import { ChainId } from '../../constants/blockchain'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
    },
    informationDivider: {
      width: '100%',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    boostColor: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    bootBlock: {
      display: 'flex',
      // justifyContent: 'flex-end',
      // alignItems: 'center',
    },
    naosBoostmarginBottom: {
      marginBottom: '20px',
      [theme.breakpoints.up(960)]: {
        marginBottom: '15px',
      },
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
  })
)

const InvestDetail = ({
  open,
  setClose,
  pendingRewards,
  galaxyPoolContract,
  remainingCapacity,
  alphaPoolInfo,
  alphaDepositAmounts,
}: {
  pendingRewards: BigNumber
  galaxyPoolContract: any
  remainingCapacity: BigNumber
  alphaPoolInfo: {
    apr: any
    name: string
    period: number
    id: number
  }[]
  alphaDepositAmounts: Object
  open: boolean
  setClose(): void
}) => {
  const amountInput = useRef<IAmountInputRefObj>(null)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  const history = useHistory()
  const [isDetailShown, setIsDetailShown] = useState(false)

  // const [betaBalance] = useTokenBalance(ADDRESS.BETA_INSURANCE, account ?? undefined)
  // const [, betaPrice] = useBetaInsuranceInfo(ADDRESS.BETA_INSURANCE)

  const [isAlphaPoolInvested, setIsAlphaPoolInvested] = useState([false, false])
  const [isBetaPoolInvested, setIsBetaPoolInvested] = useState(false)

  const [closeAlphaClaim, setCloseAlphaClaim] = useState(true)
  const [closeBetaClaim, setCloseBetaClaim] = useState(true)
  const boosted0 = useInvestBoostAPR(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'],
    account ?? undefined,
    alphaPoolInfo[0].id
  )
  // const boosted1 = useInvestBoostAPR(ADDRESS.GALAXY_STAKING_POOL.default[0], account ?? undefined, alphaPoolInfo[1].id)
  const backFloatButton = useBackToOrigin()

  const boosteds = useMemo(
    () => ({
      0: (boosted0 + 0.05) * 100,
      // 1: (boosted1 + 0.05) * 100,
    }),
    [boosted0]
  )

  const [showBoostModal, setShowBoostModal] = useState(false)

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  useEffect(() => {
    // setIsAlphaPoolInvested([alphaDepositAmounts[0].gt(0), alphaDepositAmounts[1].gt(0)])
    setIsAlphaPoolInvested([alphaDepositAmounts[0].gt(0)])
  }, [alphaDepositAmounts])

  // useEffect(() => {
  //   setIsBetaPoolInvested(betaBalance.gt(0))
  // }, [betaBalance])

  useEffect(() => {
    setCloseAlphaClaim(pendingRewards.eq(0))
  }, [pendingRewards])

  function onClickLink(innerPath: string, condition?: boolean) {
    if (condition)
      return () => {
        backFloatButton()
        history.push(innerPath)
      }
  }

  async function handleClaim(activeAlphaPool: number) {
    if (!closeAlphaClaim && galaxyPoolContract) {
      openConfirmingModal(true)
      try {
        const poolId = alphaPoolInfo[activeAlphaPool].id
        const estimateGas = await galaxyPoolContract.estimateGas.claim(poolId)
        const res = await galaxyPoolContract.claim(poolId, { gasLimit: calculateGasMargin(estimateGas) })
        openConfirmingModal(false, res.hash)
        addTransaction(res, { summary: `Claim reward from ALPHA Pool` })
        amountInput.current?.resetAmount()
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleUpdateBoostRatio(poolId) {
    if (galaxyPoolContract && account) {
      openConfirmingModal(true)
      try {
        const estimateGas = await galaxyPoolContract.estimateGas.activateBoost(poolId, account)
        const res = await galaxyPoolContract.activateBoost(poolId, account, {
          gasLimit: calculateGasMargin(estimateGas),
        })
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: "Update pool's boost ratio",
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  if (!open) {
    return <div></div>
  }
  return (
    <>
      <InformationCard title={t`Investments`} setClose={setClose}>
        {/* <InfoFlex>
          <GradientCard width="100%" aspectRatio="280/60">
            <InfoContent>
              <ButtonFlex>
                <Typography variant="h4" style={{ marginTop: '1px' }}>
                  NAOS Boost
                </Typography>
                <Typography variant="h4" style={{ marginLeft: '4px' }}>
                  {boosts[0].toFixed(2)}x
                </Typography>
              </ButtonFlex>

              <ButtonFlex style={{ cursor: 'pointer' }} onClick={() => setShowBoostModal(true)}>
                <Typography variant="body1" className={classes.boostColor}>
                  Boost
                </Typography>
              </ButtonFlex>
            </InfoContent>
          </GradientCard>
        </InfoFlex> */}
        {/* {alphaDepositAmounts[0].eq(0) && !isBetaPoolInvested ? (
          <>
            <Typography variant="h4">You haven't deposited any pools yet.</Typography>
            <Typography variant="h4">Do it soon!</Typography>
          </>
        ) : (
          <InformationSpace inputSmallHeight={'24px'} />
        )} */}
        {/* {isAlphaPoolInvested.map((isInvested, id) => {
          return ( */}

        {/* {isAlphaPoolInvested[0] && (
          <> */}
        <InformationSpace inputSmallHeight={'24px'} />
        <Typography variant="subtitle1">
          <Trans>ALPHA Pool</Trans>
        </Typography>
        <Typography variant="body2" style={{ marginTop: 8 }}>
          {/* Senior tranche - Lower risk, stable weekly return */}
          <Trans>Senior tranche - Lower risk, stable return</Trans>
        </Typography>

        <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
          <Typography variant="body2">
            <Trans>Remaining Capacity</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(remainingCapacity)}</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
          <Typography variant="body2">
            <Trans>RWA Interest APY</Trans>
          </Typography>
          <Typography variant="body2">{alphaPoolInfo[0].apr.alpha}%</Typography>
        </Grid>
        <Divider className={classes.informationDivider} />

        {/* <BasicTextFlex>
              <Typography variant="body2">Deposited</Typography>
              <Typography variant="body2">
                {displayBalance(alphaDepositAmounts[0])} {collateralTokenName}
              </Typography>
            </BasicTextFlex>
            <Divider className={classes.informationDivider} />

            <BasicTextFlex>
              <Typography variant="body2">{collateralTokenName} Rewards</Typography>
              <Typography variant="body2">
                {displayBalance(pendingRewards)} {collateralTokenName}
              </Typography>
            </BasicTextFlex> */}
        {/* <Divider className={classes.informationDivider} /> */}

        {/* <MainButton
              onClick={() => handleClaim(0)}
              style={{ marginTop: 16 }}
              disable={closeAlphaClaim}
              disableStyle={'transparent'}
            >
              <Typography variant="subtitle2" className={closeAlphaClaim ? classes.disableText : ''}>
                CLAIM
              </Typography>
            </MainButton> */}

        <InformationSpace inputSmallHeight={'40px'} />
        {/* </>
        )} */}

        {/* )
         })} */}
        {/* {isBetaPoolInvested && (
          <>
            <Typography variant="subtitle1">BETA Token</Typography>
            <InformationSpace />

            <BasicTextFlex>
              <Typography variant="body2">BETA Balance</Typography>
              <Typography variant="body2">{displayBalance(betaBalance)} BETA</Typography>
            </BasicTextFlex>
            <Divider className={classes.informationDivider} />

            <BasicTextFlex>
              <Typography variant="body2">BETA Price</Typography>
              <Typography variant="body2">{displayBalance(betaPrice, '0,0.0000')} {collateralTokenName}</Typography>
            </BasicTextFlex>
            <Divider className={classes.informationDivider} />

            <BasicTextFlex>
              <Typography variant="body2">Staked</Typography>
              <Typography variant="body2">{displayBalance(betaStakeAmounts)} BETA</Typography>
            </BasicTextFlex>
            <Divider className={classes.informationDivider} />

            <BasicTextFlex>
              <Typography variant="body2">NAOS Rewards</Typography>
              <Typography variant="body2">{displayBalance(betaPoolReward)} NAOS</Typography>
            </BasicTextFlex>
            <Divider className={classes.informationDivider} />

            <MainButton
              style={{ marginTop: 16 }}
              disable={closeBetaClaim}
              disableStyle={'transparent'}
              onClick={onClickLink('/farm/beta', !closeBetaClaim)}
            >
              <Typography variant="subtitle2" className={closeBetaClaim ? classes.disableText : ''}>
                CLAIM
              </Typography>
            </MainButton>
          </>
        )} */}
      </InformationCard>

      {/* <BoostListModal
        boostList={[]}
        open={showBoostModal}
        onClose={() => setShowBoostModal(false)}
        handleUpdate={handleUpdateBoostRatio}
        handleBoostMore={() => {
          backFloatButton()
          history.push('/farm/boost')
        }}
      /> */}
    </>
  )
}

export default InvestDetail
