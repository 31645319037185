import { useState, useMemo, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ExternalLink as IconExternalLink } from 'react-feather'
import clsx from 'clsx'
import {
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  createStyles,
  Theme,
  makeStyles,
  Grid,
  Backdrop,
} from '@material-ui/core'
import { Trans, t } from '@lingui/macro'

import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  TitleBackContainer,
  TitleBack,
  Basic,
  BasicContainer,
  BasicText,
  BasicBottom,
  BasicBottomLeft,
  BasicBottomRight,
  PoolContainer,
  PoolFlex,
  PoolFlexCenter,
  Button,
  SubButton,
  DisabledButton,
  RightButtonWrapper,
} from './styled'
import ActionBoard from '../../../common/ActionBoard'
import GradientCard from '../../../common/GradientCard'
import GradientBackground from '../../../common/GradientBackground'
import InformationCard from '../../../common/InformationCard'
import { DrawerWidth } from '../../../components/Navbar'
import { ExternalLink } from '../../../components/commons/ExternalLink'
import StakeDrawerContent from './actionContents/boostPools/stake'
import UnstakeDrawerContent from './actionContents/boostPools/unstake'

import ADDRESS from '../../../constants/addresses'
import { useTokenBalance } from '../../../hooks/smartContracts/useTokenBalance'
import { displayBalance } from '../../../utils/displayBalance'
import { useBoostPoolContract, useGalaxyStakingPoolContract } from '../../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../../redux/application/hooks'
import { useTransactionAdder } from '../../../redux/transactions/hooks'
import { PoolAction } from '.'
import { useBoostPoolsInfo } from '../../../hooks/smartContracts/useBoostPoolsInfo'
import { displayRemainingDate } from '../../../utils/displayDate'
import useTemporalState from '../../../hooks/useTemporalState'
import BasicSnackbar from '../../../components/snackbars'
import BoostListModal from '../../../components/modals/BoostListModal'
import { ChainId } from '../../../constants/blockchain'
import { usePositionOffset, useBackToOrigin } from '../../../redux/navbar/hooks'
import ImageCalculator from '../../../assets/symbol/calculator.png'
import BoostCalculator from './specificComponents/BoostCalculator'

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   display: 'flex',
    //   justifyContent: 'center',
    // },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px - ${DrawerWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
    },
    dividerVertical: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '32px auto',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 360,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '40px',
      },
    },
  })
)

const NaosBoostPool = ({ poolId, name, stakingTokenAddr, imageCoin, apr, tvl, urlToGetToken }) => {
  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const [liqudityOpen, setLiquidityOpen] = useState(false)
  const [epochOpen, setEpochOpen] = useState(false)
  const [assetDetailOpen, setAssetDetailOpen] = useState(false)
  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false, false])
  const flexBoxHeight = useMemo(() => {
    return `${window.innerHeight - 111}px`
  }, [])

  const boostPoolContract = useBoostPoolContract(ADDRESS.BOOST_POOL)
  // const galaxyPoolContract = useGalaxyStakingPoolContract(ADDRESS!.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'])

  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [balance] = useTokenBalance(stakingTokenAddr, account ?? undefined)
  const [
    stakedAmount,
    rewardAmount,
    claimImmediatelyAmount,
    lockTimes,
    cooldownPeriod,
    claimPeriod,
    userClaimPeriod,
    orders,
  ] = useBoostPoolsInfo(ADDRESS.BOOST_POOL, account!, ADDRESS.MULTICALL2)

  const [showSnackbar, setShowSnackbar] = useTemporalState(false, 10000)
  const [showBoostModal, setShowBoostModal] = useState(false)

  const closeDrawer = () => {
    setDrawerOpen([false, false, false, false])
  }

  const openDrawer = (type: number) => {
    const status = [...isDrawerOpen]
    status[type] = true
    setDrawerOpen(status)
  }

  async function handleActivateCooldown() {
    if (boostPoolContract && rewardAmount.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await boostPoolContract.startCoolDown()
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Start reward cooldown`,
        })
        closeDrawer()
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleClaimDirectly() {
    if (boostPoolContract && rewardAmount.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await boostPoolContract.claimImmediately()
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Claim ${displayBalance(rewardAmount)} NAOS`,
        })
        closeDrawer()
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleClaim() {
    if (boostPoolContract && rewardAmount.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await boostPoolContract.claim()
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Claim ${displayBalance(rewardAmount)} NAOS`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  const [showBoostCalculator, setShowBoostCalculator] = useState(false)

  const [blockPage, setBlockPage] = useState(false)
  useEffect(() => {
    if (chainId !== ChainId.BSC && chainId !== ChainId.RINKEBY) {
      setBlockPage(true)
    } else if (blockPage == true) {
      setBlockPage(false)
    }
  }, [chainId])

  return (
    <>
      <div
        className={clsx(classes.informationBox, { [classes.boxLeft]: liqudityOpen || epochOpen || assetDetailOpen })}
      >
        <Backdrop open={blockPage} style={{ zIndex: 1, color: '#ffffff', marginTop: '95px' }}>
          <Typography variant="h1">
            <Trans>Please switch to BNB network!</Trans>
          </Typography>
        </Backdrop>
        <TitleDiv>
          <TitleFront>
            <TitleIcon src={imageCoin} />
            <TitleTextDiv>
              <Typography variant="body2" className={classes.greyHeader}>
                NAOS{' '}
              </Typography>
              <Typography variant="h3">
                <Trans>{name} Boost Pool</Trans>
              </Typography>
            </TitleTextDiv>
          </TitleFront>
          <TitleBackContainer>
            {/* <SubButton className={classes.titleButtonExit} onClick={() => setShowBoostModal(true)}>
              <Typography variant="body2" color="textSecondary">
                BOOST
              </Typography>
            </SubButton> */}
            <TitleBack className={classes.titleButton} onClick={() => openDrawer(PoolAction.STAKE)}>
              <Typography variant="body2">
                <Trans>STAKE</Trans>
              </Typography>
            </TitleBack>
          </TitleBackContainer>
        </TitleDiv>
        {/* <GradientBackground width="100%" height={isPC ? '80px' : '442px'}>
          <BasicContainer> */}
        <GradientCard height={isPC ? '80px' : '442px'}>
          <Basic>
            <BasicText style={{ alignItems: 'center' }}>
              <Typography variant="h2">{name}</Typography>
              <Typography variant="h4">
                <Trans>Staked Token</Trans>
              </Typography>
            </BasicText>
            <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
            <Divider orientation="horizontal" className={classes.dividerHorizental} />
            <BasicText>
              <Typography variant="h2">$ {displayBalance(tvl, '0,0')}</Typography>
              <Typography variant="h4">
                <Trans>TVL</Trans>
              </Typography>
            </BasicText>
            <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
            <Divider orientation="horizontal" className={classes.dividerHorizental} />
            <BasicText>
              <Typography variant="h2">{+apr ? (+apr).toFixed(2) : 0} %</Typography>
              <Typography variant="h4">
                <Trans>MAX APR</Trans>
              </Typography>
            </BasicText>
            <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
            <Divider orientation="horizontal" className={classes.dividerHorizental} />
            <BasicText>
              <Typography variant="h2">
                <Trans>{cooldownPeriod.toNumber() / 60 / 60 / 24} days</Trans>
              </Typography>
              <Typography variant="h4">
                <Trans>Cooldown</Trans>
              </Typography>
            </BasicText>
          </Basic>
        </GradientCard>

        <div style={{ height: '16px' }} />
        <GradientBackground width="100%" height={isPC ? '48px' : '130px'}>
          <BasicBottom style={{ width: '100%' }}>
            <BasicBottomLeft>
              <Typography variant="subtitle1">
                <Trans>NAOS Reward Boost Calculator</Trans>
              </Typography>
            </BasicBottomLeft>
            <RightButtonWrapper onClick={() => setShowBoostCalculator(true)}>
              <Typography variant="subtitle1">
                <Trans>Get NAOS Rewards</Trans>
              </Typography>
              <img
                style={{ width: '24px', height: '24px', marginTop: '2px', marginLeft: '4px' }}
                src={ImageCalculator}
                alt="Calculator"
              />
            </RightButtonWrapper>
          </BasicBottom>
        </GradientBackground>

        <div style={{ height: '16px' }} />
        <GradientBackground width="100%" height={isPC ? '48px' : '130px'}>
          <BasicBottom style={{ width: '100%' }}>
            <BasicBottomLeft>
              <Typography variant="subtitle1">
                <Trans>Buy {name} Tokens</Trans>
              </Typography>
            </BasicBottomLeft>
            <ExternalLink href={urlToGetToken}>
              <BasicBottomRight>
                <Typography variant="subtitle1">
                  <Trans>Get {name}</Trans>
                </Typography>
                <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 12 }} />
              </BasicBottomRight>
            </ExternalLink>
          </BasicBottom>
        </GradientBackground>

        {/* <Pool inputMobileMarginTop={'40px'}>
          <div style={{ height: '10px' }} />
          <GradientBackground width="100%" height={isPC ? '80px' : '130px'}>
            <div className={classes.investmentCard}>
              <InvestmentsTitle
                className={clsx(classes.investmentTitleFlexBasis, {
                  [classes.investmentTitleBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="h4">Vault</Typography>
                <Typography variant="body1" className={classes.grayHeader}>
                  Formation
                </Typography>
              </InvestmentsTitle>
              <InvestmentsContent
                className={clsx(classes.investmentContentFlexBasis, {
                  [classes.investmentContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">APY</Typography>
                <Typography variant={isPC ? 'h1' : 'body1'}>{apr} %</Typography>
              </InvestmentsContent>
              <InvestmentsContent
                className={clsx(classes.investmentContentFlexBasis, {
                  [classes.investmentContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">{mintTokenName} Available</Typography>
                <Typography variant={isPC ? 'h1' : 'body1'}>
                  {+collateralRatio
                    ? displayBalance(availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd))
                    : '0'}{' '}
                </Typography>
              </InvestmentsContent>
            </div>
          </GradientBackground>

          <div style={{ height: '8px' }} />
          <GradientBackground width="100%" height={isPC ? '80px' : '150px'}>
            <div className={classes.investmentCardTriple}>
              <InvestmentsTitle
                className={clsx(classes.investmentTitleFlexBasis, {
                  [classes.investmentTitleTripleContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="h4">ALPHA Pool</Typography>
                <Typography variant="body1" className={classes.grayHeader}>
                  Invest
                </Typography>
              </InvestmentsTitle>
              <InvestmentsContent
                className={clsx(classes.investmentTripleContentFlexBasis, {
                  [classes.investmentTripleContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">{collateralTokenName}</Typography>
                <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(alphaDeposit)}</Typography>
              </InvestmentsContent>
              <InvestmentsContent
                className={clsx(classes.investmentTripleContentFlexBasis, {
                  [classes.investmentTripleContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">{collateralTokenName} Rewards</Typography>
                <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(alphaStableCoinReward)}</Typography>
              </InvestmentsContent>
              <InvestmentsContent
                className={clsx(classes.investmentTripleContentFlexBasis, {
                  [classes.investmentTripleContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">NAOS Rewards</Typography>
                <BalanceWrapper>
                  <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(alphaReward)}</Typography>
                  {alphaReward.gt(0) && (
                    <Typography
                      variant={'body1'}
                      style={isPC ? { marginLeft: '4px', marginBottom: '4px' } : { marginLeft: '4px' }}
                    >{`($ ${displayBalance(+formatEther(alphaReward) * naosPrice)})`}</Typography>
                  )}
                </BalanceWrapper>
              </InvestmentsContent>
            </div>
          </GradientBackground>
        </Pool> */}

        {/* <BasicBottom>
              <BasicBottomLeft>
                <Typography variant="subtitle1">Buy {name} tokens</Typography>
              </BasicBottomLeft>
              <ExternalLink href={urlToGetToken}>
                <BasicBottomRight>
                  <Typography variant="subtitle1">Get {name}</Typography>
                  <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 12 }} />
                </BasicBottomRight>
              </ExternalLink>
            </BasicBottom> */}
        {/* </BasicContainer>
        </GradientBackground> */}
        <PoolContainer>
          <Typography variant="h2">
            <Trans>Stake to earn NAOS tokens</Trans>
          </Typography>
          <PoolFlex>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '31%' : '100%'} height="232px">
              {isPC ? <></> : <div style={{ height: '1px' }}></div>}
              <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                <Typography variant="h2">{displayBalance(balance)}</Typography>
              </PoolFlexCenter>
              <PoolFlexCenter style={{ marginTop: `20px` }}>
                <Typography variant="h4">
                  <Trans>Available to Stake</Trans>
                </Typography>
              </PoolFlexCenter>
              <PoolFlexCenter>
                <Button onClick={() => openDrawer(PoolAction.STAKE)}>
                  <Typography variant="subtitle2">
                    <Trans>STAKE</Trans>
                  </Typography>
                </Button>
              </PoolFlexCenter>
            </GradientCard>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '31%' : '100%'} height="232px">
              {isPC ? <></> : <div style={{ height: '1px' }}></div>}
              <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                <Typography variant="h2">{displayBalance(stakedAmount)}</Typography>
              </PoolFlexCenter>
              <PoolFlexCenter style={{ marginTop: `20px` }}>
                <Typography variant="h4">
                  <Trans>{name} Staked</Trans>
                </Typography>
              </PoolFlexCenter>
              <PoolFlexCenter>
                {stakedAmount.gt(0) ? (
                  <Button onClick={() => openDrawer(PoolAction.UNSTAKE)}>
                    <Typography variant="subtitle2">
                      <Trans>UNSTAKE</Trans>
                    </Typography>
                  </Button>
                ) : (
                  <DisabledButton>
                    <Typography variant="subtitle2" style={{ color: 'rgba(255, 255, 255, 0.2)' }}>
                      <Trans>UNSTAKE</Trans>
                    </Typography>
                  </DisabledButton>
                )}
              </PoolFlexCenter>
            </GradientCard>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '31%' : '100%'} height="232px">
              {isPC ? <></> : <div style={{ height: '1px' }}></div>}
              <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                <Typography variant="h2">{displayBalance(rewardAmount)}</Typography>
              </PoolFlexCenter>
              <PoolFlexCenter style={{ marginTop: `20px` }}>
                <Typography variant="h4">
                  <Trans>NAOS Rewards</Trans>
                </Typography>
              </PoolFlexCenter>
              <PoolFlexCenter>
                {Date.now() <= userClaimPeriod[1].toNumber() * 1000 &&
                  (Date.now() < userClaimPeriod[0].toNumber() * 1000 ? (
                    <DisabledButton>
                      <Typography variant="subtitle2" style={{ color: 'rgba(255, 255, 255, 0.2)' }}>
                        {displayRemainingDate(userClaimPeriod[0].toNumber() * 1000)}
                      </Typography>
                    </DisabledButton>
                  ) : (
                    <Button onClick={handleClaim}>
                      <Typography variant="subtitle2">
                        <Trans>CLAIM NOW</Trans>
                      </Typography>
                    </Button>
                  ))}
                {rewardAmount.gt(0) ? (
                  Date.now() > userClaimPeriod[1].toNumber() * 1000 && (
                    <Button onClick={() => rewardAmount.gt(0) && openDrawer(PoolAction.CLAIM)}>
                      <Typography variant="subtitle2">
                        <Trans>CLAIM REWARD</Trans>
                      </Typography>
                    </Button>
                  )
                ) : (
                  <DisabledButton>
                    <Typography variant="subtitle2" style={{ color: 'rgba(255, 255, 255, 0.2)' }}>
                      <Trans>CLAIM REWARD</Trans>
                    </Typography>
                  </DisabledButton>
                )}
              </PoolFlexCenter>
            </GradientCard>
          </PoolFlex>
        </PoolContainer>
        <div className={classes.bottomSpace}></div>
      </div>

      <div className={clsx({ [classes.boxRight]: liqudityOpen || epochOpen }, classes.boxRightHide)}></div>

      <ActionBoard open={isDrawerOpen[PoolAction.STAKE]} setClose={closeDrawer}>
        <InformationCard title={t`STAKE`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <StakeDrawerContent
            isPC={isPC}
            poolId={poolId}
            poolAddr={ADDRESS.BOOST_POOL[chainId ? (chainId === ChainId.MAINNET ? ChainId.BSC : chainId) : ChainId.BSC]}
            tokenAddr={stakingTokenAddr}
            symbol={name}
            availableAmount={balance}
            lockTimes={lockTimes}
            showSnackbar={() => setShowSnackbar(true)}
          />
        </InformationCard>
      </ActionBoard>

      <ActionBoard open={isDrawerOpen[PoolAction.UNSTAKE]} setClose={closeDrawer}>
        <InformationCard title={t`UNSTAKE`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <UnstakeDrawerContent
            poolId={poolId}
            poolAddr={ADDRESS.BOOST_POOL[chainId ? (chainId === ChainId.MAINNET ? ChainId.BSC : chainId) : ChainId.BSC]}
            tokenAddr={stakingTokenAddr}
            symbol={name}
            availableAmount={stakedAmount}
            lockTimes={lockTimes}
            orders={orders}
            chainId={chainId ? (chainId === ChainId.MAINNET ? ChainId.BSC : chainId) : ChainId.BSC}
          />
        </InformationCard>
      </ActionBoard>

      <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={isDrawerOpen[PoolAction.CLAIM]}
        onClose={closeDrawer}
      >
        <DialogTitle>
          <Typography variant="h3">
            <Trans>Activate Cooldown to claim rewards?</Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4">
            <Trans>
              Activate cooldown to claim your reward. If you want to claim rewards without cooldown, 50% of your reward
              will be share to the staked community.
            </Trans>
          </Typography>
          <Typography variant="h4" style={{ color: '#b0cfff' }}>
            <Trans>Cooldown period : {cooldownPeriod.toNumber() / 60 / 60 / 24} days</Trans>
          </Typography>
          <Typography variant="h4" style={{ color: '#b0cfff' }}>
            <Trans>Claim rewards window : {claimPeriod.toNumber() / 60 / 60 / 24} day</Trans>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 24 }}>
          <MuiButton onClick={handleClaimDirectly}>
            <Trans>CLAIM NOW</Trans>
          </MuiButton>
          <MuiButton onClick={handleActivateCooldown}>
            <Trans>COOLDOWN</Trans>
          </MuiButton>
        </DialogActions>
      </Dialog>

      {/* <BasicSnackbar isOpen={showSnackbar}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body1" style={{ color: '#000000', width: '70%' }}>
            NAOS tokens locked successfully. Update your boost in ALPHA Pool Now.
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            onClick={() => {
              setShowBoostModal(true)
            }}
            style={{ cursor: 'pointer' }}
          >
            UPDATE
          </Typography>
        </Grid>
      </BasicSnackbar> */}

      {/* <BoostListModal
        boostList={[]}
        open={showBoostModal}
        onClose={() => setShowBoostModal(false)}
        handleUpdate={handleBoost}
        handleBoostMore={() => {
          setShowBoostModal(false)
          openDrawer(PoolAction.STAKE)
        }}
      /> */}

      {/* <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={isDrawerOpen[PoolAction.EXIT]}
        onClose={closeDrawer}
      >
        <DialogTitle>
          <Typography variant="h3">Exit {name} Pool?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4">Unstake {name} and claim your rewards. </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 24 }}>
          <MuiButton onClick={handleExit}>Continue</MuiButton>
          <MuiButton onClick={closeDrawer}>Cancel</MuiButton>
        </DialogActions>
      </Dialog> */}

      <BoostCalculator open={showBoostCalculator} onClose={() => setShowBoostCalculator(false)} />
    </>
  )
}
export default NaosBoostPool
