import { useEffect, useMemo, useState } from 'react'
import { getTokenPrice } from '../utils/getCryptoPrice'

export function useTokenPrice(symbol: string): number {
  const [price, setPrice] = useState(0)
  const [needRefresh, setNeedRefresh] = useState(true)

  useEffect(() => {
    if (needRefresh) {
      getTokenPrice(symbol).then(setPrice)
      setNeedRefresh(false)
    } else {
      setTimeout(() => setNeedRefresh(true), 60 * 10 * 1000)
    }
  }, [needRefresh])

  return useMemo(() => price, [price])
}
