import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { IAddressOrAddressMap, useBetaInsuranceContract } from './useContract'
import { useBlockNumber } from '../../redux/application/hooks'

interface IPremium {
  insuranceID: BigNumber
  NAOSAmount: BigNumber
  start: BigNumber
  end: BigNumber
  lastDistributedTimestamp: BigNumber
}

interface IPremiumReturn extends IPremium {
  premiumID: BigNumber
}

export function useBetaInsurancePremiums(address?: IAddressOrAddressMap): IPremiumReturn[] {
  const contract = useBetaInsuranceContract(address, true)
  const blockNumber = useBlockNumber()
  const [count, setCounts] = useState<BigNumber>(BigNumber.from(0))
  const [premiums, setPremiums] = useState<IPremiumReturn[]>([])

  useEffect(() => {
    if (contract) {
      contract.getPremiumNAOSDistributionCount().then(setCounts)
    }
  }, [contract, blockNumber])

  useEffect(() => {
    if (count.gt(0) && contract) {
      const n = count.toNumber()
      const promises: Promise<IPremium>[] = []
      const ret: IPremiumReturn[] = []

      for (let i = 0; i < n; i++) {
        promises.push(contract.premiumNAOSDistributionList(i))
      }
      Promise.all(promises).then((orders) => {
        orders.forEach((order, id) => {
          // `last_time >= end` means the premium have been totally disbursed
          if (order.lastDistributedTimestamp.lt(order.end)) {
            const tmp: IPremiumReturn = { ...order, premiumID: BigNumber.from(id) }
            ret.push(tmp)
          }
        })
        setPremiums(ret)
      })
    }
  }, [count, contract])

  return useMemo(() => premiums, [premiums])
}
