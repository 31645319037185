import styled from 'styled-components'

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 22px;
  }
`

export const TitleFront = styled.div`
  display: flex;
  margin-bottom: 27px;
`

export const TitleIcon = styled.img`
  width: 42px;
  height: 42px;
`
export const TitleTextDiv = styled.div`
  margin-left: 20px;
`

export const TitleBackContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 960px) {
    margin-top: 4px;
    align-self: flex-end;
  }
`

export const TitleBack = styled.div`
  width: 93px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const DetailIcon = styled.img`
  width: 13.33px;
  height: 13.33px;
  margin-left: 5.33px;
`

export const InfoFlex = styled.div<{ inputMarginTop?: string; inputMobileMarginTop?: string }>`
  margin-top: ${(props) => (props.inputMarginTop === undefined ? '23px' : props.inputMarginTop)};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

export const InfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px 20px 20px 16px;
`

export const AssetOriginatorInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
`

export const Basic = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  @media only screen and (max-width: 960px) {
    padding: 32px 0;
    display: block;
    text-align: center;
  }
`

export const Button = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
`

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BasicContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const BasicText = styled.div``
export const BasicTextFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
`
export const BasicTextFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

export const BasicBottom = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  margin: 8px 16px;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`

export const BasicBottomLeft = styled.div`
  display: flex;
  width: 220px;
  justify-content: flex-start;
  align-items: center;
`

export const RightButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  item-align: center;
  cursor: pointer;
`

export const BasicBottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  item-align: center;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    margin-top: 16px;
    justify-content: center;
  }
`
export const BasicBottomRightIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 10px;
  @media only screen and (max-width: 960px) {
    margin-right: 0px;
  }
`

export const Pool = styled.div`
  margin-top: 40px;
`

export const PoolFlex = styled.div<{ inputMarginTop?: string; inputMobileMarginTop?: string }>`
  margin-top: ${(props) => (props.inputMarginTop === undefined ? '23px' : props.inputMarginTop)};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    margin-top: ${(props) => (props.inputMobileMarginTop === undefined ? '23px' : props.inputMobileMarginTop)};
    display: block;
  }
`

export const PoolFlexDivider = styled.div`
  width: 48%;
  height: 100%;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`
export const PoolFlexAsset = styled.div`
  padding: 24px 16px;
  @media only screen and (max-width: 960px) {
    padding: 24px 16px;
  }
`
export const LineDataContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 15px;
`

export const InformationSpace = styled.div<{ inputHeight?: string; inputSmallHeight?: string }>`
  height: ${(props) => (props.inputHeight === undefined ? '36px' : props.inputHeight)};
  @media only screen and (max-width: 960px) {
    height: ${(props) => (props.inputSmallHeight === undefined ? '6px' : props.inputSmallHeight)};
  }
`
export const SubInformationSpace = styled.div`
  height: 24px;
`

export const DetailModal = styled.div`
  position: absolute;
  width: 240px;
  height: 112px;
  right: 116px;
  top: 186px;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #040534;
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
    0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  @media only screen and (min-width: 960px) {
    right: 164px;
    top: 228px;
  }
`
export const ModalWrapper = styled.div`
  margin: 8px 20px;
  width: calc(100%-40px);
  height: 100%;
`

export const UnderLineButton = styled.div<{ color?: string }>`
  width: 75%;
  margin-left: 25%;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  color: ${(props) => (props.color === undefined || '' ? 'white' : props.color)};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

export const Stepper = styled.div`
  width: 100%;
  height: 24px;
  margin-top: 24px;
  padding-right: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepLine = styled.div<{ activate: boolean; default: string; active: string }>`
  flex-basis: 40%;
  flex-shrink: 1;
  height: 0px;
  border: 0.75px solid ${(props) => (props.activate ? props.active : props.default)};
`

export const StepIcon = styled.div<{ activate: boolean; default: string; active: string }>`
  width: 24px;
  aspect-ratio: 1;
  background-color: transparent;
  border: 1.5px solid ${(props) => (props.activate ? props.active : props.default)};
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  color: ${(props) => (props.activate ? props.active : props.default)};
`

export const SignModalButtonWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`

export const SignModalExamples = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

export const SignModalDocument = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`
export const SignButton = styled.div<{height: string;}>`
  width: 100%;
  height: ${(props) => (props.height)};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
`
