import { BigNumber } from 'ethers'
import { useMemo, useEffect, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { useTokenContract, IAddressOrAddressMap } from './useContract'

export function useTokenAllowance(address?: IAddressOrAddressMap, owner?: string, spender?: string): BigNumber {
  const contract = useTokenContract(address, false)
  const blockNumber = useBlockNumber()
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (contract && owner && spender) {
      contract.allowance(owner, spender).then((a) => {
        setAllowance(a)
      })
    }
  }, [contract, owner, spender, blockNumber])

  return useMemo(() => allowance, [allowance])
}
