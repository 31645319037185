import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { shiftToLeft, backToOrigin } from './actions'
import { AppState } from '../index'

export function usePositionOffset(): number {
  return useSelector((state: AppState) => state.navbar.floatButtonPositionRight)
}

export function useShiftToLeft() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(shiftToLeft()), [dispatch])
}

export function useBackToOrigin() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(backToOrigin()), [dispatch])
}
