import styled from 'styled-components'

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 22px;
  }
`

export const TitleFront = styled.div`
  display: flex;
  margin-bottom: 27px;
`

export const TitleIcon = styled.img`
  width: 42px;
  height: 42px;
`
export const TitleTextDiv = styled.div`
  margin-left: 20px;
`
export const TitleBack = styled.div`
  width: 140px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const TitleBackContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 960px) {
    margin-top: 4px;
    align-self: flex-end;
  }
`

export const ButtonMenu = styled.div`
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    color: ${theme.palette.text.primary};
  `}
`

export const ButtonDark = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
      // background-color: ${theme.palette.primary.light};
      color: ${theme.palette.primary.light};
    `}
  @media only screen and (max-width: 960px) {
    width: 100%;
    margin: 8px auto;
    p {
      margin: 0 auto;
    }
  }
`
export const Button = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
      background-color: ${theme.palette.primary.light};
      color: ${theme.palette.text.primary};
    `}
  @media only screen and (max-width: 960px) {
    width: 100%;
    margin: 8px auto;
    p {
      margin: 0 auto;
    }
  }
`
export const ButtonSmall = styled.div`
  height: 30px;
  display: flex;
  font-size: 12px;
  text-align: center;
  align-items: center;
  padding: 8px 14px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => `
      background-color: ${theme.palette.primary.light};
      color: ${theme.palette.text.primary};
    `}
`
export const Basic = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const BasicTextFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const BasicContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const BasicText = styled.div`
  width: 150px;
  text-align: right;
  @media only screen and (max-width: 960px) {
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }
`
export const BasicTextCenter = styled.div`
  width: 150px;
  text-align: center;
  @media only screen and (max-width: 960px) {
    width: 100%;
    padding: 15px 0;
  }
`
export const BasicBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin: 8px 16px;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const BasicBottomLeft = styled.div``
export const BasicBottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    margin-top: 16px;
  }
`
export const BasicBottomRightIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 10px;
`
export const Pool = styled.div`
  margin-top: 40px;
`
export const PoolFlex = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const PoolFlexCenter = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`
export const PoolFlexAsset = styled.div`
  padding: 24px 16px;
`
export const BasicTextFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`
export const InformationSpace = styled.div<{ inputHeight?: string; inputSmallHeight?: string }>`
  height: ${(props) => (props.inputHeight === undefined ? '36px' : props.inputHeight)};
  @media only screen and (max-width: 960px) {
    height: ${(props) => (props.inputSmallHeight === undefined ? '6px' : props.inputSmallHeight)};
  }
`
export const SubInformationSpace = styled.div`
  height: 24px;
`
export const TextBoxCenter = styled.div`
  height: 30px;
  display: flex;
  // font-size: 12px;
  text-align: center;
  align-items: center;
  // padding: 8px 14px;
`
export const ButtonContainer = styled.div`
  // margin-top: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    display: block;
    margin: 15px 0;
  }
`
export const ButtonContainerReverse = styled.div`
  // margin-top: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    margin: 16px 0;
    flex-direction: column-reverse;
  }
`
export const BasicTextSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    @media only screen and (max-width: 960px) {
      // margin: 0 0 15px 0;
      font-size: 15px;
    }
  }
`
export const MiniButton = styled.div<{ disable?: boolean; disableStyle?: string }>`
  width: 76px;
  height: 24px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer; 
  ${(props) =>
    props.disable
      ? props.disableStyle === 'transparent'
        ? `
        border: 1px solid;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.2);
      `
        : props.disableStyle === 'solid' &&
          `
        background-color: rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.2);
      `
      : `
      background-color: ${props.theme.palette.primary.light};
      color: ${props.theme.palette.text.primary};
    `}

  @media only screen and (max-width: 960px) {
    height: 36px;
    width: 100%;
    margin: 30px auto;
    padding: 10px 16px;
    border-radius: 18px;
  }
`