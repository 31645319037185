import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { ChainId } from '../constants/blockchain'

export const BoostPoolId = 0

export const StakingPoolId = {
  [ChainId.MAINNET]: {
    NAOS: 0,
    NAOS_ETH_LP: 1,
    NUSD: 2,
    NUSD_3CRV: 3,
    NAOS_ETH_SLP: 255,
  },
  [ChainId.RINKEBY]: {
    NAOS: 0,
    NAOS_ETH_LP: 1,
    NUSD: 2,
    NUSD_3CRV: 3,
    NAOS_ETH_SLP: 255,
  },
}

export const StakingPoolWithTransferId = {
  [ChainId.MAINNET]: {
    BETA: 1, //When beta on mainet change to 0
  },
  [ChainId.RINKEBY]: {
    BETA: 0,
  },
  [ChainId.BSC]: {
    NAOS_BNB_LP: 0,
    BETA: 1,
  },
}

const DefaultPoolId = {
  NAOS: 0,
  NAOS_ETH_LP: 1,
  NUSD: 2,
  NUSD_3CRV: 3,
  NAOS_ETH_SLP: 255,
  BETA: 1,
  NAOS_BNB_LP: 0,

  OLD_NAOS_BNB_LP: 20000,

  NAOS_BOOST: 0,
}

const useStakingPoolId = () => {
  const { chainId } = useWeb3React()

  const poolId = useMemo(() => {
    let ret = DefaultPoolId
    if (chainId) {
      if (Object.keys(StakingPoolId).includes(chainId.toString())) {
        ret = { ...ret, ...StakingPoolId[chainId] }
      }
      if (Object.keys(StakingPoolWithTransferId).includes(chainId.toString())) {
        ret = { ...ret, ...StakingPoolWithTransferId[chainId] }
      }
    }
    return ret
  }, [chainId])

  return poolId
}

export default useStakingPoolId
