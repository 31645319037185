import styled from 'styled-components'

export const Button = styled.div`
  width: 240px;
  height: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
`

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
