import { Typography, Grid, FormControl, Select, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { ChangeEvent, useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { Trans, t } from '@lingui/macro'

import TestableAmountInput, { IAmountInputRefObj } from '../../../../../common/TestableAmountInput'
import { MainButton } from '../../../../../common/buttons'
import { Divider } from '../../../../../common/Divider'
import { ApprovalState, useApproveCallback } from '../../../../../hooks/smartContracts/useApproveCallback'
import { displayBalance } from '../../../../../utils/displayBalance'
import { useBoostPoolContract, useGalaxyStakingPoolContract } from '../../../../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../../../../redux/application/hooks'
import { useTransactionAdder } from '../../../../../redux/transactions/hooks'
import { useGalaxyPoolBoostRatio } from '../../../../../hooks/smartContracts/useGalaxyPoolBoostRatio'
import { useWeb3React } from '@web3-react/core'
import ADDRESS from '../../../../../constants/addresses'
import { useStakeBoostAPR } from '../../../../../hooks/smartContracts/useStakeBoostAPR'
import { DetailIcon } from '../../styled'
import ImgDefition from '../../../../../assets/symbol/definition.png'
import Tooltip from '@material-ui/core/Tooltip'
// import { ChainId } from '../../../../../constants/blockchain'

const PoolId = {
  DAY30: 0,
  DAY90: 1,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ddl: {
      width: '100%',
      marginTop: 12,

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
    },
    select: {
      height: 56,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 16,
      color: 'rgba(255, 255, 255, 0.6)',
      padding: '0px 14px',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    menuItem: {
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    detailModal: {
      width: 240,
      height: 'auto',
      backgroundColor: '#040534',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 12,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: 'rgba(255, 255, 255, 0.87)',
      [theme.breakpoints.down(960)]: {
        '&.MuiTooltip-tooltip': {
          marginTop: 12,
        },
      },
    },
    modalArrow: {
      '&::before': {
        backgroundColor: '#040534',
      },
    },
  })
)

interface IStakingPoolDrawerProps {
  isPC: boolean
  poolId: number
  tokenAddr: string
  poolAddr: string
  symbol: string
  availableAmount: BigNumber
  lockTimes: BigNumber[]
  showSnackbar(): void
}

const StakeDrawerContent = ({
  isPC,
  poolId,
  poolAddr,
  tokenAddr,
  symbol,
  availableAmount,
  lockTimes,
  showSnackbar,
}: IStakingPoolDrawerProps) => {
  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  // const [currentBoostDay30, , maxBoostDay30] = useGalaxyPoolBoostRatio(
  //   ADDRESS!.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'],
  //   ADDRESS?.BOOST_POOL,
  //   account!,
  //   PoolId.DAY30
  // )
  // const [currentBoostDay90, , maxBoostDay90] = useGalaxyPoolBoostRatio(
  //   ADDRESS!.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'],
  //   ADDRESS?.BOOST_POOL,
  //   account!,
  //   PoolId.DAY90
  // )

  const minTime = useMemo(() => lockTimes.reduce((min, t) => (min.lt(t) ? min : t), lockTimes[0]), [lockTimes])
  const amountInput = useRef<IAmountInputRefObj>(null)
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [lockIndex, setLockIndex] = useState(0)
  const lockTimeWeight = (lockIndex: number) => {
    switch (lockIndex) {
      case 1:
        return 4
      case 2:
        return 10
      case 3:
        return 25
      default:
        return 1
    }
  }

  const [isApprove, approve] = useApproveCallback(tokenAddr, inputBignumber, poolAddr)
  const boostPoolContract = useBoostPoolContract(poolAddr)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [currentApr, calcNewApr] = useStakeBoostAPR(ADDRESS.BOOST_POOL, account ?? undefined)
  const [showTooltip, setShowTooltip] = useState(false)
  const statePeriodGuid = 'The longer you stake, the higher reward weight you’ll get.'
  const isDisabled = useMemo(
    () => inputBignumber.gt(availableAmount) && isApprove === ApprovalState.APPROVED,
    [inputBignumber, availableAmount]
  )

  function handleDDL(e: ChangeEvent<{ value: unknown }>) {
    setLockIndex(e.target.value as number)
  }

  async function handleStake() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (boostPoolContract && inputBignumber.gt(0) && !isDisabled) {
        openConfirmingModal(true)
        try {
          const res = await boostPoolContract.deposit(inputBignumber, lockIndex)
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Stake ${displayBalance(inputBignumber)} ${symbol}`,
          })
          amountInput.current?.resetAmount()
          // showSnackbar()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>Lock NAOS tokens to claim NAOS rewards</Trans>
      </Typography>
      {/* <Typography variant="body2" style={{ color: '#b0cfff' }}>
        Minimum staking period: {minTime.toNumber() / 60 / 60 / 24} days
      </Typography> */}
      <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
        <Typography variant="body2">
          <Trans>{symbol} Balance</Trans>
        </Typography>
        <Typography variant="body2">{displayBalance(availableAmount)}</Typography>
      </Grid>
      <Divider />

      <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
        <Typography variant="body2">
          <Trans>Your current APR</Trans>
        </Typography>
        <Typography variant="body2">{currentApr.toFixed(2)} %</Typography>
      </Grid>
      <Divider margin={16} />

      <TestableAmountInput
        ref={amountInput}
        maxNum={availableAmount}
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={symbol}
      />
      <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set stake amount</Trans>
        </Typography>
      </Grid>

      <FormControl
        variant="outlined"
        classes={{
          root: classes.ddl,
        }}
      >
        <Select
          value={lockIndex}
          onChange={handleDDL}
          labelWidth={0}
          classes={{ root: classes.select, icon: classes.icon }}
        >
          {lockTimes.map((time, idx) => {
            const lockDays = time.toNumber() / 60 / 60 / 24
            return (
              <MenuItem value={idx} classes={{ root: classes.menuItem }}>
                <Typography variant="h3">
                  {lockDays === 90 ? `${lockDays / 30} month` : `${lockDays / 360} years`}
                </Typography>
                <Typography variant="h3">{lockTimeWeight(idx)} x</Typography>
              </MenuItem>
            )
          })}
          {/* {[90, 360, 720, 1440].map((time, idx) => (
            <MenuItem value={idx} classes={{ root: classes.menuItem }}>
              {time} days
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: `4px`, justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <Typography variant="body2">
          <Trans>Set stake period</Trans>
        </Typography>
        <Tooltip
          title={statePeriodGuid}
          arrow
          placement="bottom-end"
          classes={{ tooltip: classes.detailModal, arrow: classes.modalArrow }}
          open={showTooltip}
          onOpen={() => setShowTooltip(true)}
          onClose={() => setShowTooltip(false)}
        >
          <DetailIcon src={ImgDefition} onClick={() => !isPC && setShowTooltip(!showTooltip)} />
        </Tooltip>
      </Grid>
      <Divider margin={16} />

      <Grid container justifyContent="space-between" style={{ marginTop: '4px', marginBottom: '20px' }}>
        <Typography variant="body2">
          <Trans>Your rebalanced APR</Trans>
        </Typography>
        <Typography variant="body2">{calcNewApr(inputBignumber, lockTimeWeight(lockIndex)).toFixed(2)} %</Typography>
      </Grid>

      <MainButton onClick={handleStake} style={{ marginTop: 16 }} disable={isDisabled} disableStyle={'solid'}>
        {' '}
        {isApprove === ApprovalState.APPROVED ? t`Stake` : t`APPROVE`}
      </MainButton>

      {/* <Typography variant="subtitle1" style={{ marginTop: 32 }}>
        Increase Your Boost
      </Typography>
      <Typography variant="body2" style={{ marginTop: 8 }}>
        Stake more NAOS to maximize your boost weight
      </Typography>
      <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
        <Typography variant="body2">30 days ALPHA Pool Boost Rate</Typography>
        <Typography variant="body2">{currentBoostDay30}x</Typography>
      </Grid>
      <Divider />
      <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
        <Typography variant="body2">Boost to max rate</Typography>
        <Typography variant="body2">{displayBalance(maxBoostDay30)} NAOS</Typography>
      </Grid>
      <Divider />
      <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
        <Typography variant="body2">90 days ALPHA Pool Boost Rate</Typography>
        <Typography variant="body2">{currentBoostDay90}x</Typography>
      </Grid>
      <Divider />
      <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
        <Typography variant="body2">Boost to max rate</Typography>
        <Typography variant="body2">{displayBalance(maxBoostDay90)} NAOS</Typography>
      </Grid>
      <Divider /> */}
    </>
  )
}

export default StakeDrawerContent
