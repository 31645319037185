import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { useGalaxyStakingPoolContract, IAddressOrAddressMap } from './useContract'

export function useAlphaStakeTotalDeposited(
  galaxyAddress?: IAddressOrAddressMap,
  staker?: string,
  poolId?: number
): BigNumber {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyAddress)
  const blockNumber = useBlockNumber()

  const [depositAmount, setDepositAmount] = useState(BigNumber.from(0))

  useEffect(() => {
    if (galaxyContract && staker && poolId !== undefined) {
      galaxyContract.getStakeTotalDeposited(staker, poolId).then(setDepositAmount)
    }
  }, [galaxyContract, staker, poolId, blockNumber])

  return useMemo(() => depositAmount, [depositAmount])
}
