import { useMemo, useRef, useState } from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Typography, Grid } from '@material-ui/core'
import { Trans, t } from '@lingui/macro'

import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { useFormationContract } from '../../hooks/smartContracts/useContract'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { ApprovalState, useApproveCallback } from '../../hooks/smartContracts/useApproveCallback'

import ADDRESS from '../../constants/addresses'
import { ChainId } from '../../constants/blockchain'
import { MainButton } from '../../common/buttons'
import AmountInput, { IAmountInputRefObj } from '../../common/AmountInput'
import { Divider } from '../../common/Divider'
import { displayBalance } from '../../utils/displayBalance'
import { PoolFlexCenter } from './styled'

const DepositDrawerContent = () => {
  const { account, chainId } = useWeb3React()
  const amountInput = useRef<IAmountInputRefObj>(null)

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )
  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )

  const formationContract = useFormationContract(ADDRESS.FORMATION)
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)

  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [isApprove, approve] = useApproveCallback(ADDRESS.DAI_TOKEN, inputBignumber, ADDRESS.FORMATION[chainId ?? 1])
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  async function handleDeposit() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (formationContract && inputBignumber.gt(0)) {
        openConfirmingModal(true)
        try {
          const res = await formationContract.deposit(inputBignumber)
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Deposit ${displayBalance(inputBignumber)} ${collateralTokenName}`,
          })
          amountInput.current?.resetAmount()
        } catch (error) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>
          Deposit {collateralTokenName} to mint {mintTokenName}
        </Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>{collateralTokenName} Balance</Trans>
        </Typography>
        <Typography variant="body2">{displayBalance(daiBalance)}</Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={amountInput}
        maxNum={daiBalance}
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={collateralTokenName}
      />
      <PoolFlexCenter style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set deposit amount</Trans>
        </Typography>
      </PoolFlexCenter>
      {/* <PoolFlexCenter style={{ marginTop: `13px`, justifyContent: 'flex-end' }}>
        <MainButton onClick={handleDeposit}> {isApprove === ApprovalState.APPROVED ? 'DEPOSIT' : 'APPROVE'}</MainButton>
      </PoolFlexCenter> */}
      <MainButton onClick={handleDeposit} style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">{isApprove === ApprovalState.APPROVED ? t`DEPOSIT` : t`APPROVE`}</Typography>
      </MainButton>
    </>
  )
}
export default DepositDrawerContent
