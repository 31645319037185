import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ContentWrapper = styled.div<{ mobileHeight: string }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 640px;
  gap: 40px;
  overflow-x: hidden;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    height: ${(props) => props.mobileHeight};
  }
`

export const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 280px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`

export const ResultWrapper = styled.div``

export const OutcomePanel = styled.div`
  padding: 20px 20px;
`

export const StakeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const InputWrapper = styled.div`
  width: 280px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`

export const FooterWrapper = styled.div<{ mobileHeight: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 84px;
  @media only screen and (max-width: 960px) {
    margin-bottom: 16px;
    height: ${(props) => props.mobileHeight};
    flex-direction: column;
    gap: 16px;
  }
`

export const BasicWrapper = styled.div`
width: 100%;
margin-top: 24px;
`

export const BasicLeftContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`

export const BasicFlexContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BasciBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`

export const BasicBottomLeft = styled.div`
  @media only screen and (max-width: 960px) {
    margin-right: auto;
  }
`
export const BasicBottomRight = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    justify-content: center;
  }
`

export const MainButton = styled.div<{ disable?: boolean; disableStyle?: string }>`
  width: 118px;
  height: 36px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  ${(props) =>
    props.disable
      ? props.disableStyle === 'transparent'
        ? `
        border: 1px solid;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.2);
      `
        : props.disableStyle === 'solid' &&
          `
        background-color: rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.2);
      `
      : `
      background-color: ${props.theme.palette.primary.dark};
      color: ${props.theme.palette.text.primary};
    `}
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`

export const Stepper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 48px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
`

export const StepLine = styled.div<{width: string; activate: boolean; default: string; active: string }>`
  margin-top: 14px;
  width: ${(props) => props.width};
  height: 0.5px;
  background-color: ${(props) => (props.activate ? props.active : props.default)};
  border: 0.1px solid ${(props) => (props.activate ? props.active : props.default)};
`

export const StepUpper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: transparent;
`

export const StepIcon = styled.div<{ activate: boolean; default: string; active: string }>`
  min-width: 24px;
  aspect-ratio: 1;
  background-color: rgb(38, 40, 73);
  border: 1.5px solid ${(props) => (props.activate ? props.active : props.default)};
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  color: ${(props) => (props.activate ? props.active : props.default)};
`

export const StepLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`
