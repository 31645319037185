import { useEffect, useMemo, useState } from 'react'
import ADDRESSES from '../../constants/addresses'
import { useBetaInsuranceContract, useStakingPoolContract, useAssessorContract } from './useContract'
import { utils } from 'ethers'
import useStakingPoolId from '../useStakingPoolId'
import { ChainId } from '../../constants/blockchain'
import { formatEther } from '@ethersproject/units'
import { OFFICIAL_WEB_API_HOST } from '../../constants/endpoints'
import { useTokenBalance } from './useTokenBalance'
import { USED_CHAIN_ID } from '../../constants/env'

interface IPoolInfo {
  [addr: string]: { [poolId: string]: number }
}

export function useStkaingPoolsTvl() {
  const [tvls, setTvls] = useState<Record<'eth' | 'bsc', IPoolInfo>>({
    eth: {
      [ADDRESSES.STAKINGPOOL[ChainId.MAINNET]]: {},
      [ADDRESSES.STAKINGPOOL_WITH_TRANSFER[ChainId.RINKEBY]]: {},
      [ADDRESSES.BOOST_POOL[ChainId.RINKEBY]]: {},
    },
    bsc: {
      [ADDRESSES.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]]: {},
      [ADDRESSES.BOOST_POOL[ChainId.BSC]]: {},
    },
  })

  useEffect(() => {
    const setData = () =>
      fetch(`${OFFICIAL_WEB_API_HOST}/v2/api/v1/ethereum/tvls`).then((res) =>
        res.json().then(({ tvls }) => {
          const val = {
            eth: tvls['eth'],
            bsc: tvls['bsc'],
          }
          setTvls(val)
        })
      )

    setData()

    const interval = setInterval(() => {
      if (Object.keys(tvls['eth']).length === 0) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return useMemo(() => tvls, [tvls])
}

export function useAlphaRWAStakingPoolsTvl(chainId: number) {
  const poolContract = useStakingPoolContract(ADDRESSES.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]['TestRoot'])
  const [tvls, setTvls] = useState<string>('0')

  useEffect(() => {
    if (poolContract) {
      poolContract
        .getPoolTotalDeposited(0)
        .then((tvl) => {
          setTvls(formatEther(tvl))
        })
        .catch(() => {})
    }
  }, [poolContract])

  return useMemo(() => tvls, [tvls])
}

export function useAlphaRWAStakingPoolValue(chainId: number, alphaRootAddress: string) {
  const assessor = useAssessorContract(ADDRESSES.ASSESSOR[chainId ?? USED_CHAIN_ID][alphaRootAddress])
  const [reserveBalance] = useTokenBalance(
    ADDRESSES.DAI_TOKEN,
    ADDRESSES.GALAXY_RESERVE[chainId ?? USED_CHAIN_ID][alphaRootAddress]
  )
  const [trancheBalance] = useTokenBalance(
    ADDRESSES.DAI_TOKEN,
    ADDRESSES.GALAXY_TRANCHE[chainId ?? USED_CHAIN_ID][alphaRootAddress]
  )
  const [poolValue, setPoolValue] = useState<string>('0')

  useEffect(() => {
    if (assessor && reserveBalance && trancheBalance) {
      assessor
        .seniorDebt()
        .then((debt) => {
          setPoolValue(formatEther(debt.add(reserveBalance).add(trancheBalance)))
        })
        .catch(() => {})
    }
  }, [assessor, reserveBalance, trancheBalance])

  return useMemo(() => poolValue, [poolValue])
}

export function useAlphaProRWAStakingPoolValue(chainId: number, rootAddress: string) {
  const assessor = useAssessorContract(ADDRESSES.ASSESSOR_PRO[chainId ?? USED_CHAIN_ID][rootAddress])
  const [reserveBalance] = useTokenBalance(
    ADDRESSES.DAI_TOKEN,
    ADDRESSES.GALAXY_PRO_RESERVE[chainId ?? USED_CHAIN_ID][rootAddress]
  )
  const [trancheBalance] = useTokenBalance(
    ADDRESSES.DAI_TOKEN,
    ADDRESSES.GALAXY_PRO_TRANCHE[chainId ?? USED_CHAIN_ID][rootAddress]
  )
  const [poolValue, setPoolValue] = useState<string>('0')

  useEffect(() => {
    if (assessor && reserveBalance && trancheBalance) {
      assessor
        .seniorDebt()
        .then((debt) => {
          setPoolValue(formatEther(debt.add(reserveBalance).add(trancheBalance)))
        })
        .catch(() => {})
    }
  }, [assessor, reserveBalance, trancheBalance])

  return useMemo(() => poolValue, [poolValue])
}

export function useBetaInsuranceStakingPoolTVL() {
  const betaContract = useBetaInsuranceContract(ADDRESSES.BETA_INSURANCE)
  const [tvls, setTvls] = useState<string[]>(['0'])

  useEffect(() => {
    if (betaContract) {
      betaContract.balance().then((tvl) => {
        setTvls([formatEther(tvl)])
      })
    }
  }, [betaContract])

  return useMemo(() => tvls, [tvls])
}
