import { BigNumber, utils } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { useTokenContract, IAddressOrAddressMap, useTrancheContract } from './useContract'

export interface DisburseType {
  payoutCurrencyAmount: BigNumber,
  payoutTokenAmount: BigNumber,
  remainingSupplyCurrency: BigNumber,
  remainingRedeemToken: BigNumber
}

export function useCalcDisburse(
  address?: IAddressOrAddressMap,
  staker?: string
): {
  payoutCurrencyAmount: BigNumber,
  payoutTokenAmount: BigNumber,
  remainingSupplyCurrency: BigNumber,
  remainingRedeemToken: BigNumber
} {
  const blockNumber = useBlockNumber()
  const trancheContract = useTrancheContract(address)
  const [pca, setPCA] = useState(BigNumber.from(0)) //payoutCurrencyAmount
  const [pta, setPTA] = useState(BigNumber.from(0)) //payoutTokenAmount
  const [rsc, setRSC] = useState(BigNumber.from(0)) //remainingSupplyCurrency
  const [rrt, setRRT] = useState(BigNumber.from(0)) //remainingRedeemToken

  useEffect(() => {
    if (trancheContract && staker) {
      trancheContract['calcDisburse(address)'](staker)
        .then((res) => {
          setPCA(res[0])
          setPTA(res[1])
          setRSC(res[2])
          setRRT(res[3])
        })
        .catch((err) => console.error(err))
    }
  }, [address, staker, blockNumber])

  return useMemo<DisburseType>(() => {
    return {
      payoutCurrencyAmount: pca,
      payoutTokenAmount: pta,
      remainingSupplyCurrency: rsc,
      remainingRedeemToken: rrt,
    }
  }, [pca, pta, rsc, rrt])
}
