import { useState, useEffect, useMemo } from 'react'
import { BigNumber, utils } from 'ethers'
import moment from 'moment'
import styled from 'styled-components'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme, Typography } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { Trans, t } from '@lingui/macro'

import ADDRESS from '../../constants/addresses'
import { ChainId } from '../../constants/blockchain'
import GradientCard from '../../common/GradientCard'
import {
  TitleDiv,
  TitleFront,
  TitleIconBlock,
  TitleTextDiv,
  Button,
  BasicTextFlex,
  Pool,
  PoolFlex,
  PoolFlexCenter,
  PoolFlexAsset,
} from './styled'
import ActionBoard from '../../common/ActionBoard'
import { MainButton } from '../../common/buttons'
import DepositDrawerContent from './actionContents/deposit'
import BorrowDrawerContent from './actionContents/borrow'
import WithdrawDrawerContent from './actionContents/withdraw'
import RepayDrawerContent from './actionContents/repay'
import LiquidateDrawerContent from './actionContents/liquidate'
import InformationCard from '../../common/InformationCard'
import { useNTokenContract } from '../../hooks/smartContracts/useContract'
import { useFormationInfo } from '../../hooks/smartContracts/useFormationInfo'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { displayBalance } from '../../utils/displayBalance'
import { useVaultStableCoinApr } from '../../hooks/useVaultStableCoinApr'
import { usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'
import MapIcon from '../../common/MapIcon'

const LeftTypography = styled(Typography)`
  text-align: left;
`
const RightTypography = styled(Typography)`
  text-align: right;
`

enum VaultAction {
  DEPOSIT,
  BORROW,
  WITHDRAW,
  REPAY,
  LIQUIDATE,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    disableText: {
      color: 'rgba(255, 255, 255, 0.2)',
    },
  })
)

const Vault = () => {
  const { account, chainId } = useWeb3React()
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )
  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )

  const nusdContract = useNTokenContract(ADDRESS.NUSD_TOKEN)
  const [collateralDai, borrowedNusd, availableToBorrow, collateralRatio] = useFormationInfo(
    ADDRESS.FORMATION,
    account ?? undefined
  )
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, account ?? undefined)

  const apr = useVaultStableCoinApr(chainId)
  const [globalMinted, setGlobalMinted] = useState(BigNumber.from(0))
  useEffect(() => {
    if (nusdContract) {
      Promise.all([
        nusdContract.ceiling(ADDRESS.FORMATION[chainId ?? ChainId.BSC]),
        nusdContract.hasMinted(ADDRESS.FORMATION[chainId ?? ChainId.BSC]),
      ]).then(([ceiling, minted]) => setGlobalMinted(ceiling.sub(minted)))
    }
  }, [nusdContract])

  const dateToMature = useMemo(() => {
    const deposited = +utils.formatEther(collateralDai)
    const borrowed = +utils.formatEther(borrowedNusd)

    return moment()
      .add(+borrowed / ((+deposited * +apr) / 100 / 365), 'days')
      .calendar()
  }, [collateralDai, borrowedNusd, apr])

  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false, false])
  const closeDrawer = () => {
    setDrawerOpen([false, false, false, false])
  }
  const openDrawer = (type) => {
    const status = [...isDrawerOpen]
    status[type] = true
    setDrawerOpen(status)
  }

  const availableMintToken = useMemo(
    () =>
      +collateralRatio
        ? displayBalance(availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd))
        : '0',
    [availableToBorrow, collateralDai, borrowedNusd, collateralRatio]
  )

  const availableCollateralToken = useMemo(
    () => displayBalance(+utils.formatEther(collateralDai) - +utils.formatEther(borrowedNusd) * +collateralRatio),
    [collateralDai, borrowedNusd, collateralRatio]
  )

  const commaStringNumber2Number = (input: string) => {
    return +input.split(',').join('')
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.informationBox}>
          <TitleDiv>
            <TitleFront>
              <TitleIconBlock>
                <MapIcon cusStyled={{ width: '40px', opacity: '0.7' }} name={collateralTokenName} />
                <MapIcon cusStyled={{ width: '40px' }} name={mintTokenName} />
              </TitleIconBlock>
              <TitleTextDiv>
                <Typography variant="body2" className={classes.greyHeader}>
                  NAOS
                </Typography>
                <Typography variant="h3">
                  <Trans>
                    Deposit {collateralTokenName} to borrow {mintTokenName}
                  </Trans>
                </Typography>
              </TitleTextDiv>
            </TitleFront>
          </TitleDiv>
          <Pool style={{ marginTop: `0px` }}>
            <PoolFlex style={{ marginTop: `0px` }}>
              <GradientCard width={isPC ? '48%' : '100%'} height={isPC ? '200px' : '168px'}>
                <PoolFlexAsset>
                  <BasicTextFlex>
                    <Typography variant="h2">
                      <Trans>Deposits</Trans>
                    </Typography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Wallet Balance</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(daiBalance)} {collateralTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Collateral Balance</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(collateralDai)} {collateralTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Available to Withdraw</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(
                        +utils.formatEther(collateralDai) - +utils.formatEther(borrowedNusd) * +collateralRatio
                      )}{' '}
                      {collateralTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Yield Strategy APY</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">{apr} %</RightTypography>
                  </BasicTextFlex>
                </PoolFlexAsset>
              </GradientCard>

              {isPC ? <></> : <div style={{ height: '16px' }}></div>}

              <GradientCard width={isPC ? '48%' : '100%'} height="200px">
                <PoolFlexAsset>
                  <BasicTextFlex>
                    <Typography variant="h2">
                      <Trans>Borrows</Trans>
                    </Typography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Wallet Balance</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(nusdBalance)} {mintTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Remaining {mintTokenName} Debt</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(borrowedNusd)} {mintTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Available to Borrow</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {+collateralRatio
                        ? displayBalance(availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd))
                        : '0'}{' '}
                      {mintTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Estimated Maturity</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">{dateToMature}</RightTypography>
                  </BasicTextFlex>
                  <BasicTextFlex>
                    <LeftTypography variant="body1">
                      <Trans>Global Mintable {mintTokenName}</Trans>
                    </LeftTypography>
                    <RightTypography variant="body1">
                      {displayBalance(globalMinted)} {mintTokenName}
                    </RightTypography>
                  </BasicTextFlex>
                </PoolFlexAsset>
              </GradientCard>
            </PoolFlex>
          </Pool>

          <Pool>
            <Typography variant="h2">
              <Trans>Vault</Trans>
            </Typography>
            <PoolFlex>
              <GradientCard width={isPC ? '23%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(daiBalance)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>{collateralTokenName} Balance</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <Button onClick={() => openDrawer(VaultAction.DEPOSIT)}>
                    <Typography variant="subtitle2">
                      <Trans>DEPOSIT</Trans>
                    </Typography>
                  </Button>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '23%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{availableMintToken} </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>{mintTokenName} Available</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton
                    onClick={() => {
                      commaStringNumber2Number(availableMintToken) > 0 && openDrawer(VaultAction.BORROW)
                    }}
                    disable={commaStringNumber2Number(availableMintToken) <= 0}
                    disableStyle={'solid'}
                  >
                    <Typography
                      variant="subtitle2"
                      className={commaStringNumber2Number(availableMintToken) <= 0 ? classes.disableText : ''}
                    >
                      <Trans>BORROW</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '23%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{availableCollateralToken}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>{collateralTokenName} Available </Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton
                    onClick={() =>
                      commaStringNumber2Number(availableCollateralToken) > 0 && openDrawer(VaultAction.WITHDRAW)
                    }
                    disable={commaStringNumber2Number(availableCollateralToken) <= 0}
                    disableStyle={'solid'}
                  >
                    <Typography
                      variant="subtitle2"
                      className={commaStringNumber2Number(availableCollateralToken) <= 0 ? classes.disableText : ''}
                    >
                      <Trans>WITHDRAW</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
              {isPC ? <></> : <div style={{ height: '16px' }}></div>}
              <GradientCard width={isPC ? '23%' : '100%'} height="232px">
                {isPC ? <></> : <div style={{ height: '1px' }}></div>}
                <PoolFlexCenter style={{ marginTop: isPC ? `48px` : `47px` }}>
                  <Typography variant="h2">{displayBalance(borrowedNusd)}</Typography>
                </PoolFlexCenter>
                <PoolFlexCenter style={{ marginTop: `20px` }}>
                  <Typography variant="h4">
                    <Trans>{mintTokenName} Debt Remaining</Trans>
                  </Typography>
                </PoolFlexCenter>
                <PoolFlexCenter>
                  <MainButton
                    onClick={() => borrowedNusd.gt(0) && openDrawer(VaultAction.REPAY)}
                    style={{ marginRight: 12 }}
                    disable={borrowedNusd.lte(0)}
                    disableStyle={'solid'}
                  >
                    <Typography variant="subtitle2" className={borrowedNusd.lte(0) ? classes.disableText : ''}>
                      <Trans>REPAY</Trans>
                    </Typography>
                  </MainButton>
                  <MainButton
                    onClick={() => borrowedNusd.gt(0) && openDrawer(VaultAction.LIQUIDATE)}
                    disable={borrowedNusd.lte(0)}
                    disableStyle={'solid'}
                  >
                    <Typography variant="subtitle2" className={borrowedNusd.lte(0) ? classes.disableText : ''}>
                      <Trans>LIQUIDATE</Trans>
                    </Typography>
                  </MainButton>
                </PoolFlexCenter>
              </GradientCard>
            </PoolFlex>
          </Pool>
          <div className={classes.bottomSpace}></div>
        </div>
      </div>

      <ActionBoard open={isDrawerOpen[VaultAction.DEPOSIT]} setClose={closeDrawer}>
        <InformationCard title={t`Deposit`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <DepositDrawerContent />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[VaultAction.BORROW]} setClose={closeDrawer}>
        <InformationCard title={t`Borrow`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <BorrowDrawerContent />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[VaultAction.WITHDRAW]} setClose={closeDrawer}>
        <InformationCard title={t`Withdraw`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <WithdrawDrawerContent />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[VaultAction.REPAY]} setClose={closeDrawer}>
        <InformationCard title={t`Repay`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <RepayDrawerContent />
        </InformationCard>
      </ActionBoard>
      <ActionBoard open={isDrawerOpen[VaultAction.LIQUIDATE]} setClose={closeDrawer}>
        <InformationCard title={t`Liquidate`} setClose={closeDrawer} hideBorderLeft showAccountStatus>
          <LiquidateDrawerContent />
        </InformationCard>
      </ActionBoard>
    </>
  )
}
export default Vault