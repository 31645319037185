import { Container } from './styled'

const defaultWidth = '100%'
const defaultHeight = '100%'
const defaultAspectRatio = 'unset'

interface IProps {
  children?: any
  width?: string
  height?: string
  aspectRatio?: string
  onClick?: () => void
}

export const GradientBackground = ({ children, width, height, aspectRatio, onClick }: IProps) => {
  return (
    <Container
      onClick={onClick}
      showCursor={onClick !== undefined}
      width={width ?? defaultWidth}
      height={height ?? defaultHeight}
      aspectRatio={aspectRatio ?? defaultAspectRatio}
    >
      {children ?? ''}
    </Container>
  )
}

export default GradientBackground
