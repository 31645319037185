import { forwardRef, useImperativeHandle, useMemo } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Container, OptionContainer, OptionButton } from './styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { BigNumber } from 'ethers'
import { useState, useEffect } from 'react'
import { formatEther, parseEther } from 'ethers/lib/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: '100%',

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },

      '& .MuiInputLabel-root, fieldset': {
        color: 'rgba(255, 255, 255, 0.3)',
        fontSize: '12px',
        letterSpacing: '0.4px',
        '&.Mui-focused': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
    },
    wraningTextField: {
      width: '100%',

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #B00020',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #B00020',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #B00020',
      },

      '& .MuiInputLabel-root, fieldset': {
        color: '#B00020',
        fontSize: '12px',
        letterSpacing: '0.4px',
        '&.Mui-focused': {
          color: '#B00020',
        },
      },
    },
    wraningText: {
      marginTop: 4,
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: 1.5,
      color: '#B00020',
    },
    resize: {
      fontSize: '16px',
    },
  })
)

interface IProps {
  amount: BigNumber
  maxNum: BigNumber
  textFieldLabel: string
  setAmount: (amount: BigNumber) => void
  showPercentage?: boolean
  disabled?: boolean
}

export interface IAmountInputRefObj {
  resetAmount(): void
}

const TestableAmountInput = forwardRef<IAmountInputRefObj, IProps>(
  ({ amount, maxNum, textFieldLabel, setAmount, showPercentage, disabled = false }, ref) => {
    const classes = useStyles()
    const [displayAmount, setDisplayAmount] = useState(formatEther(amount))
    const [displayWraning, setDisplayWraning] = useState(false)

    const [chosenPct, setChosenPct] = useState([false, false, false, false])
    const pctValue = useMemo(() => {
      const quarterNum = maxNum.div(4)
      return [quarterNum, quarterNum.mul(2), quarterNum.mul(3), maxNum]
    }, [maxNum])

    useImperativeHandle(ref, () => ({
      resetAmount() {
        setDisplayAmount('0')
      },
    }))

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return
      const n = event.target.value
      if (n === '' || n === null || isNaN(+n)) {
        setDisplayAmount('')
        setAmount(BigNumber.from(0))
        setChosenPct([false, false, false, false])
      } else {
        if (/^.*$11.*$/.test(n) && n.split('.')[1].length > 18) return
        if (+n < 0) return

        if (maxNum.gte(parseEther(n))) setDisplayWraning(false)
        else setDisplayWraning(true)
        setDisplayAmount(n)
        setAmount(n.trim() === '' ? BigNumber.from(0) : parseEther(n))
        setChosenPct([false, false, false, false])
      }
    }

    const handlePct = (idx) => {
      if (disabled) return
      const n = formatEther(pctValue[idx])
      setChosenPct(chosenPct.map((_, id) => (id === idx ? true : false)))
      setDisplayAmount(n)
      setAmount(pctValue[idx])
      setDisplayWraning(false)
    }

    useEffect(() => {
      if (disabled) {
        setDisplayWraning(false)
        setDisplayAmount('')
        setAmount(BigNumber.from(0))
        setChosenPct([false, false, false, false])
      }
    }, [disabled])

    showPercentage = showPercentage ?? true

    return (
      <Container style={{height: '125px'}}>
        {showPercentage && (
          <OptionContainer>
            <OptionButton onClick={() => handlePct(0)} chosen={chosenPct[0]} disabled={disabled}>
              <Typography variant="h5">25%</Typography>
            </OptionButton>
            <OptionButton onClick={() => handlePct(1)} chosen={chosenPct[1]} disabled={disabled}>
              <Typography>50%</Typography>
            </OptionButton>
            <OptionButton onClick={() => handlePct(2)} chosen={chosenPct[2]} disabled={disabled}>
              <Typography>75%</Typography>
            </OptionButton>
            <OptionButton onClick={() => handlePct(3)} chosen={chosenPct[3]} disabled={disabled}>
              <Typography>100%</Typography>
            </OptionButton>
          </OptionContainer>
        )}

        <TextField
          id="input"
          label={textFieldLabel}
          value={displayAmount}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          classes={{
            root: displayWraning ? classes.wraningTextField : classes.textField,
          }}
          disabled={disabled}
        />

        {displayWraning && <Typography className={classes.wraningText}>Insufficient balance</Typography>}
      </Container>
    )
  }
)

export default TestableAmountInput
