import { GALAXY_API_HOST } from '../constants/endpoints'
import fetch from 'node-fetch'

export interface INFTListResponse {
  assetId: string
  maturityDate: string
  amount: string
  interestFee: string
  status: string
  debtDays: number
}

function getEndpoint(path) {
  return `${GALAXY_API_HOST}/api/v1/galaxy${path}`
}

export async function getNFTList(): Promise<INFTListResponse[]> {
  try {
    const res = await fetch(getEndpoint('/nft/list'))
    const resJson = await res.json()
    return resJson.list
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getDebtCeiling(): Promise<number> {
  try {
    const res = await fetch(getEndpoint('/debtceiling'))
    const resJson = await res.json()
    return resJson.debtCeiling
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getFundUtilization(interval: 'hour' | 'day' | 'week') {
  try {
    const res = await fetch(getEndpoint(`/fundutilization?interval=${interval}`))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getNaosDappTvlHistory(interval: 'day' | 'week') {
  try {
    const res = await fetch(getEndpoint(`/tvl?interval=${interval}`))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getTotalLoanDisbursedValue(interval: 'hour', isProd: boolean) {
  try {
    const res = await fetch(getEndpoint(`/disbursedvalue?interval=${interval}&isprod=${isProd}`))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getDefiAprHistory() {
  try {
    const res = await fetch(getEndpoint('/dashboard/cryptoapr'))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getRealWorldAprHistory() {
  try {
    const res = await fetch(getEndpoint('/dashboard/fiatapr'))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getGalaxyApr() {
  try {
    const res = await fetch(getEndpoint('/apr'))
    const resJson = await res.json()
    return resJson.apr
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getGalaxyAprs() {
  try {
    const res = await fetch(getEndpoint('/aprs'))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getGalaxyOrder(address, galaxyStakingPoolAddres): Promise<{ redeemList: any[]; investList: any[] }> {
  try {
    const res = await fetch(getEndpoint(`/order/${galaxyStakingPoolAddres}/${address}`))
    const resJson: any = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getInvestmentInfo(
  address
): Promise<{ info: { investorAddress: string; isWhiteListApplied: boolean; whiteListTxHash: string } }> {
  try {
    const res = await fetch(getEndpoint(`/investor/${address}`))
    const resJson = await res.json()
    return resJson
  } catch (e) {
    console.error(e)
    throw e
  }
}


export async function getKYCType(externalId: string) {
  const url = `/externalId/${externalId}`
  const res = await fetch(getEndpoint(url), {
    method: 'GET',
  })

  const data: any = await res.json()
  if (data && data.hasOwnProperty('status')) {
    return data.status
  }
  throw new Error(data.errors.message)
}

export async function setWhitelist(address): Promise<string> {
  try {
    const body = JSON.stringify({ address })
    const res = await fetch(getEndpoint(`/whitelist`), {
      body,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    })
    const resJson = await res.json()
    return resJson.txHash
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getKYCInvestorInfo(
  address
): Promise<Record<string,any>> {
  const res = await fetch(getEndpoint(`/kyc/investor/${address}`))
  const data = await res.json()
  if (data && data.info && data.info.investorId && data.info.investorId.length > 0) {
    return data.info
  }
  throw new Error('investor not found')
}

export async function postSecuritizeKYCInfo(address, code): Promise<Record<string, any>> {
  const postData = {
    agencyName: "securitize",
    address,
    code
  }
  const res = await fetch(getEndpoint('/kyc'), {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const data = await res.json()
  if (data && data.result) {
    return data.result
  }
  throw new Error('failed to post kyc info')
}

export async function postSumsubKYCInfo(address, code, thirdPartyName, type): Promise<Record<string, any>> {
  const postData = {
    agencyName: 'sumsub',
    thirdPartyName,
    address,
    code,
    type,
  }
  const res = await fetch(getEndpoint('/kyc'), {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data: any = await res.json()
  if (data && data.result) {
    return data.result
  }
  throw new Error('failed to post kyc info')
}