import { useEffect, useMemo, useState } from 'react'
import { GALAXY_API_HOST } from '../constants/endpoints'
import { IS_DEV } from '../constants/env'

export function useNFTListStats(galaxyStakingPoolAddres:string) {
  const [nftListStats, setNFTListStats] = useState<Record<string, any>>({
    count: 0,
    totalAsset: 0,
    totalDays: 0,
    totalInterest: 0,
    list: [],
  })

  useEffect(() => {
    const setData = () =>
      fetch(`${GALAXY_API_HOST}/api/v1/galaxy/${galaxyStakingPoolAddres}/nft/list`).then((res) =>
        res.json().then((data) => {
          let result: Record<string, any> = {}
          result.count = data.list.length
          result.totalAsset = 0
          result.totalDays = 0
          result.totalInterest = 0
          result.list = []
          for (let i = 0; i < result.count; i++) {
            let nft = data.list[i]
            let amount = Number(nft.amount)
            let interest = Number(nft.interestFee)
            result.totalAsset += amount
            result.totalDays += nft.debtDays
            result.totalInterest += interest
            result.list.push(nft)
          }
          setNFTListStats(result)
        })
      )

    setData()

    const interval = setInterval(() => {
      if (nftListStats.count === 0) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 60000)

    return () => clearInterval(interval)
  }, [galaxyStakingPoolAddres])

  return useMemo(() => nftListStats, [nftListStats])
}

export function useNFTProListStats(galayxOperatorAddress:string) {
  const [nftListStats, setNFTListStats] = useState<Record<string, any>>({
    count: 0,
    totalAsset: 0,
    totalDays: 0,
    totalInterest: 0,
    list: [],
  })

  // console.log("galayxOperatorAddress: "+`${GALAXY_API_HOST}/api/v1/galaxy/pro/${galayxOperatorAddress}/nft/list`)

  useEffect(() => {
    const setData = () =>
      fetch(`${GALAXY_API_HOST}/api/v1/galaxy/pro/${galayxOperatorAddress}/nft/list`).then((res) =>
        res.json().then((data) => {
          let result: Record<string, any> = {}
          result.count = data.list.length
          result.totalAsset = 0
          result.totalDays = 0
          result.totalInterest = 0
          result.list = []
          for (let i = 0; i < result.count; i++) {
            let nft = data.list[i]
            let amount = Number(nft.amount)
            let interest = Number(nft.interestFee)
            result.totalAsset += amount
            result.totalDays += nft.debtDays
            result.totalInterest += interest
            result.list.push(nft)
          }
          setNFTListStats(result)
        })
      )

    setData()

    const interval = setInterval(() => {
      if (nftListStats.count === 0) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return useMemo(() => nftListStats, [nftListStats])
}

// export function useFundutilization() {
//   const [fundutilization, setFundutilization] = useState<Record<string, any>>({
//     name: [],
//     categories: [],
//     numbers: [[], []],
//     color: ['#238888', '#9B9B9B'],
//   })

//   useEffect(() => {
//     const setData = () =>
//       fetch(`${GALAXY_API_HOST}/api/v1/galaxy/fundutilization?interval=hour`).then((res) =>
//         res.json().then((data) => {
//           let result = Object.assign({}, fundutilization)
//           result.name = data.name
//           result.categories = data.categories
//           result.numbers = data.numbers
//           setFundutilization(result)
//         })
//       )

//     setData()

//     const interval = setInterval(() => {
//       if (fundutilization.name && fundutilization.name.length === 0) {
//         setData().then(() => clearInterval(interval))
//       } else {
//         clearInterval(interval)
//       }
//     }, 5000)

//     return () => clearInterval(interval)
//   }, [])

//   return useMemo(() => fundutilization, [fundutilization])
// }

// export function useTotalLoanDisbursed() {
//   const [totalLoanDisbursed, setTotalLoanDisbursed] = useState<Record<string, any>>({
//     name: [],
//     categories: [],
//     numbers: [[]],
//     color: ['#238888'],
//   })

//   useEffect(() => {
//     const setData = () =>
//       fetch(`${GALAXY_API_HOST}/api/v1/galaxy/nft/totalLoanDisbursed`).then((res) =>
//         res.json().then((data) => {
//           let result = Object.assign({}, totalLoanDisbursed)
//           result.name = data.name
//           result.categories = data.categories
//           result.numbers = data.numbers

//           setTotalLoanDisbursed(result)
//         })
//       )

//     setData()

//     const interval = setInterval(() => {
//       if (totalLoanDisbursed.name && totalLoanDisbursed.name.length === 0) {
//         setData().then(() => clearInterval(interval))
//       } else {
//         clearInterval(interval)
//       }
//     }, 5000)

//     return () => clearInterval(interval)
//   }, [])

//   return useMemo(() => totalLoanDisbursed, [totalLoanDisbursed])
// }

export function usePoolValue(galaxyStakingPoolAddres:string) {
  const [poolValue, setPoolValue] = useState<Record<string, any>>({
    name: [],
    categories: [],
    numbers: [[], []],
    color: ['#238888', '#9B9B9B', '#1D77FF'],
  })

  useEffect(() => {
    const setData = () =>
      fetch(`${GALAXY_API_HOST}/api/v1/galaxy/${galaxyStakingPoolAddres}/poolvalue?interval=hour`).then((res) =>
        res.json().then((data) => {
          let result = Object.assign({}, poolValue)
          result.name = data.name
          result.categories = data.categories
          result.numbers = data.numbers
          setPoolValue(result)
        })
      )

    setData()

    const interval = setInterval(() => {
      if (poolValue.name && poolValue.name.length === 0) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [galaxyStakingPoolAddres])

  return useMemo(() => poolValue, [poolValue])
}

export function usePoolProValue(galayxOperatorAddress:string) {
  const [poolValue, setPoolValue] = useState<Record<string, any>>({
    name: [],
    categories: [],
    numbers: [[], []],
    color: ['#238888', '#9B9B9B', '#1D77FF'],
  })

  // console.log(`${GALAXY_API_HOST}/api/v1/galaxy/pro/${galayxOperatorAddress}/poolvalue?interval=hour`)

  useEffect(() => {
    const setData = () =>
      fetch(`${GALAXY_API_HOST}/api/v1/galaxy/pro/poolvalue?interval=hour`).then((res) =>
        res.json().then((data) => {
          let result = Object.assign({}, poolValue)
          result.name = data.name
          result.categories = data.categories
          result.numbers = data.numbers
          setPoolValue(result)
        })
      )

    setData()

    const interval = setInterval(() => {
      if (poolValue.name && poolValue.name.length === 0) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return useMemo(() => poolValue, [poolValue])
}
