import styled from 'styled-components'

export const Container = styled.div<{ width: string; height: string; showCursor: boolean }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  cursor: ${(props) => (props.showCursor ? 'pointer' : 'inherit')};
`

export const BackgroundBorder = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  background: rgb(3, 7, 45);
  border-radius: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    margin: -2px;
    border-radius: 16px;
    opacity: 0.4;
    background: ${(props) => props.background};
  }
`

export const BackgroundGradient = styled.div<{ background: string }>`
  opacity: 0.4;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  z-index: 1;
  background-image: ${(props) => props.background};
  border-radius: 8px;
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NaosBackgroundBorder = styled(BackgroundBorder)`
  position: relative;
`
