import { Theme } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div``

export const ContainerButton = styled.div``

export const LanguageOption = styled.div<{ disable: boolean }>`
  cursor: pointer;
  white-space: pre;
  margin: 7px 0;
  min-width: 60px;

  &:hover {
    opacity: 0.5;
  }
`
export const OpenButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 12px;
  &:hover {
    opacity: 0.5;
  }
`

export const LanguageMenu = styled.div<{ theme: Theme }>`
  max-height: 150px;
  position: absolute;
  right: -10px;
  top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
  text-align: center;
  border-radius: 8px;
  z-index: 5;
  background-color: ${({ theme }) => theme.palette.background.default};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export const ContainerAddressFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ContainerAddressText = styled.div<{ pending: boolean }>`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`
export const ContainerAddressIcon = styled.img`
  width: 32px;
  height: 32px;
`
export const ContainerAddressPendingFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
export const ContainerAddressPendingIcon = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 8px;
`
export const ContainerAddressPendingText = styled.div``
