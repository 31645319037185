import { FC, useEffect, useState, useMemo } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { ChevronDown } from 'react-feather'
import { useWeb3React } from '@web3-react/core'
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  Grid,
  Menu,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import GradientBackground from '../GradientBackground'
import {
  ContainerButton,
  ContainerAddressFlex,
  ContainerAddressText,
  ContainerAddressIcon,
  ContainerAddressPendingFlex,
  ContainerAddressPendingIcon,
  ContainerAddressPendingText,
} from './styled'
import { useAllPendingTransactions } from '../../redux/transactions/hooks'
import { useOpenModal } from '../../redux/application/hooks'
import { ApplicationModal } from '../../redux/application/actions'
import { ChainId } from '../../constants/blockchain'
import { USED_CHAIN_ID } from '../../constants/env'
import { displayAddress } from '../../utils/displayAddress'
import { switchUsingNetwork } from '../../utils/switchNetwork'
import { Trans } from '@lingui/macro'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '16px !important',
      '&:before': {
        borderRadius: '16px !important',
      },
    },
    networkButon: {
      width: '120px',
      [theme.breakpoints.down(960)]: {
        width: '60px',
      },
    },
    ddl: {
      top: '80px !important',
    },
    wrongBorder: {
      border: '2px solid #B00020',
    },
  })
)

const idToName = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TEST]: 'BSC Test',
}

const UIAvailableChainId =
  USED_CHAIN_ID === ChainId.MAINNET ? [ChainId.MAINNET, ChainId.BSC] : [ChainId.RINKEBY, ChainId.BSC, ChainId.BSC_TEST]

// Todo:
// 1. Pending circle
// 2. background color doesn't fit design
// 3. utility to show address in short
const AccountStatus: FC<{ hideNetwork?: boolean }> = ({ hideNetwork }) => {
  const classes = useStyles()
  const { account, chainId } = useWeb3React()
  const openModal = useOpenModal()

  const pendingTransactions = useAllPendingTransactions()

  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))

  const [width, setWidth] = useState('100px')
  const height = '32px'
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const wrongNetwork = useMemo(() => chainId && !UIAvailableChainId.includes(chainId), [chainId])

  useEffect(() => {
    if (account) {
      setWidth('155px')
    }
  }, [account])

  const pendingBackground = 'linear-gradient(180deg, rgba(35, 45, 136, 0.6) 100%, rgba(35, 136, 136, 0.6) 100%);'

  function renderUnconnectedButton() {
    return (
      <ContainerButton>
        <Typography color="textPrimary">
          <Trans>Connect</Trans>
        </Typography>
      </ContainerButton>
    )
  }

  function renderConnectedButton() {
    if (chainId && !UIAvailableChainId.includes(chainId)) {
      return (
        <ContainerButton>
          <Typography color="error">
            <Trans>Wrong Network</Trans>
          </Typography>
        </ContainerButton>
      )
    }
    if (pendingTransactions.length > 0) {
      return (
        <ContainerAddressFlex>
          <GradientBackground
            classes={{ root: classes.box, background: classes.box }}
            borderRadius="16px"
            width="155px"
            height={height}
            gradiantBackground={pendingBackground}
          >
            <ContainerAddressPendingFlex>
              <CircularProgress size={16} style={{ color: 'white' }} />
              <ContainerAddressPendingText>
                <Typography color="textPrimary" variant="caption">
                  <Trans>{pendingTransactions.length} Pending</Trans>
                </Typography>
              </ContainerAddressPendingText>
              <Jazzicon diameter={32} seed={jsNumberForAddress(account)} />
            </ContainerAddressPendingFlex>
          </GradientBackground>
        </ContainerAddressFlex>
      )
    } else {
      return (
        <ContainerAddressFlex>
          <ContainerAddressText pending={false}>
            <Typography color="textPrimary">{displayAddress(account)}</Typography>
          </ContainerAddressText>
          <Jazzicon diameter={32} seed={jsNumberForAddress(account)} />
        </ContainerAddressFlex>
      )
    }
  }

  function renderUsingNetworkText() {
    return chainId ? <Typography color="textPrimary"> {idToName[chainId]} </Typography> : <></>
  }

  return (
    <Grid style={{ display: 'flex' }}>
      {chainId && !hideNetwork && (
        <Grid style={{ marginRight: 12 }}>
          {isPC && !(window.onto && window.onto.selectedAddress && window.onto.selectedAddress.length > 40) ? (
            <>
              <GradientBackground
                width={'120px'}
                height={height}
                classes={{ root: classes.box, background: classes.box }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              // onClick={() => {
              //   if (chainId !== ChainId.RINKEBY) switchUsingNetwork(ChainId.RINKEBY)
              // }}
              >
                {/* <Typography color="textPrimary">RINKEBY</Typography> */}
                <Typography color="textPrimary">{renderUsingNetworkText()}</Typography>
                <ChevronDown color="white" size={12} style={{ marginLeft: 4 }} />
              </GradientBackground>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{ paper: classes.ddl }}
              >
                <GradientBackground
                  width={'120px'}
                  height={height}
                  classes={{ root: classes.box, background: classes.box }}
                  onClick={() => {
                    switchUsingNetwork(chainId === ChainId.BSC ? USED_CHAIN_ID : ChainId.BSC)
                    setAnchorEl(null)
                  }}
                >
                  <Typography color="textPrimary">
                    {idToName[chainId === ChainId.BSC ? USED_CHAIN_ID : ChainId.BSC]}
                  </Typography>
                </GradientBackground>
              </Menu>
            </>
          ) : (
            <GradientBackground width={'60px'} height={height} classes={{ root: classes.box, background: classes.box }}>
              <Typography color="textPrimary">{renderUsingNetworkText()}</Typography>
            </GradientBackground>
          )}
        </Grid>
      )}

      <GradientBackground
        onClick={() => {
          if (chainId && !UIAvailableChainId.includes(chainId)) {
            return
          }
          openModal(ApplicationModal.WALLET)
        }}
        classes={{ root: classes.box, background: classes.box, border: wrongNetwork ? classes.wrongBorder : '' }}
        borderRadius="16px"
        width={width}
        height={height}
      >
        {account ? renderConnectedButton() : renderUnconnectedButton()}
      </GradientBackground>
    </Grid>
  )
}

export default AccountStatus
