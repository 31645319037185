import { useMemo } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

import ImageCoin from '../../../assets/symbol/coin.png'
import NAOS_ETH from '../../../assets/symbol/NAOS_ETH.png'
import nUSD_3CRV from '../../../assets/symbol/nUSD_3CRV.png'
import NAOS_BNB from '../../../assets/symbol/NAOS_BNB.png'
import betaTokenImage from '../../../assets/symbol/betaToken.svg'

import { DrawerWidth } from '../../../components/Navbar'
import NaosPool from './NaosPools'
import SushiPool from './SushiPools'
import ADDRESS from '../../../constants/addresses'
import { urls } from '../../../constants/urls'
import NaosBoostPool from './NaosBoostPool'

import { useStkaingPoolsApr } from '../../../hooks/smartContracts/useStakingPoolsAprs'
import { useStkaingPoolsTvl } from '../../../hooks/smartContracts/useStakingPoolsTvl'
import useStakingPoolId from '../../../hooks/useStakingPoolId'
import { useWeb3React } from '@web3-react/core'
import { ChainId } from '../../../constants/blockchain'

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    // informationBox: {
    //   padding: '48px 48px',
    //   width: '100%',
    //   overflow: 'auto',
    //   [theme.breakpoints.down(960)]: {
    //     padding: '16px 16px',
    //   },
    // },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px - ${DrawerWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
    },
    dividerVertical: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '20px auto',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
  })
)

export enum StakingPoolType {
  NAOS,
  NAOS_WITH_TRANSFER,
  SUSHI,
  BOOST,
}

export enum PoolAction {
  EXIT,
  STAKE,
  UNSTAKE,
  CLAIM,
}

const Pool = ({ match }) => {
  const { chainId } = useWeb3React()
  const classes = useStyles()
  const PoolId = useStakingPoolId()

  const aprs = useStkaingPoolsApr()
  const tvls = useStkaingPoolsTvl()

  const chain = useMemo(
    () =>
      chainId === ChainId.BSC ? 'bsc' : chainId === ChainId.RINKEBY || chainId === ChainId.MAINNET ? 'eth' : 'bsc',
    [chainId]
  )
  const chainName = useMemo(
    () =>
      {
        if(chainId!== undefined && (chainId === ChainId.BSC || chainId === ChainId.RINKEBY || chainId === ChainId.MAINNET)){
          return chainId
        }
        else{
          return ChainId.BSC
        }
      },
    [chainId]
  )
 
  const URL = useMemo(() => (chainId ? urls[chainId] : urls[ChainId.MAINNET]), [chainId])

  const poolName = match.params.poolname
  const [poolId, name, stakingTokenAddr, imageCoin, apr, tvl, url, poolType, supportedChains] = useMemo(() => {
    switch (poolName) {
      case 'naos':
        return [
          PoolId.NAOS,
          'NAOS',
          ADDRESS.NAOS_TOKEN,
          ImageCoin,
          aprs[PoolId.NAOS],
          tvls[PoolId.NAOS],
          URL?.UNI_GET_NAOS,
          StakingPoolType.NAOS,
          [ChainId.MAINNET, ChainId.RINKEBY],
        ]
      case 'boost':
        return [
          PoolId.NAOS_BOOST,
          'NAOS',
          ADDRESS?.NAOS_TOKEN,
          ImageCoin,
          aprs[PoolId.NAOS_BOOST],
          tvls[PoolId.NAOS_BOOST],
          URL?.UNI_GET_NAOS,
          StakingPoolType.BOOST,
          [ChainId.BSC],
        ]
      case 'naos-eth-lp':
        return [
          PoolId.NAOS_ETH_LP,
          'NAOS/ETH LP',
          ADDRESS.UNI_ETH_NAOS_LPTOKEN,
          NAOS_ETH,
          aprs[PoolId.NAOS_ETH_LP],
          tvls[PoolId.NAOS_ETH_LP],
          URL?.UNI_GET_NAOS_ETH_LP,
          StakingPoolType.NAOS,
          [ChainId.MAINNET, ChainId.RINKEBY],
        ]
      case 'beta':
        return [
          PoolId.BETA,
          'BETA',
          ADDRESS.BETA_INSURANCE,
          betaTokenImage,
          aprs[PoolId.BETA],
          tvls[PoolId.BETA],
          // aprs['20000'],
          // aprs['20000'],
          '/galaxy/rwa-0',
          StakingPoolType.NAOS_WITH_TRANSFER,
          [ChainId.BSC, ChainId.RINKEBY],
        ]
      case 'naos-eth-slp':
        return [
          PoolId.NAOS_ETH_SLP,
          'NAOS/ETH SLP',
          ADDRESS.SUSHI_ETH_NAOS_LPTOKEN,
          NAOS_ETH,
          aprs[PoolId.NAOS_ETH_SLP],
          tvls[PoolId.NAOS_ETH_SLP],
          URL?.SUSHI_GET_NAOS_ETH_LP,
          StakingPoolType.SUSHI,
          [ChainId.MAINNET, ChainId.RINKEBY],
        ]
      case 'nusd-3crv':
        return [
          PoolId.NUSD_3CRV,
          'nUSD/3CRV',
          ADDRESS.CURVE_NUSD_3CRV_LPTOKEN,
          nUSD_3CRV,
          aprs[PoolId.NUSD_3CRV],
          tvls[PoolId.NUSD_3CRV],
          URL?.CURVE_GET_NUSD_3CRV_LP,
          StakingPoolType.NAOS,
          [ChainId.MAINNET],
        ]
      case 'naos-bnb-lp':
        return [
          PoolId.NAOS_BNB_LP,
          'NAOS/BNB LP',
          ADDRESS.PANCAKE_NAOS_BNB_LPTOKEN,
          NAOS_BNB,
          aprs[PoolId.OLD_NAOS_BNB_LP],
          tvls[PoolId.OLD_NAOS_BNB_LP],
          URL?.PANCAKE_GET_NAOS_BNB_LP,
          StakingPoolType.NAOS_WITH_TRANSFER,
          [ChainId.BSC],
        ]
      default:
        return [
          PoolId.NAOS,
          'NAOS',
          ADDRESS.NAOS_TOKEN,
          ImageCoin,
          aprs[PoolId.NAOS],
          tvls[PoolId.NAOS],
          URL?.UNI_GET_NAOS,
          StakingPoolType.NAOS,
          [ChainId.MAINNET, ChainId.RINKEBY],
        ]
    }
  }, [poolName, aprs, chainId])

  return (
    <div className={classes.root}>
      {poolType === StakingPoolType.NAOS && (
        <NaosPool
          poolId={poolId}
          name={name}
          stakingTokenAddr={stakingTokenAddr}
          imageCoin={imageCoin}
          // apr={aprs[chain][ADDRESS.STAKINGPOOL[chainName]] ? aprs[chain][ADDRESS.STAKINGPOOL[chainName]][poolId] : 0}
          // tvl={tvls[chain][ADDRESS.STAKINGPOOL[chainName]] ? tvls[chain][ADDRESS.STAKINGPOOL[chainName]][poolId] : 0}
          apr={aprs[chain][ADDRESS.STAKINGPOOL[chainName]] ? aprs[chain][ADDRESS.STAKINGPOOL[chainName]][poolId] : aprs['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][poolId]}
          tvl={tvls[chain][ADDRESS.STAKINGPOOL[chainName]] ? tvls[chain][ADDRESS.STAKINGPOOL[chainName]][poolId] : tvls['eth'][ADDRESS.STAKINGPOOL[ChainId.MAINNET]][poolId]}
          urlToGetToken={url}
          poolType={poolType}
          supportedChains={supportedChains}
        />
      )}

      {poolType === StakingPoolType.NAOS_WITH_TRANSFER && (
        <NaosPool
          poolId={poolId}
          name={name}
          stakingTokenAddr={stakingTokenAddr}
          imageCoin={imageCoin}
          apr={
            aprs[chain][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainName]]
              ? aprs[chain][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainName]][poolId]
              : aprs['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][poolId]

            // poolId === PoolId.BETA
            // ? aprs[chain][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainName]][poolId]
            // : aprs['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][poolId]
          }
          tvl={
            tvls[chain][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainName]]
              ? tvls[chain][ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainName]][poolId]
              : tvls['bsc'][ADDRESS.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]][poolId]
          }
          urlToGetToken={url}
          poolType={poolType}
          supportedChains={supportedChains}
        />
      )}

      {poolType === StakingPoolType.BOOST && (
        <NaosBoostPool
          poolId={poolId}
          name={name}
          stakingTokenAddr={stakingTokenAddr}
          imageCoin={imageCoin}
          apr={
            aprs[chain][ADDRESS.BOOST_POOL[chainName]]
              ? aprs[chain][ADDRESS.BOOST_POOL[chainName]][poolId]
              : aprs['bsc'][ADDRESS.BOOST_POOL[ChainId.BSC]][poolId]
          }
          tvl={
            tvls[chain][ADDRESS.BOOST_POOL[chainName]]
              ? tvls[chain][ADDRESS.BOOST_POOL[chainName]][poolId]
              : tvls['bsc'][ADDRESS.BOOST_POOL[ChainId.BSC]][poolId]
          }
          urlToGetToken={url}
        />
      )}

      {poolType === StakingPoolType.SUSHI && (
        <SushiPool
          poolId={poolId}
          name={name}
          stakingTokenAddr={stakingTokenAddr}
          imageCoin={imageCoin}
          apr={aprs[chain][ADDRESS.STAKINGPOOL[chainName]] ? aprs[chain][ADDRESS.STAKINGPOOL[chainName]][poolId] : 0}
          tvl={tvls[chain][ADDRESS.STAKINGPOOL[chainName]] ? tvls[chain][ADDRESS.STAKINGPOOL[chainName]][poolId] : 0}
          urlToGetToken={url}
        />
      )}
    </div>
  )
}

export default Pool
