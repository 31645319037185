import { useState, useEffect, useMemo } from 'react'
import { getGalaxyOrder } from '../../utils/getGalaxyInfo'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import BigNumberJs from 'bignumber.js'
import { useGalaxyStakingPoolContract } from '../../hooks/smartContracts/useContract'
import { useGalaxyPoolCurrentEpoch, useAlphaTokenPrice } from '../../hooks/smartContracts/useGalaxy'

const useAlphaStableCoinRewards = (account?: string, galaxyAddress?: string, acessorAddress?: string): BigNumberJs => {
  const galaxyPoolContract = useGalaxyStakingPoolContract(galaxyAddress)
  const alphaPrice = useAlphaTokenPrice(acessorAddress)
  const currentEpoch = useGalaxyPoolCurrentEpoch(galaxyAddress)
  const [redeems, setRedeems] = useState<Record<string, any>>({ 0: [] })
  const [invests, setInvests] = useState<Record<string, any>>({ 0: [] })
  const [redeemTotalReward, setRedeemTotalReward] = useState<BigNumberJs>(new BigNumberJs(0))
  const [investTotalReward, setInvestTotalReward] = useState<BigNumberJs>(new BigNumberJs(0))

  useEffect(() => {
    if (account && galaxyAddress) {
      getGalaxyOrder(account?.toLowerCase(), galaxyAddress?.toLowerCase()).then(({ redeemList, investList }) => {
        const redeems: Record<string, any> = { 0: [] }
        const invests: Record<string, any> = { 0: [] }
        if (redeemList && redeemList.length > 0) {
          redeemList.map((r) => r.poolId === 0 && redeems[r.poolId].push(r))
          setRedeems(redeems)
        }
        if (investList && investList.length > 0) {
          investList.map((l) => l.poolId === 0 && invests[l.poolId].push(l))
          setInvests(invests)
        }
      })
    }
  }, [account, galaxyAddress])

  useEffect(() => {
    if (redeems && invests && galaxyPoolContract && alphaPrice && currentEpoch) {
      let redeemReward: BigNumberJs = new BigNumberJs(0)
      let investReward: BigNumberJs = new BigNumberJs(0)

      if (redeems[0].length > 0) {
        redeems[0].forEach((redeem) => {
          let redeemdCurrency = new BigNumberJs(redeem.redeemedCurrency)
          let remainingRedeemToken = new BigNumberJs(redeem.remainingRedeemToken)
          let amount = new BigNumberJs(redeem.amount)
          let temp = redeemdCurrency
            .plus(
              remainingRedeemToken.multipliedBy(
                alphaPrice.div(BigNumber.from('1000000000000000000000000000')).toString()
              )
            )
            .minus(amount)
          redeemReward = redeemReward.plus(temp)
        })
        setRedeemTotalReward(redeemReward)
      }

      if (invests[0].length > 0) {
        const investEpochs = invests[0].map((invest) => galaxyPoolContract.epochTokenPrice(invest.epoch))

        Promise.all(investEpochs).then((epochPrices) => {
          invests[0].forEach((invest, index) => {
            if (invest.epoch < currentEpoch) {
              let epochPrice = epochPrices[index] as BigNumber
              let epochPriceJs = new BigNumberJs(epochPrice.toString())
              let alphaPriceJs = new BigNumberJs(alphaPrice.toString())
              let amountJs = new BigNumberJs(invest.amount)
              let temp = alphaPriceJs.div(epochPriceJs).multipliedBy(amountJs).minus(amountJs)
              investReward = investReward.plus(temp)
            }
          })
          setInvestTotalReward(investReward)
        })
      }
    }
  }, [redeems, invests, galaxyPoolContract, alphaPrice, currentEpoch])

  return useMemo(() => {
    return redeemTotalReward.plus(investTotalReward)
  }, [redeemTotalReward, investTotalReward])
}

export default useAlphaStableCoinRewards
