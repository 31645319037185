import { useMemo, useRef, useState } from 'react'
import { Grid, createStyles, Theme, makeStyles, Typography } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { Trans, t } from '@lingui/macro'

import ActionBoard from '../../common/ActionBoard'
import AmountInput, { IAmountInputRefObj } from '../../common/AmountInput'
import { MainButton } from '../../common/buttons'
import { Divider } from '../../common/Divider'
import InformationCard from '../../common/InformationCard'
import ADDRESS from '../../constants/addresses'
import { ApprovalState, useApproveCallback } from '../../hooks/smartContracts/useApproveCallback'
import { useBetaInsuranceContract } from '../../hooks/smartContracts/useContract'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { displayBalance } from '../../utils/displayBalance'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'
import useAddTokenToWallet from '../../hooks/useAddTokenToWallet'
import { TOKEN } from '../../constants/tokens'
import { UnderLineButton } from './styled'
import { injected } from '../../utils/connectors'
import { ChainId } from '../../constants/blockchain'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addTokenButton: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'center',
    },
    addedTokenSign: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'right',
    },
    addedTokenContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  })
)

export const BetaDrawer = ({ isOpen, onClose, apr }) => {
  const classes = useStyles()
  const { account, chainId, connector } = useWeb3React()
  const betaContract = useBetaInsuranceContract(ADDRESS.BETA_INSURANCE)

  const mintTokenName = useMemo(() => (chainId === ChainId.BSC ? 'nBUSD' : 'nUSD'), [chainId])
  const [nusdBalance] = useTokenBalance(ADDRESS.NUSD_TOKEN, account ?? undefined)
  const [betaBalance] = useTokenBalance(ADDRESS.BETA_INSURANCE, account ?? undefined)
  const [inputBetaBignumber, setInputBetaBignumber] = useState(BigNumber.from(0))
  const [isBetaApprove, approveBeta] = useApproveCallback(
    ADDRESS.NUSD_TOKEN,
    inputBetaBignumber,
    ADDRESS.BETA_INSURANCE[chainId ?? ChainId.BSC]
  )
  const amountBetaInput = useRef<IAmountInputRefObj>(null)
  const [betaSupply, betaPrice, lockAmount] = useBetaInsuranceInfo(ADDRESS.BETA_INSURANCE)
  const tvl = useMemo(() => betaSupply.mul(betaPrice).div(parseUnits('1', 18)), [betaSupply, betaPrice])

  const addBetaToken = useAddTokenToWallet(TOKEN.BETA_INSURANCE)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  async function handleBetaDeposit() {
    if (isBetaApprove !== ApprovalState.APPROVED) {
      await approveBeta()
    } else {
      if (betaContract && inputBetaBignumber.gt(0)) {
        openConfirmingModal(true)
        try {
          const res = await betaContract.deposit(inputBetaBignumber)
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Deposit ${displayBalance(inputBetaBignumber)} ${mintTokenName}`,
          })
          amountBetaInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <ActionBoard open={isOpen} setClose={onClose}>
      <InformationCard setClose={onClose} title="BETA Insurance Pool" hideBorderLeft showAccountStatus>
        {/* <Typography variant="h3" style={{ marginTop: 32 }}>
          BETA Insurance Pool
        </Typography> */}
        <Typography variant="body2" style={{ marginTop: 8 }}>
          <Trans>Stake {mintTokenName} to provide coverage to the ALPHA Lending pool</Trans>
        </Typography>

        {/* <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">Staking APR</Typography>
          <Typography variant="body2">{apr} %</Typography>
        </Grid> */}
        {/* <Divider /> */}

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Lock Amount</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(lockAmount)} USD</Typography>
        </Grid>
        <Divider />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>TVL</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(tvl)} USD</Typography>
        </Grid>
        <Divider />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>Default Amount</Trans>
          </Typography>
          {/* TODO: not to use fix balance */}
          <Typography variant="body2">{displayBalance(0, '0,0.0000')}</Typography>
        </Grid>
        <Divider />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>{mintTokenName} Balance</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(nusdBalance)}</Typography>
        </Grid>
        <Divider />

        <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
          <Typography variant="body2">
            <Trans>BETA Balance</Trans>
          </Typography>
          <Typography variant="body2">{displayBalance(betaBalance)}</Typography>
        </Grid>
        <Divider style={{ marginBottom: 20 }} />

        <AmountInput
          ref={amountBetaInput}
          maxNum={nusdBalance}
          amount={inputBetaBignumber}
          setAmount={setInputBetaBignumber}
          textFieldLabel={mintTokenName}
        />
        <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
          <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            <Trans>Set insure amount</Trans>
          </Typography>
        </Grid>
        <MainButton onClick={handleBetaDeposit} style={{ marginTop: 16 }}>
          <Typography variant="subtitle2">
            {isBetaApprove === ApprovalState.APPROVED ? t`DEPOSIT` : t`APPROVE`}
          </Typography>
        </MainButton>

        {connector === injected && !addBetaToken.success && (
          <UnderLineButton color={'rgba(255, 255, 255, 0.6)'} onClick={addBetaToken.addToken}>
            <Typography variant="body1" className={classes.addTokenButton}>
              <Trans>Add BETA token to your wallet</Trans>
            </Typography>
          </UnderLineButton>
        )}
      </InformationCard>
    </ActionBoard>
  )
}

export default BetaDrawer
