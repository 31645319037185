import { useMediaQuery, useTheme, createStyles, Theme, makeStyles, Typography, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { TitleTextDiv, TitleTextMobileHide, Pool, Basic, BasicText } from '../../components/pool/PoolBar'
import { ExternalLink as IconExternal, ChevronDown, ChevronUp, ChevronRight } from 'react-feather'
import { useEffect, useMemo, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import useStakingPoolId from '../../hooks/useStakingPoolId'
import { ChainId } from '../../constants/blockchain'
import ADDRESS from '../../constants/addresses'
import InvestPoolBar from './InvestPoolBar'
import { PoolNames, MobilePoolNames, BetaPoolIds } from '../../constants/insuranceConstants'
import { usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'
import betaTokenImage from '../../assets/symbol/betaToken.svg'
import ImageTidal from '../../assets/symbol/tidal_white.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
        marginBottom: 60,
      },
    },
    subRow: {
      marginTop: 8,
    },
  })
)

export interface AssetDataType {
  nftID: string
  nftRegistry: string
  owner: string
  riskGroup: string
  maturityDate: string
  amount: string
  financeFee: string
  status: string
  available: string
  outstanding: string
}

const Invest = () => {
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const classes = useStyles()
  const history = useHistory()
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const { account, chainId } = useWeb3React()

  const PoolId = useStakingPoolId()

  {
    /* 
      TODO: Modify aprs for root address array
    */
  }

  const [alphaRootArray, setAlphaRootArray] = useState(Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]))

  useEffect(() => {
    setAlphaRootArray(Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]))
  }, [chainId])

  return (
    <div className={classes.root}>
      <div className={classes.informationBox}>
        <Pool style={{ marginTop: `0px` }}>
          <Basic style={{ padding: `0px 0px` }}>
            {/* <BasicText> */}
            <Typography variant="subtitle2">
              <Trans>Pool</Trans>
            </Typography>
            <Basic style={{ padding: `0px 0px` }}>
              <TitleTextMobileHide>
                <BasicText>
                  <Typography variant="subtitle2">
                    <Trans>TVL</Trans>
                  </Typography>
                </BasicText>
              </TitleTextMobileHide>
              <TitleTextDiv>
                <BasicText>
                  <Typography variant="subtitle2">
                    <Trans>MAX APR</Trans>
                  </Typography>
                </BasicText>
              </TitleTextDiv>
              {/* <TitleTextDiv style={{ display: isPC ? 'block' : 'none' }}>
                <BasicText style={{ width: isPC ? 50 : 20, visibility: 'hidden' }}>
                  <ChevronRight size={20} color="white" />
                </BasicText>
              </TitleTextDiv> */}
            </Basic>
          </Basic>

          {/* 
            TODO: Add mapping array to show pool lists
           */}

          {chainId &&
            alphaRootArray.map((root, index) => {
              return (
                <>
                  {root !== 'TestRoot' ? (
                    <InvestPoolBar
                      poolName={i18n._(PoolNames[index])}
                      mobilePoolName={i18n._(MobilePoolNames[index])}
                      alphaRootAddress={root}
                      poolId={BetaPoolIds[chainId]}
                      image={betaTokenImage}
                      link={''}
                      onlyLink={false}
                    />
                  ) : (
                    ''
                  )}
                </>
              )
            })}
          <InvestPoolBar
            poolName={t`Tidal`}
            mobilePoolName={t`Tidal`}
            alphaRootAddress={''}
            poolId={0}
            image={ImageTidal}
            link={'https://app.tidal.finance/'}
            onlyLink={true}
          />
        </Pool>
      </div>
    </div>
  )
}

export default Invest
