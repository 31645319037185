import { ChainId } from './blockchain'

// APP Settings
export const IS_DEV = process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_IS_DEV === 'true' : true
export const ENABLE_BETA_FUNCTION = process.env.REACT_APP_ENABLE_BETA_FUNCTION
  ? process.env.REACT_APP_ENABLE_BETA_FUNCTION === 'true'
  : true
export const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE
  ? process.env.REACT_APP_MAINTENANCE_MODE === 'true'
  : false
export const USED_CHAIN_ID =
  Number(process.env.REACT_APP_USED_CHAIN_ID) in ChainId
    ? (Number(process.env.REACT_APP_USED_CHAIN_ID) as ChainId)
    : ChainId.RINKEBY

// 3rd party keys
export const INFURA_ID = process.env.REACT_APP_INFURA_ID || 'c761587575d44dfebc32bae2985bc88f'

export const SECURITIZE_API_URL =
  process.env.REACT_APP_SECURITIZE_API_URL || 'https://id.sandbox.securitize.io/#/authorize'
export const SECURITIZE_CLIENT_KEY = process.env.REACT_APP_SECURITIZE_CLIENT_KEY
export const SECURITIZE_CLIENT_ID = process.env.REACT_APP_SECURITIZE_CLIENT_ID || '5fb7b9f2-5bba-4e0d-8f52-8cded9e1ec8d'

// Sumsub
export const SUMSUB_APP_TOKEN = 'sbx:QLKgTvbsT6xWW2tdqm1QnoSt.Uxm5NDfrgnPyL3AWxVZqG3y2SiTRFIhf' // Example: sbx:uY0CgwELmgUAEyl4hNWxLngb.0WSeQeiYny4WEqmAALEAiK2qTC96fBad - Please don't forget to change when switching to production
export const SUMSUB_SECRET_KEY = 'yfnKGQvkmtMOU3YwXy5dlBc3Pd5KovTu' // Example: Hej2ch71kG2kTd1iIUDZFNsO5C1lh5Gq - Please don't forget to change when switching to production
export const SUMSUB_BASE_URL = 'https://api.sumsub.com'

// Google Login
export const GOOGLE_CLIENT_ID = IS_DEV ? '8047820491-q2jh42dkntqohcba749bcplasndu8b4a.apps.googleusercontent.com' : '670932369586-nma0ldiaisbaeu5amgumbade04t29qi0.apps.googleusercontent.com'
// export const GTMParams = {id: '	GTM-5BW522W'}
export const GTMParams = {id: 'GTM-WZ43ZPR'}