import { BigNumber } from '@ethersproject/bignumber'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useBoostPoolContract, useGalaxyStakingPoolContract } from './useContract'
import { useMulticallContract } from '../smartContracts/useContract'
import ADDRESS from '../../constants/addresses'
import multicall from '../../utils/multicall'
import BigNumberJs from 'bignumber.js'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { SEC_PER_BLOCK_CHAIN } from '../../constants/blockchain'
import { useTokenPrice } from '../useTokenPrice'

export function useReBoostRatio(
  alphaDepositAmount: BigNumber,
  newUserBoostWeight: BigNumber,
  galaxyAddress: IAddressOrAddressMap,
  chainId: number,
  constData: Record<string, any>,
  inputAmount: number,
  work: boolean
): [number, BigNumberJs] {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyAddress)
  const naosPrice = useTokenPrice('NAOS')

  const [updatedRatio, setUpdatedRatio] = useState(1)
  const [updatedAPR, setUpdatedAPR] = useState<BigNumberJs>(new BigNumberJs(0))
  useEffect(() => {
    if (
      work &&
      (alphaDepositAmount.gt(0) || inputAmount > 0) &&
      chainId &&
      constData &&
      galaxyContract &&
      naosPrice &&
      newUserBoostWeight &&
      newUserBoostWeight.gte(0)
    ) {

      const newUserDepositAmount = parseEther(inputAmount.toString())
      let weightBN = constData.bigNumber_userDeposited.add(newUserDepositAmount).mul(40).div(100)
      if (constData.bigNumber_boostPoolDepositedWeight.gt(0)) {
        weightBN = weightBN.add(
          constData.bigNumber_poolDeposited
            .add(newUserDepositAmount)
            .mul(constData.bigNumber_boostUserDepositedWeight.add(newUserBoostWeight))
            .div(constData.bigNumber_boostPoolDepositedWeight.add(newUserBoostWeight))
            .mul(60)
            .div(100)
        )
        if (weightBN.gt(constData.bigNumber_userDeposited.add(newUserDepositAmount))) {
          weightBN = constData.bigNumber_userDeposited.add(newUserDepositAmount)
        }
      }

      const weight = +weightBN.toString()
      const amount = +constData.bigNumber_userDeposited.add(newUserDepositAmount).toString()
      setUpdatedRatio(amount > 0 ? +((weight / amount) * 2.5).toFixed(2) : 1)

      if (constData.bigNumber_userDeposited.add(newUserDepositAmount).gt(0)) {
        galaxyContract
          .calcUserWeight(
            constData.bigNumber_poolDeposited.add(newUserDepositAmount),
            constData.bigNumber_userDeposited.add(newUserDepositAmount),
            constData.bigNumber_boostPoolDepositedWeight.add(newUserBoostWeight),
            constData.bigNumber_boostUserDepositedWeight.add(newUserBoostWeight)
          )
          .then((res) => {
            const newUserWeight = new BigNumberJs(formatEther(res))
            const newTotalWeight = constData.poolDepositedWeight
              .minus(constData.userDepositedWeight)
              .plus(newUserWeight)
            const share = newUserWeight.div(newTotalWeight)
            const rewardPerSec = constData.rewardRate.div(SEC_PER_BLOCK_CHAIN[chainId])
            const newNAOS_APR = share
              .multipliedBy(rewardPerSec)
              .multipliedBy(60)
              .multipliedBy(60)
              .multipliedBy(24)
              .multipliedBy(365)
              .multipliedBy(naosPrice)
              .multipliedBy(100)
              .div(constData.userDeposited.plus(new BigNumberJs(formatEther(newUserDepositAmount))))

            if (!isNaN(+newNAOS_APR.toFixed(2))) setUpdatedAPR(newNAOS_APR)
          })
      } else {
        setUpdatedAPR(new BigNumberJs(0))
      }
    } else {
      setUpdatedRatio(1)
      setUpdatedAPR(new BigNumberJs(0))
    }
  }, [chainId, newUserBoostWeight, constData, naosPrice, galaxyContract, alphaDepositAmount, inputAmount, work])

  return useMemo(() => {
    return [updatedRatio, updatedAPR]
  }, [updatedRatio, updatedAPR])
}
