import React, { useState } from 'react'
import { Fab, List, ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { usePositionOffset } from '../../redux/navbar/hooks'
import { createStyles, Theme, makeStyles, styled } from '@material-ui/core/styles'

import MediaIcon from '../../assets/symbol/Shape.png'
import DiscordIcon from '../../assets/symbol/Discord.png'
import MediumIcon from '../../assets/symbol/Medium.png'
import RedditIcon from '../../assets/symbol/Reddit.png'
import TelegramIcon from '../../assets/symbol/Telegram.png'
import TwitterIcon from '../../assets/symbol/Twitter.png'
import FAQIcon from '../../assets/navbar/FAQ_white.svg'

const MediaOptionBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '265px',
  padding: '0px 20px',
  '& .mediaOption': {
    width: '20px',
    height: '100%',
    margin: '12px',
    cursor: 'pointer',
    opacity: 0.5,
    flex: 1,
    '&:hover': {
      opacity: 1,
    },
  },
})

const MediaMask = styled('div')({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  zIndex: 3,
})

const MediaListIcon = styled('div')({
  width: '20px',
  height: '20px',
  backgroundImage: `url(${MediaIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mediaModal: {
      position: 'fixed',
      right: '40px',
      bottom: '144px',
      width: '200px',
      height: '220px',
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #040534',
      boxShadow:
        '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      padding: '8px 0px',
      zIndex: 100,
    },
    mediaModalButtonList: {
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    mediaButton: {
      paddingLeft: '16px',
      paddingRight: '16px',
      flex: 1,
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
    mediaButtonOption: {
      height: '100%',
      width: '24px',
    },
  })
)

const mediaIconList = [
  {
    name: 'Discord',
    link: 'https://discord.gg/rRaPwCVTaA',
    icon: DiscordIcon,
  },
  {
    name: 'Medium',
    link: 'https://medium.com/naos-finance',
    icon: MediumIcon,
  },
  {
    name: 'Reddit',
    link: 'https://www.reddit.com/r/NAOS_Finance/',
    icon: RedditIcon,
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/naos_finance',
    icon: TwitterIcon,
  },
  {
    name: 'Telegram',
    link: 'https://t.me/naos_finance',
    icon: TelegramIcon,
  },
]

export const MediaOptionList = () => {
  return (
    <MediaOptionBlock>
      {mediaIconList.map((media) => (
        <img
          onClick={() => window.open(media.link, '_blank', 'noopener noreferrer')}
          className={'mediaOption'}
          src={media.icon}
          alt={media.name}
          key={media.name}
        />
      ))}
    </MediaOptionBlock>
  )
}

/**
 * TODO:
 * FAQ is not include this list, but include in mobile mode
 * Sync in the future
 */
const MediaModal = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const floatButtonOffset = usePositionOffset()
  const classes = useStyles()

  const modalControl = () => {
    if (isModalOpen) {
      setModalOpen(false)
    } else setModalOpen(true)
  }

  return (
    <>
      {isModalOpen && (
        <>
          <MediaMask onClick={() => modalControl()} />
          <div className={classes.mediaModal} style={{ right: 40 + floatButtonOffset }}>
            <List className={classes.mediaModalButtonList}>
              {mediaIconList.map((media) => (
                <ListItem
                  key={media.name}
                  button
                  disableGutters
                  className={classes.mediaButton}
                  onClick={() => window.open(media.link, '_blank', 'noopener noreferrer')}
                >
                  <Typography variant="h4">{media.name}</Typography>
                  <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                    <img className={classes.mediaButtonOption} src={media.icon} alt={media.name} />
                  </ListItemIcon>
                </ListItem>
              ))}

              <ListItem
                button
                disableGutters
                className={classes.mediaButton}
                onClick={() =>
                  window.open('https://naosfinance.gitbook.io/naos-finance/', '_blank', 'noopener noreferrer')
                }
              >
                <Typography variant="h4">FAQ</Typography>
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  <img className={classes.mediaButtonOption} src={FAQIcon} alt="FAQ" />
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
        </>
      )}

      <Fab
        variant="circular"
        style={{ position: 'fixed', background: '#1D77FF', right: 12 + floatButtonOffset, bottom: 56 + 16, zIndex: 99 }}
        onClick={() => modalControl()}
      >
        <MediaListIcon />
      </Fab>
    </>
  )
}

export default MediaModal
