import { ReactNode } from 'react'
import styled from 'styled-components'

const Link = styled.a<{inputColor?: string;}>`
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  color: ${props => (props.inputColor === undefined || "" ? 'white' : props.inputColor)};

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`
export const ExternalLink = ({ href, children, color }: { href: string; children?: ReactNode; color?: string; }) => {
  return <Link rel="noopener noreferrer" target="_blank" href={href} children={children} inputColor={color} />
}
export default ExternalLink
