import styled from 'styled-components'

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 22px;
  }
`

export const TitleFront = styled.div`
  display: flex;
  margin-bottom: 27px;
`

export const TitleIcon = styled.img`
  width: 42px;
  height: 42px;
`
export const TitleTextDiv = styled.div`
  margin-left: 20px;
`

export const TitleBackContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 960px) {
    margin-top: 4px;
    align-self: flex-end;
  }
`

export const TitleBack = styled.div`
  width: 93px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Button = styled.div`
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
`
export const Basic = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
`
export const BasicTextFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`
export const Pool = styled.div`
  margin-top: 40px;
`
export const PoolFlex = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`
export const PoolFlexCenter = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`
export const PoolFlexAsset = styled.div`
  padding: 24px 16px;
`
