import { useRef, useMemo } from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts'
import { Container, LineLabel, LineToolTip } from './styled'
import numeral from 'numeral'

export interface LineDataType {
  name: string[]
  categories: string[]
  numbers: number[][]
  color: string[]
}

interface Props {
  lineData: LineDataType
  height: number
  width: number | undefined
  yRange?: any[]
  XAxisTicks?: Object
  XInterval?: number | 'preserveEnd'
  tickFormatX?: any
  tickFormatY?: any
  labelFormatter?: any
  yMaximum?: number
}

const LineData = ({
  lineData,
  height,
  width,
  yRange,
  XInterval = 'preserveEnd',
  XAxisTicks,
  tickFormatX,
  tickFormatY,
  labelFormatter,
}: Props) => {
  yRange = yRange ? yRange : [(dataMin) => Math.floor(dataMin * 0.95), (dataMax) => Math.floor(dataMax * 1.2)]

  const elementRef = useRef(null)
  const processedData = useMemo(() => {
    const processed = lineData.name.map((name, index) => {
      let obj = {
        name: name,
      }
      lineData.categories.forEach((c, index2) => {
        obj[c] = Number(lineData.numbers[index2][index])
      })
      return obj
    })
    return processed
  }, [lineData])

  // Todo - choose type
  const lineKeys = useMemo(() => {
    return lineData.categories.map((c, index) => {
      return <Line key={c} type="monotone" dot={false} dataKey={c} stroke={lineData.color[index]} />
    })
  }, [])

  return (
    <Container ref={elementRef}>
      <LineChart
        width={width}
        height={height}
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: -5,
          bottom: 5,
        }}
      >
        {XAxisTicks !== undefined ? (
          <XAxis
            dataKey="name"
            interval={XInterval}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
            tickFormatter={(tick: string) => {
              if (XAxisTicks.hasOwnProperty(tick) && XAxisTicks[tick] !== '') {
                return XAxisTicks[tick]
              } else {
                return ''
              }
            }}
          />
        ) : (
          <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} tickFormatter={tickFormatX} />
        )}
        <YAxis
          type="number"
          domain={yRange}
          interval="preserveEnd"
          tickCount={6}
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          tickFormatter={tickFormatY}
        />
        <Tooltip formatter={labelFormatter} />
        {/* <Legend /> */}
        {lineKeys}
      </LineChart>
    </Container>
  )
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <LineToolTip>
        <p>{`${label}`}</p>
        <LineLabel color={payload[2].color}>{`${payload[2].name} : $${numeral(payload[2].value)
          .format('0,0')
          .toUpperCase()}`}</LineLabel>
        <LineLabel color={'#9B9B9B'}>{`${payload[1].name} : $${numeral(payload[1].value)
          .format('0,0')
          .toUpperCase()}`}</LineLabel>
        <LineLabel color={payload[0].color}>{`${payload[0].name} : $${numeral(payload[0].value)
          .format('0,0')
          .toUpperCase()}`}</LineLabel>
      </LineToolTip>
    )
  }

  return null
}

export const CustomLineData = ({
  lineData,
  height,
  width,
  yRange,
  XInterval = 'preserveEnd',
  XAxisTicks,
  tickFormatX,
  tickFormatY,
  labelFormatter,
}: Props) => {
  yRange = yRange ? yRange : [(dataMin) => Math.floor(dataMin * 0.95), (dataMax) => Math.floor(dataMax * 1.2)]

  const elementRef = useRef(null)
  const processedData = useMemo(() => {
    const processed = lineData.name.map((name, index) => {
      let obj = {
        name: name,
      }
      lineData.categories.forEach((c, index2) => {
        obj[c] = Number(lineData.numbers[index2][index])
      })
      return obj
    })
    return processed
  }, [lineData])

  // Todo - choose type
  const lineKeys = useMemo(() => {
    return lineData.categories.map((c, index) => {
      if (lineData.categories[index] === 'Reserve') {
        return <Line key={c} type="monotone" dot={false} dataKey={c} stroke={'transparent'} />
      }
      return <Line key={c} type="monotone" dot={false} dataKey={c} stroke={lineData.color[index]} />
    })
  }, [])

  return (
    <Container ref={elementRef}>
      <LineChart
        width={width}
        height={height}
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: -5,
          bottom: 5,
        }}
      >
        {XAxisTicks !== undefined ? (
          <XAxis
            dataKey="name"
            interval={XInterval}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
            tickFormatter={(tick: string) => {
              if (XAxisTicks.hasOwnProperty(tick) && XAxisTicks[tick] !== '') {
                return XAxisTicks[tick]
              } else {
                return ''
              }
            }}
          />
        ) : (
          <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} tickFormatter={tickFormatX} />
        )}
        <YAxis
          type="number"
          domain={yRange}
          interval="preserveEnd"
          tickCount={6}
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          tickFormatter={tickFormatY}
        />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        {lineKeys}
      </LineChart>
    </Container>
  )
}

const MaximumTooltip = ({ active, payload, label, data, dateIndex }: any) => {
  if (active && payload && payload.length) {
    return (
      <LineToolTip>
        <p>{`${label}`}</p>
        <LineLabel color={payload[3].color}>{`${payload[3].name} : ${data.numbers[3][dateIndex[label]]}`}</LineLabel>
        <LineLabel color={payload[2].color}>{`${payload[2].name} : ${data.numbers[2][dateIndex[label]]}`}</LineLabel>
        <LineLabel color={payload[1].color}>{`${payload[1].name} : ${data.numbers[1][dateIndex[label]]}`}</LineLabel>
        <LineLabel color={payload[0].color}>{`${payload[0].name} : ${data.numbers[0][dateIndex[label]]}`}</LineLabel>
      </LineToolTip>
    )
  }

  return null
}

export const MaximumLineData = ({
  lineData,
  height,
  width,
  yRange,
  XInterval = 'preserveEnd',
  XAxisTicks,
  tickFormatX,
  tickFormatY,
  labelFormatter,
  yMaximum,
}: Props) => {
  yRange = yRange ? yRange : [(dataMin) => Math.floor(dataMin * 0.95), (dataMax) => Math.floor(dataMax * 1.2)]

  const elementRef = useRef(null)
  const processedData = useMemo(() => {
    const processed = lineData.name.map((name, index) => {
      let obj = {
        name: name,
      }
      lineData.categories.forEach((c, index2) => {
        let numLineData = Number(lineData.numbers[index2][index])
        if (yMaximum && numLineData > yMaximum) {
          obj[c] = yMaximum
        } else {
          obj[c] = numLineData
        }
      })
      return obj
    })
    return processed
  }, [lineData])

  const dateIndex = useMemo(() => {
    const dateObj = {}
    lineData.name.forEach((name,index) => {
      dateObj[name] = index
    })
    return dateObj
  }, [lineData])

  // Todo - choose type
  const lineKeys = useMemo(() => {
    return lineData.categories.map((c, index) => {
      return <Line key={c} type="monotone" dot={false} dataKey={c} stroke={lineData.color[index]} />
    })
  }, [])

  return (
    <Container ref={elementRef}>
      <LineChart
        width={width}
        height={height}
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: -5,
          bottom: 5,
        }}
      >
        {XAxisTicks !== undefined ? (
          <XAxis
            dataKey="name"
            interval={XInterval}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
            tickFormatter={(tick: string) => {
              if (XAxisTicks.hasOwnProperty(tick) && XAxisTicks[tick] !== '') {
                return XAxisTicks[tick]
              } else {
                return ''
              }
            }}
          />
        ) : (
          <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} tickFormatter={tickFormatX} />
        )}
        <YAxis
          type="number"
          domain={yRange}
          interval="preserveEnd"
          tickCount={6}
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          tickFormatter={tickFormatY}
        />
        <Tooltip content={<MaximumTooltip data={lineData} dateIndex={dateIndex} />} />
        {/* <Legend /> */}
        {lineKeys}
      </LineChart>
    </Container>
  )
}

export default LineData
