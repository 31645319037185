import { ChainId, SEC_PER_BLOCK } from '../constants/blockchain'
import { ExplorerAPIEndpoint } from '../constants/urls'

export async function getAccountTransactionHistory(address, chainId, currentBlock) {
  const endpoint = ExplorerAPIEndpoint[chainId]
  const twoWeekBlockCount =
    chainId === ChainId.BSC
      ? Math.ceil((60 * 60 * 24 * 14) / SEC_PER_BLOCK.BSC)
      : Math.ceil((60 * 60 * 24 * 14) / SEC_PER_BLOCK.ETH)
  try {
    let url = endpoint
    url += '?module=account'
    url += '&action=txlist'
    url += `&address=${address}`
    url += `&startblock=${currentBlock - twoWeekBlockCount}`
    url += `&endblock=${currentBlock}`
    url += '&sort=desc'
    const res = await fetch(url)
    const resJson = await res.json()
    return resJson.result
  } catch (e) {
    console.error(e)
    throw e
  }
}
