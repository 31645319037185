import { useState, useMemo, useEffect } from 'react'
import { useMediaQuery, useTheme, createStyles, Theme, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useWeb3React } from '@web3-react/core'
import { Trans } from '@lingui/macro'
import { formatEther } from 'ethers/lib/utils'
import { BigNumber, utils } from 'ethers'

import getTokenName from '../../constants/historyToken'
import GradientBackground from '../../common/GradientBackground'
import { InvestmentsTitle, InvestmentsContent, Pool, PoolFlex, PoolFlexWrapper, BalanceWrapper } from './styled'
import Investments from './Investments'
import { DrawerWidth } from '../../components/Navbar'
import InvestmentDetail from './InvestmentDetail'
import TvlGraph from './graphs/TvlGraph'
import RealWorldAprGraph from './graphs/RealWorldAprGraph'
import DefiAprGraph from './graphs/DefiAprGraph'
import TotalLoanDisburse from './graphs/TotalLoanDisburse'
import ADDRESS from '../../constants/addresses'
import { getAccountTransactionHistory } from '../../utils/getAccountTransactionHistory'
import { useBlockNumber } from '../../redux/application/hooks'
import getExplorerLink from '../../utils/getExplorerLink'
import { displayBalance } from '../../utils/displayBalance'
import { useVaultStableCoinApr } from '../../hooks/useVaultStableCoinApr'
import { useFormationInfo } from '../../hooks/smartContracts/useFormationInfo'
import { useGalaxyStakingPoolContract } from '../../hooks/smartContracts/useContract'
import { useStakingPoolInfo } from '../../hooks/smartContracts/useStakingPoolsInfo'
import useStakingPoolId from '../../hooks/useStakingPoolId'
import { useShiftToLeft, usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'
import { ChainId } from '../../constants/blockchain'
import { useBoostPoolsInfo } from '../../hooks/smartContracts/useBoostPoolsInfo'
import useAlphaStableCoinRewards from '../../hooks/smartContracts/useAlphaStableCoinRewards'
import { useAlphaStakeTotalDeposited } from '../../hooks/smartContracts/useAlphaStakeTotalDeposited'
import { useTokenPrice } from '../../hooks/useTokenPrice'

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '0 48px 40px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px - ${DrawerWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    dividerVertical: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '20px auto',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    investmentCard: {
      width: '100%',
      height: '90px',
      padding: '20px',
      [theme.breakpoints.up(960)]: {
        display: 'flex',
        padding: '0px 20px 0 20px',
      },
    },
    investmentCardTriple: {
      width: '100%',
      height: '110px',
      padding: '20px',
      [theme.breakpoints.up(960)]: {
        display: 'flex',
        height: '90px',
        padding: '0px 20px 0 20px',
      },
    },
    grayHeader: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    investmentTitleFlexBasis: {
      [theme.breakpoints.up(1024)]: {
        flexBasis: '60%',
      },
      [theme.breakpoints.up(960) && theme.breakpoints.down(1024)]: {
        flexBasis: '40%',
      },
    },
    investmentContentFlexBasis: {
      [theme.breakpoints.up(1024)]: {
        flexBasis: '20%',
      },
      [theme.breakpoints.up(960) && theme.breakpoints.down(1024)]: {
        flexBasis: '30%',
      },
    },
    investmentTripleContentFlexBasis: {
      [theme.breakpoints.up(1024)]: {
        flexBasis: '30%',
      },
      [theme.breakpoints.up(960) && theme.breakpoints.down(1024)]: {
        flexBasis: '30%',
      },
    },
    investmentTitleBoxLeft: {
      [theme.breakpoints.up(960)]: {
        flexBasis: '50%',
      },
    },
    investmentContentBoxLeft: {
      [theme.breakpoints.up(960)]: {
        flexBasis: '25%',
      },
    },
    investmentTitleTripleContentBoxLeft: {
      [theme.breakpoints.up(960)]: {
        flexBasis: '25%',
      },
    },
    investmentTripleContentBoxLeft: {
      [theme.breakpoints.up(960)]: {
        flexBasis: '25%',
      },
    },
  })
)

export interface ITransactionData {
  blockNumber: number
  date: number
  tx: string
  url: string
  from: string
  to: string
  amount: BigNumber
  token: string
  contractName: string
  method: string
}

const ActionMapping = {
  FORMATION: {
    name: 'FORMATION',
    '0xb6b55f25': 'DEPOSIT',
    '0xa0712d68': 'BORROW',
    '0x2e1a7d4d': 'WITHDRAW',
    '0x415f1240': 'LIQUIDATE',
    '0xd8aed145': 'REPAY',
  },
  TRANSMUTER: {
    name: 'TRANSMUTER',
    '0xe044205e': 'EXIT',
    '0xa694fc3a': 'STAKE',
    '0x2a03cf68': 'TRANSMUTE',
    '0x2e17de78': 'UNSTAKE',
  },
  STAKING_POOL: {
    name: 'STAKING POOL',
    '0x379607f5': 'CLAIM',
    '0xe2bbb158': 'STAKE',
    '0x7f8661a1': 'EXIT',
    '0x441a3e70': 'UNSTAKE',
  },
  BOOST_POOL: {
    name: 'BOOST POOL',
    '0x4e71d92d': 'CLAIM',
    '0x4009cf24': 'CLAIM IMMEDIATE',
    '0x43144dbb': 'COOLDOWN',
    '0xe2bbb158': 'STAKE',
    '0x983d95ce': 'UNSTAKE',
  },
  STAKINGPOOL_WITH_TRANSFER: {
    name: 'STAKING POOL',
    '0x379607f5': 'CLAIM',
    '0xe2bbb158': 'STAKE',
    '0x7f8661a1': 'EXIT',
    '0x441a3e70': 'UNSTAKE',
  },
  ALPHA_POOL: {
    name: 'ALPHA POOL',
    '0xe2bbb158': 'DEPOSIT',
    // '0xc4a0db96': 'REDEEM',
    '0xbe040fb0': 'REDEEM REGISTERED',
    '0x3ccfd60b': 'REDEEM',
    '0x379607f5': 'CLAIM',
    '0x2a3a6213': 'ACTIVEBOOST',
  },
  ALPHA_POOL_PRO: {
    name: 'ALPHA POOL PRO',
    '0xf1e4c866': 'DEPOSIT',
    '0x4a58ca0e': 'REDEEM',
    '0xabc6fd0b': 'DISBUSE',
  },
  BETA_POOL: {
    name: 'BETA POOL',
    '0xb6b55f25': 'DEPOSIT',
    '0x2e1a7d4d': 'WITHDRAW',
    '0xfee41165': 'BOOST',
  },
}

function parseInputData(input) {
  if (input.length < 10) {
    return []
  }
  const action = input.slice(0, 10)
  let params = input.slice(10, input.length)
  const ret: string[] = []
  while (params !== '') {
    const param = params.slice(0, 64)
    ret.push(utils.hexStripZeros(`0x${param}`))
    params = params.slice(64, params.length)
  }
  return [action, ...ret]
}

const Home = () => {
  const { account, chainId } = useWeb3React()
  const blocknumber = useBlockNumber()
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const PoolId = useStakingPoolId()
  const naosPrice = useTokenPrice('NAOS')

  const [alphaRootAddress, setAlphaRootAddress] = useState(
    Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC])[0]
  )
  const galaxyPoolContract = useGalaxyStakingPoolContract(
    ADDRESS!.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const alphaStableCoinReward = useAlphaStableCoinRewards(
    account ?? undefined,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    ADDRESS.ASSESSOR[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const shiftFloatButton = useShiftToLeft()
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const [collateralDai, borrowedNusd, availableToBorrow, collateralRatio] = useFormationInfo(
    ADDRESS.FORMATION,
    account ?? undefined
  )

  const collateralTokenName = useMemo(
    () => (chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || !chainId ? 'BUSD' : 'DAI'),
    [chainId]
  )
  const mintTokenName = useMemo(
    () => (chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || !chainId ? 'nBUSD' : 'nUSD'),
    [chainId]
  )

  const showAlphaStatistics = useMemo(() => chainId === ChainId.RINKEBY || chainId === ChainId.BSC, [chainId])
  const showBoostStatistics = useMemo(() => chainId === ChainId.RINKEBY || chainId === ChainId.BSC, [chainId])
  const showBetaStatistics = useMemo(
    () => chainId === ChainId.RINKEBY || chainId === ChainId.BSC || !chainId,
    [chainId]
  )

  const AlphaPoolInfo = [
    { name: 'ALPHA', period: 30, id: 0 },
    { name: 'ALPHA', period: 90, id: 1 },
  ]

  const [investmentsOpen, setInvestmentsOpen] = useState(false)
  const ContractAddrList = useMemo(() => {
    if (chainId) {
      switch (chainId) {
        case ChainId.BSC:
          return {
            [ADDRESS.FORMATION[chainId].toLowerCase()]: { ...ActionMapping.FORMATION },
            [ADDRESS.TRANSMUTER[chainId].toLowerCase()]: { ...ActionMapping.TRANSMUTER },
            [ADDRESS.BOOST_POOL[chainId].toLowerCase()]: { ...ActionMapping.BOOST_POOL },
            [ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainId].toLowerCase()]: { ...ActionMapping.STAKINGPOOL_WITH_TRANSFER },
            // TODO: modified index 0 to a root address index for multiple alpha pools
            [ADDRESS.GALAXY_STAKING_POOL[chainId][alphaRootAddress]?.toLowerCase()]: {
              ...ActionMapping.ALPHA_POOL,
            },
            [ADDRESS.GALAXY_PRO_OPERATOR[chainId]['TestRoot']?.toLowerCase()]: { ...ActionMapping.ALPHA_POOL_PRO },
            [ADDRESS.BETA_INSURANCE[chainId].toLowerCase()]: { ...ActionMapping.BETA_POOL },
          }
        default:
          return {
            [ADDRESS.FORMATION[chainId].toLowerCase()]: { ...ActionMapping.FORMATION },
            [ADDRESS.TRANSMUTER[chainId].toLowerCase()]: { ...ActionMapping.TRANSMUTER },
            [ADDRESS.STAKINGPOOL[chainId].toLowerCase()]: { ...ActionMapping.STAKING_POOL },
            [ADDRESS.BOOST_POOL[chainId].toLowerCase()]: { ...ActionMapping.BOOST_POOL },
            [ADDRESS.STAKINGPOOL_WITH_TRANSFER[chainId].toLowerCase()]: { ...ActionMapping.STAKINGPOOL_WITH_TRANSFER },
            // TODO: modified index 0 to a root address index for multiple alpha pools
            [ADDRESS.GALAXY_STAKING_POOL[chainId][alphaRootAddress]?.toLowerCase()]: {
              ...ActionMapping.ALPHA_POOL,
            },
            [ADDRESS.GALAXY_PRO_OPERATOR[chainId]['TestRoot']?.toLowerCase()]: { ...ActionMapping.ALPHA_POOL_PRO },
            [ADDRESS.BETA_INSURANCE[chainId].toLowerCase()]: { ...ActionMapping.BETA_POOL },
          }
      }
    }
    return {}
  }, [chainId])

  const [investmentsData, setInvestmentsData] = useState<ITransactionData[]>([])
  const [investmentDetailData, setInvestmentDetailData] = useState<undefined | ITransactionData>(undefined)
  useEffect(() => {
    if (account && chainId && blocknumber && ContractAddrList !== {}) {
      getAccountTransactionHistory(account, chainId, blocknumber).then(async (results) => {
        if (typeof results === 'string') {
          return
        }
        const txs: ITransactionData[] = []
        for (const result of results) {
          const contractAddr = result.to
          if (ContractAddrList[contractAddr] === undefined) continue

          const [method, ...params] = parseInputData(result.input)
          
          if (!!ContractAddrList[contractAddr][method]) {
            txs.push({
              blockNumber: +result.blockNumber,
              date: +result.timeStamp * 1000,
              tx: result.hash,
              url: getExplorerLink(chainId, result.hash, 'transaction'),
              from: result.from,
              to: result.to,
              amount:
                ContractAddrList[contractAddr].name === 'BOOST POOL'
                  ? isNaN(params[0])
                    ? BigNumber.from(0)
                    : BigNumber.from(params[0])
                  : isNaN(params[params.length - 1])
                  ? BigNumber.from(0)
                  : BigNumber.from(params[params.length - 1]),
              token: getTokenName(ContractAddrList[contractAddr].name, chainId, ContractAddrList[contractAddr][method]),
              contractName: ContractAddrList[contractAddr].name,
              method: ContractAddrList[contractAddr][method],
            })
          }
        }
        setInvestmentsData(txs)
      })
    }
  }, [account, chainId, blocknumber, ContractAddrList])

  const apr = useVaultStableCoinApr(chainId)
  // const [alphaDeposit, setAlphaDeposit] = useState<BigNumber>(BigNumber.from(0))
  const alphaDeposit: BigNumber = useAlphaStakeTotalDeposited(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined,
    AlphaPoolInfo[0].id
  )
  const [alphaReward, setAlphaReward] = useState<BigNumber>(BigNumber.from(0))
  const [stakedAmount, rewardAmount, totalDeposited] = useStakingPoolInfo(
    ADDRESS.STAKINGPOOL_WITH_TRANSFER,
    account ?? undefined,
    PoolId.BETA
  )
  const [boostStakedAmount, boostRewardAmount] = useBoostPoolsInfo(ADDRESS.BOOST_POOL, account!, ADDRESS.MULTICALL2)

  useEffect(() => {
    if (chainId !== undefined) {
      let rootAddress = Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC])[0]
      if (rootAddress !== undefined) {
        setAlphaRootAddress(rootAddress)
      }
    }
  }, [chainId])

  useEffect(() => {
    if (account && galaxyPoolContract) {
      const getAlphaReward = (alphaPoolId: number): Promise<BigNumber> =>
        galaxyPoolContract.getStakeTotalUnclaimed(account, alphaPoolId).then((r) => r)
      getAlphaReward(0).then((value) => setAlphaReward(value))
    }
  }, [account, galaxyPoolContract])

  return (
    <div className={classes.root}>
      <div className={clsx(classes.informationBox, { [classes.boxLeft]: investmentsOpen })}>
        <Pool>
          <PoolFlex>
            <TvlGraph isPC={isPC} widthPercent={'24%'} />

            {isPC ? '' : <div style={{ height: '10px' }} />}

            <TotalLoanDisburse isPC={isPC} widthPercent={'24%'} />

            {isPC ? '' : <div style={{ height: '10px' }} />}

            <DefiAprGraph isPC={isPC} widthPercent={'24%'} />

            {isPC ? '' : <div style={{ height: '10px' }} />}

            <RealWorldAprGraph isPC={isPC} widthPercent={'24%'} />
          </PoolFlex>
        </Pool>

        <Pool inputMobileMarginTop={'40px'}>
          <Typography variant="h2">
            <Trans>Your Investments</Trans>
          </Typography>
          {/* <GradientBlock>Tests</GradientBlock> */}
          <div style={{ height: '10px' }} />
          <GradientBackground width="100%" height={isPC ? '80px' : '130px'}>
            <div className={classes.investmentCard}>
              <InvestmentsTitle
                className={clsx(classes.investmentTitleFlexBasis, {
                  [classes.investmentTitleBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="h4">
                  <Trans>Vault</Trans>
                </Typography>
                <Typography variant="body1" className={classes.grayHeader}>
                  <Trans>Formation</Trans>
                </Typography>
              </InvestmentsTitle>
              <InvestmentsContent
                className={clsx(classes.investmentContentFlexBasis, {
                  [classes.investmentContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">
                  <Trans>APY</Trans>
                </Typography>
                <Typography variant={isPC ? 'h1' : 'body1'}>{apr} %</Typography>
              </InvestmentsContent>
              <InvestmentsContent
                className={clsx(classes.investmentContentFlexBasis, {
                  [classes.investmentContentBoxLeft]: investmentsOpen,
                })}
              >
                <Typography variant="body1">
                  <Trans>{mintTokenName} Available</Trans>
                </Typography>
                <Typography variant={isPC ? 'h1' : 'body1'}>
                  {+collateralRatio
                    ? displayBalance(availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd))
                    : '0'}{' '}
                </Typography>
              </InvestmentsContent>
            </div>
          </GradientBackground>
          {showAlphaStatistics && (
            <>
              <div style={{ height: '8px' }} />
              <GradientBackground width="100%" height={isPC ? '80px' : '150px'}>
                <div className={classes.investmentCardTriple}>
                  <InvestmentsTitle
                    className={clsx(classes.investmentTitleFlexBasis, {
                      [classes.investmentTitleTripleContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="h4">
                      <Trans>ALPHA Pool</Trans>
                    </Typography>
                    <Typography variant="body1" className={classes.grayHeader}>
                      <Trans>Invest</Trans>
                    </Typography>
                  </InvestmentsTitle>
                  <InvestmentsContent
                    className={clsx(classes.investmentTripleContentFlexBasis, {
                      [classes.investmentTripleContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">{collateralTokenName}</Typography>
                    <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(alphaDeposit)}</Typography>
                  </InvestmentsContent>
                  <InvestmentsContent
                    className={clsx(classes.investmentTripleContentFlexBasis, {
                      [classes.investmentTripleContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">
                      <Trans>{collateralTokenName} Rewards</Trans>
                    </Typography>
                    <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(alphaStableCoinReward)}</Typography>
                  </InvestmentsContent>
                  <InvestmentsContent
                    className={clsx(classes.investmentTripleContentFlexBasis, {
                      [classes.investmentTripleContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">
                      <Trans>NAOS Rewards</Trans>
                    </Typography>
                    <BalanceWrapper>
                      <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(alphaReward)}</Typography>
                      {alphaReward.gt(0) && (
                        <Typography
                          variant={'body1'}
                          style={isPC ? { marginLeft: '4px', marginBottom: '4px' } : { marginLeft: '4px' }}
                        >{`($ ${displayBalance(+formatEther(alphaReward) * naosPrice)})`}</Typography>
                      )}
                    </BalanceWrapper>
                  </InvestmentsContent>
                </div>
              </GradientBackground>
            </>
          )}

          {showBoostStatistics && (
            <>
              <div style={{ height: '8px' }} />
              <GradientBackground width="100%" height={isPC ? '80px' : '130px'}>
                <div className={classes.investmentCard}>
                  <InvestmentsTitle
                    className={clsx(classes.investmentTitleFlexBasis, {
                      [classes.investmentTitleBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="h4">
                      <Trans>Boost Pool</Trans>
                    </Typography>
                    <Typography variant="body1" className={classes.grayHeader}>
                      <Trans>Farm</Trans>
                    </Typography>
                  </InvestmentsTitle>
                  <InvestmentsContent
                    className={clsx(classes.investmentContentFlexBasis, {
                      [classes.investmentContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">NAOS</Typography>
                    <BalanceWrapper>
                      <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(boostStakedAmount)}</Typography>
                      {boostStakedAmount.gt(0) && (
                        <Typography
                          variant={'body1'}
                          style={isPC ? { marginLeft: '4px', marginBottom: '4px' } : { marginLeft: '4px' }}
                        >{`($ ${displayBalance(+formatEther(boostStakedAmount) * naosPrice)})`}</Typography>
                      )}
                    </BalanceWrapper>
                  </InvestmentsContent>
                  <InvestmentsContent
                    className={clsx(classes.investmentContentFlexBasis, {
                      [classes.investmentContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">
                      <Trans>NAOS Rewards</Trans>
                    </Typography>
                    <BalanceWrapper>
                      <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(boostRewardAmount)}</Typography>
                      {boostRewardAmount.gt(0) && (
                        <Typography
                          variant={'body1'}
                          style={isPC ? { marginLeft: '4px', marginBottom: '4px' } : { marginLeft: '4px' }}
                        >{`($ ${displayBalance(+formatEther(boostRewardAmount) * naosPrice)})`}</Typography>
                      )}
                    </BalanceWrapper>
                  </InvestmentsContent>
                </div>
              </GradientBackground>
            </>
          )}

          {showBetaStatistics && (
            <>
              <div style={{ height: '8px' }} />
              <GradientBackground width="100%" height={isPC ? '80px' : '130px'}>
                <div className={classes.investmentCard}>
                  <InvestmentsTitle
                    className={clsx(classes.investmentTitleFlexBasis, {
                      [classes.investmentTitleBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="h4">
                      <Trans>BETA Pool</Trans>
                    </Typography>
                    <Typography variant="body1" className={classes.grayHeader}>
                      <Trans>Farm</Trans>
                    </Typography>
                  </InvestmentsTitle>
                  <InvestmentsContent
                    className={clsx(classes.investmentContentFlexBasis, {
                      [classes.investmentContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">
                      <Trans>BETA</Trans>
                    </Typography>
                    <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(stakedAmount)}</Typography>
                  </InvestmentsContent>
                  <InvestmentsContent
                    className={clsx(classes.investmentContentFlexBasis, {
                      [classes.investmentContentBoxLeft]: investmentsOpen,
                    })}
                  >
                    <Typography variant="body1">
                      <Trans>NAOS Rewards</Trans>
                    </Typography>
                    <BalanceWrapper>
                      <Typography variant={isPC ? 'h1' : 'body1'}>{displayBalance(rewardAmount)}</Typography>
                      {rewardAmount.gt(0) && (
                        <Typography
                          variant={'body1'}
                          style={isPC ? { marginLeft: '4px', marginBottom: '4px' } : { marginLeft: '4px' }}
                        >{`($ ${displayBalance(+formatEther(rewardAmount) * naosPrice)})`}</Typography>
                      )}
                    </BalanceWrapper>
                  </InvestmentsContent>
                </div>
              </GradientBackground>
            </>
          )}
        </Pool>

        <Pool>
          <PoolFlex inputMobileMarginTop={'40px'}>
            <PoolFlexWrapper style={isPC ? { width: '100%' } : { width: '100%' }}>
              <Typography variant="h2">
                <Trans>Recent Transaction History</Trans>
              </Typography>
              <div style={{ height: '10px' }} />
              <GradientBackground width="100%" height={isPC ? '650px' : '950px'}>
                <Investments
                  data={investmentsData}
                  chainId={chainId ?? ChainId.MAINNET}
                  openDetail={() => {
                    isPC && shiftFloatButton()
                    setInvestmentsOpen(true)
                  }}
                  setDetail={(data: ITransactionData) => {
                    setInvestmentDetailData(data)
                  }}
                />
              </GradientBackground>
            </PoolFlexWrapper>
          </PoolFlex>
          <div className={classes.bottomSpace}></div>
        </Pool>
      </div>
      <div className={clsx({ [classes.boxRight]: investmentsOpen }, classes.boxRightHide)}>
        <InvestmentDetail
          data={investmentDetailData}
          open={investmentsOpen}
          setClose={() => {
            isPC && backFloatButton()
            setInvestmentsOpen(false)
          }}
        />
      </div>
    </div>
  )
}
export default Home
