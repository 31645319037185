import { useCallback, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { TokenMap } from '../constants/tokens'

export default function useAddTokenToWallet(
  tokenToAdd: TokenMap | undefined,
  alphaPoolName?: string | undefined
): {
  addToken: () => void
  success: boolean | undefined
} {
  const { chainId } = useWeb3React()

  const [success, setSuccess] = useState<boolean | undefined>()

  const addToken = useCallback(() => {
    if (window.ethereum && chainId && tokenToAdd) {
      if (window.onto && window.onto.selectedAddress && window.onto.selectedAddress.length > 40) {
        return
      }
      try {
        window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: tokenToAdd.type[chainId],
            options: {
              address: !!alphaPoolName ? tokenToAdd.address[alphaPoolName][chainId] : tokenToAdd.address[chainId],
              symbol: tokenToAdd.symbol,
              decimals: tokenToAdd.decimals,
              image: tokenToAdd.image,
            },
          },
        })
        setSuccess(true)
      } catch (err: any) {
        setSuccess(false)
      }
    } else {
      setSuccess(false)
    }
  }, [window.ethereum, tokenToAdd, chainId])

  return { addToken, success }
}
