import { GALAXY_API_HOST } from '../constants/endpoints'
import fetch from 'node-fetch'

function getEndpoint(path) {
  return `${GALAXY_API_HOST}/api/v1/docusign${path}`
}

export async function getAgreementApply(chainId: number, stakingPoolAddress: string, walletAddress: string) {
  const url = `/agreement/apply?chainId=${chainId}&stakingPoolAddress=${stakingPoolAddress}&walletAddress=${walletAddress}`
  const res = await fetch(getEndpoint(url), {
    method: 'GET',
  })

  const data: any = await res.json()
  if (data && data.hasOwnProperty('isPass')) {
    return data.isPass
  }
  throw new Error(data.errors.message)
}

export async function postAgreementApply(chainId, stakingPoolAddress, redirectUrl, walletAddress) {
  const postData = {
    chainId,
    stakingPoolAddress,
    redirectUrl,
    walletAddress,
  }
  const res = await fetch(getEndpoint('/agreement/apply'), {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data: any = await res.json()
  if (data && data.hasOwnProperty('signUrl')) {
    return data.signUrl
  }
  throw new Error(data.errors.message)
}

export async function putAgreementApply(chainId, stakingPoolAddress, walletAddress) {
  const postData = {
    chainId,
    stakingPoolAddress,
    walletAddress,
  }
  const res = await fetch(getEndpoint('/agreement/apply'), {
    method: 'PUT',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data: any = await res.json()
  if (data && data.hasOwnProperty('message')) {
    return data.message
  }
  throw new Error(data.errors.message)
}