import { CSSProperties } from '@material-ui/core/styles/withStyles'
import ImageBUSD from '../../assets/symbol/BUSD.svg'
import ImageDAI from '../../assets/symbol/DAI.svg'
import ImageNBUSD from '../../assets/symbol/nBUSD.svg'
import ImageNUSD from '../../assets/symbol/nUSD.svg'


const IconMapping = {
  'BUSD': ImageBUSD,
  'DAI': ImageDAI,
  'nBUSD': ImageNBUSD,
  'nUSD': ImageNUSD
}

/**
 * use image name get Image
 * 
 * @returns <img>
 */
export const MapIcon = ({ name, cusStyled, cusClass }: {
  name: string,
  cusStyled?: CSSProperties,
  cusClass?: string
}) => {
  const image = IconMapping[name]

  if (image) {
    return <img style={cusStyled} className={cusClass} src={image} alt={name} />
  }
  return null
}

export default MapIcon
