import { Theme, ThemeProvider as MuiThemeProvider, useMediaQuery } from '@material-ui/core'
import { ThemeProvider } from 'styled-components'
import { ReactNode, useMemo } from 'react'
import theme from './theme'

export const MuiProvider = ({ children }: { children: ReactNode }) => {
  const isPC = useMediaQuery<Theme>('(min-width: 960px)')
  const usingTheme = useMemo(() => theme, [isPC])
  return <MuiThemeProvider theme={usingTheme}>{children}</MuiThemeProvider>
}

export const StyledProvider = ({ children }: { children: ReactNode }) => {
  const isPC = useMediaQuery<Theme>('(min-width: 960px)')
  const usingTheme = useMemo(() => theme, [isPC])
  return <ThemeProvider theme={usingTheme}>{children}</ThemeProvider>
}

export default MuiProvider
