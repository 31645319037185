import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { IAddressOrAddressMap, useBoostPoolContract } from './useContract'

export function useBoostPoolLockTimes(address?: IAddressOrAddressMap): [lockTimes: BigNumber[]] {
  const contract = useBoostPoolContract(address)
  const [lockTimeCount, setLockTimeCount] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (contract) {
      contract
        .getLockTimeWeightedListLength()
        .then(setLockTimeCount)
        .catch((error) => console.error(error))
    }
  }, [contract])

  const [lockTimes, setLockTimes] = useState<BigNumber[]>([])
  useEffect(() => {
    if (contract && lockTimeCount.toNumber() > 0) {
      const promises: Promise<any>[] = []
      for (let i = 0; i < lockTimeCount.toNumber(); i++) {
        promises.push(contract.getLockTimeWeightedByIndex(i))
      }

      Promise.all(promises).then((values) => {
        const times: any[] = []
        for (const value of values) {
          switch (value.lockTime.toNumber()) {
            case 3600:
              times.push(BigNumber.from(7776000))
              break
            case 7200:
              times.push(BigNumber.from(31104000))
              break
            case 14400:
              times.push(BigNumber.from(62208000))
              break
            case 28800:
              times.push(BigNumber.from(124416000))
              break
            default:
              times.push(value.lockTime)
              break
          }
        }
        setLockTimes(times)
      })
    }
  }, [contract, lockTimeCount])

  return useMemo(() => [lockTimes], [lockTimes])
}
