import { useMediaQuery, useTheme, createStyles, Theme, makeStyles, Typography, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { i18n } from '@lingui/core'

import {
  TitleDiv,
  TitleFront,
  TitleIconOne,
  TitleIconTwo,
  TitleTextDiv,
  TitleTextMobileHide,
  TitleTextMainDiv,
  Pool,
  BasicContainer,
  Basic,
  BasicText,
} from '../../components/pool/PoolBar'
import GradientBackground from '../../common/GradientBackground'
import ImageCoin from '../../assets/symbol/coin.png'

import { useRWAStkaingPoolsApr, useRWAProStakingPoolsApr } from '../../hooks/smartContracts/useStakingPoolsAprs'
import { displayBalance } from '../../utils/displayBalance'
import {
  useAlphaRWAStakingPoolsTvl,
  useBetaInsuranceStakingPoolTVL,
  useAlphaProRWAStakingPoolValue,
} from '../../hooks/smartContracts/useStakingPoolsTvl'
import { ExternalLink as IconExternal, ChevronDown, ChevronUp, ChevronRight } from 'react-feather'
import { ChainId } from '../../constants/blockchain'
import ADDRESS from '../../constants/addresses'
import { useEffect, useMemo, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
        marginBottom: 60,
      },
    },
    subRow: {
      marginTop: 8,
    },
  })
)

const InvestPoolBar = ({
  poolName,
  mobilePoolName,
  alphaRootAddress,
  poolId,
}: {
  poolName: string
  mobilePoolName: string
  alphaRootAddress: string
  poolId: number
}) => {
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const classes = useStyles()
  const history = useHistory()

  const { account, chainId } = useWeb3React()

  const aprs = useRWAProStakingPoolsApr(
    ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )

  const alphaPoolValue = useAlphaProRWAStakingPoolValue(chainId ?? ChainId.BSC, alphaRootAddress)

  const onClickNaosPool = (poolId: number = 0) => {
    history.push(`/galaxy-pro/rwa-${poolId}`)
  }

  return (
    <Pool style={{ marginTop: `13px` }}>
      <GradientBackground width="100%" height={isPC ? '80px' : '56px'}>
        <BasicContainer>
          <TitleDiv>
            <TitleFront style={{ cursor: 'pointer' }}>
              <Grid container onClick={() => onClickNaosPool(poolId)}>
                <TitleIconOne src={ImageCoin} />
                <TitleTextMainDiv>
                  <Typography variant="h2">{isPC ? i18n._(poolName) : i18n._(mobilePoolName)}</Typography>
                </TitleTextMainDiv>
                <TitleTextMobileHide>
                  <BasicText>
                    <Typography variant="h2">{`$ ${displayBalance(alphaPoolValue, '0,0')}`}</Typography>
                  </BasicText>
                </TitleTextMobileHide>
                <TitleTextDiv>
                  <BasicText>
                    <Typography variant="h2">{aprs[0].alpha[0].alpha.toFixed(2)}%</Typography>
                  </BasicText>
                </TitleTextDiv>
              </Grid>
              <TitleTextDiv style={{ visibility: 'hidden' }}>
                <BasicText style={{ width: isPC ? 50 : 20 }}></BasicText>
              </TitleTextDiv>
            </TitleFront>
          </TitleDiv>
        </BasicContainer>
      </GradientBackground>
    </Pool>
  )
}

export default InvestPoolBar
