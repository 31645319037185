import { BigNumber } from '@ethersproject/bignumber'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useBoostPoolContract, useGalaxyStakingPoolContract } from './useContract'
import { useMulticallContract } from '../smartContracts/useContract'
import ADDRESS from '../../constants/addresses'
import multicall from '../../utils/multicall'

export function useGalaxyPoolBoostRatio(
  galaxyAddress?: IAddressOrAddressMap,
  boostAddres?: IAddressOrAddressMap,
  staker?: string,
  poolId?: number
): [currentRatio: number, updatedRatio: number, maxBoostNeededAmount: BigNumber] {
  const galaxyContract = useGalaxyStakingPoolContract(galaxyAddress)
  const boostContract = useBoostPoolContract(boostAddres)
  const blockNumber = useBlockNumber()
  const multicallContract = useMulticallContract(ADDRESS.MULTICALL2, true)

  // Galaxy Pool
  const [depositWeight, setDepositWeight] = useState(BigNumber.from(0))
  const [depositAmount, setDepositAmount] = useState(BigNumber.from(0))
  const [galaxyTotalDeposited, setGalaxyTotalDeposited] = useState(BigNumber.from(0))

  // Boost Pool
  const [boostDeposited, setBoostDeposited] = useState(BigNumber.from(0))
  const [boostTotalDeposited, setBoostTotalDeposited] = useState(BigNumber.from(0))
  const [boostDepositedWeight, setBoostDepositedWeight] = useState(BigNumber.from(0))
  const [boostTotalDepositedWeight, setBoostTotalDepositedWeight] = useState(BigNumber.from(0))

  // Returns
  const [currentRatio, setCurrentRatio] = useState(1)
  const [updatedRatio, setUpdatedRatio] = useState(1)
  const [maxBoostNeededAmount, setMaxBoostNeededAmount] = useState(BigNumber.from(0))

  useEffect(() => {
    if (multicallContract && galaxyContract && boostContract && staker && poolId !== undefined) {
      const calls = [
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getStakeTotalDepositedWeight',
          value: [staker, poolId],
        },
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getStakeTotalDeposited',
          value: [staker, poolId],
        },
        {
          itf: galaxyContract.interface,
          address: galaxyContract.address as string,
          function: 'getPoolTotalDeposited',
          value: [poolId],
        },
        {
          itf: boostContract.interface,
          address: boostContract.address as string,
          function: 'getStakeTotalDeposited',
          value: [staker],
        },
        {
          itf: boostContract.interface,
          address: boostContract.address as string,
          function: 'getPoolTotalDeposited',
          value: [],
        },
        {
          itf: boostContract.interface,
          address: boostContract.address as string,
          function: 'getStakeTotalDepositedWeight',
          value: [staker],
        },
        {
          itf: boostContract.interface,
          address: boostContract.address as string,
          function: 'getPoolTotalDepositedWeight',
          value: [],
        },
      ]

      multicall(multicallContract, calls)
        .then((res) => {
          setDepositWeight(res[0][0])
          setDepositAmount(res[1][0])
          setGalaxyTotalDeposited(res[2][0])
          setBoostDeposited(res[3][0])
          setBoostTotalDeposited(res[4][0])
          setBoostDepositedWeight(res[5][0])
          setBoostTotalDepositedWeight(res[6][0])
        })
        .catch((err) => {
          console.error(err)
        })
    }

  }, [galaxyContract, boostContract, staker])


  useEffect(() => {
    const weight = +depositWeight.toString()
    const amount = +depositAmount.toString()
    setCurrentRatio(amount > 0 ? +((weight / amount) * 2.5).toFixed(2) : 1)
  }, [depositWeight, depositAmount])

  useEffect(() => {
    let weightBN = depositAmount.mul(40).div(100)
    if (boostTotalDepositedWeight.gt(0)) {
      weightBN = weightBN.add(
        galaxyTotalDeposited.mul(boostDepositedWeight).div(boostTotalDepositedWeight).mul(60).div(100)
      )
      if (weightBN.gt(depositAmount)) {
        weightBN = depositAmount
      }
    }

    const weight = +weightBN.toString()
    const amount = +depositAmount.toString()
    setUpdatedRatio(amount > 0 ? +((weight / amount) * 2.5).toFixed(2) : 1)
  }, [depositAmount, galaxyTotalDeposited, boostDepositedWeight, boostTotalDepositedWeight])

  useEffect(() => {
    if (galaxyTotalDeposited.gt(0)) {
      // calc in 4 decimals
      const k = depositAmount.mul(1000).div(galaxyTotalDeposited)
      if (k.eq(1000)) setMaxBoostNeededAmount(BigNumber.from(0))
      else {
        const r = k
          .mul(boostTotalDeposited)
          .div(1000)
          .sub(boostDeposited)
          .mul(1000)
          .div(BigNumber.from(1000 - k.toNumber()))
        setMaxBoostNeededAmount(r.gt(0) ? r : BigNumber.from(0))
      }
    }
  }, [boostDeposited, boostTotalDeposited, galaxyTotalDeposited, depositAmount])

  return useMemo(
    () => [currentRatio, updatedRatio, maxBoostNeededAmount],
    [currentRatio, updatedRatio, maxBoostNeededAmount]
  )
}
