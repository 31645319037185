import { PoolNames } from "./investConstants"
import { PoolNames as PoolNamesPro } from "./investConstantsPro"
import { t } from "@lingui/macro"

const BreadcrumbMapping = {
  '/formation': t`Formation`,
  '/home': t`Home`,
  '/transmuter': t`Transmuter`,
  '/farm': t`Farm`,
  '/farm/naos-eth-slp': t`NAOS/ETH SLP Pool`,
  '/farm/naos': t`NAOS Pool`,
  '/farm/naos-eth-lp': t`NAOS/ETH LP Pool`,
  '/farm/nusd-3crv': t`nUSD/3CRV Pool`,
  '/farm/naos-bnb-lp': t`NAOS/BNB LP Pool`,
  '/farm/boost': t`NAOS Boost Pool`,
  '/farm/beta': t`BETA Staking Pool`,
  '/galaxy': t`Galaxy`,
  '/galaxy/rwa-0': PoolNames[0],
  '/galaxy/rwa-0/kyc': t`KYC`,
  '/galaxy/rwa-1': PoolNames[1],
  '/galaxy/rwa-1/kyc': t`KYC`,
  '/galaxy-pro': t`Galaxy Pro`,
  '/galaxy-pro/rwa-0': PoolNamesPro[0],
  '/galaxy-pro/rwa-0/kyc': t`KYC`,
  '/galaxy-pro/rwa-1': PoolNamesPro[1],
  '/galaxy-pro/rwa-1/kyc': t`KYC`,
  '/insurance': t`Insurance`,
  '/insurance/beta-0': t`BETA Insurance Pool`,
  '/insurance/beta-1': t`BETA Insurance Pool`,
  '/faucet': t`NAOS Faucet`,
}
export default BreadcrumbMapping
