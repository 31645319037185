import { ChainId } from '../constants/blockchain'

const BrowserUrlMap = {
  [ChainId.MAINNET]: 'https://etherscan.io',
  [ChainId.ROPSTEN]: 'https://ropsten.etherscan.io',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io',
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.BSC_TEST]: 'https://testnet.bscscan.com/',
}

export default function getExplorerLink(chainId, data, type: 'transaction' | 'address' | 'block'): string {
  if (chainId) {
    switch (type) {
      case 'transaction': {
        return `${BrowserUrlMap[chainId]}/tx/${data}`
      }
      case 'address': {
        return `${BrowserUrlMap[chainId]}/address/${data}`
      }
      case 'block': {
        return `${BrowserUrlMap[chainId]}/block/${data}`
      }
    }
  } else {
    return ''
  }
}
