import { BigNumber, ethers } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useFormationContract } from './useContract'
import { useMulticallContract } from '../smartContracts/useContract'
import multicall from '../../utils/multicall'
import { ADDRESS } from '../../constants/addresses'

export function useFormationInfo(
  address?: IAddressOrAddressMap,
  account?: string
): [collateralDai: BigNumber, borrowedNusd: BigNumber, availableToBorrow: BigNumber, collateralRatio: string] {
  const contract = useFormationContract(address, true)
  const blockNumber = useBlockNumber()
  const [collateralDai, setCollateralDai] = useState<BigNumber>(BigNumber.from(0))
  const [borrowedNusd, setBorrowedNusd] = useState<BigNumber>(BigNumber.from(0))
  const [availableToBorrow, setAvailableToBorrow] = useState<BigNumber>(BigNumber.from(0))
  const [collateralRatio, setCollateralRatio] = useState<string>('0')
  const multicallContract = useMulticallContract(ADDRESS.MULTICALL2, true)

  useEffect(() => {
    if (contract && account) {
      const calls = [{
        itf: contract.interface,
        address: contract.address as string,
        function: 'getCdpTotalDeposited',
        value: [account],
      }, {
        itf: contract.interface,
        address: contract.address as string,
        function: 'getCdpTotalDebt',
        value: [account],
      }, {
        itf: contract.interface,
        address: contract.address as string,
        function: 'getCdpTotalCredit',
        value: [account],
      }, {
        itf: contract.interface,
        address: contract.address as string,
        function: 'collateralizationLimit',
        value: [],
      }]
      if (multicallContract) {
        multicall(multicallContract, calls)
          .then((res) => {
            setCollateralDai(res[0][0])
            setBorrowedNusd(res[1][0])
            setAvailableToBorrow(res[2][0])
            setCollateralRatio(ethers.utils.formatEther(res[3][0][0]))
          })
          .catch(() => {})
      }
    }
  }, [contract, account, blockNumber])

  return useMemo(
    () => [collateralDai, borrowedNusd, availableToBorrow, collateralRatio],
    [collateralDai, borrowedNusd, availableToBorrow, collateralRatio]
  )
}
