import { ChainId } from './blockchain'

const ethereumTokens: object = {
  FORMATION: {
    DEPOSIT: 'DAI',
    BORROW: 'nUSD',
    WITHDRAW: 'DAI',
    LIQUIDATE: 'nUSD',
    REPAY: '',
  },
  TRANSMUTER: {
    EXIT: '',
    STAKE: 'nUSD',
    TRANSMUTE: '',
    UNSTAKE: 'nUSD',
    CLAIM: '',
  },
  'ALPHA POOL': {
    DEPOSIT: 'DAI',
    REDEEM: '',
    WITHDRAW: 'DAI',
    CLAIM: '',
    ACTIVEBOOST: '',
  },
  'ALPHA POOL PRO': {
    DEPOSIT: 'DAI',
    REDEEM: 'ALPHA',
    DISBUSE: '',
  },
  'BETA POOL': {
    DEPOSIT: 'nUSD',
    WITHDRAW: '',
    BOOST: '',
  },
}

const bscTokens: object = {
  FORMATION: {
    DEPOSIT: 'BUSD',
    BORROW: 'nBUSD',
    WITHDRAW: 'BUSD',
    LIQUIDATE: 'nBUSD',
    REPAY: '',
  },
  TRANSMUTER: {
    EXIT: '',
    STAKE: 'nBUSD',
    TRANSMUTE: '',
    UNSTAKE: 'nBUSD',
  },
  'ALPHA POOL': {
    DEPOSIT: 'BUSD',
    REDEEM: '',
    WITHDRAW: 'BUSD',
    CLAIM: '',
    ACTIVEBOOST: '',
  },
  'ALPHA POOL PRO': {
    DEPOSIT: 'BUSD',
    REDEEM: 'ALPHA',
    DISBUSE: '',
  },
  'BETA POOL': {
    DEPOSIT: 'nBUSD',
    WITHDRAW: '',
    BOOST: '',
  },
}

const NAOS: object = {
  'STAKING POOL': {
    CLAIM: '',
    STAKE: '',
    EXIT: 'NAOS',
    UNSTAKE: '',
  },
  'BOOST POOL': {
    CLAIM: '',
    'CLAIM IMMEDIATE': '',
    COOLDOWN: '',
    STAKE: 'NAOS',
    UNSTAKE: '',
  },
  'BETA STAKING POOL': {
    CLAIM: '',
    STAKE: 'Beta',
    EXIT: 'NAOS',
    UNSTAKE: 'Beta',
  },
}

export const TokenList: object = {
  FORMATION: {
    [ChainId.MAINNET]: ethereumTokens['FORMATION'],
    [ChainId.BSC]: bscTokens['FORMATION'],
    [ChainId.RINKEBY]: ethereumTokens['FORMATION'],
  },
  TRANSMUTER: {
    [ChainId.MAINNET]: ethereumTokens['TRANSMUTER'],
    [ChainId.BSC]: bscTokens['TRANSMUTER'],
    [ChainId.RINKEBY]: ethereumTokens['TRANSMUTER'],
  },
  'STAKING POOL': {
    [ChainId.MAINNET]: NAOS['STAKING POOL'],
    [ChainId.BSC]: NAOS['STAKING POOL'],
    [ChainId.RINKEBY]: NAOS['STAKING POOL'],
  },
  'BOOST POOL': {
    [ChainId.MAINNET]: NAOS['BOOST POOL'],
    [ChainId.BSC]: NAOS['BOOST POOL'],
    [ChainId.RINKEBY]: NAOS['BOOST POOL'],
  },
  'BETA STAKING POOL': {
    [ChainId.MAINNET]: NAOS['BETA STAKING POOL'],
    [ChainId.BSC]: NAOS['BETA STAKING POOL'],
    [ChainId.RINKEBY]: NAOS['BETA STAKING POOL'],
  },
  'ALPHA POOL': {
    [ChainId.MAINNET]: ethereumTokens['ALPHA POOL'],
    [ChainId.BSC]: bscTokens['ALPHA POOL'],
    [ChainId.RINKEBY]: ethereumTokens['ALPHA POOL'],
  },
  'ALPHA POOL PRO': {
    [ChainId.MAINNET]: ethereumTokens['ALPHA POOL PRO'],
    [ChainId.BSC]: bscTokens['ALPHA POOL PRO'],
    [ChainId.RINKEBY]: bscTokens['ALPHA POOL PRO'],
  },
  'BETA POOL': {
    [ChainId.MAINNET]: ethereumTokens['BETA POOL'],
    [ChainId.BSC]: bscTokens['BETA POOL'],
    [ChainId.RINKEBY]: ethereumTokens['BETA POOL'],
  },
}

export default function getTokenName(contractName: string, chainId: number, method: string): string {
  return TokenList[contractName][chainId][method]
}
