import { useCallback, useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useBoostPoolContract } from './useContract'
import useSecondPerBlock from '../useSecondPerBlock'

export function useStakeBoostAPR(
  address: IAddressOrAddressMap,
  staker?: string
): [number, (amount: BigNumber, weight: number) => number] {
  const boostPoolContract = useBoostPoolContract(address)
  const blockNumber = useBlockNumber()
  const secondPerBlock = useSecondPerBlock()

  const [depositAmount, setDepositAmount] = useState(BigNumber.from(0))
  const [depositTotalWeight, setDepositTotalWeight] = useState(BigNumber.from(0))
  const [depositWeight, setDepositWeight] = useState(BigNumber.from(0))
  const [rewardRate, setrewardRate] = useState(BigNumber.from(0))
  useEffect(() => {
    if (boostPoolContract && staker) {
      boostPoolContract.getStakeTotalDeposited(staker).then(setDepositAmount)
      boostPoolContract.getPoolTotalDepositedWeight().then(setDepositTotalWeight)
      boostPoolContract.getStakeTotalDepositedWeight(staker).then(setDepositWeight)
      boostPoolContract.rewardRate().then(setrewardRate)
    }
  }, [boostPoolContract, blockNumber, staker])

  const currentAPR = useMemo(() => {
    if (depositTotalWeight.gt(0) && depositAmount.gt(0)) {
      const userWeight = +formatEther(depositWeight)
      const totalWeight = +formatEther(depositTotalWeight)
      const yearPoolRewardRate = (+formatEther(rewardRate) * 86400 * 365) / secondPerBlock
      const totalDepositAmount = +formatEther(depositAmount)
      return (userWeight * yearPoolRewardRate * 100) / totalWeight / totalDepositAmount
    }
    return 0
  }, [depositWeight, depositTotalWeight, rewardRate, secondPerBlock, depositAmount])

  const calcEstimatedAPR = useCallback(
    (amount: BigNumber, weight: number) => {
      if (amount.gt(0) || (depositTotalWeight.gt(0) && depositAmount.gt(0))) {
        const userWeight = +formatEther(depositWeight)
        const appendWeight = +formatEther(amount) * weight
        const totalWeight = +formatEther(depositTotalWeight)
        const yearPoolRewardRate = (+formatEther(rewardRate) * 86400 * 365) / secondPerBlock
        const totalDepositAmount = +formatEther(depositAmount.add(amount))

        return (
          ((userWeight + appendWeight) * yearPoolRewardRate * 100) / ((totalWeight + appendWeight) * totalDepositAmount)
        )
      }
      return 0
    },
    [depositWeight, depositTotalWeight, rewardRate, secondPerBlock, depositAmount]
  )

  return useMemo(() => [currentAPR, calcEstimatedAPR], [currentAPR, calcEstimatedAPR])
}
