const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.']

export function displayDate(date: Date) {
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export function displayDateAndTime(date: Date) {
  return `${months[date.getMonth()]}-${date.getDate()}-${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}

export function displayRemainingDate(endDate: Date | number, startDate: Date = new Date()) {
  const end = endDate instanceof Date ? endDate.getTime() : endDate
  let diff = Math.floor((end - startDate.getTime()) / 1000)
  const day = Math.floor(diff / 60 / 60 / 24)
  diff -= day * 60 * 60 * 24
  const hour = Math.floor(diff / 60 / 60)
  diff -= hour * 60 * 60
  const min = Math.floor(diff / 60)

  return `${day} D ${hour} H ${min} M`
}
