const defaultPrice = {
  ETH: 4000.0,
  NAOS: 1.0,
  SUSHI: 10,
}

const naosGeckoUrl =
  'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496&vs_currencies=usd'

const ethGeckoUrl =
  'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&vs_currencies=usd'

const sushiGeckoUrl =
  'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x6b3595068778dd592e39a122f4f5a5cf09c90fe2&vs_currencies=usd'

const urlMapping = {
  ETH: ethGeckoUrl,
  NAOS: naosGeckoUrl,
  SUSHI: sushiGeckoUrl,
}

export const getPrice = async (url, tokenName) => {
  try {
    const res = await fetch(url)
    const resJson = await res.json()
    const retPrice = resJson[Object.keys(resJson)[0]].usd
    defaultPrice[tokenName] = retPrice

    return resJson[Object.keys(resJson)[0]].usd
  } catch (e) {
    return defaultPrice[tokenName]
  }
}

export const getTokenPrice = async (token) => {
  return await getPrice(urlMapping[token], token)
}

export const getETHPrice = async () => {
  return await getPrice(ethGeckoUrl, 'ETH')
}

export const getNAOSPrice = async () => {
  return await getPrice(naosGeckoUrl, 'NAOS')
}

export const getSUSHIPrice = async () => {
  return await getPrice(sushiGeckoUrl, 'SUSHI')
}

export default getTokenPrice
