import { ChangeEvent, useMemo, useState, useRef, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  useMediaQuery,
  useTheme,
  createStyles,
  Theme,
  makeStyles,
  Typography,
  TextField,
  Tooltip,
  Grid,
  Divider,
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import {
  TitleWrapper,
  ContentWrapper,
  SelectionWrapper,
  ResultWrapper,
  OutcomePanel,
  BasicWrapper,
  BasicLeftContent,
  BasicFlexContent,
  BasicBottomRight,
} from './styled'
import { X as Close, ExternalLink as IconExternalLink } from 'react-feather'
import { Trans } from '@lingui/macro'
import GradientCard from '../../../../../common/GradientCard'
import GradientBackground from '../../../../../common/GradientBackground'
import ExternalLink from '../../../../../components/commons/ExternalLink'
import { urls } from '../../../../../constants/urls'
import { ChainId } from '../../../../../constants/blockchain'
import { BigNumber } from 'ethers'
import ADDRESS from '../../../../../constants/addresses'
import { useAlphaStakeTotalDeposited } from '../../../../../hooks/smartContracts/useAlphaStakeTotalDeposited'
import { displayBalance } from '../../../../../utils/displayBalance'
import { useTokenBalance } from '../../../../../hooks/smartContracts/useTokenBalance'
import TestableAmountInput, { IAmountInputRefObj } from '../../../../../common/TestableAmountInput'
import { useReBoostRatio } from '../../../../../hooks/smartContracts/useReBoostRatio'
import { useCalcMaxRatioAmount } from '../../../../../hooks/smartContracts/useCalcMaxRatioAmount'
import { useGetConstData } from '../../../../../hooks/smartContracts/useRebalancedAlphaRewards'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { useBoostPoolLockTimes } from '../../../../../hooks/smartContracts/useBoostPoolLockTimes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: '688px',
      height: '700px',
      overflowX: 'hidden',
    },
    paperMobile: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: '90%',
      overflow: 'hidden',
    },
    dividerHorizental: {
      width: '100%',
      height: '1px',
      margin: '0px auto',
      marginBottom: '24px',
      backgroundColor: theme.palette.text.primary,
      opacity: '12%',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    radioRoot: {
      padding: '0px',
      marginRight: '8px',
    },
    ddl: {
      width: '100%',
      marginTop: 12,

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
    },
    select: {
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 14,
      color: 'rgba(255, 255, 255, 0.6)',
      padding: '0px 14px',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    menuItem: {
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    StepperBoxRoot: {
      width: '177px',
      height: '24px',
      border: 'none',
    },
    StepperRoot: {
      width: '177px',
      height: '48px',
      border: 'none',
      // margin: '0px 0px 24px 0px',
      padding: '0px 0px 24px 0px',
      backgroundColor: 'transparent',
      '& .MuiStepLabel-label .MuiStepLabel-alternativeLabel': {
        marginTop: '4px',
      },
    },
    textField: {
      width: '100%',

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.3)',
      },

      '& .MuiInputLabel-root, fieldset': {
        color: 'rgba(255, 255, 255, 0.3)',
        fontSize: '12px',
        letterSpacing: '0.4px',
        '&.Mui-focused': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
    },
    resize: {
      fontSize: '16px',
    },
  })
)

const BoostCalculator = ({ open, onClose }: { open: boolean; onClose: any }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const { account, chainId } = useWeb3React()
  const URL = useMemo(() => (chainId ? urls[chainId] : urls[ChainId.MAINNET]), [chainId])

  const [poolIdx, setPoolIdx] = useState(0)
  const poolNames = ['Corl']
  //   const poolNames = ['Corl', 'Modalku', 'AdvanceAI']

  const [alphaRootAddress, setAlphaRootAddress] = useState(
    Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC])[poolIdx]
  )

  useEffect(() => {
    if (chainId !== undefined) {
      let rootAddress = Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC])[poolIdx]
      if (rootAddress !== undefined) {
        setAlphaRootAddress(rootAddress)
      }
    }
  }, [chainId, poolIdx])

  const constData = useGetConstData(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    ADDRESS.BOOST_POOL,
    account ?? undefined
  )

  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))

  const amountInput = useRef<IAmountInputRefObj>(null)
  const [step, setStep] = useState<number>(0)
  const [calculateMode, setCalculateMode] = useState<boolean[]>([true, false])
  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  const alphaDepositAmount: BigNumber = useAlphaStakeTotalDeposited(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined,
    0
  )

  const [naosBalance] = useTokenBalance(ADDRESS?.NAOS_TOKEN, account ?? undefined)
  const [lockTimes] = useBoostPoolLockTimes(ADDRESS.BOOST_POOL)

  const [lockIndex, setLockIndex] = useState(0)
  const lockTimeWeight = (lockIndex: number) => {
    switch (lockIndex) {
      case 1:
        return 4
      case 2:
        return 10
      case 3:
        return 25
      default:
        return 1
    }
  }

  const lockTimeLabel = ['3 months', '1 years', '2 years', '4 years']

  const newUserBoostWeight = useMemo(() => {
    return inputBignumber.mul(lockTimeWeight(lockIndex))
  }, [inputBignumber, lockIndex])

  const [inputStableCoinBignumber, setInputStableCoinBignumber] = useState(constData.bigNumber_userDeposited)
  const [displayAmount, setDisplayAmount] = useState(formatEther(constData.bigNumber_userDeposited))

  useEffect(() => {
    setInputStableCoinBignumber(constData.bigNumber_userDeposited)
    setDisplayAmount(formatEther(constData.bigNumber_userDeposited))
  }, [constData.bigNumber_userDeposited])

  const [reBoostRatio, reBoostAPR] = useReBoostRatio(
    alphaDepositAmount,
    newUserBoostWeight,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    chainId ?? ChainId.BSC,
    constData,
    +formatEther(inputStableCoinBignumber.sub(constData.bigNumber_userDeposited)),
    true
  )

  const [reMaxBoostAPR, maxRatioNAOS] = useCalcMaxRatioAmount(
    alphaDepositAmount,
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    chainId ?? ChainId.BSC,
    constData,
    true,
    +formatEther(inputStableCoinBignumber.sub(constData.bigNumber_userDeposited)),
    true
  )

  function handlePool(e: ChangeEvent<{ value: unknown }>) {
    setPoolIdx(e.target.value as number)
  }

  function handleDDL(e: ChangeEvent<{ value: unknown }>) {
    setLockIndex(e.target.value as number)
  }

  function handleModeChange(triggerIndex: number) {
    if (triggerIndex === 0) {
      setCalculateMode([true, false])
    } else if (triggerIndex === 1) {
      setCalculateMode([false, true])
    }

    setLockIndex(0)
    setInputBignumber(BigNumber.from(0))
  }

  const handleTextFeildChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const n = event.target.value
    if (n === '' || n === null || isNaN(+n)) {
      setDisplayAmount('')
      setInputStableCoinBignumber(BigNumber.from(0))
    } else {
      if (/^.*$11.*$/.test(n) && n.split('.')[1].length > 18) return
      if (+n < 0) return

      setDisplayAmount(n)
      setInputStableCoinBignumber(n.trim() === '' ? BigNumber.from(0) : parseEther(n))
    }
  }

  return (
    <>
      <Dialog
        classes={{
          paper: isPC ? classes.paper : classes.paperMobile,
        }}
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <DialogTitle disableTypography>
          <TitleWrapper>
            <Typography variant="h3">
              <Trans>NAOS Reward Boost Calculator</Trans>
            </Typography>
            <Close color="#ffffff" width={24} onClick={onClose} style={{ cursor: 'pointer' }} />
          </TitleWrapper>
        </DialogTitle>
        <DialogContent>
          <Divider orientation="horizontal" className={classes.dividerHorizental} />
          <ContentWrapper mobileHeight={step === 1 ? '466px' : ''}>
            <SelectionWrapper>
              <Typography variant="subtitle1">
                <Trans>Select a RWA pool</Trans>
              </Typography>
              <FormControl
                variant="outlined"
                classes={{
                  root: classes.ddl,
                }}
              >
                <Select
                  value={poolIdx}
                  onChange={handlePool}
                  labelWidth={0}
                  classes={{ root: classes.select, icon: classes.icon }}
                >
                  {poolNames.map((poolName, idx) => {
                    return (
                      <MenuItem value={idx} classes={{ root: classes.menuItem }}>
                        <Typography variant="h3">{poolName}</Typography>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <BasicWrapper>
                <BasicFlexContent>
                  <Typography variant="subtitle1">
                    <Trans>Total Deposited</Trans>
                  </Typography>
                  <Typography variant="subtitle1">
                    {displayBalance(alphaDepositAmount)} {collateralTokenName}
                  </Typography>
                </BasicFlexContent>
                <Typography variant="subtitle2" style={{ marginTop: '8px', marginBottom: '12px' }}>
                  <Trans>Deposit BUSD in ALPHA</Trans>
                </Typography>
                <TextField
                  id="input"
                  label={collateralTokenName}
                  value={displayAmount}
                  onChange={handleTextFeildChange}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  classes={{
                    root: classes.textField,
                  }}
                />
              </BasicWrapper>

              <BasicWrapper>
                <BasicFlexContent>
                  <Typography variant="subtitle1">
                    <Trans>NAOS Token</Trans>
                  </Typography>
                  <Typography variant="subtitle1">{displayBalance(naosBalance)} NAOS</Typography>
                </BasicFlexContent>
                <BasicLeftContent>
                  <Radio
                    classes={{
                      root: classes.radioRoot,
                    }}
                    checked={calculateMode[0]}
                    onChange={() => {
                      handleModeChange(0)
                    }}
                    value="a"
                    //   name="radio-button-demo"
                    //   inputProps={{ 'aria-label': 'A' }}
                  />
                  <Typography variant="h5">
                    <Trans>Lock period</Trans>
                  </Typography>
                </BasicLeftContent>
                <FormControl
                  variant="outlined"
                  classes={{
                    root: classes.ddl,
                  }}
                  disabled={!calculateMode[0]}
                >
                  <Select
                    value={lockIndex}
                    onChange={handleDDL}
                    labelWidth={0}
                    classes={{ root: classes.select, icon: classes.icon }}
                  >
                    {lockTimes.map((time, idx) => {
                      const lockDays = time.toNumber() / 60 / 60 / 24
                      return (
                        <MenuItem value={idx} classes={{ root: classes.menuItem }}>
                          <Typography
                            variant="h3"
                            style={{ color: calculateMode[0] ? '#ffffff' : 'rgba(255, 255, 255, 0.2)' }}
                          >
                            {lockDays === 90 ? `${lockDays / 30} month` : `${lockDays / 360} years`}
                          </Typography>
                          <Typography
                            variant="h3"
                            style={{ color: calculateMode[0] ? '#ffffff' : 'rgba(255, 255, 255, 0.2)' }}
                          >
                            {lockTimeWeight(idx)} x
                          </Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <Typography
                  variant="h5"
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                    color: calculateMode[0] ? '#ffffff' : 'rgba(255, 255, 255, 0.2)',
                  }}
                >
                  <Trans>Stake Amount</Trans>
                </Typography>
                <TestableAmountInput
                  ref={amountInput}
                  maxNum={naosBalance}
                  amount={inputBignumber}
                  setAmount={setInputBignumber}
                  textFieldLabel={'NAOS'}
                  disabled={!calculateMode[0]}
                />
                <BasicLeftContent style={{ marginTop: '8px' }}>
                  <Radio
                    classes={{
                      root: classes.radioRoot,
                    }}
                    checked={calculateMode[1]}
                    onChange={() => {
                      handleModeChange(1)
                    }}
                    value="a"
                    //   name="radio-button-demo"
                    //   inputProps={{ 'aria-label': 'A' }}
                  />
                  <Typography variant="h5">
                    <Trans>2.5x ratio</Trans>
                  </Typography>
                </BasicLeftContent>
              </BasicWrapper>
            </SelectionWrapper>
            <ResultWrapper>
              <Typography variant="h3" style={{ marginBottom: '16px' }}>
                <Trans>Calculation Outcome</Trans>
              </Typography>
              <GradientCard width={isPC ? '280px' : '260px'} height="524px">
                <OutcomePanel>
                  <GradientBackground width="100%" height="48px">
                    <ExternalLink href={URL?.UNI_GET_NAOS}>
                      <BasicLeftContent style={{ marginTop: 0, padding: '0 16px' }}>
                        <Typography variant="subtitle1">
                          <Trans>Buy NAOS tokens</Trans>
                        </Typography>
                        <IconExternalLink color="#ffffff" width={20} style={{ marginLeft: 8 }} />
                      </BasicLeftContent>
                    </ExternalLink>
                    <BasicBottomRight style={{ width: '20%' }} />
                  </GradientBackground>
                  {calculateMode[0] && (
                    <>
                      <BasicFlexContent style={{ marginTop: '8px', marginBottom: '8px' }}>
                        <Typography variant="subtitle1">
                          <Trans>APR</Trans>
                        </Typography>
                        <Typography variant="subtitle1">
                          {inputStableCoinBignumber.gt(0) ? `${reBoostAPR.toFixed(2)} %` : '0.00 %'}
                        </Typography>
                      </BasicFlexContent>
                      <BasicFlexContent>
                        <Typography variant="subtitle1">
                          <Trans>Boost ratio</Trans>
                        </Typography>
                        <Typography variant="subtitle1">
                          {inputStableCoinBignumber.gt(0) ? `${displayBalance(reBoostRatio)}x` : '1.00x'}
                        </Typography>
                      </BasicFlexContent>
                    </>
                  )}
                  {calculateMode[1] && (
                    <>
                      <BasicFlexContent style={{ marginTop: '8px', marginBottom: '8px' }}>
                        <Typography variant="subtitle1">
                          <Trans>APR</Trans>
                        </Typography>
                        <Typography variant="subtitle1">
                          {inputStableCoinBignumber.gt(0) ? `${reMaxBoostAPR.toFixed(2)} %` : '0.00 %'}
                        </Typography>
                      </BasicFlexContent>
                      <BasicFlexContent style={{ marginBottom: '8px' }}>
                        <Typography variant="subtitle1">
                          <Trans>Boost ratio</Trans>
                        </Typography>
                        <Typography variant="subtitle1">
                          {inputStableCoinBignumber.gt(0) ? '2.50x' : '1.00x'}
                        </Typography>
                      </BasicFlexContent>
                      {lockTimeLabel.map((label, idx) => (
                        <BasicFlexContent style={{ marginBottom: '8px' }}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: maxRatioNAOS[idx].eq(0)
                                ? 'rgba(255, 255, 255, 0.2)'
                                : naosBalance.lt(maxRatioNAOS[idx])
                                ? 'rgba(255, 255, 255, 0.2)'
                                : '#ffffff',
                            }}
                          >
                            {label}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: maxRatioNAOS[idx].eq(0)
                                ? 'rgba(255, 255, 255, 0.2)'
                                : naosBalance.lt(maxRatioNAOS[idx])
                                ? 'rgba(255, 255, 255, 0.2)'
                                : '#ffffff',
                            }}
                          >{`Need ${displayBalance(maxRatioNAOS[idx], '0.0a', true)} NAOS`}</Typography>
                        </BasicFlexContent>
                      ))}
                    </>
                  )}
                </OutcomePanel>
              </GradientCard>
            </ResultWrapper>
          </ContentWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BoostCalculator
