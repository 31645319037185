import React, { useState, useMemo, FC, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Tabs,
  Tab,
  Tooltip,
  Backdrop,
  useMediaQuery,
  useTheme,
  createStyles,
  Theme,
  makeStyles,
  Typography,
  TextField,
  Grid,
  Divider,
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel as MuiStepLabel,
  StepContent,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { useResizeDetector } from 'react-resize-detector'
import clsx from 'clsx'
import { ChevronRight, CheckCircle, Circle, ExternalLink as IconExternalLink } from 'react-feather'
import { useParams } from 'react-router-dom'
import { formatEther, parseUnits } from '@ethersproject/units'
import styled from 'styled-components'
import { Trans, t } from '@lingui/macro'

import { displayBalance, displayBalanceUnits } from '../../utils/displayBalance'
import { useRWAStkaingPoolsApr, useRWAProStakingPoolsApr } from '../../hooks/smartContracts/useStakingPoolsAprs'
import {
  useAlphaRWAStakingPoolsTvl,
  useBetaInsuranceStakingPoolTVL,
  useAlphaProRWAStakingPoolValue,
} from '../../hooks/smartContracts/useStakingPoolsTvl'
import { useOpenModal } from '../../redux/application/hooks'
import { ApplicationModal } from '../../redux/application/actions'
import {
  USED_CHAIN_ID,
  SECURITIZE_API_URL,
  SECURITIZE_CLIENT_KEY,
  SECURITIZE_CLIENT_ID,
  GOOGLE_CLIENT_ID,
} from '../../constants/env'
import { useNFTProListStats, usePoolProValue } from '../../hooks/useGalaxyBackendAPI'
import {
  useAlphaTokenPrice,
  useEpochReserveRatio,
  useFundingReserve,
  useGalaxyPoolRewardRate,
  useGalaxyPoolTotalSupplyCurrency,
  useGalaxyWhitelisted,
  useMemberlisted,
  useGalaxyPendingRewards,
} from '../../hooks/smartContracts/useGalaxy'

import ActionBoard from '../../common/ActionBoard'
import GradientCard from '../../common/GradientCard'
import GradientBackground from '../../common/GradientBackground'
import LineData, { LineDataType, CustomLineData } from '../../common/LineData'
import {
  TitleDiv,
  TitleFront,
  TitleIcon,
  TitleTextDiv,
  TitleBack,
  AssetOriginatorInfo,
  AssetsInfoFlex,
  Basic,
  BasicContainer,
  BasicText,
  BasicTextFlex,
  BasicBottom,
  BasicBottomLeft,
  BasicTextSpaceBetween,
  BasicBottomRight,
  BasicBottomRightIcon,
  Button,
  MiniButton,
  ButtonFlex,
  InformationSpace,
  Pool,
  PoolFlex,
  PoolFlexDivider,
  PoolFlexAsset,
  LineDataContainer,
  TitleBackContainer,
  UnderLineButton,
  PersonalStatus,
  PersonalStatusInfo,
  PersonalAssets,
  PersonalAssetsInfo,
} from '../InvestPro/styled'
import LiquidityDetail from '../Invest/LiquidityDetail'
import EpochDetail from '../Invest/EpochDetail'
import AssetDetail from '../Invest/AssetDetail'
import Assets from '../Invest/Assets'
import { DrawerWidth } from '../../components/Navbar'
import ImageCoin from '../../assets/symbol/coin.png'
import ImgDefition from '../../assets/symbol/definition.png'
import InformationCard from '../../common/InformationCard'
import InvestDetail from '../InvestPro/InvestDetail'
import { useWeb3React } from '@web3-react/core'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import ADDRESS from '../../constants/addresses'
// import AmountInput, { IAmountInputRefObj } from '../../common/AmountInput'
import TestableAmountInput, { IAmountInputRefObj } from '../../common/TestableAmountInput'
import { BigNumber } from '@ethersproject/bignumber'
import { MainButton } from '../../common/buttons'
import {
  useGalaxyStakingPoolContract,
  useStakingPoolContract,
  useTrancheContract,
  useOperatorContract,
} from '../../hooks/smartContracts/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/smartContracts/useApproveCallback'
import { useBlockNumber, useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import RedeemDrawerContent from '../InvestPro/RedeemDrawerContent'
import { useGalaxyPoolBoostRatio } from '../../hooks/smartContracts/useGalaxyPoolBoostRatio'
import {
  getInvestmentInfo,
  setWhitelist,
  getKYCInvestorInfo,
  postSecuritizeKYCInfo,
  postSumsubKYCInfo,
  getKYCType,
} from '../../utils/getGalaxyInfo'
import { postAgreementApply, getAgreementApply, putAgreementApply } from '../../utils/getDocusignInfo'
import { getRegisteredInfo, postRegisteredInfo, statusCode } from '../../utils/getRegisteredAddressInfo'
import { useTokenTotalSupply } from '../../hooks/smartContracts/useTokenTotalSupply'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'
import BetaDrawer from '../Invest/BetaDrawer'
import useAddTokenToWallet from '../../hooks/useAddTokenToWallet'
import { TOKEN } from '../../constants/tokens'
import { injected } from '../../utils/connectors'
import useStakingPoolId from '../../hooks/useStakingPoolId'
import { useSecondPerBlock } from '../../hooks/useSecondPerBlock'
import { useStakingPoolInfo } from '../../hooks/smartContracts/useStakingPoolsInfo'
import { useAlphaStakeTotalDeposited } from '../../hooks/smartContracts/useAlphaStakeTotalDeposited'
import numeral from 'numeral'
import { useShiftToLeft, useBackToOrigin } from '../../redux/navbar/hooks'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ChainId } from '../../constants/blockchain'
import queryString from 'query-string'
import LabelField from '../../components/commons/LabelField'
import { GoogleLogin } from 'react-google-login'
import { providers } from 'ethers'
import securitizeIcon from '../../assets/symbol/securitize-logo.svg'
import sumsubIcon from '../../assets/symbol/Logo_horizontal_white.png'
import TelegramLoginButton from 'react-telegram-login'
import { useRebalancedAlphaRewards } from '../../hooks/smartContracts/useRebalancedAlphaRewards'
import { useCalcDisburse } from '../../hooks/smartContracts/useCalcDisburse'
import { utils } from 'ethers'
import {
  PoolNames,
  AssetType,
  AssetMaturity,
  AssetOriginatorNames,
  AODescription,
  AssetOriginatorsShow,
  AOGradientCardHeight,
  AOGradientCardMobileHeight,
  ExecutiveSummaryLink,
  WebsiteLink,
} from '../../constants/investConstantsPro'

const InformationCardWidth = 320

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '100vw',
      height: '88.15vh',
      [theme.breakpoints.up(960)]: {
        maxWidth: '90.625vw',
      },
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
      },
    },
    boxLeft: {
      width: `calc(100% - ${InformationCardWidth}px)`,
      [theme.breakpoints.down(960)]: {},
    },
    boxRightHide: {
      display: 'none',
      width: '0px',
    },
    boxRight: {
      width: `${InformationCardWidth}px`,
      display: 'block',
      backgroundColor: '#10113E',
      [theme.breakpoints.down(960)]: {
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        top: 0,
        height: 'calc(100% - 56px)',
      },
    },
    titleButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
    disalbeTitleButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: 'rgba(255, 255, 255, 0.2)',
    },
    titleButtonExit: {
      border: `1px solid ${theme.palette.primary.light}`,
      marginRight: '8px',
    },
    titleButtonOutline: {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    dividerVertical: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
    dividerHorizental: {
      width: '48px',
      height: '2px',
      margin: '32px auto',
      backgroundColor: theme.palette.text.primary,
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    informationDivider: {
      width: '280px',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
    greyHeader: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    tabs: {
      minHeight: 0,
      height: 32,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 4,
      marginTop: 24,
    },
    tab: {
      minWidth: 0,
      minHeight: 32,
      padding: 0,
      width: '50%',
      borderRadius: 3,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    hideIndicator: {
      display: 'none',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 360,
    },
    bottomSpace: {
      height: '0px',
      [theme.breakpoints.down(960)]: {
        height: '80px',
      },
    },
    addTokenButton: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'center',
    },
    addedTokenSign: {
      color: 'rgba(255, 255, 255, 0.6)',
      marginTop: 16,
      textAlign: 'right',
    },
    addedTokenContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    stepLabel: {
      '&.MuiStepLabel-root': {
        alignItems: 'flex-start',
      },
    },
    titleMargin: {
      marginBottom: 10,
    },
    assetOriginatorBG: {
      backgroundColor: 'rgba(255, 255, 255) !important',
      // '&:before': {
      //   backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
      // },
    },
    marginBottom_24: {
      marginBottom: 24,
    },
    detailModal: {
      width: 200,
      height: 'auto',
      backgroundColor: '#040534',
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: 'rgba(255, 255, 255, 0.87)',
      [theme.breakpoints.down(960)]: {
        '&.MuiTooltip-tooltip': {
          marginTop: 12,
        },
      },
    },
    modalArrow: {
      '&::before': {
        backgroundColor: '#040534',
      },
    },
  })
)

const StepLabel = styled(MuiStepLabel)`
  .MuiStepLabel-label {
    color: white;
  }
`

export interface DisburseType {
  payoutCurrencyAmount: BigNumber
  payoutTokenAmount: BigNumber
  remainingSupplyCurrency: BigNumber
  remainingRedeemToken: BigNumber
}

export interface AssetDataType {
  nftID: string
  nftRegistry: string
  owner: string
  riskGroup: string
  maturityDate: string
  amount: string
  financeFee: string
  status: string
  available: string
  outstanding: string
}

export enum AlphaPoolAction {
  INVEST,
  INVEST_CLAIM,
  REDEEM,
  REDEEM_CLAIM,
}

export enum AlphaPoolDetail {
  INVEST,
  LIQUIDITY,
  ASSET,
  EPOCH,
}

const PoolCard: FC<{ apr: any; isPC: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const { apr, isPC, onClick } = props
  return (
    <GradientBackground width={isPC ? '48%' : '100%'} height="92px" onClick={onClick}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        // style={{ padding: '8px 13px 12px 16px', cursor: 'pointer' }}
        style={{ padding: '8px 13px 12px 16px' }}
      >
        <Typography variant="body2">
          <Trans>ESTIMATED APR</Trans>
        </Typography>
        <Typography variant="h2">{(apr.naos + apr.alpha).toFixed(2)} %</Typography>
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '8px' }}>
          <Typography variant="body1">
            <Trans>ALPHA Pool</Trans>
            {/* Coming Soon */}
          </Typography>
          <ChevronRight color="white" size={24} />
        </Grid>
      </Grid>
    </GradientBackground>
  )
}

const AlphaPro = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const history = useHistory()
  const { width, ref } = useResizeDetector()

  const { poolname } = useParams() as Record<string, any>

  let poolID = 0
  if (poolname.substr(0, 4) == 'rwa-') {
    poolID = Number(poolname.substr(4))
  }

  const { account, library, chainId, connector } = useWeb3React()

  const [alphaRootAddress, setAlphaRootAddress] = useState(
    Object.keys(ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC])[poolID]
  )
  const galaxyPoolContract = useGalaxyStakingPoolContract(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )
  // const trancheContract = useTrancheContract(ADDRESS.GALAXY_PRO_TRANCHE.default[0])
  const investDisburse: DisburseType = useCalcDisburse(
    ADDRESS.GALAXY_PRO_TRANCHE[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined
  )
  const operatorContract = useOperatorContract(
    ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )
  const [remainingCapacity, setRemainingCapacity] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (galaxyPoolContract) {
      Promise.all([galaxyPoolContract?.getPoolCeiling(0), galaxyPoolContract?.getPoolTotalDeposited(0)]).then((res) => {
        setRemainingCapacity(res[0].sub(res[1]))
      })
    }
    if (chainId !== undefined) {
      let rootAddress = Object.keys(ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC])[poolID]
      if (rootAddress !== undefined) {
        setAlphaRootAddress(rootAddress)
      }
    }
  }, [galaxyPoolContract, chainId])

  // const currentChainId = useMemo(() => (chainId ? chainId : USED_CHAIN_ID), [chainId])

  // useEffect(() => {
  //   if (currentChainId == ChainId.MAINNET) {
  //     history.push('/home')
  //   }
  // }, [currentChainId])

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  const alphaPrice = useAlphaTokenPrice(ADDRESS?.ASSESSOR_PRO[chainId ?? ChainId.BSC][alphaRootAddress])

  // TODO:
  // 這邊要抓一下真實 bsc 上的數據
  const galaxyPoolRewardRate = useGalaxyPoolRewardRate(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const [betaSupply, betaPrice] = useBetaInsuranceInfo(ADDRESS.BETA_INSURANCE)
  const betaContract = useStakingPoolContract(ADDRESS.STAKINGPOOL_WITH_TRANSFER)
  const betaPoolId = useMemo(() => (chainId === ChainId.BSC ? 1 : 0), [chainId])
  const [betaNaosReward, setBetaNaosReward] = useState(BigNumber.from('0'))
  const secondPerBlock = useSecondPerBlock()
  useEffect(() => {
    if (betaContract) {
      betaContract.getPoolRewardRate(betaPoolId).then((r) => {
        const reward = r.mul(60).mul(60).mul(24)
        setBetaNaosReward(reward.mul(10).div(secondPerBlock * 10))
      })
    }
  }, [betaContract, secondPerBlock])

  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [daiBalance] = useTokenBalance(ADDRESS.DAI_TOKEN, account ?? undefined)
  const [alphaBalance] = useTokenBalance(
    ADDRESS.ALPHA_PRO_TOKEN[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined
  )
  const [isApprove, approve] = useApproveCallback(
    ADDRESS.DAI_TOKEN,
    inputBignumber,
    ADDRESS.GALAXY_PRO_TRANCHE[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const [isApproveSenior, approveSenior] = useApproveCallback(
    ADDRESS.ALPHA_PRO_TOKEN[chainId ?? ChainId.BSC][alphaRootAddress],
    inputBignumber,
    ADDRESS.GALAXY_PRO_TRANCHE[chainId ?? ChainId.BSC][alphaRootAddress]
  )

  const totalPortfolio = useMemo(() => {
    const price = +displayBalanceUnits(alphaPrice)
    const tokenAmount = +formatEther(investDisburse.payoutTokenAmount)
    const tokenBalance = +formatEther(alphaBalance)
    const redeemToken = +formatEther(investDisburse.remainingRedeemToken)
    const currency = +formatEther(investDisburse.payoutCurrencyAmount.add(investDisburse.remainingSupplyCurrency))

    return currency + (tokenAmount + tokenBalance + redeemToken) * price
  }, [investDisburse, alphaPrice, alphaBalance, chainId])

  const amountInput = useRef<IAmountInputRefObj>(null)

  const [activeAlphaPool, setActiveAlphaPool] = useState(0)
  const [isDrawerOpen, setDrawerOpen] = useState<boolean[]>([false, false, false])
  const openDrawer = (type: number) => {
    const status = new Array(3).fill(false)
    status[type] = true
    setDrawerOpen(status)
  }
  const closeDrawer = () => {
    setDrawerOpen([false, false, false])
  }

  const isWhiteList = useMemberlisted(ADDRESS.MEMBERLIST[chainId ?? ChainId.BSC][alphaRootAddress], account)
  const [tabValue, setTabValue] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)
  const [whitelistModal, setWhiteListModal] = useState(false)
  const [isKYCProcessing, setIsKYCProcessing] = useState(false)
  const [isKYCInfoPending, setIsKYCInfoPending] = useState(false)
  const [isCheckDocuSign, setIsCheckDocuSign] = useState(true)
  const [isClickDocuSignBtn, setIsClickDocuSignBtn] = useState(false)
  const [kcyAgency, setKcyAgency] = useState<string>('securitize')
  const [isKYCDone, setIsKYCDone] = useState<boolean>(false)
  const [isWalletRegistered, setIsWalletRegistered] = useState<number>(0)

  // enum KYC_TYPE {
  //   NONE = 1,
  //   INDIVIDUAL = 2,
  //   ENTITY = 3,
  // }

  const documentSignedCheck = (chainId: number) => {
    if (account && chainId) {
      getAgreementApply(
        chainId,
        ADDRESS.GALAXY_STAKING_POOL[chainId as number][alphaRootAddress].toLowerCase(),
        account?.toLowerCase()
      )
        .then((res) => {
          setIsKYCInfoPending(false)
          if (res) {
            //Pass
            window.history.pushState(
              {},
              '',
              window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?event=wait_for_approve'
            )
            setActiveStep(4)
          } else {
            //No Pass
            setActiveStep(3)
          }
        })
        .catch((err) => {
          setIsKYCInfoPending(false)
          setActiveStep(3)
        })
    }
  }

  const handleRegisterWallet = () => {
    if (account && (externalToken || externalObj) && thirdPartyName) {
      const code = !!externalToken ? externalToken : externalObj
      getSigner().then((res) => {
        if (res && res.hasOwnProperty('signature')) {
          postRegisteredInfo(thirdPartyName, code, account?.toLowerCase(), res.signature.substring(2)).then((res) => {
            if (res && chainId) {
              documentSignedCheck(chainId)
            }
          })
        }
      })
    }
  }

  const registeredAddressCheck = () => {
    if (account && externalId) {
      getRegisteredInfo(externalId, account?.toLowerCase()).then((status) => {
        setIsKYCInfoPending(false)
        switch (status) {
          case statusCode.ADDRESS_BEEN_REGISTERED:
          case statusCode.UNREGISTERED:
          case statusCode.INTERNAL_ERROR:
            setIsWalletRegistered(status)
            break
          case statusCode.REGISTERED:
            if (chainId) {
              //docusign
              setIsWalletRegistered(status)
              documentSignedCheck(chainId)
            }
            break
          default:
            // console.log('default')
            break
        }
      })
    }
  }

  const getSigner = async () => {
    const message = t`This is to confirm that you approve registration of your wallet in NAOS`

    if (account) {
      const provider = library
      const signer = provider.getSigner(account)
      const hexMessage = utils.hexlify(utils.toUtf8Bytes(message))
      const address = await signer.getAddress()
      try {
        const signature = await provider.send('personal_sign', [hexMessage, account.toLowerCase()])
        return {
          signature,
          address,
        }
      } catch (err: any) {
        console.log(err.message)
      }
    }
  }

  const sumsubKYCCheck = (kycType: string) => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    }

    if ((externalToken || externalObj) && thirdPartyName) {
      const code = !!externalToken ? externalToken : externalObj
      postSumsubKYCInfo(account?.toLowerCase(), code, thirdPartyName, kycType)
        .then((res) => {
          const { hasVerified, hasToken, status, accessToken, msg } = res
          if (hasVerified) {
            setIsKYCDone(hasVerified)
            registeredAddressCheck()
          } else {
            //no pass kyc
            setIsKYCInfoPending(false)
            if (accessToken) {
              window.open(
                window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  `/kyc?access=${accessToken}&redirecturl=${
                    window.location.protocol + '//' + window.location.host + window.location.pathname
                  }&type=${kycType}`,
                '_self'
              )
            }
          }
        })
        .catch((err) => {
          setIsKYCInfoPending(false)
          setActiveStep(1)
          console.warn(err.message)
        })
    }
  }

  useEffect(() => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    } else if (isWhiteList) {
      setWhiteListModal(false)
    }

    // setIsWalletRegistered(0)

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    let step = 1
    let auth = {
      linkedAccount: '',
      code: '',
      country: '',
      auth: false,
    }
    let openModal = false
    if (parsed && parsed.code && parsed.country && account) {
      const { linkedAccount, code, country, authorized } = parsed
      if (window.localStorage.getItem('kycCode') === null || window.localStorage.getItem('kycCode') !== code) {
        window.localStorage.setItem('kycCode', code as string)
      } else {
        setActiveStep(1)
        return
      }

      if (linkedAccount === account) {
        // only for securitize
        auth = {
          linkedAccount: linkedAccount as string,
          code: code as string,
          country: country as string,
          auth: authorized === 'true' ? true : false,
        }
        setActiveStep(2)
        openModal = true
        setIsKYCInfoPending(true)
        postSecuritizeKYCInfo(account?.toLowerCase(), code)
          .then((res) => {
            const { hasVerified, hasToken, status, accessToken, msg } = res
            if (hasVerified) {
              documentSignedCheck(chainId)
            } else {
              setIsKYCInfoPending(false)
              if (status == 'processing') {
                setIsKYCProcessing(true)
              }
              if (msg) {
                setKYCMessage(msg)
              } else {
                setKYCMessage(status)
              }
              setActiveStep(2)
            }
          })
          .catch((err) => {
            setIsKYCInfoPending(false)
            setActiveStep(1)
            console.warn(err.message)
          })
      } else {
        setActiveStep(2)
      }
    }
    if (parsed && parsed.event) {
      openModal = true
      if (parsed.event == 'signing_complete') {
        step = 4
        putAgreementApply(
          chainId,
          ADDRESS.GALAXY_STAKING_POOL[chainId as number][alphaRootAddress].toLowerCase(),
          account?.toLowerCase()
        )
          .then((res) => {
            if (res === 'success') {
              window.history.pushState(
                {},
                '',
                window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?event=wait_for_approve'
              )
            } else {
              window.history.pushState(
                {},
                '',
                window.location.protocol + '//' + window.location.host + window.location.pathname
              )
            }
          })
          .catch((err) => {
            setIsCheckDocuSign(false)
            window.history.pushState(
              {},
              '',
              window.location.protocol + '//' + window.location.host + window.location.pathname
            )
          })
      } else if (parsed.event == 'wait_for_approve') {
        step = 4
      } else if (parsed.event === 'sumsub_kyc' && parsed.kycType && typeof parsed.kycType === 'string') {
        sumsubKYCCheck(parsed.kycType)
      }
    }
    if (account && !isWhiteList) {
      setIsWalletRegistered(0)
      if (openModal && !whitelistModal) {
        setWhiteListModal(true)
      } else if ((externalToken || externalObj) && externalId) {
        setActiveStep(2)
        if (isKycTypeChoose && isKYCDone) {
          registeredAddressCheck()
        }
        return
      }
      setActiveStep(step)
    }
  }, [account, chainId, isWhiteList])

  const pendingReward = useGalaxyPendingRewards(galaxyPoolContract, account)
  const [assetDetailData, setAssetDetailData] = useState<undefined | AssetDataType>(undefined)
  const [isDetailOpen, setDetailOpen] = useState<boolean[]>([false, false, false, false])
  const shiftFloatButton = useShiftToLeft()
  const backFloatButton = useBackToOrigin()

  const openDetail = (type: number) => {
    const status = new Array(4).fill(false)
    status[type] = true
    shiftFloatButton()
    setDetailOpen(status)
  }
  const closeDetail = () => {
    backFloatButton()
    setDetailOpen([false, false, false, false])
  }

  // useEffect(() => {
  //   if (isPC && isWhiteList) {
  //     shiftFloatButton()
  //     setDetailOpen([true, false, false, false])
  //   } else {
  //     backFloatButton()
  //     setDetailOpen([false, false, false, false])
  //   }
  // }, [isPC, isWhiteList])

  // const aprs = useRWAStkaingPoolsApr(account?.toLowerCase() ?? undefined)
  const aprs = useRWAProStakingPoolsApr(
    ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )
  // const alphaTvls = useAlphaRWAStakingPoolsTvl()
  const alphaPoolValue = useAlphaProRWAStakingPoolValue(chainId ?? ChainId.BSC, alphaRootAddress)
  const nftListStats = useNFTProListStats(
    ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  )
  const epochReserve = useFundingReserve(chainId ?? ChainId.BSC, alphaRootAddress)
  const epochReserveRatio = useEpochReserveRatio(chainId ?? ChainId.BSC, alphaRootAddress)

  const AlphaPoolInfo = useMemo(() => {
    return [
      { apr: aprs[0].alpha[0], name: 'ALPHA', period: 7, id: 0 },
      // { apr: aprs[0].alpha[1], name: 'ALPHA', period: 90, id: 1 },
    ]
  }, [aprs])

  // for testing usage
  const [comingSoon] = useState(false)

  const lineData = usePoolProValue(
    ADDRESS.GALAXY_PRO_OPERATOR[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
  ) as LineDataType
  const [xAxisDate, setXAxisDate] = useState<Object>({})

  useEffect(() => {
    if (lineData && lineData.name && lineData.name.length > 0) {
      const dateArr = lineData.name
      const dateObj = {}
      let monthDate = ''
      dateArr.forEach((date: string) => {
        let splitString = date.split(/[\-]/)
        if (monthDate === '' || monthDate !== `${splitString[0]}-${+splitString[1]}`) {
          monthDate = `${splitString[0]}-${+splitString[1]}`
          dateObj[date] = monthDate
        }
      })
      setXAxisDate(dateObj)
    }
  }, [lineData])

  async function handleSupplyOrder() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (operatorContract && inputBignumber.gte(0) && inputBignumber.lte(daiBalance)) {
        openConfirmingModal(true)
        try {
          const totalAmount = investDisburse.remainingSupplyCurrency.add(inputBignumber)
          const estimateGas = await operatorContract.estimateGas.supplyOrder(totalAmount)
          const res = await operatorContract.supplyOrder(totalAmount, {
            gasLimit: calculateGasMargin(estimateGas),
          })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: t`Deposit ${displayBalance(inputBignumber)} ${collateralTokenName}`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          console.log(error)
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  async function handleDisburse(displaySummary: string, approveType: string) {
    if (approveType === 'SeniorToken' && investDisburse.payoutTokenAmount.lte(0)) return
    else if (approveType === 'StableCoin' && investDisburse.payoutCurrencyAmount.lte(0)) return

    if (approveType === 'StableCoin' && isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else if (approveType === 'SeniorToken' && isApproveSenior !== ApprovalState.APPROVED) {
      await approveSenior()
    } else {
      if (operatorContract) {
        openConfirmingModal(true)
        try {
          const estimateGas = await operatorContract.estimateGas['disburse()']()
          const res = await operatorContract['disburse()']({ gasLimit: calculateGasMargin(estimateGas) })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: displaySummary, //`Disburse ${displayBalance(investDisburse.payoutTokenAmount)} ALPHA`,
          })
          amountInput.current?.resetAmount()
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  const alphaDepositAmount0: BigNumber = useAlphaStakeTotalDeposited(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined,
    AlphaPoolInfo[0].id
  )
  // const alphaDepositAmount1: BigNumber = useAlphaStakeTotalDeposited(
  //   ADDRESS.GALAXY_STAKING_POOL.default[0],
  //   account ?? undefined,
  //   AlphaPoolInfo[1].id
  // )

  // const alphaDepositAmounts = useMemo(
  //   () => ({ 0: alphaDepositAmount0, 1: alphaDepositAmount1 }),
  //   [alphaDepositAmount0, alphaDepositAmount1]
  // )

  const alphaDepositAmounts = useMemo(() => ({ 0: alphaDepositAmount0 }), [alphaDepositAmount0])

  // const weightedBoosts = useMemo(() => {
  //   if (alphaDepositAmount0.eq(0) && alphaDepositAmount1.eq(0)) return '0.00'

  //   const alphaWeight0 = +formatEther(alphaDepositAmount0) * boost0
  //   const alphaWeight1 = +formatEther(alphaDepositAmount1) * boost1
  //   const totalAmount = +formatEther(alphaDepositAmount0.add(alphaDepositAmount1))
  //   return ((alphaWeight0 + alphaWeight1) / totalAmount).toFixed(2)
  // }, [alphaDepositAmount0, alphaDepositAmount1, boost0, boost1])

  // const addNAOSToken = useAddTokenToWallet(TOKEN.NAOS)
  const addALPHAToken = useAddTokenToWallet(TOKEN.ALPHA_PRO, 'default')

  const [stakedAmount, rewardAmount] = useStakingPoolInfo(
    ADDRESS.STAKINGPOOL_WITH_TRANSFER,
    account ?? undefined,
    useStakingPoolId().BETA
  )

  const UIAvailableChainId =
    USED_CHAIN_ID === ChainId.MAINNET
      ? [ChainId.MAINNET, ChainId.BSC]
      : [ChainId.RINKEBY, ChainId.BSC, ChainId.BSC_TEST]

  const steps = [
    {
      label: t`Connect wallet`,
      description: '',
    },
    {
      label: t`Login KYC Account`,
      description: t`Please sign up or sign in with Securitize account to continue your onboarding process.`,
    },
    {
      label: t`Start KYC`,
      description: '',
    },
    {
      label: t`Sign the Subscription Document`,
      description: '',
    },
    {
      label: t`Thanks for signing the agreement. The issuer will counter-sign your subscription agreement soon. You will be ready to invest in the pool upon it is signed back.`,
      description: '',
    },
  ]

  const openModal = useOpenModal()

  const [activeStep, setActiveStep] = useState(0)
  const [kycMessage, setKYCMessage] = useState('')
  const [isKycTypeChoose, setIsKycTypeChoose] = useState<boolean>(false)

  const redirect =
    window.location.protocol + '//' + window.location.host + window.location.pathname + `?linkedAccount=${account}`

  const handleKYC = () => {
    setKcyAgency('securitize')
    window.open(
      `${SECURITIZE_API_URL}?issuerId=${SECURITIZE_CLIENT_ID}&scope=info details verification&redirecturl=${redirect}`,
      '_self'
    )
  }

  const [externalToken, setExternalToken] = useState('')
  const [externalId, setExternalId] = useState('')
  const [externalObj, setExternalObj] = useState<Record<string, any>>({})
  const [thirdPartyName, setThirdPartyName] = useState<string>('')

  const handleSumsubKYC = (kycType: string) => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    }
    if (!isKycTypeChoose) {
      setIsKYCInfoPending(true)
      setIsKycTypeChoose(true)
      sumsubKYCCheck(kycType)
    }
  }

  const successResponseGoogle = (e: any) => {
    setKcyAgency('sumsub')
    setThirdPartyName('google')
    setActiveStep(2)
    setExternalToken(e.tokenId)
    setExternalId(e.googleId)

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    if (parsed && parsed.linkedAccount) {
      window.history.pushState(
        {},
        '',
        window.location.protocol + '//' + window.location.host + window.location.pathname
      )
    }

    //check does kyc pending or not
    // if(e.googleId){
    //   console.log('checkout')
    //   getKYCType(e.googleId).then((status)=> {
    //     switch(status){
    //       case KYC_TYPE.INDIVIDUAL:
    //         setIsKYCInfoPending(true)
    //         setIsKycTypeChoose(true)
    //         sumsubKYCCheck('individual')
    //         break
    //       case KYC_TYPE.ENTITY:
    //         setIsKYCInfoPending(true)
    //         setIsKycTypeChoose(true)
    //         sumsubKYCCheck('entity')
    //         break
    //       default:
    //         break
    //     }
    //   })
    // }
  }

  const failureResponseGoogle = (e: any) => {
    console.log('failure google rsps: ', e)

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    if (parsed && parsed.linkedAccount) {
      window.history.pushState(
        {},
        '',
        window.location.protocol + '//' + window.location.host + window.location.pathname
      )
    }
  }

  const responseTelegram = (e: any) => {
    setKcyAgency('sumsub')
    setThirdPartyName('telegram')
    setActiveStep(2)
    setExternalId(e.id)
    setExternalObj(e)
  }

  const submitKYC = async () => {
    setKcyAgency('securitize')
    window.open(SECURITIZE_API_URL.replace(/#\/authorize/, '#/settings'), '_blank')
  }

  const signDOC = async () => {
    if (chainId !== undefined && chainId !== null && !isNaN(chainId) && account) {
      setIsClickDocuSignBtn(true)
      await postAgreementApply(
        chainId,
        ADDRESS.GALAXY_STAKING_POOL[chainId as number][alphaRootAddress].toLowerCase(),
        window.location.protocol + '//' + window.location.host + window.location.pathname,
        account?.toLowerCase()
      )
        .then((url) => {
          if (url && url !== '' && url !== null) {
            window.open(url, '_self')
          } else {
            setIsClickDocuSignBtn(false)
          }
        })
        .catch((err) => {
          setIsClickDocuSignBtn(false)
          console.error(err)
        })
    }
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.informationBox, { [classes.boxLeft]: isDetailOpen.includes(true) })}>
        <TitleDiv>
          <TitleFront>
            <TitleIcon src={ImageCoin} />
            <TitleTextDiv>
              <Typography variant="body2" className={classes.greyHeader}>
                Galaxy Pro
              </Typography>
              <Typography variant="h3">{PoolNames[poolID]}</Typography>
            </TitleTextDiv>
          </TitleFront>
          {/* <TitleBackContainer>
            <TitleBack className={classes.titleButtonExit} onClick={() => openDrawer(AlphaPoolAction.REDEEM)}>
              <Typography variant="body2" color="textSecondary">
                REDEEM
              </Typography>
            </TitleBack>
            <TitleBack
              className={classes.titleButton}
              onClick={() => {
                setActiveAlphaPool(0)
                if (isWhiteList) {
                  openDrawer(AlphaPoolAction.INVEST)
                } else {
                  setWhiteListModal(true)
                }
              }}
            >
              <Typography variant="body2" color="inherit">
                INVEST
              </Typography>
            </TitleBack>
          </TitleBackContainer> */}
        </TitleDiv>

        <GradientCard width="100%" height={isPC ? '80px' : '442px'}>
          <BasicContainer>
            <GradientCard height={isPC ? '80px' : '442px'}>
              <Basic>
                <BasicText>
                  <Typography variant="h2">
                    <Trans>Emerging Market Consumer</Trans>
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Asset Type</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">
                    {/* {`$ ${displayBalance(+alphaTvls[0] + +alphaTvls[1], '0,0')} ${mintTokenName}`} */}
                    {`$ ${displayBalance(+alphaPoolValue, '0,0')} ${collateralTokenName}`}
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Pool Value</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">{aprs[0].max[0].toFixed(2)}%</Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>MAX APR</Trans>
                  </Typography>
                </BasicText>
                <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                <BasicText>
                  <Typography variant="h2">
                    <Trans>1 Year</Trans>
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: '1px' }}>
                    <Trans>Asset Maturity</Trans>
                  </Typography>
                </BasicText>
              </Basic>
            </GradientCard>
          </BasicContainer>
        </GradientCard>

        {/* <Grid
          container
          direction={isPC ? 'row' : 'column'}
          justifyContent="space-between"
          style={isPC ? { margin: '20px 0' } : { margin: '20px 0', gap: 16 }}
        >
          <PoolCard
            apr={AlphaPoolInfo[0].apr}
            isPC={isPC}
            onClick={() => {
              setActiveAlphaPool(0)
              if (isWhiteList) {
                openDrawer(AlphaPoolAction.INVEST)
              } else {
                setWhiteListModal(true)
              }
            }}
          />

          <GradientBackground
            width={isPC ? '48%' : '100%'}
            height="92px"
            onClick={() => openDrawer(AlphaPoolAction.INSURANCE)}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              style={{ padding: '8px 13px 12px 16px', cursor: 'pointer' }}
            >
              <Typography variant="body2">APR</Typography>
              <Typography variant="h2">{aprs[0].beta[0].toFixed(2)} %</Typography>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '8px' }}>
                <Typography variant="body1">BETA Insurance Pool</Typography>
                <ChevronRight color="white" size={24} />
              </Grid>
            </Grid>
          </GradientBackground>
        </Grid> */}

        <PoolFlex inputMarginTop={'32px'}>
          <GradientBackground width="100%" height={isPC ? '236px' : '886px'}>
            <BasicContainer>
              <GradientCard height={isPC ? '72px' : '265px'}>
                <PersonalStatus style={{ margin: '4px 0' }}>
                  <PersonalStatusInfo>
                    <Typography variant="h2">
                      <Trans>PORTFOLIO</Trans>
                    </Typography>
                    <Typography variant="h2">$ {totalPortfolio.toFixed(2)} USD</Typography>
                  </PersonalStatusInfo>
                  <Divider orientation="horizontal" className={classes.dividerHorizental} />
                  <PersonalStatusInfo
                    style={{
                      visibility: 'hidden',
                    }}
                  >
                    <Typography variant="h2">
                      <Trans>EARN</Trans>
                    </Typography>
                    <Typography variant="h2">$ 597,569,000 USD</Typography>
                  </PersonalStatusInfo>
                </PersonalStatus>
              </GradientCard>
              <PersonalAssets>
                <PersonalAssetsInfo>
                  <Typography variant="h2">{collateralTokenName}</Typography>
                  <BasicTextFlex style={{ marginBottom: '12px' }} />
                  <AssetsInfoFlex>
                    <BasicTextSpaceBetween>
                      <Typography variant="body1">
                        <Trans>Investment</Trans>
                      </Typography>
                      <Typography variant="body1" style={{ textAlign: 'end' }}>
                        {displayBalance(investDisburse.remainingSupplyCurrency)}
                      </Typography>
                    </BasicTextSpaceBetween>
                    <MiniButton style={isPC ? { flexBasis: '5%', visibility: 'hidden' } : { display: 'none' }}>
                      <Typography variant="caption" style={{ letterSpacing: '0.5px' }}>
                        <Trans>EDIT</Trans>
                      </Typography>
                    </MiniButton>
                  </AssetsInfoFlex>
                  <AssetsInfoFlex>
                    <BasicTextSpaceBetween>
                      <Typography variant="body1">
                        <Trans>Wallet Balance</Trans>
                      </Typography>
                      <Typography variant="body1" style={{ textAlign: 'end' }}>
                        {displayBalance(daiBalance)}
                      </Typography>
                    </BasicTextSpaceBetween>
                    <MiniButton
                      className={classes.titleButton}
                      style={isPC ? { flexBasis: '5%' } : { marginTop: '4px', marginBottom: '12px' }}
                      mobileWidth="100%"
                      mobileHeight="36px"
                      onClick={() => {
                        setActiveAlphaPool(0)
                        if (isWhiteList) {
                          openDrawer(AlphaPoolAction.INVEST)
                        } else {
                          setWhiteListModal(true)
                        }
                      }}
                    >
                      {isPC ? (
                        <Typography variant="caption" style={{ letterSpacing: '0.5px' }}>
                          <Trans>DEPOSIT</Trans>
                        </Typography>
                      ) : (
                        <Typography variant="body2" style={{ letterSpacing: '1.25px' }}>
                          <Trans>DEPOSIT</Trans>
                        </Typography>
                      )}
                    </MiniButton>
                  </AssetsInfoFlex>
                  <AssetsInfoFlex>
                    <BasicTextSpaceBetween>
                      <Typography variant="body1">
                        <Trans>Claimable</Trans>
                      </Typography>
                      <Typography variant="body1" style={{ textAlign: 'end' }}>
                        {displayBalance(investDisburse.payoutCurrencyAmount)}
                      </Typography>
                    </BasicTextSpaceBetween>
                    <MiniButton
                      style={
                        isPC
                          ? investDisburse.payoutCurrencyAmount.gt(0)
                            ? { border: `1px solid ${theme.palette.primary.light}`, flexBasis: '10%' }
                            : { flexBasis: '10%' }
                          : investDisburse.payoutCurrencyAmount.gt(0)
                          ? { border: `1px solid ${theme.palette.primary.light}` }
                          : { marginTop: '4px' }
                      }
                      mobileWidth="100%"
                      mobileHeight="36px"
                      onClick={() => {
                        handleDisburse(
                          `Disburse ${displayBalance(investDisburse.payoutCurrencyAmount)} BUSD`,
                          'StableCoin'
                        )
                      }}
                      disable={true}
                      disableStyle="transparent"
                    >
                      <ButtonFlex style={{ width: '76px' }}>
                        {isPC ? (
                          <Typography
                            variant="caption"
                            style={
                              investDisburse.payoutCurrencyAmount.lte(0)
                                ? { color: 'rgba(255, 255, 255, 0.2)', letterSpacing: '0.5px' }
                                : { color: '#1D77FF', letterSpacing: '0.5px' }
                            }
                          >
                            {isApprove === ApprovalState.APPROVED ? t`CLAIM` : t`APPROVE`}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={
                              investDisburse.payoutCurrencyAmount.lte(0)
                                ? { color: 'rgba(255, 255, 255, 0.2)', letterSpacing: '1.25px' }
                                : { color: '#1D77FF', letterSpacing: '1.25px' }
                            }
                          >
                            {isApprove === ApprovalState.APPROVED ? t`CLAIM` : t`APPROVE`}
                          </Typography>
                        )}
                      </ButtonFlex>
                    </MiniButton>
                  </AssetsInfoFlex>
                </PersonalAssetsInfo>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.dividerVertical}
                  style={{ height: '128px', marginTop: '8px' }}
                />
                <Divider orientation="horizontal" className={classes.dividerHorizental} />
                {/* ALPHA - Senoir Token */}
                <PersonalAssetsInfo>
                  <Typography variant="h2">
                    <Trans>ALPHA Token</Trans>
                  </Typography>
                  <BasicTextFlex style={{ marginBottom: '12px' }} />
                  <AssetsInfoFlex>
                    <BasicTextSpaceBetween>
                      <Typography variant="body1">
                        <Trans>Registered</Trans>
                      </Typography>
                      <Typography variant="body1" style={{ textAlign: 'end' }}>
                        {displayBalance(investDisburse.remainingRedeemToken)}
                      </Typography>
                    </BasicTextSpaceBetween>
                    <MiniButton style={isPC ? { flexBasis: '5%', visibility: 'hidden' } : { display: 'none' }}>
                      <Typography variant="caption" style={{ letterSpacing: '0.5px' }}>
                        LALALALA
                      </Typography>
                    </MiniButton>
                  </AssetsInfoFlex>
                  <AssetsInfoFlex>
                    <BasicTextSpaceBetween>
                      <Typography variant="body1">
                        <Trans>Wallet Balance</Trans>
                      </Typography>
                      <Typography variant="body1" style={{ textAlign: 'end' }}>
                        {displayBalance(alphaBalance)}
                      </Typography>
                    </BasicTextSpaceBetween>
                    <MiniButton
                      className={classes.titleButton}
                      style={isPC ? { flexBasis: '5%' } : { marginTop: '4px', marginBottom: '12px' }}
                      mobileWidth="100%"
                      mobileHeight="36px"
                      onClick={() => openDrawer(AlphaPoolAction.REDEEM)}
                    >
                      {isPC ? (
                        <Typography variant="caption" style={{ letterSpacing: '0.5px' }}>
                          <Trans>REDEEM</Trans>
                        </Typography>
                      ) : (
                        <Typography variant="body2" style={{ letterSpacing: '1.25px' }}>
                          <Trans>REDEEM</Trans>
                        </Typography>
                      )}
                    </MiniButton>
                  </AssetsInfoFlex>
                  <AssetsInfoFlex>
                    <BasicTextSpaceBetween>
                      <Typography variant="body1">
                        <Trans>Claimable</Trans>
                      </Typography>
                      <Typography variant="body1" style={{ textAlign: 'end' }}>
                        {displayBalance(investDisburse.payoutTokenAmount)}
                      </Typography>
                    </BasicTextSpaceBetween>
                    <MiniButton
                      style={
                        isPC
                          ? investDisburse.payoutTokenAmount.gt(0)
                            ? { border: `1px solid ${theme.palette.primary.light}`, flexBasis: '10%' }
                            : { flexBasis: '10%' }
                          : investDisburse.payoutTokenAmount.gt(0)
                          ? { border: `1px solid ${theme.palette.primary.light}` }
                          : { marginTop: '4px' }
                      }
                      mobileWidth="100%"
                      mobileHeight="36px"
                      onClick={() => {
                        handleDisburse(
                          t`Disburse ${displayBalance(investDisburse.payoutTokenAmount)} ALPHA`,
                          'SeniorToken'
                        )
                      }}
                      disable={true}
                      disableStyle="transparent"
                    >
                      <ButtonFlex style={{ width: '76px' }}>
                        {isPC ? (
                          <Typography
                            variant="caption"
                            style={
                              investDisburse.payoutTokenAmount.lte(0)
                                ? { color: 'rgba(255, 255, 255, 0.2)', letterSpacing: '0.5px' }
                                : { color: '#1D77FF', letterSpacing: '0.5px' }
                            }
                          >
                            {isApprove === ApprovalState.APPROVED ? t`CLAIM` : t`APPROVE`}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={
                              investDisburse.payoutTokenAmount.lte(0)
                                ? { color: 'rgba(255, 255, 255, 0.2)', letterSpacing: '1.25px' }
                                : { color: '#1D77FF', letterSpacing: '1.25px' }
                            }
                          >
                            {isApprove === ApprovalState.APPROVED ? t`CLAIM` : t`APPROVE`}
                          </Typography>
                        )}
                      </ButtonFlex>
                    </MiniButton>
                  </AssetsInfoFlex>
                </PersonalAssetsInfo>
              </PersonalAssets>
            </BasicContainer>
          </GradientBackground>
        </PoolFlex>

        <Pool>
          <Typography variant="h2">
            <Trans>Asset Status</Trans>
          </Typography>
          <PoolFlex inputMarginTop={'11px'} inputMobileMarginTop={'16px'}>
            <GradientCard width={isPC ? '48%' : '100%'} height="auto">
              <PoolFlexAsset>
                <BasicTextFlex>
                  <Typography variant="h2">
                    <Trans>Asset Value</Trans>
                  </Typography>
                  <Typography variant="h2">{`$ ${displayBalance(nftListStats.totalAsset, '0,0')}`} USD</Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Number of Assets</Trans>
                  </Typography>
                  <Typography variant="body2">{nftListStats.count}</Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Average Loan Amount</Trans>
                  </Typography>
                  <Typography variant="body2">
                    {`$ ${displayBalance(
                      nftListStats.count > 0 ? nftListStats.totalAsset / nftListStats.count : 0,
                      '0,0'
                    )}`}{' '}
                    USD
                  </Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Weighted Interest Rate</Trans>
                  </Typography>
                  <Typography variant="body2">
                    {nftListStats.count > 0 ? (nftListStats.totalInterest / nftListStats.count).toFixed(2) : 0}%
                  </Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Average Maturity</Trans>
                  </Typography>
                  <Typography variant="body2">
                    <Trans>
                      {nftListStats.count > 0 ? (nftListStats.totalDays / nftListStats.count).toFixed(0) : 0} days
                    </Trans>
                  </Typography>
                </BasicTextFlex>
                <BasicTextFlex />
                <BasicTextFlex />
                <BasicTextFlex>
                  <Typography variant="h2">
                    <Trans>Reserve</Trans>
                  </Typography>
                  <Typography variant="h2">{`$ ${displayBalance(epochReserve, '0,0')}`} USD</Typography>
                </BasicTextFlex>
                <BasicTextFlex>
                  <Typography variant="body2">
                    <Trans>Reserve Ratio</Trans>
                  </Typography>
                  <Typography variant="body2">{epochReserveRatio.toString()}%</Typography>
                </BasicTextFlex>
              </PoolFlexAsset>
            </GradientCard>
            {isPC ? <></> : <div style={{ height: '16px' }}></div>}
            <GradientCard width={isPC ? '48%' : '100%'} height="auto">
              <PoolFlexAsset>
                <BasicTextFlex>
                  <Typography variant="h2">
                    <Trans>Pool Value</Trans>
                  </Typography>
                  <Typography variant="h2">
                    <Trans>Nov 11, 2021 - Present</Trans>
                  </Typography>
                </BasicTextFlex>
                {lineData && lineData.name && lineData.name.length > 0 && (
                  <LineDataContainer ref={ref}>
                    <CustomLineData
                      lineData={lineData}
                      height={208}
                      width={width}
                      yRange={[0, (dataMax) => Math.floor(dataMax * 1.2)]}
                      XAxisTicks={xAxisDate}
                      XInterval={0}
                      tickFormatY={(tick: number) => {
                        return `$${numeral(tick).format('0a').toUpperCase()}`
                      }}
                      labelFormatter={(value, name, props) => {
                        return [`$${numeral(value).format('0,0').toUpperCase()}`, name, props]
                      }}
                    />
                  </LineDataContainer>
                )}
              </PoolFlexAsset>
            </GradientCard>
          </PoolFlex>
          {/* <PoolFlex inputMarginTop={'24px'} inputMobileMarginTop={'16px'}>
            <BasicContainer>
              <GradientCard width="100%" height={isPC ? '128px' : '320px'}>
                <PoolFlexAsset>
                  <PoolFlex style={{ alignItems: 'center' }} inputMarginTop={'0px'} inputMobileMarginTop={'0px'}>
                    <PoolFlexDivider>
                      <BasicTextFlex>
                        <Typography variant="h2">ALPHA Lending Pool</Typography>
                        <Typography variant="h2">
                          {`$ ${displayBalance(+alphaPoolValue, '0,0')}`} USD
                        </Typography>
                      </BasicTextFlex>
                      <BasicTextFlex style={{ visibility: 'hidden' }}>
                        <Typography variant="body2">Interest Earned in {collateralTokenName}</Typography>
                        <Typography variant="body2">
                          {`${(
                            +displayBalance(
                              alphaTotalSupply.mul(alphaPrice).div(BigNumber.from('1000000000000000000000000000')),
                              '0'
                            ) -
                            (+alphaPoolValue - +formatEther(toTransformedDai))
                          ).toFixed(2)} ${collateralTokenName}`}
                        </Typography>
                      </BasicTextFlex>
                      <BasicTextFlex style={{ visibility: 'hidden' }}>
                        <Typography variant="body2">LALALA</Typography>
                        <Typography variant="body2">HAHA</Typography>
                      </BasicTextFlex>
                    </PoolFlexDivider>

                    <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                    <Divider orientation="horizontal" className={classes.dividerHorizental} />

                    <PoolFlexDivider>
                      <BasicTextFlex>
                        <Typography variant="h2">BETA Insurance Pool</Typography>
                        <Typography variant="h2">
                          $ {displayBalance(betaSupply.mul(betaPrice).div(parseUnits('1', 18)))} USD
                        </Typography>
                      </BasicTextFlex>
                      <BasicTextFlex>
                        <Typography variant="body2">Interest Earned in NAOS per day</Typography>
                        <Typography variant="body2">{displayBalance(betaNaosReward)} NAOS</Typography>
                      </BasicTextFlex>
                      <BasicTextFlex style={{ visibility: 'hidden' }}>
                        <Typography variant="body2">LALALA</Typography>
                        <Typography variant="body2">HAHA</Typography>
                      </BasicTextFlex>
                    </PoolFlexDivider>
                  </PoolFlex>
                </PoolFlexAsset>
              </GradientCard>
            </BasicContainer>
          </PoolFlex> */}
        </Pool>

        {/* <Pool>
          <Typography variant="h2" className={classes.titleMargin}>
            Asset Originator
          </Typography>
          <GradientCard width="100%" height={isPC ? (isDetailOpen.includes(true) ? '220x' : '198px') : '360px'}>
            <BasicContainer>
              <GradientCard height={isPC ? (isDetailOpen.includes(true) ? '220px' : '198px') : '360px'}>
                <AssetOriginatorInfo>
                  <Typography variant="h2" className={classes.titleMargin}>
                    Sandalwood
                  </Typography>
                  <Typography variant="subtitle1" className={classes.titleMargin}>
                    Fintopia Inc. is a global regulated non-bank financial company providing unsecured consumer loans in
                    China, Southeast Asia, South America and Africa. Since founded in 2015, Fintopia has originated over
                    US$13 billion in loans to over 53 million borrowers. In addition to holding financing licenses in
                    each country of operations, Fintopia partners with over 50 financial institutions globally including
                    HSBC, Bank Central Asia, Bank Mandiri, China International Capital Corporation, People’s Insurance
                    Company of China, Bank Negara Indonesia, Bank Rakyat Indonesia. Fintopia is named “Top Compliance
                    Development FinTech” and “China Fintech Best Risk Control Enterprise, and works with Central Bank of
                    China to develop the national credit system.
                  </Typography>
                </AssetOriginatorInfo>
              </GradientCard>
            </BasicContainer>
          </GradientCard>
        </Pool> */}

        <Pool style={isPC ? { marginBottom: 80 } : { marginBottom: 25 }}>
          <Typography variant="h2">
            <Trans>Assets</Trans>
          </Typography>
          <PoolFlex inputMarginTop={'10.5px'} inputMobileMarginTop={'11px'}>
            <GradientCard width="100%" height={isPC ? '650px' : '950px'}>
              <Assets
                data={nftListStats.list.map((nft, index) => {
                  return {
                    nftID: index.toString(),
                    nftRegistry: nft.nftRegistry,
                    owner: nft.owner,
                    riskGroup: nft.riskGroup,
                    maturityDate: nft.maturityDate,
                    amount: nft.amount,
                    financeFee: nft.interestFee,
                    status: nft.status,
                    available: nft.available,
                    outstanding: nft.outstanding,
                  } as AssetDataType
                })}
                chainId={chainId ?? ChainId.MAINNET}
                openDetail={() => openDetail(AlphaPoolDetail.ASSET)}
                setDetail={(data: AssetDataType) => {
                  setAssetDetailData(data)
                }}
              />
            </GradientCard>
          </PoolFlex>
        </Pool>

        <div className={classes.bottomSpace}></div>
      </div>

      {/* right side panel */}
      <div className={clsx({ [classes.boxRight]: isDetailOpen.includes(true) }, classes.boxRightHide)}>
        <InvestDetail
          pendingRewards={pendingReward}
          galaxyPoolContract={galaxyPoolContract}
          remainingCapacity={remainingCapacity}
          alphaPoolInfo={AlphaPoolInfo}
          alphaDepositAmounts={alphaDepositAmounts}
          open={isDetailOpen[AlphaPoolDetail.INVEST]}
          setClose={() => closeDetail()}
        />
        {/* <LiquidityDetail open={isDetailOpen[AlphaPoolDetail.LIQUIDITY]} setClose={() => closeDetail()} /> */}
        <EpochDetail open={isDetailOpen[AlphaPoolDetail.EPOCH]} setClose={() => closeDetail()} />
        <AssetDetail
          open={isDetailOpen[AlphaPoolDetail.ASSET]}
          assetDetailData={assetDetailData}
          setClose={() => closeDetail()}
        />
      </div>

      <ActionBoard open={isDrawerOpen[AlphaPoolAction.REDEEM]} setClose={closeDrawer}>
        <InformationCard setClose={closeDrawer} title={t`Redeem`} hideBorderLeft showAccountStatus>
          <RedeemDrawerContent
            seniorTokenPrice={alphaPrice}
            seniorTokenBalance={alphaBalance}
            remainingRedeemToken={investDisburse.remainingRedeemToken}
            payoutCurrencyAmount={investDisburse.payoutCurrencyAmount}
          />
        </InformationCard>
      </ActionBoard>

      <ActionBoard open={isDrawerOpen[AlphaPoolAction.INVEST]} setClose={closeDrawer}>
        <InformationCard
          setClose={closeDrawer}
          title={t`Invest`}
          hideBorderLeft
          showAccountStatus
          showBottom
          handleSupplyOrder={handleSupplyOrder}
          isApprove={isApprove}
          buttonContent={t`DEPOSIT`}
        >
          <Typography variant="h3" style={{ marginTop: 32 }}>
            <Trans>ALPHA Pro Lending Pool</Trans>
          </Typography>
          <Typography variant="body2" style={{ marginTop: 8 }}>
            <Trans>Senior tranche - Lower risk, stable return</Trans>
          </Typography>

          <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
            <Typography variant="body2">
              <Trans>Remaining Capacity</Trans>
            </Typography>
            <Typography variant="body2">{displayBalance(remainingCapacity)}</Typography>
          </Grid>
          <Divider className={classes.informationDivider} />

          <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
            <Typography variant="body2">
              <Trans>RWA Interest APY</Trans>
            </Typography>
            <Typography variant="body2">{aprs[0].alpha[activeAlphaPool].alpha}%</Typography>
          </Grid>
          <Divider className={classes.informationDivider} />

          <Typography variant="body2" style={{ marginTop: 30 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita dolorem laborum ullam omnis! Totam
            laudantium maxime eligendi, distinctio dolorem ut.
          </Typography>

          {/* <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
            <Typography variant="body2">Test content</Typography>
            <Typography variant="body2">{displayBalanceUnits(alphaPrice)}</Typography>
          </Grid>
          <Divider className={classes.informationDivider} />

          <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
            <Typography variant="body2">Test content</Typography>
            <Typography variant="body2">{displayBalanceUnits(alphaPrice)}</Typography>
          </Grid>
          <Divider className={classes.informationDivider} /> */}

          <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
            <Typography variant="body2">
              <Trans>Wallet Balance</Trans>
            </Typography>
            <Typography variant="body2">
              {displayBalance(daiBalance, '0,0.00', true)} {collateralTokenName}
            </Typography>
          </Grid>
          <Divider className={classes.informationDivider} />

          <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
            <Typography variant="body2">
              <Trans>ALPHA Price</Trans>
            </Typography>
            <Typography variant="body2">{displayBalanceUnits(alphaPrice)} USD</Typography>
          </Grid>
          <Divider className={classes.informationDivider} />

          <TestableAmountInput
            ref={amountInput}
            maxNum={daiBalance}
            amount={inputBignumber}
            setAmount={setInputBignumber}
            textFieldLabel={collateralTokenName}
          />
          <Grid container justifyContent="center" style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
            <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              <Trans>Please set the amount</Trans>
            </Typography>
          </Grid>

          {/* <MainButton onClick={handleSupplyOrder} style={{ marginTop: 16 }}>
            <Typography variant="subtitle2">{isApprove === ApprovalState.APPROVED ? 'DEPOSIT' : 'APPROVE'}</Typography>
          </MainButton> */}
        </InformationCard>
      </ActionBoard>

      <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={whitelistModal}
        onClose={() => setWhiteListModal(false)}
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">
            <Trans>Onboarding</Trans>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography variant="h4">
            <Trans>
              ALPHA pools are backed by real-world assets and financing those assets on-chain requires identity
              verification and legal agreements to ensure regulatory requirements are adhered.
            </Trans>
          </Typography>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ padding: 0, background: 'transparent', marginTop: 20 }}
          >
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  StepIconComponent={activeStep > index ? CheckCircle : Circle}
                  style={{ color: 'white' }}
                  classes={{
                    root: index === 4 ? classes.stepLabel : '',
                  }}
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography variant="body1" style={{ marginBottom: 22 }}>
                    {step.description}
                  </Typography>
                  {activeStep == 2 && index == 2 && kycMessage && (
                    <Typography style={{ color: isKYCProcessing ? 'white' : 'red', marginBottom: 22 }}>
                      {kycMessage}
                    </Typography>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {activeStep == 0 && (
                        <Button
                          className={classes.titleButton}
                          onClick={() => {
                            if (chainId && !UIAvailableChainId.includes(chainId)) {
                              return
                            }
                            openModal(ApplicationModal.WALLET)
                          }}
                        >
                          <Typography variant="subtitle2">
                            <Trans>Connect</Trans>
                          </Typography>
                        </Button>
                      )}
                      {activeStep == 1 && (
                        <>
                          <LabelField
                            fieldHeight="120px"
                            isTitleShow={false}
                            iconSrc={sumsubIcon}
                            iconAlt="Sumsub Icon"
                            iconWidth="68px"
                            iconRatio="800/186"
                            classes={{ root: classes.marginBottom_24 }}
                          >
                            <GoogleLogin
                              clientId={GOOGLE_CLIENT_ID}
                              render={(renderProps) => (
                                <Button
                                  style={{ marginBottom: '8px' }}
                                  className={classes.titleButton}
                                  onClick={renderProps.onClick}
                                >
                                  <Typography variant="subtitle2">
                                    <Trans>Log in with Google</Trans>
                                  </Typography>
                                </Button>
                              )}
                              buttonText={t`Login`}
                              onSuccess={successResponseGoogle}
                              onFailure={failureResponseGoogle}
                              isSignedIn={false}
                            />

                            <div style={{ marginTop: '8px' }}>
                              <TelegramLoginButton dataOnauth={responseTelegram} botName="NAOS_Sumsub_KYC_bot" />
                            </div>
                          </LabelField>

                          <LabelField
                            isTitleShow={false}
                            iconSrc={securitizeIcon}
                            iconAlt="Securitize Icon"
                            iconWidth="72px"
                            iconRatio="522.657/101.019"
                          >
                            <Button className={classes.titleButton} onClick={handleKYC}>
                              {isKYCInfoPending && (
                                <CircularProgress size={20} style={{ color: 'white', marginRight: '10px' }} />
                              )}
                              <Typography variant="subtitle2">
                                <Trans>Login with Securitize</Trans>
                              </Typography>
                            </Button>
                          </LabelField>
                        </>
                      )}
                      {activeStep == 2 &&
                        !isKYCProcessing &&
                        (kcyAgency === 'securitize' ? (
                          <Button className={classes.titleButton} onClick={submitKYC}>
                            <Typography variant="subtitle2">
                              <Trans>Continue</Trans>
                            </Typography>
                          </Button>
                        ) : !isKycTypeChoose ? (
                          <>
                            <Button
                              className={classes.titleButton}
                              onClick={() => {
                                handleSumsubKYC('individual')
                              }}
                            >
                              <Typography variant="subtitle2">
                                <Trans>Individual</Trans>
                              </Typography>
                            </Button>
                            <Button
                              className={classes.titleButton}
                              onClick={() => {
                                handleSumsubKYC('entity')
                              }}
                              style={{ marginTop: 12 }}
                            >
                              <Typography variant="subtitle2">
                                <Trans>Entity</Trans>
                              </Typography>
                            </Button>
                          </>
                        ) : !isKYCDone ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={32} style={{ color: 'white', margin: '0 auto' }} />
                          </div>
                        ) : isWalletRegistered === 0 ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={32} style={{ color: 'white', margin: '0 auto' }} />
                          </div>
                        ) : isWalletRegistered === statusCode.ADDRESS_BEEN_REGISTERED ? (
                          <Typography variant="subtitle2">
                            <Trans>This wallet has been registered already. Please register with another wallet.</Trans>
                          </Typography>
                        ) : (
                          isWalletRegistered === statusCode.UNREGISTERED && (
                            <Button className={classes.titleButton} onClick={handleRegisterWallet}>
                              <Typography variant="subtitle2">
                                <Trans>Register Wallet</Trans>
                              </Typography>
                            </Button>
                          )
                        ))}

                      {activeStep == 3 &&
                        (isCheckDocuSign ? (
                          <Button className={classes.titleButton} onClick={signDOC}>
                            {isClickDocuSignBtn && (
                              <CircularProgress size={20} style={{ color: 'white', marginRight: '10px' }} />
                            )}
                            <Typography variant="subtitle2">
                              <Trans>Sign document</Trans>
                            </Typography>
                          </Button>
                        ) : (
                          <Typography variant="body1" style={{ color: 'red' }}>
                            <Trans>Something went wrong, please refresh page. </Trans>
                          </Typography>
                        ))}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <DialogActions style={{ marginTop: 24 }} />
        </DialogContent>
      </Dialog>

      <Backdrop open={comingSoon} style={{ zIndex: 1, color: '#ffffff' }}>
        <Typography variant="h1">
          <Trans>Coming Soon !!</Trans>
        </Typography>
      </Backdrop>
    </div>
  )
}
export default AlphaPro
