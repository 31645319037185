import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core'
import GradientCard from '../../common/GradientCard'
import { X } from 'react-feather'
import { Trans } from '@lingui/macro'
import { Divider } from '../../common/Divider'
import { useGalaxyPoolBoostRatio } from '../../hooks/smartContracts/useGalaxyPoolBoostRatio'
import ADDRESS from '../../constants/addresses'
import { useWeb3React } from '@web3-react/core'
import { ChainId } from '../../constants/blockchain'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      maxWidth: 560,
    },
    title: {
      padding: '20px 24px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    content: {
      padding: '30px 24px 30px 24px',
    },
    barContainer: {
      marginTop: 30,
      maxHeight: 240,
      overflowY: 'scroll',
    },
    bar: {
      height: '100%',
      padding: '18px 20px',
    },
  })
)

function BoostListModal({ rootAddress, boostList, open, onClose, handleUpdate, handleBoostMore }) {
  const classes = useStyles()
  const { account, chainId } = useWeb3React()

  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))

  const [boost0, update0] = useGalaxyPoolBoostRatio(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][rootAddress],
    ADDRESS.BOOST_POOL,
    account ?? undefined,
    0
  )
  // const [boost1, update1] = useGalaxyPoolBoostRatio(
  //   ADDRESS.GALAXY_STAKING_POOL.default[0],
  //   ADDRESS.BOOST_POOL,
  //   account ?? undefined,
  //   1
  // )

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant="h3">
          <Trans>Rebalance Reward Boost</Trans>
        </Typography>
        <X size={24} onClick={onClose} style={{ cursor: 'pointer' }} />
      </DialogTitle>

      <Divider />

      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Typography variant="h4">
            <Trans>
              Rebalance your reward weight in the boost pool, boost again to increase weight and reward APR.
            </Trans>
          </Typography>
        </Grid>

        {/* TODO */}
        {/* Import the pools that in different GALAXY */}
        <Grid container direction="column" wrap="nowrap" classes={{ root: classes.barContainer }}>
          <Grid style={{ width: '100%' }}>
            <GradientCard height={isPC ? '70px' : '90px'}>
              <Grid container alignItems="center" justifyContent="space-between" classes={{ root: classes.bar }}>
                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">{`Rebalance from ${boost0}x -> ${update0}x`}</Typography>
                  <Typography variant="body1" style={{ color: 'rgba(255,255,255,0.6)' }}>
                    <Trans>ALPHA Lending Pool</Trans>
                  </Typography>
                </Grid>
                <Grid style={{ display: 'flex' }}>
                  <Typography
                    variant="h4"
                    style={{ marginRight: 8, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleUpdate(0)}
                  >
                    <Trans>Rebalance</Trans>
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleBoostMore}
                  >
                    <Trans>Boost</Trans>
                  </Typography>
                </Grid>
              </Grid>
            </GradientCard>
          </Grid>

          {/* <Grid style={{ width: '100%', marginTop: 8 }}>
            <GradientCard height="70px">
              <Grid container alignItems="center" justifyContent="space-between" classes={{ root: classes.bar }}>
                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">{`Rebalance from ${boost1}x -> ${update1}x`}</Typography>
                  <Typography variant="body1" style={{ color: 'rgba(255,255,255,0.6)' }}>
                    ALPHA Pool - 90 Days
                  </Typography>
                </Grid>
                <Grid style={{ display: 'flex' }}>
                  <Typography
                    variant="h4"
                    style={{ marginRight: 8, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleUpdate(1)}
                  >
                    Rebalance
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleBoostMore}
                  >
                    Boost
                  </Typography>
                </Grid>
              </Grid>
            </GradientCard>
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default BoostListModal
