import {
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import BarDrawer from './BarDrawer'
import MediaModal from './MediaModal'

export const DrawerWidth = 72
export const ExtendDrawerWidth = 265

const Navbar = () => {

  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))

  return (
    <div className={'appNav'}>
      <BarDrawer />
      {!isPC && <MediaModal />}
    </div>
  )
}

export default Navbar