import Typography from '@material-ui/core/Typography'
import React, { useState, useMemo } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Box from '@material-ui/core/Box'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { Trans } from '@lingui/macro'

import { BasicContainer } from './styled'
import TablePaginationActions from './AssetsTablePaginationActions'
import { AssetDataType } from '.'
import gradientImg from '../../assets/symbol/gradient_rectangle.png'
import { ChainId } from '../../constants/blockchain'
import numeral from 'numeral'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {},
    th: {
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
      borderTop: 'none',
    },
    tr: {
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
      borderTop: '1px solid rgba(255, 255, 255, .12)',
    },
    thover: {
      height: 49,
      [theme.breakpoints.down(960)]: {
        height: 81,
      },
      '&:hover': {
        backgroundImage: `url(${gradientImg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      },
    },
    pagination: {
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
    },
  })
)

const rowsPerPage = 10

const Assets = ({
  data,
  chainId,
  openDetail,
  setDetail,
}: {
  data: Array<AssetDataType>
  chainId: number
  openDetail: () => void
  setDetail: (data: AssetDataType) => void
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const mintTokenName = useMemo(() => (chainId === ChainId.BSC ? 'BUSD' : 'DAI'), [chainId])
  const [page, setPage] = useState(0)
  const handleClick = (data: AssetDataType) => {
    setDetail(data)
    openDetail()
  }

  const colSpan = isPC ? 5 : 3

  const showHeader = useMemo(() => {
    return isPC ? (
      <TableRow>
        <TableCell className={classes.th}>
          <Trans>NFT ID</Trans>
        </TableCell>
        <TableCell align="right" className={classes.th}>
          <Trans>Maturity Date</Trans>
        </TableCell>
        <TableCell align="right" className={classes.th}>
          <Trans>Amount (USD)</Trans>
        </TableCell>
        <TableCell align="right" className={classes.th}>
          <Trans>Financing Fee</Trans>
        </TableCell>
        <TableCell align="right" className={classes.th}>
          <Trans>Status</Trans>
        </TableCell>
      </TableRow>
    ) : (
      <TableRow>
        <TableCell className={classes.th}>
          <Trans>NFT ID</Trans>
        </TableCell>
        <TableCell align="right" className={classes.th}>
          <Trans>Maturity Date</Trans>
        </TableCell>
        <TableCell align="right" className={classes.th}>
          <Trans>Status</Trans>
        </TableCell>
      </TableRow>
    )
  }, [isPC])
  const showData = useMemo(() => {
    return isPC
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <TableRow key={row.nftID} onClick={() => handleClick(row)} className={classes.thover}>
            <TableCell component="th" scope="row" className={classes.tr}>
              {row.nftID}
            </TableCell>
            <TableCell align="right" className={classes.tr}>
              {row.maturityDate}
            </TableCell>
            <TableCell align="right" className={classes.tr}>
              {numeral(row.amount).format('0,0')}
            </TableCell>
            <TableCell align="right" className={classes.tr}>
              {row.financeFee}
            </TableCell>
            <TableCell align="right" className={classes.tr}>
              {row.status}
            </TableCell>
          </TableRow>
        ))
      : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <TableRow key={row.nftID} onClick={() => handleClick(row)} className={classes.thover}>
            <TableCell component="th" scope="row" className={classes.tr} size="small">
              {row.nftID}
            </TableCell>
            <TableCell align="right" className={classes.tr}>
              {row.maturityDate}
            </TableCell>
            <TableCell align="right" className={classes.tr}>
              {row.status}
            </TableCell>
          </TableRow>
        ))
  }, [data, page, isPC])
  const showEmptyRows = useMemo(() => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    if (emptyRows === 0) return <div></div>
    return (
      <TableRow style={isPC ? { height: 49 * emptyRows } : { height: 81 * emptyRows }}>
        <TableCell colSpan={colSpan} className={classes.tr} />
      </TableRow>
    )
  }, [data, page, isPC])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  return (
    <BasicContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>{showHeader}</TableHead>
        <TableBody>
          {showData}
          {showEmptyRows}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={colSpan}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              className={classes.tr}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </BasicContainer>
  )
}

export default Assets
