import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { createStyles, Theme, makeStyles, Typography, Divider, Grid } from '@material-ui/core'
import { ExternalLink as ExteralIcon } from 'react-feather'
import { Trans } from '@lingui/macro'

import { AssetDataType } from '.'
import { BasicTextFlex, BasicTextFlexColumn, InformationSpace, SubInformationSpace } from './styled'
import InformationCard from '../../common/InformationCard'
import ExternalLink from '../../components/commons/ExternalLink'
import { displayBalance } from '../../utils/displayBalance'
import { displayShortString } from '../../utils/displayAddress'
import getExplorerLink from '../../utils/getExplorerLink'
import { ChainId } from '../../constants/blockchain'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
    },
    informationDivider: {
      width: '100%',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
  })
)

const AssetDetail = ({
  open,
  setClose,
  assetDetailData,
}: {
  open: boolean
  setClose: () => void
  assetDetailData?: AssetDataType
}) => {
  const classes = useStyles()
  const { chainId } = useWeb3React()
  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )

  if (!open || !assetDetailData) {
    return <div></div>
  }
  return (
    <InformationCard title="Asset" setClose={setClose}>
      <InformationSpace/>
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Status</Trans>
        </Typography>
        <Typography variant="body2">{assetDetailData.status}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Available for Financing</Trans>
        </Typography>
        <Typography variant="body2">
          {assetDetailData.available} {collateralTokenName}
        </Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Outstanding</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(BigNumber.from(assetDetailData.outstanding))} {collateralTokenName}
        </Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Maturity Date</Trans>
        </Typography>
        <Typography variant="body2">{assetDetailData.maturityDate}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Risk Group</Trans>
        </Typography>
        <Typography variant="body2">{assetDetailData.riskGroup}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Financing fee</Trans>
        </Typography>
        <Typography variant="body2">{assetDetailData.financeFee}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <InformationSpace inputHeight={'40px'} />
      <Typography variant="subtitle1">
        <Trans>NFT Data</Trans>
      </Typography>
      <InformationSpace inputHeight={'24px'} />
      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>NFT ID</Trans>
        </Typography>
        <Typography variant="body2">{assetDetailData.nftID}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlexColumn>
        <Typography variant="body2">
          <Trans>NFT Registry</Trans>
        </Typography>
        <ExternalLink href={assetDetailData.nftRegistry}>
          <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 8 }}>
            <Typography variant="body2">{displayShortString(assetDetailData.nftRegistry, 23, 10)}</Typography>
            <ExteralIcon size={14} color="white" />
          </Grid>
        </ExternalLink>
      </BasicTextFlexColumn>
      <Divider className={classes.informationDivider} />
      <BasicTextFlexColumn>
        <Typography variant="body2">
          <Trans>Current Owner</Trans>
        </Typography>
        <ExternalLink href={getExplorerLink(chainId, assetDetailData.owner, 'address')}>
          <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 8 }}>
            <Typography variant="body2">{displayShortString(assetDetailData.owner, 12, 10)}</Typography>
            <ExteralIcon size={14} color="white" />
          </Grid>
        </ExternalLink>
      </BasicTextFlexColumn>
      <Divider className={classes.informationDivider} />
    </InformationCard>
  )
}

export default AssetDetail
