import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { Typography } from '@material-ui/core'
import { Trans } from '@lingui/macro'

import LineData, { LineDataType } from '../../../common/LineData'
import GradientWhite from '../../../common/GradientCard'
import { BasicText, PoolFlexAsset, LineDataContainer } from '../styled'
import { getRealWorldAprHistory } from '../../../utils/getGalaxyInfo'

export const RealWorldAprGraph = ({ isPC, widthPercent }) => {
  const [lineData, setLineData] = useState<LineDataType | null>(null)
  const { width, ref } = useResizeDetector()
  const [xAxisDate, setXAxisDate] = useState<Object>({})

  useEffect(() => {
    getRealWorldAprHistory().then((ret) => {
      const data = {
        ...ret,
        color: ['#9B9B9B', '#238888', '#1D77FF', '#CFBC36'],
      }

      if (data) {
        const dateArr = data['name']
        const dateObj = {}
        let monthDate = ''
        dateArr.forEach((date: string) => {
          let splitString = date.split(/[\-]/)
          if (monthDate === '' || monthDate !== `${splitString[0]}-${+splitString[1]}`) {
            monthDate = `${splitString[0]}-${+splitString[1]}`
            dateObj[date] = monthDate
          }
        })
        setXAxisDate(dateObj)
      }

      setLineData(data)
    })
  }, [])

  return (
    <GradientWhite width={isPC ? widthPercent : '100%'} height="280px">
      <PoolFlexAsset>
        <BasicText style={{ paddingLeft: 20 }}>
          <Trans>
            <Typography variant="h3">Bank Deposit APR</Typography>
            <Typography variant="h3">in Selected Countries</Typography>
          </Trans>
        </BasicText>
        {/* <LineDataContainer ref={ref}> */}
        <LineDataContainer ref={ref}>
          {lineData && <LineData lineData={lineData} height={280 * 0.68} width={width} XAxisTicks={xAxisDate} />}
        </LineDataContainer>
      </PoolFlexAsset>
    </GradientWhite>
  )
}

export default RealWorldAprGraph
