import { useState, useEffect, useRef, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Typography, Grid } from '@material-ui/core'
import { Trans } from '@lingui/macro'

import ADDRESS from '../../../constants/addresses'
import { ChainId } from '../../../constants/blockchain'
import { useOpenConfirmingModal } from '../../../redux/application/hooks'
import { useTransactionAdder } from '../../../redux/transactions/hooks'

import { useFormationContract } from '../../../hooks/smartContracts/useContract'
import { ApprovalState, useApproveCallback } from '../../../hooks/smartContracts/useApproveCallback'
import { useNTokenContract } from '../../../hooks/smartContracts/useContract'
import { useFormationInfo } from '../../../hooks/smartContracts/useFormationInfo'
import { MainButton } from '../../../common/buttons'
import AmountInput, { IAmountInputRefObj } from '../../../common/AmountInput'
import { Divider } from '../../../common/Divider'
import { displayBalance } from '../../../utils/displayBalance'
import { PoolFlexCenter } from '../styled'

const BorrowDrawerContent = () => {
  const { account, chainId } = useWeb3React()
  const amountInput = useRef<IAmountInputRefObj>(null)
  const nusdContract = useNTokenContract(ADDRESS.NUSD_TOKEN)
  const formationContract = useFormationContract(ADDRESS.FORMATION)

  const mintTokenName = useMemo(() => (chainId === ChainId.BSC ? 'nBUSD' : 'nUSD'), [chainId])

  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const [isApprove, approve] = useApproveCallback(ADDRESS.DAI_TOKEN, inputBignumber, ADDRESS.FORMATION[chainId ?? 1])
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()
  const [collateralDai, borrowedNusd, availableToBorrow, collateralRatio] = useFormationInfo(
    ADDRESS?.FORMATION,
    account ?? undefined
  )
  const [globalMinted, setGlobalMinted] = useState(BigNumber.from(0))
  useEffect(() => {
    if (nusdContract) {
      Promise.all([
        nusdContract.ceiling(ADDRESS.FORMATION[chainId ?? 1]),
        nusdContract.hasMinted(ADDRESS.FORMATION[chainId ?? 1]),
      ]).then(([ceiling, minted]) => setGlobalMinted(ceiling.sub(minted)))
    }
  }, [nusdContract])

  async function handleBorrow() {
    if (isApprove !== ApprovalState.APPROVED) {
      await approve()
    } else {
      if (formationContract && inputBignumber.gt(0)) {
        openConfirmingModal(true)
        try {
          const res = await formationContract.mint(inputBignumber)
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Borrow ${displayBalance(inputBignumber)} ${mintTokenName}`,
          })
          amountInput.current?.resetAmount()
        } catch (error) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>
          Borrow up to 50% of collateral in {mintTokenName}. Your debt will be automatically paid down by the yield
          strategy.
        </Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>Global Mintable {mintTokenName}</Trans>
        </Typography>
        <Typography variant="body2">
          {displayBalance(globalMinted)} {mintTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>Available to Borrow</Trans>
        </Typography>
        <Typography variant="body2">
          {+collateralRatio
            ? displayBalance(availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd))
            : '0'}{' '}
          {mintTokenName}
        </Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={amountInput}
        maxNum={
          +collateralRatio
            ? availableToBorrow.add(collateralDai.div(+collateralRatio)).sub(borrowedNusd)
            : BigNumber.from(0)
        }
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={mintTokenName}
      />
      <PoolFlexCenter style={{ marginTop: `4px`, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set borrow amount</Trans>
        </Typography>
      </PoolFlexCenter>
      {/* <PoolFlexCenter style={{ marginTop: `13px`, justifyContent: `flex-end` }}>
        <MainButton onClick={handleBorrow}>
          <Typography variant="body1">Borrow</Typography>
        </MainButton>
      </PoolFlexCenter> */}
      <MainButton onClick={handleBorrow} style={{ marginTop: 16 }}>
        <Typography variant="subtitle2">
          <Trans>BORROW</Trans>
        </Typography>
      </MainButton>
    </>
  )
}
export default BorrowDrawerContent
