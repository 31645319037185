import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { AppDispatch, AppState } from '../index'
import { addPopup, ApplicationModal, PopupContent, removePopup, setOpenModal } from './actions'
import { IOpenModal } from './reducer'
import { ChainId } from '../../constants/blockchain'
import { t } from '@lingui/macro'

// Blocknumber
export function useBlockNumber(): number | undefined {
  const { chainId } = useWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

// Modal
export function useOpenModal() {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(
    (modal: ApplicationModal = ApplicationModal.ADDRESS_CLAIM, data = {}) => dispatch(setOpenModal({ modal, data })),
    [dispatch]
  )
}

export function useCloseModals() {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal({ modal: null })), [dispatch])
}

export function useModalIsOpen(modal?: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal.modal)
  if (modal !== undefined) {
    return openModal === modal
  } else {
    return openModal ? true : false
  }
}

export function useToggleModal() {
  const open = useModalIsOpen()
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    if (open) {
      dispatch(setOpenModal({ modal: null }))
    }
  }, [dispatch, open])
}

export function useOpenConfirmingModal() {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(
    (attemptingTxn: boolean, txHash?: string, error?: any, chainId?: number) => {
      const prefix =
        t`Please send the error to support@naos.finance or contact our admin in Telegram group @naos_finance for assistance.`

      if (chainId && chainId !== ChainId.BSC_TEST && chainId !== ChainId.BSC) {
        const errorMessage = error
          ? error.code === 4001
            ? error.message
            : 'Error: ' + error.error.message + '.\n' + prefix
          : ''
        dispatch(
          setOpenModal({
            modal: ApplicationModal.CONFIRMATION,
            data: { attemptingTxn, txHash, errorMessage },
          })
        )
      } else {
        const errorMessage = error
          ? error.code === 4001
            ? error.message
            : 'Error: ' + error.message + '\n' + prefix
          : ''
        dispatch(
          setOpenModal({
            modal: ApplicationModal.CONFIRMATION,
            data: { attemptingTxn, txHash, errorMessage },
          })
        )
      }
    },
    [dispatch]
  )
}

export function useConfirmationModalInfo(): IOpenModal {
  const info = useSelector((state: AppState) => state.application.openModal)
  return useMemo(() => {
    if (info.modal === ApplicationModal.CONFIRMATION) {
      return info.data as IOpenModal
    } else {
      return { attemptingTxn: false, errorMessage: undefined, txHash: undefined }
    }
  }, [info])
}

// export function useOpenModalInfo(): IOpenModal {
//   const info = useSelector((state: AppState) => state.application.openModal)
//   return info ?? { attemptingTxn: false, errorMessage: undefined, txHash: undefined }
// }

// Popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}

export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}
