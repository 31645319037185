import { useState } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import InformationCard from '../../common/InformationCard'
import Typography from '@material-ui/core/Typography'
import { BasicTextFlex, InformationSpace } from './styled'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
    },
    informationDivider: {
      width: '100%',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
  })
)

const LiquidityDetail = ({ open, setClose }: { open: boolean; setClose: () => void }) => {
  const classes = useStyles()
  if (!open) {
    return <div></div>
  }
  return (
    <InformationCard title="Epoch Details" setClose={setClose}>
      <Typography variant="subtitle1">Total Locked Orders</Typography>
      <InformationSpace />
      <BasicTextFlex>
        <Typography variant="body2">Investments Alpha Tranche</Typography>
        <Typography variant="body2">9,900 DAI</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Investments Beta Tranche</Typography>
        <Typography variant="body2">0 DAI</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Total Pending Investments</Typography>
        <Typography variant="body2">9,900 DAI</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Redemptions Alpha Tranche</Typography>
        <Typography variant="body2">9,900 DAI</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <InformationSpace />
      <BasicTextFlex>
        <Typography variant="body2">Redemptions Beta Tranche</Typography>
        <Typography variant="body2">0 Beta</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Estimated Pending Redemptions</Typography>
        <Typography variant="body2">0 DAI</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <InformationSpace />
      <Typography variant="subtitle1">Total Locked Orders</Typography>
      <InformationSpace />
      <BasicTextFlex>
        <Typography variant="body2">Epoch #</Typography>
        <Typography variant="body2">57</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Minimum epoch duration</Typography>
        <Typography variant="body2">24 hrs</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Current epoch state</Typography>
        <Typography variant="body2">Open</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
      <BasicTextFlex>
        <Typography variant="body2">Total epoch investment capacity</Typography>
        <Typography variant="body2">1 DAI</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
    </InformationCard>
  )
}

export default LiquidityDetail
