import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Trans } from '@lingui/macro'
import {
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
  Link,
  Breadcrumbs,
  createStyles,
  Theme,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import queryString from 'query-string'
import SumsubWebSdk from '@sumsub/websdk-react'
import { SnsError } from '@sumsub/websdk/types/types'
import { Button } from './styled'
import { PoolFlexWrapper } from '../Home/styled'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100vw',
      padding: '12px 28px 12px 0px',

      // overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up(960)]: {
        // maxWidth: '90.625vw',
        maxHeight: '100%',
        marginBottom: '12px',
      },
    },
    informationBox: {
      width: '100%',
      height: '100vh',
      overflowY: 'auto',
      // marginBottom: '12px',
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
    },
    sumsubWrapper: {
      width: '100%',
      height: 'auto',
    },
    waitProgress: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(960)]: {
        height: '560px',
      },
    },
    space: {
      width: '100%',
      height: '120px',
    },
    titleButton: {
      width: '120px',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      margin: '0 auto',
      cursor: 'pointer',
    },
  })
)

const Sumsub = () => {
  const classes = useStyles()
  const history = useHistory()
  const { account, chainId } = useWeb3React()
  const [sumsubAccessToken, setSumsubAccessToken] = useState<string>('')
  const [redirectURL, setRedirectURL] = useState<string>('')
  const [kycType, setKycType] = useState<string>('')
  const [isPending, setIsPending] = useState<boolean>(false)

  const onClickLink = () => {
    window.open(redirectURL, '_self')
  }

  useEffect(() => {
    if (!account || chainId === undefined || chainId === null || isNaN(chainId)) {
      return
    }

    let query = window.location.search.substring(1)
    const parsed = queryString.parse(query)
    if (
      parsed &&
      parsed.access &&
      parsed.redirecturl &&
      parsed.type &&
      typeof parsed.access === 'string' &&
      typeof parsed.redirecturl === 'string' &&
      typeof parsed.type === 'string'
    ) {
      setSumsubAccessToken(parsed.access)
      setRedirectURL(parsed.redirecturl)
      setKycType(parsed.type)
    }
  }, [account, chainId])

  return (
    <div className={classes.root}>
      {!!sumsubAccessToken && sumsubAccessToken !== '' ? (
        <>
          <div className={classes.informationBox}>
            <div>
              <SumsubWebSdk
                accessToken={sumsubAccessToken}
                expirationHandler={async () => {
                  return 'new-token'
                }}
                // config={config}
                // options={options}
                onMessage={(type: any, payload: any) => {
                  if (
                    type === 'idCheck.applicantStatus' &&
                    payload.reviewStatus &&
                    payload.reviewResult &&
                    payload.reviewResult.reviewAnswer
                  ) {
                    switch (payload.reviewStatus) {
                      case 'init':
                        // console.log('init')
                        break
                      case 'completed':
                        console.log('completed')
                        if (payload.reviewResult.reviewAnswer === 'GREEN') {
                          setTimeout(() => {
                            window.open(redirectURL + `?event=sumsub_kyc&kycType=${kycType}`, '_self')
                          }, 5000)
                        }
                        break
                      default:
                        // console.log('pending')
                        // if(payload.reviewResult)
                        //   console.log('payload.reviewResult: ',payload.reviewResult)

                        setIsPending(true)

                        break
                    }
                  }
                }}
                onError={(err: SnsError) => {
                  console.log('onError: ', err.error)
                }}
              />
            </div>
            {isPending && (
              <Button className={classes.titleButton} onClick={onClickLink}>
                <Trans>Back to Galaxy Page</Trans>
              </Button>
            )}
            <div className={classes.space}></div>
          </div>
        </>
      ) : (
        <div className={classes.waitProgress}>
          <CircularProgress size={50} style={{ color: 'white' }} />
        </div>
      )}
    </div>
  )
}

export default Sumsub
