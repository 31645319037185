import ADDRESSES from '../../constants/addresses'
import { useStakingPoolContract, useUniswapV2PairContract, IAddressOrAddressMap } from './useContract'
import { BigNumber, utils } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useTokenPrice } from '../useTokenPrice'
import { GALAXY_API_HOST, OFFICIAL_WEB_API_HOST } from '../../constants/endpoints'
import useStakingPoolId from '../useStakingPoolId'
import { ChainId, SEC_PER_BLOCK_CHAIN } from '../../constants/blockchain'
import { useWeb3React } from '@web3-react/core'
import { ContactSupportOutlined } from '@material-ui/icons'
import { useGalaxyStakingPoolContract, useBetaInsuranceContract } from './useContract'
import { useBetaInsuranceInfo } from './useBetaInsuranceInfo'
import BigNumberJs from 'bignumber.js'

// LEGACY: use new function below
// export function useFarmingPoolsApr(address?: IAddressOrAddressMap) {
//   const poolContract = useStakingPoolContract(address)
//   const lpTokenContract = useUniswapV2PairContract(ADDRESS.UNI_ETH_NAOS_LPTOKEN)
//   const ethPrice = useTokenPrice('ETH')
//   const naosPrice = useTokenPrice('NAOS')

//   const [poolApr, setPoolApr] = useState([0, 0, 0, 0])

//   // NAOS Pool
//   useEffect(() => {
//     if (poolContract) {
//       Promise.all([poolContract.getPoolRewardRate(PoolId.NAOS), poolContract.getPoolTotalDeposited(PoolId.NAOS)]).then(
//         ([rewardRate, naosDeposited]) => {
//           const share = 1 / (+utils.formatEther(naosDeposited) + 1)
//           const rewardPerSec = +utils.formatEther(rewardRate) / 13.5
//           setPoolApr((aprs) => ({
//             ...aprs,
//             [PoolId.NAOS]: share * rewardPerSec * 60 * 60 * 24 * 365 * 100,
//           }))
//         }
//       )
//     }
//   }, [poolContract])

//   // UNI-LP Pool
//   useEffect(() => {
//     if (poolContract && ethPrice && naosPrice && lpTokenContract) {
//       Promise.all([
//         poolContract.getPoolRewardRate(PoolId.NAOS_ETH_LP),
//         poolContract.getPoolTotalDeposited(PoolId.NAOS_ETH_LP),
//         lpTokenContract.getReserves(),
//         lpTokenContract.totalSupply(),
//       ]).then(([rewardRate, lpDeposited, { _reserve0: naosBalance, _reserve1: ethBalance }, totalSupply]) => {
//         const share = 1 / (+utils.formatEther(lpDeposited) + 1)
//         const rewardPerSec = +utils.formatEther(rewardRate) / 13.5

//         // [Hack]
//         // Testnet setting error results that reserve0 is ETH but NAOS
//         if (ethBalance.gt(naosBalance)) {
//           const tmp = naosBalance
//           naosBalance = ethBalance
//           ethBalance = tmp
//         }

//         const lpPrice =
//           (+utils.formatEther(ethBalance) * ethPrice + +utils.formatEther(naosBalance) * naosPrice) /
//           +utils.formatEther(totalSupply)

//         setPoolApr((aprs) => ({
//           ...aprs,
//           [PoolId.NAOS_ETH_LP]: (share * rewardPerSec * 60 * 60 * 24 * 365 * naosPrice * 100) / lpPrice,
//         }))
//       })
//     }
//   }, [poolContract, lpTokenContract, ethPrice, naosPrice])

//   // nUSD Pool
//   useEffect(() => {
//     if (poolContract && naosPrice) {
//       Promise.all([
//         poolContract.getPoolRewardRate(PoolId.NUSD_3CRV),
//         poolContract.getPoolTotalDeposited(PoolId.NUSD_3CRV),
//       ]).then(([rewardRate, nusdDeposited]) => {
//         const share = 1 / (+utils.formatEther(nusdDeposited) + 1)
//         const rewardPerSec = +utils.formatEther(rewardRate) / 13.5
//         setPoolApr((aprs) => ({
//           ...aprs,
//           [PoolId.NUSD]: share * rewardPerSec * 60 * 60 * 24 * 365 * naosPrice * 100,
//           [PoolId.NUSD_3CRV]: share * rewardPerSec * 60 * 60 * 24 * 365 * naosPrice * 100,
//         }))
//       })
//     }
//   }, [poolContract, naosPrice])

//   return useMemo(() => poolApr, [poolApr])
// }

interface IPoolInfo {
  [addr: string]: { [poolId: string]: number }
}

export function useStkaingPoolsApr() {
  const [aprs, setAprs] = useState<Record<'eth' | 'bsc', IPoolInfo>>({
    eth: {
      [ADDRESSES.STAKINGPOOL[ChainId.MAINNET]]: {},
      [ADDRESSES.STAKINGPOOL_WITH_TRANSFER[ChainId.RINKEBY]]: {},
      [ADDRESSES.BOOST_POOL[ChainId.RINKEBY]]: {},
    },
    bsc: {
      [ADDRESSES.STAKINGPOOL_WITH_TRANSFER[ChainId.BSC]]: {},
      [ADDRESSES.BOOST_POOL[ChainId.BSC]]: {},
    },
  })

  useEffect(() => {
    const setData = () =>
      fetch(`${GALAXY_API_HOST}/api/v1/farm/aprs`).then((res) =>
        res.json().then(({ aprs }) => {
          const val = {
            eth: aprs['eth'],
            bsc: aprs['bsc'],
          }
          setAprs(val)
        })
      )

    setData()

    const interval = setInterval(() => {
      if (Object.keys(aprs['bsc']).length === 0) {
        setData().then(() => clearInterval(interval))
      } else {
        clearInterval(interval)
      }
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  return useMemo(() => aprs, [aprs])
}

const getData = async (
  naosPrice: number,
  account: string,
  chainId: number,
  rwaPoolAddress: string,
  galaxyPoolContract: any,
  betaPrice?: BigNumber,
  betaTransferContract?: any
) => {
  const totalDeposited = await galaxyPoolContract.getPoolTotalDepositedWeight(0)
  const share = new BigNumberJs(1).div(new BigNumberJs(utils.formatEther(totalDeposited)).plus(1))
  const rewardRate = new BigNumberJs(utils.formatEther(await galaxyPoolContract.getPoolRewardRate(0)))

  const getStakeDepositedWeight = new BigNumberJs(
    (await galaxyPoolContract.getStakeTotalDepositedWeight(account, 0)).toString()
  )
  const getPooltotalDepositedWeight = new BigNumberJs(
    (await galaxyPoolContract.getPoolTotalDepositedWeight(0)).toString()
  )
  const reward = rewardRate
    .multipliedBy(86400)
    .dividedBy(SEC_PER_BLOCK_CHAIN[chainId])
    .multipliedBy(365)
    .multipliedBy(naosPrice)

  const getStakeDeposited = new BigNumberJs((await galaxyPoolContract.getStakeTotalDeposited(account, 0)).toString())

  const self = getStakeDepositedWeight
    .dividedBy(getPooltotalDepositedWeight)
    .multipliedBy(reward)
    .multipliedBy(10 ** 18)
    .dividedBy(getStakeDeposited)
    .multipliedBy(100)

  const rewardPerSec = rewardRate.div(SEC_PER_BLOCK_CHAIN[chainId])

  const apr = share
    .multipliedBy(rewardPerSec)
    .multipliedBy(60)
    .multipliedBy(60)
    .multipliedBy(24)
    .multipliedBy(365)
    .multipliedBy(naosPrice)
    .multipliedBy(100)

  const aprs = {
    naos: Number(apr.toFixed(2)) || 28,
    alpha: 7,
    self: Number(self.toFixed(2)) || 0,
  }

  if (betaTransferContract && betaPrice && chainId) {
    let poolId = 0

    if (chainId === ChainId.BSC) {
      poolId = 1
    }

    const avgSecondPerBlock = SEC_PER_BLOCK_CHAIN[chainId]
    const betaTotalDeposited = utils.formatEther(await betaTransferContract.getPoolTotalDeposited(0))
    const betaRewardRate = utils.formatEther(await betaTransferContract.getPoolRewardRate(poolId))

    const insuranceBalance = utils.formatEther(betaPrice)
    const betaShare = 1 / (+betaTotalDeposited + 1)
    const rewardPerSec = +betaRewardRate / avgSecondPerBlock
    const betaApr =
      +insuranceBalance > 0
        ? (+(betaShare * rewardPerSec * 60 * 60 * 24 * 365) * 100 * naosPrice) / +insuranceBalance
        : 0

    const aprsFormatted: any = {
      alpha: [
        {
          naos: Number(aprs.naos),
          alpha: Number(aprs.alpha),
          self: Number(aprs.self),
        },
      ],
      beta: [Number(betaApr)],
    }

    const resultObj = {
      [chainId]: {
        [rwaPoolAddress]: {
          0: aprsFormatted,
        },
      },
    }

    if (account) {
      return resultObj
    } else {
      return
    }
  }
}

export function useRWAStkaingPoolsApr(account?: string, rwaPoolAddress?: string) {
  const { chainId } = useWeb3React()
  const naosPrice = useTokenPrice('NAOS')
  const galaxyPoolContract = useGalaxyStakingPoolContract(rwaPoolAddress)
  const [, betaPrice] = useBetaInsuranceInfo(ADDRESSES.BETA_INSURANCE)
  const betaTransferContract = useStakingPoolContract(ADDRESSES.STAKINGPOOL_WITH_TRANSFER)
  const [aprs, setAprs] = useState<Record<string, any>>({
    0: {
      alpha: [
        { naos: 0, alpha: 0, self: 0 },
        // { naos: 0, alpha: 0, self: 0 },
      ],
      beta: [0],
      max: [0],
    },
  })

  useEffect(() => {
    if (account && chainId && rwaPoolAddress && rwaPoolAddress !== '' && galaxyPoolContract) {
      getData(naosPrice, account, chainId, rwaPoolAddress, galaxyPoolContract, betaPrice, betaTransferContract)
        .then((data) => {
          if (data) {
            const apr = data[chainId][rwaPoolAddress]
            for (const poolId of Object.keys(apr)) {
              let maxApr = 0
              for (const { naos, alpha } of apr[poolId].alpha) {
                maxApr = Math.max(maxApr, naos + alpha)
              }
              for (const betaApr of apr[poolId].beta) {
                maxApr = Math.max(maxApr, betaApr)
              }
              apr[poolId]['max'] = [maxApr]
            }
            setAprs(apr)
          }
        })
        .catch((err) => {
          console.error(err)
        })

      // let interval
      // if (chainId) {
      //   setData(chainId)
      //   interval = setInterval(() => {
      //     if (aprs.length === 0) {
      //       setData(chainId).then(() => clearInterval(interval))
      //     } else {
      //       clearInterval(interval)
      //     }
      //   }, 5000)
      // }

      // return () => clearInterval(interval)
    }
  }, [chainId, account, rwaPoolAddress, naosPrice, galaxyPoolContract, betaPrice, betaTransferContract])

  return useMemo(() => aprs, [aprs])
}

//From Backend
export function useRWAStakingPoolsAprBE(rwaPoolAddress?: string) {
  const { chainId } = useWeb3React()
  const [aprs, setAprs] = useState<Record<string, any>>({
    0: {
      alpha: [
        { naos: 0, alpha: 0, self: 0 },
        // { naos: 0, alpha: 0, self: 0 },
      ],
      beta: [0],
      max: [0],
    },
  })

  let endPoint = `${GALAXY_API_HOST}/api/v1/galaxy/aprs`
  // endPoint = account ? `${endPoint}?address=${account}` : endPoint

  useEffect(() => {
    if (rwaPoolAddress && rwaPoolAddress !== '') {
      const setData = async (chainId) =>
        fetch(endPoint).then((res) =>
          res.json().then((data) => {
            const chain = chainId === ChainId.BSC ? 'bsc' : 'eth'
            const apr = data[chain][rwaPoolAddress]
            if (apr) {
              for (const poolId of Object.keys(apr)) {
                let maxApr = 0
                for (const { naos, alpha } of apr[poolId].alpha) {
                  maxApr = Math.max(maxApr, naos + alpha)
                }
                // for (const betaApr of apr[poolId].beta) {
                //   maxApr = Math.max(maxApr, betaApr)
                // }
                apr[poolId]['max'] = [maxApr]
              }
              setAprs(apr)
            }
          })
        )

      let interval
      if (chainId) {
        setData(chainId)
        interval = setInterval(() => {
          if (aprs.length === 0) {
            setData(chainId).then(() => clearInterval(interval))
          } else {
            clearInterval(interval)
          }
        }, 5000)
      }

      return () => clearInterval(interval)
    }
  }, [chainId, rwaPoolAddress])

  return useMemo(() => aprs, [aprs])
}

export function useRWAProStakingPoolsApr(rwaPoolAddress?: string) {
  const { chainId } = useWeb3React()
  const [aprs, setAprs] = useState<Record<string, any>>({
    0: {
      alpha: [
        { naos: 0, alpha: 0, self: 0 },
        // { naos: 0, alpha: 0, self: 0 },
      ],
      beta: [0],
      max: [0],
    },
  })

  let endPoint = `${GALAXY_API_HOST}/api/v1/galaxy/pro/aprs`

  useEffect(() => {
    if (rwaPoolAddress && rwaPoolAddress !== '') {
      const setData = async (chainId) =>
        fetch(endPoint).then((res) =>
          res.json().then((data) => {
            const chain = chainId === ChainId.BSC ? 'bsc' : 'eth'
            const apr = data[chain][rwaPoolAddress]

            for (const poolId of Object.keys(apr)) {
              let maxApr = 0
              for (const { naos, alpha } of apr[poolId].alpha) {
                maxApr = Math.max(maxApr, naos + alpha)
              }
              apr[poolId]['max'] = [maxApr]
            }
            setAprs(apr)
          })
        )

      let interval
      if (chainId) {
        setData(chainId)
        interval = setInterval(() => {
          if (aprs.length === 0) {
            setData(chainId).then(() => clearInterval(interval))
          } else {
            clearInterval(interval)
          }
        }, 60000)
      }

      return () => clearInterval(interval)
    }
  }, [chainId, rwaPoolAddress])

  return useMemo(() => aprs, [aprs])
}
