import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// import { useActiveWeb3React } from '../../hooks/web3'
import { useWeb3React } from '@web3-react/core'
import { AppDispatch, AppState } from '../index'
import { addTransaction } from './actions'
import { TransactionDetails } from './reducer'
import { useOpenModal } from '../application/hooks'
import { ApplicationModal } from '../application/actions'

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (
  response: TransactionResponse,
  customData?: { summary?: string; approval?: { tokenAddress: string; spender: string }; claim?: { recipient: string } }
) => void {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (
      response: TransactionResponse,
      {
        summary,
        approval,
        claim,
      }: { summary?: string; claim?: { recipient: string }; approval?: { tokenAddress: string; spender: string } } = {}
    ) => {
      if (!account) return
      if (!chainId) return

      const { hash } = response
      if (!hash) {
        throw Error('No transaction hash found.')
      }
      dispatch(addTransaction({ hash, from: account, chainId, approval, summary, claim }))
    },
    [dispatch, chainId, account]
  )
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId, account } = useWeb3React()
  const tx = useSelector<AppState, AppState['transactions']>((state) => state.transactions)

  return useMemo(() => {
    if (chainId && tx[chainId] && account) {
      const txByUser = {}
      Object.values(tx[chainId]).map((tx) => {
        if (tx.from === account) txByUser[tx.hash] = tx
      })
      return txByUser
    } else {
      return {}
    }
  }, [chainId, account, tx])
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(tokenAddress: string | undefined, spender: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          const approval = tx.approval
          if (!approval) return false
          return approval.spender === spender && approval.tokenAddress === tokenAddress
        }
      }),
    [allTransactions, spender, tokenAddress]
  )
}

export function useAllPendingTransactions(): TransactionDetails[] {
  const allTransactions = useAllTransactions()
  return Object.values(allTransactions).filter((tx) => !tx.receipt)
}
