import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { IAddressOrAddressMap, useAdapterContract } from './useContract'

export function useAdapterInfo(address?: IAddressOrAddressMap): [depositedDaiInVault: BigNumber] {
  const blockNumber = useBlockNumber()
  const adapterContract = useAdapterContract(address)
  const [depositedDaiInVault, setDepositedDaiInVault] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (adapterContract) {
      adapterContract
        .totalValue()
        .then(setDepositedDaiInVault)
        .catch(() => {})
    }
  }, [adapterContract, blockNumber])

  return useMemo(() => [depositedDaiInVault], [depositedDaiInVault])
}
