import { createStyles, Theme, makeStyles, Typography, Divider, Grid } from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'

import InformationCard from '../../common/InformationCard'
import { displayDateAndTime } from '../../utils/displayDate'
import { displayBalance } from '../../utils/displayBalance'
import { displayAddress } from '../../utils/displayAddress'
import { ITransactionData } from '.'
import { BasicTextFlex, InformationSpace } from './styled'
import ExternalLink from '../../components/commons/ExternalLink'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: '2px',
      backgroundColor: theme.palette.text.primary,
    },
    informationDivider: {
      width: '100%',
      backgroundColor: theme.palette.text.primary,
      opacity: 0.12,
      margin: '16px 0',
    },
  })
)

const InvestmentDetail = ({
  open,
  setClose,
  data,
}: {
  open: boolean
  setClose: () => void
  data?: ITransactionData
}) => {
  const classes = useStyles()

  if (!open) {
    return <div></div>
  }
  return (
    <InformationCard title={t`Transaction Detail`} setClose={setClose}>
      <InformationSpace />

      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>TxID</Trans>
        </Typography>

        <ExternalLink href={data?.url ? data.url : ''}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" style={{ marginRight: 4 }}>
              {displayAddress(data?.tx, 8, 6)}
            </Typography>
            <ExternalLinkIcon color="white" size={14} />
          </Grid>
        </ExternalLink>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />

      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Contract</Trans>
        </Typography>
        <Typography variant="body2">{data?.contractName}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />

      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Action</Trans>
        </Typography>
        <Typography variant="body2">{data?.method}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />

      {data?.method !== 'EXIT' && data?.method !== 'REPAY' && (
        <>
          <BasicTextFlex>
            <Typography variant="body2">
              <Trans>Amount</Trans>
            </Typography>
            <Typography variant="body2">
              {displayBalance(data?.amount)} {data?.token}
            </Typography>
          </BasicTextFlex>
          <Divider className={classes.informationDivider} />
        </>
      )}

      <BasicTextFlex>
        <Typography variant="body2">
          <Trans>Date</Trans>
        </Typography>
        <Typography variant="body2">{data?.date && displayDateAndTime(new Date(data.date))}</Typography>
      </BasicTextFlex>
      <Divider className={classes.informationDivider} />
    </InformationCard>
  )
}

export default InvestmentDetail
