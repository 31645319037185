import { BigNumber, utils } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from '../../redux/application/hooks'
import { useTokenContract, IAddressOrAddressMap } from './useContract'

export function useTokenBalance(address?: IAddressOrAddressMap, owner?: string): [b1: BigNumber, b2: string] {
  const contract = useTokenContract(address, false)
  const blockNumber = useBlockNumber()
  const [balanceBN, setBalanceBN] = useState<BigNumber>()
  const [decimals, setDecimals] = useState<number>()

  useEffect(() => {
    if (contract && owner) {
      contract.balanceOf(owner).then((b) => {
        setBalanceBN(b)
      }).catch(() => {})
      contract.decimals().then((d) => {
        setDecimals(d)
      }).catch(() => {})
    }
  }, [contract, owner, blockNumber])

  return useMemo(
    () => (balanceBN && decimals ? [balanceBN, utils.formatUnits(balanceBN, decimals)] : [BigNumber.from(0), '0']),
    [balanceBN, decimals]
  )
}
