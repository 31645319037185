import { Typography, Grid, Checkbox } from '@material-ui/core'
import { BigNumber } from 'ethers'
import { useState, Fragment } from 'react'
import styled from 'styled-components'
import { Trans, t } from '@lingui/macro'

import { MainButton } from '../../../../../common/buttons'
import { Divider } from '../../../../../common/Divider'
import { displayBalance } from '../../../../../utils/displayBalance'
import { displayDate } from '../../../../../utils/displayDate'
import { useBoostPoolContract } from '../../../../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../../../../redux/application/hooks'
import { useTransactionAdder } from '../../../../../redux/transactions/hooks'

const MyCheckbox = styled(Checkbox)`
  padding: unset;
  &.MuiCheckbox-colorSecondary.Mui-disabled {
    color: rgba(255, 255, 255, 0.2);
  }
`

interface IStakingPoolDrawerProps {
  poolId: number
  tokenAddr: string
  poolAddr: string
  symbol: string
  availableAmount: BigNumber
  lockTimes: BigNumber[]
  orders: any[]
  chainId: number
}

function OrderRow({ amount, endDate, onChecked }) {
  const [checked, setChecked] = useState(false)
  const isDue = Date.now() > endDate.toNumber() * 1000 ? true : false
  const date = displayDate(new Date(endDate.toNumber() * 1000))

  const handleCheck = (e) => {
    setChecked(e.target.checked)
    onChecked(e.target.checked)
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" style={{ margin: '12px 0px' }}>
      <Grid direction="column">
        <Typography variant="subtitle1">{displayBalance(amount)} NAOS</Typography>
        <Typography variant="body1">
          {/* {date}, {postfix} */}
          <Trans>locked until {date}</Trans>
        </Typography>
      </Grid>
      <Grid>
        <MyCheckbox checked={checked} onChange={handleCheck} disabled={!isDue} />
      </Grid>
    </Grid>
  )
}

const UnstakeDrawerContent = ({ poolAddr, lockTimes, orders, chainId }: IStakingPoolDrawerProps) => {
  const [withdrawOrderIds, setWithdrawOrderIds] = useState<number[]>([])
  const boostPoolContract = useBoostPoolContract(poolAddr)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  async function handleUnstake() {
    if (boostPoolContract && withdrawOrderIds.length > 0) {
      openConfirmingModal(true)
      try {
        const res = await boostPoolContract.withdraw(withdrawOrderIds)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Unstake orders, id: ${withdrawOrderIds.join(',')}`,
        })
      } catch (error) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleCheck(id, check) {
    if (check) {
      if (!withdrawOrderIds.includes(id)) {
        setWithdrawOrderIds([...withdrawOrderIds, id])
      }
    } else {
      setWithdrawOrderIds(withdrawOrderIds.filter((i) => i !== id))
    }
  }

  return (
    <>
      <Grid style={{ marginTop: 32 }} />
      {orders.map((order, idx) => {
        // `isWithdraw` should be false
        const [amount, expiredTime, weighted, isWithdraw] = order
        if (!isWithdraw) {
          return (
            <Fragment key={`${order}-${idx}`}>
              <OrderRow amount={amount} endDate={expiredTime} onChecked={(check) => handleCheck(idx, check)} />
              <Divider />
            </Fragment>
          )
        }
      })}
      <MainButton style={{ marginTop: 42 }} onClick={handleUnstake}>
        <Typography variant="subtitle2">
          <Trans>UNSTAKE</Trans>
        </Typography>
      </MainButton>
    </>
  )
}

export default UnstakeDrawerContent
