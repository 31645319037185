import { useMediaQuery, useTheme, createStyles, Theme, makeStyles, Typography, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo, useState } from 'react'
import { ExternalLink as IconExternal, ChevronDown, ChevronUp, ChevronRight } from 'react-feather'
import { Trans } from '@lingui/macro'
import { i18n } from '@lingui/core'

import { TitleTextDiv, TitleTextMobileHide, Pool, Basic, BasicText } from '../../components/pool/PoolBar'
import ExternalLink from '../../components/commons/ExternalLink'
import URLS from '../../constants/urls'
import useStakingPoolId from '../../hooks/useStakingPoolId'
import { IS_DEV, USED_CHAIN_ID } from '../../constants/env'
import { ChainId } from '../../constants/blockchain'
import ADDRESS from '../../constants/addresses'
import InvestPoolBar from './InvestPoolBar'
import { PoolNames, MobilePoolNames } from '../../constants/investConstants'
import { usePositionOffset, useBackToOrigin } from '../../redux/navbar/hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    informationBox: {
      padding: '32px 48px 32px 0',
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down(960)]: {
        padding: '16px 16px',
        marginBottom: 60,
      },
    },
    subRow: {
      marginTop: 8,
    },
  })
)

export interface AssetDataType {
  nftID: string
  nftRegistry: string
  owner: string
  riskGroup: string
  maturityDate: string
  amount: string
  financeFee: string
  status: string
  available: string
  outstanding: string
}

const Invest = () => {
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const classes = useStyles()
  const history = useHistory()
  const floatButtonOffset = usePositionOffset()
  const backFloatButton = useBackToOrigin()

  useEffect(() => {
    if (isPC && floatButtonOffset > 0) {
      backFloatButton()
    }
  })

  const { chainId } = useWeb3React()

  useEffect(() => {
    if(chainId && chainId !== ChainId.BSC) {
      history.push('/home')
    }
  }, [chainId])

  const PoolId = useStakingPoolId()

  const [alphaRootArray, setAlphaRootArray] = useState(Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]))

  useEffect(() => {
    setAlphaRootArray(Object.keys(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC]))
  }, [chainId])

  return (
    <div className={classes.root}>
      <div className={classes.informationBox}>
        <Pool style={{ marginTop: `0px` }}>
          <Basic style={{ padding: `0px 0px` }}>
            {/* <BasicText> */}
            <Typography variant="subtitle2">
              <Trans>Pool</Trans>
            </Typography>
            <Basic style={{ padding: `0px 0px` }}>
              <TitleTextMobileHide>
                <BasicText>
                  <Typography variant="subtitle2">
                    <Trans>TVL</Trans>
                  </Typography>
                </BasicText>
              </TitleTextMobileHide>
              <TitleTextDiv>
                <BasicText>
                  <Typography variant="subtitle2">
                    <Trans>MAX APR</Trans>
                  </Typography>
                </BasicText>
              </TitleTextDiv>
              <TitleTextDiv style={{ display: isPC ? 'block' : 'none' }}>
                <BasicText style={{ width: isPC ? 50 : 20, visibility: 'hidden' }}>
                  <ChevronRight size={20} color="white" />
                </BasicText>
              </TitleTextDiv>
            </Basic>
          </Basic>

          {alphaRootArray.map((root, index) => {
            return (
              <>
                {root === 'TestRoot' ? (
                  IS_DEV ? (
                    <InvestPoolBar
                      poolName={i18n._(PoolNames[index])}
                      mobilePoolName={i18n._(MobilePoolNames[index])}
                      alphaRootAddress={root}
                      poolId={index}
                    />
                  ) : (
                    ''
                  )
                ) : (
                  <InvestPoolBar
                    poolName={i18n._(PoolNames[index])}
                    mobilePoolName={i18n._(MobilePoolNames[index])}
                    alphaRootAddress={root}
                    poolId={index}
                  />
                )}
              </>
            )
          })}
        </Pool>
      </div>
    </div>
  )
}

export default Invest
