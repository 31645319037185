import { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

import './App.css'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Popups from '../components/popups'
import Galaxy from './Invest'
import Alpha from './Invest/Alpha'
import GalaxyPro from './InvestPro'
import AlphaPro from './InvestPro/AlphaPro'
import Vault from './Vault'
import Transmute from './Transmute'
import Farm from './Farm'
import Pool from './Farm/Pool'
import Faucet from './Faucet'
import Home from './Home'
import Sumsub from './Kyc/Sumsub'
import Insurance from './Insurance'
import Beta from './Insurance/Beta'

import ApplicationUpdater from '../redux/application/updater'
import TransactionUpdater from '../redux/transactions/updater'
import { useEagerConnect } from '../hooks/useEagerConnect'
import { useInactiveListener } from '../hooks/useInactiveListener'
import GeneralConfirmationModal from '../components/modals/confirmationModals/GeneralConfirmationModal'
import { useCloseModals, useConfirmationModalInfo, useModalIsOpen } from '../redux/application/hooks'
import { ApplicationModal } from '../redux/application/actions'

const Updaters = () => (
  <>
    <ApplicationUpdater />
    <TransactionUpdater />
  </>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100vw',
    },
    appWrapper: {
      width: '100%',
      height: '100vh',
      [theme.breakpoints.up(960)]: {
        width: 'calc(100% - 72px)',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px 0 0 50px',
      },
    },
    appBarCard: {
      width: '100%',
      height: '100%',
      borderRadius: '20px 20px 0 0',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up(960)]: {
        width: 'calc(100% - 3.75vw)',
        borderRadius: '0',
        marginLeft: '3.75vw',
      },
    },
  })
)

function App() {
  const classes = useStyles()

  const tryEager = useEagerConnect()
  useInactiveListener(!tryEager)

  const isOpen = useModalIsOpen(ApplicationModal.CONFIRMATION)
  const { attemptingTxn, txHash, errorMessage } = useConfirmationModalInfo()
  const closeModal = useCloseModals()

  return (
    <>
      <Updaters />
      <div className={classes.root}>
        <Navbar />
        <div className={classes.appWrapper}>
          <Header />
          <div className={classes.appBarCard}>
            <Switch>
              <Route exact strict path="/formation" component={Vault} />
              <Route exact strict path="/transmuter" component={Transmute} />
              <Route exact strict path="/galaxy" component={Galaxy} />
              <Route exact strict path="/galaxy/:poolname" component={Alpha} />
              <Route exact strict path="/galaxy/:poolname/kyc" component={Sumsub} />
              <Route exact strict path="/galaxy-pro" component={GalaxyPro} />
              <Route exact strict path="/galaxy-pro/:poolname" component={AlphaPro} />
              <Route exact strict path="/insurance" component={Insurance} />
              <Route exact strict path="/insurance/:poolname" component={Beta} />
              {/* <Route exact strict path="/galaxy/:poolname/kyc" component={Sumsub} /> */}
              <Route exact strict path="/farm" component={Farm} />
              <Route exact strict path="/farm/:poolname" component={Pool} />
              <Route exact strict path="/home" component={Home} />
              <Redirect to="/home" />
            </Switch>
          </div>
        </div>
      </div>
      <Popups />
      <GeneralConfirmationModal
        isOpen={isOpen}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        errorMessage={errorMessage}
        onDismiss={closeModal}
      />
    </>
  )
}

export default App
