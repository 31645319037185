import { Web3Provider } from '@ethersproject/providers'
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError
} from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { SUPPORTED_CHAIN_IDS, ChainId } from '../../constants/blockchain'
import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import warning from 'tiny-warning'

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(
    provider,
    typeof provider.chainId === 'number'
      ? provider.chainId
      : typeof provider.chainId === 'string'
      ? parseInt(provider.chainId)
      : 'any'
  )
  library.pollingInterval = 15000
  return library
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const walletconnect = new WalletConnectConnector({
  rpc: {
    [ChainId.BSC]: 'https://bsc-dataseed.binance.org/'
  },
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  infuraId: '3021f794d4d3493c86e4188ae65df8ce',
  // bridge:
  qrcode: true,
  pollingInterval: 15000,
})

function parseSendReturn(sendReturn: Record<string, any>): any {
  return sendReturn.hasOwnProperty('result') ? sendReturn.result : sendReturn
}

export class OntoConnector extends AbstractConnector {
  constructor(kwargs: AbstractConnectorArguments) {
    super(kwargs)

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  private async handleChainChanged(chainId: string | number): Promise<void> {
    this.emitUpdate({ chainId, provider: await this.getProvider() })
  }

  private handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
      this.emitDeactivate()
    } else {
      this.emitUpdate({ account: accounts[0] })
    }
  }

  private handleClose(code: number, reason: string): void {
    this.emitDeactivate()
  }

  private async handleNetworkChanged(networkId: string | number): Promise<void> {
    this.emitUpdate({ chainId: networkId, provider: await this.getProvider() })
  }

  public async activate(): Promise<ConnectorUpdate> {
    if (!window.onto) {
      throw new NoEthereumProviderError()
    }

    if (window.onto.on) {
      window.onto.on('chainChanged', this.handleChainChanged)
      window.onto.on('accountsChanged', this.handleAccountsChanged)
      window.onto.on('close', this.handleClose)
      window.onto.on('disconnect', this.handleClose)
      window.onto.on('networkChanged', this.handleNetworkChanged)
    }

    const provider = await this.getProvider()

    // try to activate + get account via eth_requestAccounts
    let account
    try {
      account = await provider.send('eth_requestAccounts').then(
        sendReturn => parseSendReturn(sendReturn)[0]
      )
    } catch (error) {
      if ((error as any).code === 4001) {
        throw new UserRejectedRequestError()
      }
      warning(false, 'eth_requestAccounts was unsuccessful, falling back to enable')
    }

    // if unsuccessful, try enable
    if (!account) {
      // if enable is successful but doesn't return accounts, fall back to getAccount (not happy i have to do this...)
      account = await provider.send('eth_requestAccounts').then(
        sendReturn => parseSendReturn(sendReturn)[0]
      )
    }

    return { provider: new Web3Provider(window.onto as any), ...(account ? { account } : {}) }
  }

  public async getProvider(): Promise<any> {
    return new Web3Provider(
      window.onto as any,
    )
  }

  public async getChainId(): Promise<number | string> {
    if (!window.onto) {
      throw new NoEthereumProviderError()
    }
    const provider = await this.getProvider()

    let chainId
    try {
      chainId = await provider.send('eth_chainId').then(parseSendReturn)
    } catch {
      warning(false, 'eth_chainId was unsuccessful, falling back to net_version')
    }

    if (!chainId) {
      try {
        chainId = await provider.send('net_version').then(parseSendReturn)
      } catch (err) {
        warning(false, 'net_version was unsuccessful, falling back to net version v2')
      }
    }

    if (!chainId) {
      try {
        chainId = await provider.send({ method: 'net_version' }).then(parseSendReturn)
      } catch {
        warning(false, 'net_version v2 was unsuccessful, falling back to manual matches and static properties')
      }
    }

    if (!chainId) {
      chainId =
        (window.onto as any).chainId ||
        (window.onto as any).netVersion ||
        (window.onto as any).networkVersion ||
        (window.onto as any)._chainId
    }

    return chainId
  }

  public async getAccount(): Promise<null | string> {
    if (!window.onto) {
      throw new NoEthereumProviderError()
    }
    const provider = await this.getProvider()

    let account
    try {
      account = await provider.send('eth_accounts').then(sendReturn => parseSendReturn(sendReturn)[0])
    } catch {
      warning(false, 'eth_accounts was unsuccessful, falling back to enable')
    }

    if (!account) {
      try {
        account = await provider.send('eth_requestAccounts').then(sendReturn => parseSendReturn(sendReturn)[0])
      } catch {
        warning(false, 'enable was unsuccessful, falling back to eth_accounts v2')
      }
    }

    if (!account) {
      try {
        account = await provider.send({ method: 'eth_accounts' }).then(sendReturn => parseSendReturn(sendReturn)[0])
      } catch {
        warning(false, 'eth_accounts was unsuccessful, falling back to enable')
      }
    }

    return account
  }

  public deactivate() {
    if (window.onto && window.onto.removeListener) {
      window.onto.removeListener('chainChanged', this.handleChainChanged)
      window.onto.removeListener('accountsChanged', this.handleAccountsChanged)
      window.onto.removeListener('close', this.handleClose)
      window.onto.removeListener('networkChanged', this.handleNetworkChanged)
    }
  }

  public async isAuthorized(): Promise<boolean> {
    if (!window.onto) {
      return false
    }

    try {
      const provider = await this.getProvider()
      return provider.send('eth_accounts', []).then(sendReturn => {
        if (parseSendReturn(sendReturn).length > 0) {
          return true
        } else {
          return false
        }
      })
    } catch {
      return false
    }
  }
}

export const ontoconnector = new OntoConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})