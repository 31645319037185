import { Contract, utils } from 'ethers'
import { useMulticallContract } from '../hooks/smartContracts/useContract'

type Call = {
  itf: utils.Interface
  address: string
  function: string
  value: Array<any>
}

const multicall = async (
  contract: Contract,
  calls: Array<Call>
): Promise<Array<any>> => {
  const res = await contract.callStatic.aggregate(
    calls.map((call) => {
      return {
        target: call.address,
        callData: call.itf.encodeFunctionData(call.function, call.value)
      }
    }),
  )
  return res[1].map((call: any, i: number) => calls[i].itf.decodeFunctionResult(calls[i].function, call))
}

export default multicall
