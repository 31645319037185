import { Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import numeral from 'numeral'
import { Trans } from '@lingui/macro'

import LineData, { LineDataType } from '../../../common/LineData'
import GradientWhite from '../../../common/GradientCard'
import { getTotalLoanDisbursedValue } from '../../../utils/getGalaxyInfo'
import { BasicText, PoolFlexAsset, LineDataContainer } from '../styled'

export const TotalLoanDisburse = ({ isPC, widthPercent }) => {
  const [lineData, setLineData] = useState<LineDataType | null>(null)
  const { width, ref } = useResizeDetector()
  const [currentNum, setCurrentNum] = useState(0)
  const [xAxisDate, setXAxisDate] = useState<Object>({})

  useEffect(() => {
    getTotalLoanDisbursedValue('hour', true).then((ret: any) => {
      const data = Object.assign({ color: ['#9B9B9B'] }, ret)

      if (data) {
        const dateArr = data['name']
        const dateObj = {}
        let monthDate = ''
        dateArr.forEach((date: string) => {
          let splitString = date.split(/[\-]/)
          if (monthDate === '' || monthDate !== `${splitString[0]}-${+splitString[1]}`) {
            monthDate = `${splitString[0]}-${+splitString[1]}`
            dateObj[date] = monthDate
          }
        })
        setXAxisDate(dateObj)
      }

      setLineData(data)
      setCurrentNum(Number(ret.numbers[0][ret.numbers[0].length - 1]))
    })
  }, [])

  return (
    <GradientWhite width={isPC ? widthPercent : '100%'} height="280px">
      <PoolFlexAsset>
        <BasicText style={{ paddingLeft: 20 }}>
          <Typography variant="h3">
            <Trans>Total Loan Disbursed</Trans>
          </Typography>
          <Typography variant="h2">${numeral(currentNum).format('0,0').toUpperCase()}</Typography>
        </BasicText>
        {/* <LineDataContainer ref={ref}> */}
        <LineDataContainer ref={ref}>
          {lineData && (
            <LineData
              lineData={lineData}
              height={280 * 0.68}
              width={width}
              XAxisTicks={xAxisDate}
              XInterval={3}
              tickFormatY={(tick: number) => {
                return `$${numeral(tick).format('0a').toUpperCase()}`
              }}
              labelFormatter={(value, name, props) => {
                return [`$${numeral(value).format('0,0').toUpperCase()}`, name, props]
              }}
            />
          )}
        </LineDataContainer>
      </PoolFlexAsset>
    </GradientWhite>
  )
}

export default TotalLoanDisburse
