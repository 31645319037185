import { useMemo, useState, useEffect } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { isMobile } from 'react-device-detect'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import {
  ExternalLink as IconExternalLink,
  X as IconX,
  Copy as IconCopy,
  CheckCircle,
  AlertTriangle,
  Trash2,
  Power,
} from 'react-feather'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'

import { ClickableTypography, MinorButton } from '../../common/buttons'
import ExternalLink from '../commons/ExternalLink'

import { ApplicationModal } from '../../redux/application/actions'
import { useAllTransactions } from '../../redux/transactions/hooks'
import { useCloseModals, useModalIsOpen } from '../../redux/application/hooks'
import usePrevious from '../../hooks/usePrevious'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { SUPPORTED_WALLETS } from '../../constants/wallet'
import { displayAddress } from '../../utils/displayAddress'
import getExplorerLink from '../../utils/getExplorerLink'
import { injected, ontoconnector } from '../../utils/connectors'
import { useDispatch } from 'react-redux'
import { clearAllTransactions } from '../../redux/transactions/actions'

const StyledClose = styled(IconX)`
  cursor: pointer;
`

const FlexGrid = styled(Grid)`
  display: flex;
`

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 16,
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 12,
    paddingBottom: 32,
    backgroundColor: 'rgb(38, 40, 73)',
  },
  cardGrid: {
    height: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    marginBottom: 10,
    border: `1px solid ${theme.palette.text.hint}`,
    borderRadius: 8,
    cursor: 'pointer',
  },
  cardInactive: {
    opacity: 0.3,
    cursor: 'unset',
  },
  cardMargin: {
    marginTop: 12,
  },
  powerBackground: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    padding: 5,
    borderRadius: 15,
    cursor: 'pointer',
  },
}))

const renderTransaction = (txs: (string | undefined)[][], type: TransactionType, chainId?: number) => {
  return (
    <>
      {txs.map(([hash, summary], i) => {
        return (
          <Grid container item justify="space-between" alignItems="center" key={i} style={{ marginBottom: 4 }}>
            <ExternalLink href={getExplorerLink(chainId, hash, 'transaction')}>
              <FlexGrid item alignItems="center">
                <IconExternalLink strokeWidth={1} width={20} style={{ marginRight: 8 }} />
                {summary}
              </FlexGrid>
            </ExternalLink>
            <Grid item>
              {type === TransactionType.pending ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : type === TransactionType.failed ? (
                <AlertTriangle width={20} />
              ) : (
                <CheckCircle width={20} />
              )}
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

enum TransactionType {
  pending,
  confirmed,
  failed,
}

enum WALLET_VIEWS {
  OPTIONS,
  ACCOUNT,
  PENDING,
}

const WalletModal = () => {
  const { active, account, chainId, connector, activate, error } = useWeb3React()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isCopied, copy] = useCopyClipboard()

  const modalIsOpen = useModalIsOpen(ApplicationModal.WALLET)
  const closeModal = useCloseModals()

  const [view, setView] = useState(WALLET_VIEWS.ACCOUNT)
  // When open modal, set default view to account
  useEffect(() => {
    if (modalIsOpen) {
      setView(WALLET_VIEWS.ACCOUNT)
    }
  }, [modalIsOpen])

  // Back to account view once the option is connected sucessfully
  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (modalIsOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setView, active, error, connector, modalIsOpen, activePrevious, connectorPrevious])

  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(
    () => Object.values(allTransactions).sort((tx1, tx2) => tx2.addedTime - tx1.addedTime),
    [allTransactions]
  )
  const pendingTransactions = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => [tx.hash, tx.summary])
  const confirmedTransactions = sortedRecentTransactions
    .filter((tx) => tx.receipt && tx.receipt.status)
    .map((tx) => [tx.hash, tx.summary])
  const failedTransactions = sortedRecentTransactions
    .filter((tx) => tx.receipt && !tx.receipt.status)
    .map((tx) => [tx.hash, tx.summary])

  function activateWallet(connector?: AbstractConnector) {
    if (connector instanceof WalletConnectConnector && connector?.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }
    connector &&
      activate(connector, undefined, true).catch((err) => {
        console.log(err)
      })
  }

  function renderOptions() {
    const isMetamask = window.ethereum?.isMetaMask
    const isCoin98 = window.ethereum?.isCoin98
    const isSafePal = window.ethereum?.isSafePal
    const isMathWallet = window.ethereum?.isMathWallet
    const isOnto = window.onto

    return Object.keys(SUPPORTED_WALLETS).map((name) => {
      const wallet = SUPPORTED_WALLETS[name]

      if (isMobile) {
        if (wallet.mobile) {
          return (
            <Grid
              key={`wallet-${name}`}
              container
              className={classes.cardGrid}
              onClick={() => {
                if (wallet.connector !== connector) {
                  activateWallet(wallet.connector)
                }
              }}
            >
              <Typography>{wallet.name}</Typography>
              {wallet.style ? (
                <img src={wallet.icon} alt={wallet.name} width={24} style={wallet.style} />
              ) : (
                <img src={wallet.icon} alt={wallet.name} width={24} />
              )}
            </Grid>
          )
        }
        return
      }

      if (wallet.connector == ontoconnector) {
        const active = isOnto
        return (
          <Grid
            key={`wallet-${name}`}
            container
            className={clsx(classes.cardGrid, !active && classes.cardInactive)}
            onClick={() => {
              if (!active) return
              if (wallet.connector === connector) {
                setView(WALLET_VIEWS.ACCOUNT)
              } else {
                activateWallet(wallet.connector)
              }
            }}
          >
            <Typography>{wallet.name}</Typography>
            {wallet.style ? (
              <img src={wallet.icon} alt={wallet.name} width={24} style={wallet.style} />
            ) : (
              <img src={wallet.icon} alt={wallet.name} width={24} />
            )}
          </Grid>
        )
      }

      if (wallet.connector === injected) {
        // If no extension is installed, redirect user to download Metamask
        // Otherwise, only let the injected option clickable
        if (!(window.web3 || window.ethereum || window.onto)) {
          if (wallet.name === 'MetaMask') {
            return (
              <ExternalLink href="https://metamask.io/" key={`wallet-${name}`}>
                <Grid container className={classes.cardGrid}>
                  <Typography>{wallet.name}</Typography>
                  <img src={wallet.icon} alt={wallet.name} width={24} />
                </Grid>
              </ExternalLink>
            )
          } else if (wallet.name === 'Coin98') {
            return (
              <ExternalLink href="https://docs.coin98.com/products/coin98-wallet" key={`wallet-${name}`}>
                <Grid container className={classes.cardGrid}>
                  <Typography>{wallet.name}</Typography>
                  <img src={wallet.icon} alt={wallet.name} width={24} />
                </Grid>
              </ExternalLink>
            )
          }
        } else if (isMetamask || isCoin98 || isSafePal || isMathWallet) {
          const active = (isMetamask && wallet.name === 'MetaMask') ||
            (isCoin98 && wallet.name === 'Coin98') ||
            (isSafePal && wallet.name === 'SafePal') ||
            (isMathWallet && wallet.name === 'MathWallet')
          return (
            <Grid
              key={`wallet-${name}`}
              container
              className={clsx(classes.cardGrid, !active && classes.cardInactive)}
              onClick={() => {
                if (!active) return
                if (wallet.connector === connector) {
                  setView(WALLET_VIEWS.ACCOUNT)
                } else {
                  activateWallet(wallet.connector)
                }
              }}
            >
              <Typography>{wallet.name}</Typography>
              {wallet.style ? (
                <img src={wallet.icon} alt={wallet.name} width={24} style={wallet.style} />
              ) : (
                <img src={wallet.icon} alt={wallet.name} width={24} />
              )}
            </Grid>
          )
        } else if (wallet.name === 'Injected' && isMetamask) {
          return null
        }
      }

      return (
        !isMobile && (
          <Grid
            key={`wallet-${name}`}
            container
            className={classes.cardGrid}
            onClick={() => {
              if (wallet.connector === connector) {
                setView(WALLET_VIEWS.ACCOUNT)
              } else {
                activateWallet(wallet.connector)
              }
            }}
          >
            <Typography>{wallet.name}</Typography>
            {wallet.style ? (
              <img src={wallet.icon} alt={wallet.name} width={24} style={wallet.style} />
            ) : (
              <img src={wallet.icon} alt={wallet.name} width={24} />
            )}
          </Grid>
        )
      )
    })
  }

  function renderViewOptions() {
    return (
      <>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Typography variant="body1">
              <Trans>Connect to a wallet</Trans>
            </Typography>
            <StyledClose onClick={closeModal} />
          </Grid>
        </DialogTitle>
        <DialogContent>{renderOptions()}</DialogContent>
      </>
    )
  }

  function renderViewAccount() {
    const isMetamask = window.ethereum?.isMetaMask
    const walletName = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetamask === (k === 'METAMASK'))
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0]

    return (
      <>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Typography variant="body1">
              <Trans>- My Account -</Trans>
            </Typography>
            <StyledClose onClick={closeModal} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid container justifyContent="flex-start">
              <Typography variant="body2">
                <Trans>Connected with {walletName}</Trans>
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
              <FlexGrid alignItems="center">
                <Jazzicon diameter={30} seed={jsNumberForAddress(account)} />
                <Typography style={{ marginLeft: 12 }}>{displayAddress(account)}</Typography>
              </FlexGrid>
              {connector instanceof WalletConnectConnector ? (
                <Grid className={classes.powerBackground} onClick={() => connector.close()}>
                  <Power color="white" size={20} />
                </Grid>
              ) : (
                <MinorButton>
                  <Typography
                    variant="body2"
                    onClick={() => {
                      setView(WALLET_VIEWS.OPTIONS)
                    }}
                  >
                    <Trans>Change</Trans>
                  </Typography>
                </MinorButton>
              )}
            </Grid>
            <Grid container justifyContent="flex-start" alignItems="center" style={{ marginTop: 20 }}>
              <FlexGrid
                alignItems="center"
                style={{ marginRight: 16, cursor: 'pointer' }}
                onClick={() => copy(account ?? '')}
              >
                {isCopied ? (
                  <>
                    <CheckCircle strokeWidth={1} width={20} style={{ marginRight: 8 }} />
                    <Typography variant="body2">
                      <Trans>Copied</Trans>
                    </Typography>
                  </>
                ) : (
                  <>
                    <IconCopy strokeWidth={1} width={20} style={{ marginRight: 8 }} />
                    <ClickableTypography variant="body2">
                      <Trans>Copy Address</Trans>
                    </ClickableTypography>
                  </>
                )}
              </FlexGrid>
              <ExternalLink href={getExplorerLink(chainId, account, 'address')}>
                <FlexGrid alignItems="center">
                  <IconExternalLink strokeWidth={1} width={20} style={{ marginRight: 8 }} />
                  <Typography variant="body2">
                    <Trans>View on Blockchain Explorer</Trans>
                  </Typography>
                </FlexGrid>
              </ExternalLink>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: 20, marginBottom: 20, backgroundColor: 'white' }} />

          <Grid container>
            {!!pendingTransactions.length || !!confirmedTransactions.length || !!failedTransactions.length ? (
              <>
                <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 16 }}>
                  <Typography>
                    <Trans>Recent Transactions</Trans>
                  </Typography>
                  <Trash2
                    size={16}
                    style={{ cursor: 'pointer' }}
                    onClick={() => dispatch(clearAllTransactions({ chainId: chainId! }))}
                  />
                </Grid>
                {renderTransaction(pendingTransactions, TransactionType.pending, chainId)}
                {renderTransaction(confirmedTransactions, TransactionType.confirmed, chainId)}
                {renderTransaction(failedTransactions, TransactionType.failed, chainId)}
              </>
            ) : (
              <Typography>
                <Trans>No Pending Transactions ...</Trans>
              </Typography>
            )}
          </Grid>
        </DialogContent>
      </>
    )
  }

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper,
        }}
        open={modalIsOpen}
        onClose={closeModal}
        maxWidth="xs"
        fullWidth
      >
        {account && view === WALLET_VIEWS.ACCOUNT ? renderViewAccount() : renderViewOptions()}
      </Dialog>
    </>
  )
}
export default WalletModal
