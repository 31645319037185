import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  // Stepper,
  // Step,
  // StepIcon,
  // StepLabel,
  withStyles,
  CheckboxProps,
} from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import { Stepper, StepLine, StepIcon } from './styled'

import { MainButton } from '../../common/buttons'
import GradientCard from '../../common/GradientCard'
import { Divider } from '../../common/Divider'
import { displayDate } from '../../utils/displayDate'
import { displayBalance } from '../../utils/displayBalance'
import { getGalaxyOrder } from '../../utils/getGalaxyInfo'
import { useBetaInsuranceContract, useGalaxyStakingPoolContract } from '../../hooks/smartContracts/useContract'
import {
  useGalaxyPoolCurrentEpoch,
  useGalaxyPoolLockPeriod,
  useGalaxyRegisterInfo,
} from '../../hooks/smartContracts/useGalaxy'
import { useOpenConfirmingModal } from '../../redux/application/hooks'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import ADDRESS from '../../constants/addresses'
import { useTokenBalance } from '../../hooks/smartContracts/useTokenBalance'
import AmountInput, { IAmountInputRefObj } from '../../common/AmountInput'
import { PoolFlexCenter } from '../Farm/styled'
import { useBetaInsuranceInfo } from '../../hooks/smartContracts/useBetaInsuranceInfo'
import { useBetaRedeemable } from '../../hooks/smartContracts/useBetaRedeemable'

import OrderActive from '../../assets/drawer/orderRedeemable.png'
import OrderInactive from '../../assets/drawer/orderPending.png'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ChainId } from '../../constants/blockchain'
import { useLocation } from 'react-router-dom'
import InformationCard from '../../common/InformationCard'
import { ApprovalState } from '../../hooks/smartContracts/useApproveCallback'

function RedeemRow({ amount, redeemable, redeemToken, remainingRedeemToken, collateralToken }) {
  return (
    <Grid container alignItems="center" style={{ margin: '12px 0px' }}>
      <Grid direction="column">
        <Typography variant="subtitle1">
          {displayBalance(amount)} {collateralToken}
        </Typography>
        <Typography variant="body1">
          {remainingRedeemToken.eq(redeemToken)
            ? t`Queueing`
            : remainingRedeemToken.lt(redeemToken) && remainingRedeemToken.gt(0)
            ? t`Redeemable: ${displayBalance(redeemable)}`
            : remainingRedeemToken.eq(0) && t`Finished`}
        </Typography>
      </Grid>
    </Grid>
  )
}

function OrderRow({ amount, startDate, isValidEpoch, lockTime, isChecked, onClick, collateralToken }) {
  const postfix = isValidEpoch ? t`Unlocked` : t`Wait for asset settlement`
  const date = displayDate(new Date(startDate.toNumber() * 1000))
  const [checked, setChecked] = useState(false)
  // const CustomCheckbox = withStyles({
  //   root: {
  //     color: '#FFFFFF',
  //     '&$checked': {
  //       color: '#1D77FF',
  //     },
  //   },
  //   checked: {},
  // })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ margin: '12px 0px', cursor: isValidEpoch ? 'pointer' : 'unset' }}
      onClick={onClick}
    >
      <Grid direction="column">
        <Typography variant="subtitle1" style={{ color: isValidEpoch ? 'white' : 'rgba(255, 255, 255, 0.3)' }}>
          {displayBalance(amount)} {collateralToken}
        </Typography>
        <Typography variant="body1" style={{ color: isValidEpoch ? 'white' : 'rgba(255, 255, 255, 0.3)' }}>
          {date}, {i18n._(postfix)}
        </Typography>
      </Grid>
      <Checkbox
        style={{ color: isValidEpoch ? 'white' : 'rgba(255, 255, 255, 0.3)' }}
        // name={boxName}
        disabled={!isValidEpoch}
        checked={isChecked}
        // onChange={() => {setChecked(!checked)}}
      />
      {/* <img src={isValidEpoch ? OrderActive : OrderInactive} /> */}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      minHeight: 0,
      height: 32,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 4,
      marginTop: 24,
    },
    tab: {
      minWidth: 0,
      minHeight: 32,
      padding: 0,
      width: '50%',
      borderRadius: 3,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    hideIndicator: {
      display: 'none',
    },
    paper: {
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'rgb(38, 40, 73)',
      width: 360,
    },
    stepLabel: {
      '&$completed': {
        color: 'pink',
      },
      '&$active': {
        color: '#1D77FF',
      },
    },
    stepIconRoot: {
      fill: 'transparent',
      border: '2px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '50%',
      color: 'rgba(255, 255, 255, 0.3)',
    },
    stepIconText: {
      fill: 'inherit',
    },
    stepIconActive: {
      // fill: '#1D77FF',
    },
    stepIcon: {
      '&.MuiStepLabel-iconContainer': {
        padding: 0,
      },
    },
  })
)

const RedeemDrawerContent = (props) => {
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const showAlpha = props.showAlpha ? true : false
  const classes = useStyles()
  const { account, chainId } = useWeb3React()

  // const { pathname } = useLocation()
  const poolId = props.poolId
  const alphaRootAddress = props.alphaRootAddress
  const [invests, setInvests] = useState<Record<number, any[]>>({ 0: [], 1: [] })
  const [redeems, setRedeems] = useState<Record<number, any[]>>({ 0: [], 1: [] })
  const [redeemsWithoutFinish, setRedeemsWithoutFinish] = useState<any[]>([])
  const [tabValue, setTabValue] = useState(showAlpha ? 0 : 1)
  const [registerModalInfo, setRegisterModalInfo] = useState<{
    open: boolean
    poolId: undefined | number
    orderIds: undefined | number[]
  }>({
    open: false,
    poolId: undefined,
    orderIds: undefined,
  })

  const collateralTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'BUSD'
        : 'DAI',
    [chainId]
  )
  const mintTokenName = useMemo(
    () =>
      chainId === ChainId.BSC || chainId === ChainId.BSC_TEST || chainId === ChainId.RINKEBY || !chainId
        ? 'nBUSD'
        : 'nUSD',
    [chainId]
  )
  const galaxyContract = useGalaxyStakingPoolContract(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]
  )
  const currentEpoch = useGalaxyPoolCurrentEpoch(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress])
  // const currentEpoch = 0
  const lockTime = [
    useGalaxyPoolLockPeriod(ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress], 0),
    // useGalaxyPoolLockPeriod(ADDRESS.GALAXY_STAKING_POOL.default[0], 1),
  ]
  // const lockTime = [0, 0]
  const [registerForRedeem, redeemable] = useGalaxyRegisterInfo(
    ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress],
    ADDRESS.ASSESSOR[chainId ?? ChainId.BSC][alphaRootAddress],
    account ?? undefined
  )
  // const [registerForRedeem, redeemable] = [BigNumber.from(0), BigNumber.from(0)]
  const [redeemableRatio, setRedeemableRatio] = useState(0)
  useEffect(() => {
    if (registerForRedeem.gt(0)) {
      setRedeemableRatio(redeemable.mul(100).div(registerForRedeem).toNumber())
    }
  }, [registerForRedeem, redeemable])

  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  const [orderCheckStatus, setOrderCheckStatus] = useState<Record<number, boolean>>({})

  function checkUnlockedOrder(invest) {
    if (currentEpoch > +invest.epoch) {
      if (!orderCheckStatus[+invest.depositedOrderIndex]) {
        setOrderCheckStatus({ ...orderCheckStatus, [+invest.depositedOrderIndex]: true })
      } else {
        setOrderCheckStatus({ ...orderCheckStatus, [+invest.depositedOrderIndex]: false })
      }
    }
  }

  async function handleRedeem() {
    if (galaxyContract && redeemable.gt(0)) {
      openConfirmingModal(true)
      try {
        const estimateGas = await galaxyContract.estimateGas.withdraw()
        const res = await galaxyContract.withdraw({ gasLimit: calculateGasMargin(estimateGas) })
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Withdraw redeemable`,
        })
      } catch (error: any) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  async function handleRegister() {
    if (galaxyContract) {
      const keysOfOrderCheckStatus = Object.keys(orderCheckStatus)
      const orderIds = keysOfOrderCheckStatus.filter((key) => orderCheckStatus[key])

      if (orderIds && orderIds.length > 0) {
        openConfirmingModal(true)
        try {
          const estimateGas = await galaxyContract.estimateGas.redeem(0, orderIds)
          const res = await galaxyContract.redeem(0, orderIds, {
            gasLimit: calculateGasMargin(estimateGas),
          })
          openConfirmingModal(false, res.hash)
          addTransaction(res, {
            summary: `Register orders, id: ${orderIds.toString()}`,
          })
          registerToRedeem(0, orderIds)
        } catch (error: any) {
          openConfirmingModal(false, undefined, error, chainId)
        }
      }
    }
  }

  function registerToRedeem(poolId, toMoveInvestIds) {
    const newInvest = { ...invests }
    const newRedeem = { ...redeems }

    const newRedeemWithoutFinish = newRedeem[0].filter((redeem) => BigNumber.from(redeem.remainingRedeemToken).gt(0))

    newInvest[poolId] = []
    for (const invest of invests[poolId]) {
      if (toMoveInvestIds.includes(invest.depositedOrderIndex)) {
        newRedeem[poolId].push(invest)
        newRedeemWithoutFinish.push(invest)
      } else {
        newInvest[poolId].push(invest)
      }
    }

    setInvests(newInvest)
    setRedeems(newRedeem)
    setRedeemsWithoutFinish(newRedeemWithoutFinish)
  }

  useEffect(() => {
    if (account) {
      getGalaxyOrder(
        account?.toLowerCase(),
        ADDRESS.GALAXY_STAKING_POOL[chainId ?? ChainId.BSC][alphaRootAddress]?.toLowerCase()
      ).then(({ redeemList, investList }) => {
        if (redeemList && redeemList.length > 0) {
          // const redeems = { 0: [], 1: [] }
          const redeems: Record<string, any> = { 0: [] }
          redeemList.map((r) => r.poolId === 0 && redeems[r.poolId].push(r))
          setRedeems(redeems)

          const redeemWithoutFinish = redeems[0].filter((redeem) => BigNumber.from(redeem.remainingRedeemToken).gt(0))
          setRedeemsWithoutFinish(redeemWithoutFinish)
        }
        if (investList && investList.length > 0) {
          // const invests = { 0: [], 1: [] }
          const invests: Record<string, any> = { 0: [] }
          investList.map((l) => l.poolId === 0 && invests[l.poolId].push(l))

          const checkStatus: Record<string, boolean> = {}
          invests[0].forEach((invest) => {
            checkStatus[+invest.depositedOrderIndex] = false
          })
          setOrderCheckStatus(checkStatus)
          setInvests(invests)
        }
      })
    }
  }, [account])

  // useEffect(() => {
  //   console.log('info', registerModalInfo)
  // }, [registerModalInfo])

  // BETA Hooks
  // const beta = useBetaInsuranceInfo(ADDRESS?.BETA_INSURANCE, account ?? undefined)
  const betaContract = useBetaInsuranceContract(ADDRESS.BETA_INSURANCE)
  const [, betaPrice, lockAmount, redeemableAmount] = useBetaInsuranceInfo(ADDRESS.BETA_INSURANCE)
  const [nusdReedemable] = useBetaRedeemable()
  const [betaBalance] = useTokenBalance(ADDRESS.BETA_INSURANCE, account ?? undefined)
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))
  const amountInput = useRef<IAmountInputRefObj>(null)

  // const [activeStep, setActiveStep] = useState([false, false])
  const steps = ['1', '2']

  const activeStep = useMemo(() => {
    if (invests[0] && redeemsWithoutFinish) {
      return [invests[0].length > 0, redeemsWithoutFinish.length > 0]
    } else {
      return [false, false]
    }
  }, [invests, redeemsWithoutFinish])

  // const [register, setRegister]

  const [betaRatio, setBetaRatio] = useState(0)
  useEffect(() => {
    if (redeemableAmount.gt(0)) {
      setBetaRatio(redeemableAmount.mul(100).div(redeemableAmount.add(lockAmount)).toNumber())
    }
  }, [redeemableAmount, lockAmount])

  const receiveAmount = useMemo(() => {
    let nusd, dai
    if (nusdReedemable.gte(inputBignumber)) {
      nusd = inputBignumber
      dai = BigNumber.from(0)
    } else {
      nusd = nusdReedemable
      dai = inputBignumber.sub(nusdReedemable)
    }
    return {
      NUSD: nusd,
      DAI: dai,
    }
  }, [inputBignumber])

  async function handleBetaRedeem() {
    if (betaContract && inputBignumber.gt(0) && inputBignumber.lte(redeemableAmount)) {
      openConfirmingModal(true)
      try {
        const res = await betaContract.withdraw(inputBignumber)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Withdraw ${displayBalance(inputBignumber)} ${mintTokenName}`,
        })
        amountInput.current?.resetAmount()
      } catch (error) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  return (
    <InformationCard
      setClose={props.setClose}
      title={t`Redeem`}
      hideBorderLeft
      showAccountStatus
      showBottom
      handleSupplyOrder={tabValue === 1 ? handleRedeem : handleRegister}
      isApprove={ApprovalState.APPROVED}
      buttonContent={tabValue === 1 ? t`REDEEM` : t`REGISTER`}
    >
      <Box style={{ marginTop: 24 }}>
        <GradientCard width="100%" height="88px">
          <Grid
            container
            justifyContent={isPC ? 'space-between' : 'center'}
            alignItems="center"
            style={{ height: '100%' }}
          >
            <Grid style={{ width: 56, height: 56, paddingLeft: 12 }}>
              <div style={{ position: 'relative' }}>
                <CircularProgress
                  variant="determinate"
                  value={redeemableRatio}
                  style={{ position: 'absolute', color: '#238888' }}
                  size={56}
                />
                <CircularProgress
                  variant="determinate"
                  value={100 - redeemableRatio}
                  style={{
                    position: 'absolute',
                    color: 'rgba(255,255,255,0.2)',
                    transform: `rotate(${360 * (redeemableRatio / 100) - 90}deg)`,
                  }}
                  size={56}
                />
                <Grid style={{ width: 56, textAlign: 'center' }}>
                  <Typography variant="h4" style={{ lineHeight: '56px' }}>
                    {redeemableRatio}%
                  </Typography>
                </Grid>
              </div>
            </Grid>
            <Grid
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '16px 12px 16px 8px',
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                style={{ padding: '0px 0px 0px 8px', borderLeft: '3px solid rgba(255,255,255,0.2)' }}
              >
                <Typography variant="body1">
                  <Trans>Registered</Trans>
                </Typography>
                <Typography variant="body1">
                  {displayBalance(registerForRedeem, undefined, true)} {collateralTokenName}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ padding: '0px 0px 0px 8px', borderLeft: '3px solid #238888', marginTop: 8 }}
              >
                <Typography variant="body1">
                  <Trans>Redeemable</Trans>
                </Typography>
                <Typography variant="body1">
                  {displayBalance(redeemable, undefined, true)} {collateralTokenName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </GradientCard>
      </Box>

      {/* <MainButton style={{ marginTop: 16 }} onClick={handleRedeem}>
        REDEEM
      </MainButton> */}

      <Stepper>
        <StepIcon activate={activeStep[0]} default="rgba(255, 255, 255, 0.3)" active="#1D77FF">
          1
        </StepIcon>
        <StepLine activate={activeStep[1]} default="rgba(255, 255, 255, 0.3)" active="#1D77FF" />
        <StepIcon activate={activeStep[1]} default="rgba(255, 255, 255, 0.3)" active="#1D77FF">
          2
        </StepIcon>
      </Stepper>

      <Tabs
        value={tabValue}
        onChange={(_, v) => {
          setTabValue(v)
        }}
        classes={{ indicator: classes.hideIndicator, root: classes.tabs }}
      >
        <Tab label={t`Register (${invests[0].length})`} classes={{ root: classes.tab }} />
        <Tab label={t`Redeem (${redeemsWithoutFinish.length})`} classes={{ root: classes.tab }} />
      </Tabs>

      {tabValue === 0 && (
        <>
          {
            <>
              <Typography variant="subtitle1" style={{ margin: '24px 0px' }}>
                <Trans>Register to redeem investments</Trans>
              </Typography>
              <Grid style={{ marginTop: 32 }}>
                {invests[0].map((invest, idx) => (
                  <>
                    <OrderRow
                      key={`invest-${idx}`}
                      amount={BigNumber.from(invest.amount)}
                      startDate={BigNumber.from(invest.expiredTime)}
                      lockTime={BigNumber.from(lockTime[0])}
                      isValidEpoch={currentEpoch > +invest.epoch}
                      isChecked={orderCheckStatus[+invest.depositedOrderIndex]}
                      onClick={() => {
                        checkUnlockedOrder(invest)
                        // console.log('index: ',invest.depositedOrderIndex)
                        // currentEpoch > +invest.epoch &&
                        //   setRegisterModalInfo({
                        //     open: !registerModalInfo.open,
                        //     poolId: +invest.poolId,
                        //     orderIds: [+invest.depositedOrderIndex],
                        //   })
                      }}
                      collateralToken={collateralTokenName}
                    />
                    <Divider />
                  </>
                ))}
              </Grid>
            </>
          }
        </>
      )}

      {tabValue === 1 && (
        <>
          {redeems[0].length > 0 ? (
            <Grid>
              {redeems[0].map((redeem, idx) => (
                <>
                  <RedeemRow
                    key={`redeem-${idx}`}
                    amount={BigNumber.from(redeem.amount)}
                    redeemable={BigNumber.from(redeem.redeemedCurrency)}
                    redeemToken={BigNumber.from(redeem.redeemToken)}
                    remainingRedeemToken={BigNumber.from(redeem.remainingRedeemToken)}
                    collateralToken={collateralTokenName}
                  />
                  <Divider />
                </>
              ))}
            </Grid>
          ) : (
            <Typography variant="subtitle1" style={{ margin: '24px 0px' }}>
              <Trans>No redemption registered</Trans>
            </Typography>
          )}
        </>
      )}

      {/* <Typography variant="subtitle1" style={{ margin: '24px 0px' }}>
        Investments
      </Typography>
      <Typography variant="body2">Register to redeem investments</Typography> */}

      <Dialog
        classes={{
          paper: classes.paper,
        }}
        // open={registerModalInfo.open}
        open={false}
        onClose={() =>
          setRegisterModalInfo({
            ...registerModalInfo,
            open: false,
          })
        }
      >
        <DialogTitle>
          <Typography variant="h3">
            <Trans>Register to redeem</Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4">
            <Trans>Selected investment will shown in the redemption list</Trans>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 24 }}>
          <Button onClick={handleRegister}>
            <Trans>REGISTER</Trans>
          </Button>
          <Button
            onClick={() =>
              setRegisterModalInfo({
                ...registerModalInfo,
                open: false,
              })
            }
          >
            <Trans>CANCEL</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </InformationCard>
  )
}

export default RedeemDrawerContent
