import { providers, Contract } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

export const AddressZero = '0x0000000000000000000000000000000000000000'

export function getSigner(library: providers.Web3Provider, account: string): providers.JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(
  library: providers.Web3Provider,
  account?: string
): providers.Web3Provider | providers.JsonRpcSigner {
  if (window.onto && window.onto.selectedAddress && window.onto.selectedAddress.length > 40) {
    const provider = new Web3Provider(
      window.onto as any,
    )
    return account ? getSigner(provider, account) : provider
  }
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: providers.Web3Provider, account?: string): Contract {
  if (address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}
