import React, { useState, useMemo } from 'react'
import {
  useMediaQuery,
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
} from '@material-ui/core'
import { formatEther, parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { BigNumber } from 'ethers'

import { ITransactionData } from '.'
import { BasicContainer } from './styled'
import { displayBalance } from '../../utils/displayBalance'
import { displayDateAndTime } from '../../utils/displayDate'
import TablePaginationActions from './InvestmentsTablePaginationActions'
import gradientImg from '../../assets/symbol/gradient_rectangle.png'
import { TokenList } from '../../constants/historyToken'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {},
    tr: {
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
    },
    thover: {
      height: 49,
      [theme.breakpoints.down(960)]: {
        height: 81,
      },
      '&:hover': {
        backgroundImage: `url(${gradientImg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      },
    },
    pagination: {
      borderBottom: '1px solid rgba(255, 255, 255, .12)',
    },
  })
)

const rowsPerPage = 10

const Investments = ({
  data,
  chainId,
  openDetail,
  setDetail,
}: {
  data: Array<ITransactionData>
  chainId: number
  openDetail: () => void
  setDetail: (data: ITransactionData) => void
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up(960))
  const [page, setPage] = useState(0)
  const handleClick = (data: ITransactionData) => {
    setDetail(data)
    openDetail()
  }

  const colSpan = isPC ? 5 : 3

  const showHeader = useMemo(() => {
    return isPC ? (
      <TableRow>
        <TableCell className={classes.tr}>
          <Trans>Date</Trans>
        </TableCell>
        <TableCell align="right" className={classes.tr}>
          <Trans>Contract</Trans>
        </TableCell>
        <TableCell align="right" className={classes.tr}>
          <Trans>Action</Trans>
        </TableCell>
        <TableCell align="right" className={classes.tr}>
          <Trans>Amount</Trans>
        </TableCell>
        <TableCell align="right" className={classes.tr}>
          <Trans>Token</Trans>
        </TableCell>
      </TableRow>
    ) : (
      <TableRow>
        <TableCell className={classes.tr}>
          <Trans>Date</Trans>
        </TableCell>
        <TableCell align="right" className={classes.tr}>
          <Trans>Contract</Trans>
        </TableCell>
        <TableCell align="right" className={classes.tr}>
          <Trans>Action</Trans>
        </TableCell>
      </TableRow>
    )
  }, [isPC])

  const showData = useMemo(() => {
    return isPC
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
          (row) =>
            TokenList[row.contractName][chainId].hasOwnProperty(row.method) && (
              <TableRow key={row.tx} onClick={() => handleClick(row)} className={classes.thover}>
                <TableCell component="th" scope="row" className={classes.tr}>
                  {displayDateAndTime(new Date(row.date))}
                </TableCell>
                <TableCell align="right" className={classes.tr}>
                  {row.contractName}
                </TableCell>
                <TableCell align="right" className={classes.tr}>
                  {row.method}
                </TableCell>
                <TableCell align="right" className={classes.tr}>
                  {row.amount.gt(0) &&
                    row.method !== 'CLAIM' &&
                    row.method !== 'EXIT' &&
                    row.method !== 'REPAY' &&
                    row.method !== 'BOOST' &&
                    row.method !== 'ACTIVEBOOST' &&
                    !(row.contractName === 'BOOST POOL' && row.method === 'UNSTAKE') &&
                    displayBalance(row.amount)}
                </TableCell>
                <TableCell align="right" className={classes.tr}>
                  {row.token}
                </TableCell>
              </TableRow>
            )
        )
      : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
          (row) =>
            TokenList[row.contractName][chainId].hasOwnProperty(row.method) && (
              <TableRow key={row.tx} onClick={() => handleClick(row)} className={classes.thover}>
                <TableCell component="th" scope="row" className={classes.tr}>
                  {displayDateAndTime(new Date(row.date))}
                </TableCell>
                <TableCell align="right" className={classes.tr}>
                  {row.contractName}
                </TableCell>
                <TableCell align="right" className={classes.tr}>
                  {row.method}
                </TableCell>
              </TableRow>
            )
        )
  }, [data, page, isPC])

  const showEmptyRows = useMemo(() => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    if (emptyRows === 0) return <tr></tr>
    return (
      <TableRow style={isPC ? { height: 49 * emptyRows } : { height: 81 * emptyRows }}>
        <TableCell colSpan={colSpan} className={classes.tr} />
      </TableRow>
    )
  }, [data, page, isPC])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  return (
    <BasicContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {showHeader}
          {showData}
          {showEmptyRows}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={colSpan}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              className={classes.tr}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </BasicContainer>
  )
}

export default Investments
