import { useCallback, useMemo } from 'react'
import { BigNumber, constants, providers } from 'ethers'
import { useWeb3React } from '@web3-react/core'

import { useTokenContract, IAddressOrAddressMap } from './useContract'
import { useTokenAllowance } from './useTokenAllowance'
import { useTransactionAdder } from '../../redux/transactions/hooks'
import { useOpenConfirmingModal } from '../../redux/application/hooks'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export function useApproveCallback(
  tokenAddr?: IAddressOrAddressMap,
  amountToApprove?: BigNumber,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const { account, chainId } = useWeb3React()
  const tokenContract = useTokenContract(tokenAddr)
  const addTransaction = useTransactionAdder()
  const openConfirmingModal = useOpenConfirmingModal()

  // check the current approval status
  const currentAllowance = useTokenAllowance(tokenAddr, account ?? undefined, spender)
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (!currentAllowance) return ApprovalState.UNKNOWN

    return currentAllowance.lt(amountToApprove) ? ApprovalState.NOT_APPROVED : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, spender])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }
    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }
    if (!spender) {
      console.error('no spender')
      return
    }

    openConfirmingModal(true)
    const symbol = await tokenContract.symbol()
    tokenContract
      .approve(spender, constants.MaxUint256)
      .then((response: providers.TransactionResponse) => {
        openConfirmingModal(false, response.hash)
        addTransaction(response, {
          summary: 'Approve ' + symbol,
          approval: { tokenAddress: tokenContract.address, spender: spender },
        })
      })
      .catch((error: Error) => {
        openConfirmingModal(false, undefined, error, chainId)
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [approvalState, tokenContract, amountToApprove, spender, addTransaction])

  return [approvalState, approve]
}
