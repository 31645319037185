import { createSlice, createSelector } from '@reduxjs/toolkit'

const networkSlice = createSlice({
  name: 'network',
  initialState: {
    status: null,
  },
  reducers: {
    startChecking: (state) => {
      // checking
    },
    statusChanged: (state, action) => {
      state.status = action.payload
    },
  },
})

export const selectNetwork = (state) => state.network.status

export const { startChecking, statusChanged } = networkSlice.actions

export default networkSlice.reducer
