import { Typography, Grid } from '@material-ui/core'
import { BigNumber } from 'ethers'
import { useRef, useState } from 'react'
import { Trans, t } from '@lingui/macro'

import AmountInput, { IAmountInputRefObj } from '../../../../../common/AmountInput'
import { MainButton } from '../../../../../common/buttons'
import { Divider } from '../../../../../common/Divider'
import { displayBalance } from '../../../../../utils/displayBalance'
import { useSushiChefContract } from '../../../../../hooks/smartContracts/useContract'
import { useOpenConfirmingModal } from '../../../../../redux/application/hooks'
import { useTransactionAdder } from '../../../../../redux/transactions/hooks'
import { PoolFlexCenter } from '../../styled'

interface IStakingPoolDrawerProps {
  poolId: number
  tokenAddr: string
  poolAddr: string
  symbol: string
  availableAmount: BigNumber
  chainId: number
}

const UnstakeDrawerContent = ({
  poolId,
  poolAddr,
  tokenAddr,
  symbol,
  availableAmount,
  chainId,
}: IStakingPoolDrawerProps) => {
  const amountInput = useRef<IAmountInputRefObj>(null)
  const [inputBignumber, setInputBignumber] = useState(BigNumber.from(0))

  const masterChefContract = useSushiChefContract(poolAddr)
  const openConfirmingModal = useOpenConfirmingModal()
  const addTransaction = useTransactionAdder()

  async function handleUnstake() {
    if (masterChefContract && inputBignumber.gt(0)) {
      openConfirmingModal(true)
      try {
        const res = await masterChefContract.withdraw(poolId, inputBignumber)
        openConfirmingModal(false, res.hash)
        addTransaction(res, {
          summary: `Unstake ${displayBalance(inputBignumber)} ${symbol}`,
        })
        amountInput.current?.resetAmount()
      } catch (error) {
        openConfirmingModal(false, undefined, error, chainId)
      }
    }
  }

  return (
    <>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <Trans>Remove {symbol} tokens from the staking pool.</Trans>
      </Typography>
      <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
        <Typography variant="body2">
          <Trans>Staked {symbol}</Trans>
        </Typography>
        <Typography variant="body2">{displayBalance(availableAmount)}</Typography>
      </Grid>
      <Divider margin={16} />
      <AmountInput
        ref={amountInput}
        maxNum={availableAmount}
        amount={inputBignumber}
        setAmount={setInputBignumber}
        textFieldLabel={symbol}
      />
      <Grid container justifyContent="center" style={{ marginTop: 4, justifyContent: 'flex-start' }}>
        <Typography variant="body2">
          <Trans>Set unstake amount</Trans>
        </Typography>
      </Grid>
      <Grid justifyContent="flex-end">
        <PoolFlexCenter style={{ marginTop: 14, justifyContent: `flex-end` }}>
          <MainButton onClick={handleUnstake}>
            <Trans>UNSTAKE</Trans>
          </MainButton>
        </PoolFlexCenter>
      </Grid>
    </>
  )
}

export default UnstakeDrawerContent
