import { ChainId } from './blockchain'
import { USED_CHAIN_ID } from './env'

interface IUrls {
  UNI_GET_NAOS: string
  UNI_GET_NAOS_ETH_LP: string
  SUSHI_GET_NAOS_ETH_LP: string
  CURVE_GET_NUSD_3CRV_LP: string
  PANCAKE_GET_NAOS_BNB_LP: string
  SUSHI_FARM: string
  TELEGRAM: string
  TWITTER: string
  DISCORD: string
  GITBOOK: string
  PANCAKE_SWAP_FARM: string
  ALPACA_FARM: string
  BEEFY_FARM: string
}

export const urls: Partial<Record<ChainId, IUrls>> = {
  [ChainId.MAINNET]: {
    UNI_GET_NAOS:
      'https://app.uniswap.org/#/swap?inputCurrency=0x4a615bb7166210cce20e6642a6f8fb5d4d044496&outputCurrency=ETH',
    UNI_GET_NAOS_ETH_LP: 'https://app.uniswap.org/#/add/v2/0x4a615bb7166210cce20e6642a6f8fb5d4d044496/ETH',
    SUSHI_GET_NAOS_ETH_LP: 'https://app.sushi.com/add/ETH/0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496',
    CURVE_GET_NUSD_3CRV_LP: 'https://crv.to/pool',
    PANCAKE_GET_NAOS_BNB_LP: 'https://pancakeswap.finance/add/BNB/0x758d08864fB6cCE3062667225ca10b8F00496cc2',
    SUSHI_FARM: 'https://app.sushi.com/farm',
    TELEGRAM: 'https://t.me/naos_finance',
    TWITTER: 'https://twitter.com/naos_finance',
    DISCORD: 'https://discord.gg/r9dCza5qWW',
    GITBOOK: 'https://naosfinance.gitbook.io/naos-finance',
    PANCAKE_SWAP_FARM: 'https://pancakeswap.finance/farms',
    ALPACA_FARM: 'https://app.alpacafinance.org/farm',
    BEEFY_FARM: 'https://app.beefy.finance/#/bsc',
  },
  [ChainId.RINKEBY]: {
    UNI_GET_NAOS:
      'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC83E6391DcCF286Ca64E67BAc361fAba69A48A61',
    UNI_GET_NAOS_ETH_LP: 'https://app.uniswap.org/#/add/v2/ETH/0xC83E6391DcCF286Ca64E67BAc361fAba69A48A61',
    SUSHI_GET_NAOS_ETH_LP: 'https://app.sushi.com/add/ETH/0xC83E6391DcCF286Ca64E67BAc361fAba69A48A61',
    CURVE_GET_NUSD_3CRV_LP: 'https://crv.to/pool',
    PANCAKE_GET_NAOS_BNB_LP: 'https://pancakeswap.finance/add/BNB/0x758d08864fB6cCE3062667225ca10b8F00496cc2',
    SUSHI_FARM: 'https://app.sushi.com/farm',
    TELEGRAM: 'https://t.me/naos_finance',
    TWITTER: 'https://twitter.com/naos_finance',
    DISCORD: 'https://discord.gg/r9dCza5qWW',
    GITBOOK: 'https://naosfinance.gitbook.io/naos-finance',
    PANCAKE_SWAP_FARM: 'https://pancakeswap.finance/farms',
    ALPACA_FARM: 'https://app.alpacafinance.org/farm',
    BEEFY_FARM: 'https://app.beefy.finance/#/bsc',
  },
  [ChainId.BSC]: {
    UNI_GET_NAOS:
      'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x758d08864fb6cce3062667225ca10b8f00496cc2',
    UNI_GET_NAOS_ETH_LP: 'https://app.uniswap.org/#/add/v2/ETH/0xC83E6391DcCF286Ca64E67BAc361fAba69A48A61',
    SUSHI_GET_NAOS_ETH_LP: 'https://app.sushi.com/add/ETH/0xC83E6391DcCF286Ca64E67BAc361fAba69A48A61',
    CURVE_GET_NUSD_3CRV_LP: 'https://crv.to/pool',
    PANCAKE_GET_NAOS_BNB_LP: 'https://pancakeswap.finance/add/BNB/0x758d08864fB6cCE3062667225ca10b8F00496cc2',
    SUSHI_FARM: 'https://app.sushi.com/farm',
    TELEGRAM: 'https://t.me/naos_finance',
    TWITTER: 'https://twitter.com/naos_finance',
    DISCORD: 'https://discord.gg/r9dCza5qWW',
    GITBOOK: 'https://naosfinance.gitbook.io/naos-finance',
    PANCAKE_SWAP_FARM: 'https://pancakeswap.finance/farms',
    ALPACA_FARM: 'https://app.alpacafinance.org/farm',
    BEEFY_FARM: 'https://app.beefy.finance/#/bsc',
  },
}

export const ExplorerAPIEndpoint = {
  [ChainId.MAINNET]: 'https://api.etherscan.io/api',
  [ChainId.RINKEBY]: 'https://api-rinkeby.etherscan.io/api',
  [ChainId.BSC]: 'https://api.bscscan.com/api',
}

export default urls[USED_CHAIN_ID]
